export const status = {
  0: '正常',
  1: '已关闭',
}
export const paymentStatus = {
  0: '未付款',
  1: '已付款',
  2: '已退款',
}
export const deliveryStatus = {
  0: '未发货',
  1: '已发货',
  2: '已取消',
  7: '已撤销',
}
export const paymentStatusColor = {
  0: 'pink',
  1: '#87d068',
  2: '#2db7f5',
}
export const deliveryStatusColor = {
  0: '#f50',
  1: '#87d068',
  2: '#2db7f5',
  7: 'purple',
}
export const filterPayType = {
  0: '#87d068',
  1: '#2db7f5',
  2: '#f50',
}
export const statusColor = {
  0: 'blue',
  1: '',
}
export const payType = {
  1: '寄付现结',
  2: '寄付月结',
  3: '到付',
  4: '第三方付',
}

export const printOrign = {
  1: '合同',
  2: '用印',
  3: '检测/鉴定报告',
  4: '检测合同',
  5:'新·合同',
  6:'PDF盖章',
}