
/**
 * 省市区
 */
// export default class Area {
//   /**
//    * 构造器
//    * @param express
//    */
//   constructor(pcaa) {
//     let arr = []
//     const province = pcaa['86']
//     Object.keys(province).map(key=>{
//       arr.push({id:key, text:province[key], pid:'86', index:1});
//       const city = pcaa[key]; // 市
//       Object.keys(city).map(key2=>{
//         arr.push({id:key2, text:city[key2], pid:key, index:2});
//         const qu = pcaa[key2]; // 区
//         if(qu){
//           Object.keys(qu).map(key3=>{
//             arr.push({id:key3, text:qu[key3], pid:key2, index:3});
//           })
//         }
//       })
//     })
//     this.all = arr;
//   }

//   get pca(){
//     return this.all;
//   }

//   getCode(text){
//     if(!text || text.length==0){
//       return ''
//     }
//     for(let item of this.all){
//       if(item.text === text){
//         return item.id;
//       }
//     }
//   }

//   getText(code){
//     if(!code || code.length==0){
//       return ''
//     }
//     let arr = []
//     this.getAreaBycode(code, arr, 3);
//     return arr.join('/')
//   }

//   getRealCode(code){
//     let arr = []
//     this.getPcode(code, arr, 3)
//     return arr;
//   }

//   getPcode(id, arr, index){
//     for(let item of this.all){
//       if(item.id === id && item.index == index){
//         arr.unshift(id)
//         if(item.pid != '86'){
//           this.getPcode(item.pid, arr, --index)
//         }
//       }
//     }
//   }

//   getAreaBycode(code, arr, index){
//     for(let item of this.all){
//       if(item.id === code && item.index == index){
//         arr.unshift(item.text);
//         if(item.pid != '86'){
//           this.getAreaBycode(item.pid, arr, --index)
//         }

//       }
//     }
//   }

// }
export const filterArea = (dataArr) => {
    let arr = []
    const province = dataArr['86']
    Object.keys(province).map((key, index) => {
        arr.push({ value: province[key], label: province[key], pid: '86', index: key });
        const city = dataArr[key]; // 市
        let children = []
        Object.keys(city).map((key2, childrenIndex) => {
            if (city[key2] == '市辖区') {
                children.push({ value: province[key], label: province[key], pid: key, index: key2 });
            } else {
                children.push({ value: city[key2], label: city[key2], pid: key, index: key2 });
            }
            const qu = dataArr[key2]; // 区
            if (qu) {
                let childrenArr = []
                Object.keys(qu).map(key3 => {
                    if (qu[key3] != '市辖区') {
                        childrenArr.push({ value: qu[key3], label: qu[key3], pid: key2, index: key3 });
                    }
                })
                children[childrenIndex].children = childrenArr
            }
        })
        arr[index].children = children
    })
    return arr
}
export const _area = [
    {
        "value": "北京市",
        "label": "北京市",
        "pid": "86",
        "index": "110000",
        "children": [
            {
                "value": "北京市",
                "label": "北京市",
                "pid": "110000",
                "index": "110100",
                "children": [
                    {
                        "value": "东城区",
                        "label": "东城区",
                        "pid": "110100",
                        "index": "110101"
                    },
                    {
                        "value": "西城区",
                        "label": "西城区",
                        "pid": "110100",
                        "index": "110102"
                    },
                    {
                        "value": "朝阳区",
                        "label": "朝阳区",
                        "pid": "110100",
                        "index": "110105"
                    },
                    {
                        "value": "丰台区",
                        "label": "丰台区",
                        "pid": "110100",
                        "index": "110106"
                    },
                    {
                        "value": "石景山区",
                        "label": "石景山区",
                        "pid": "110100",
                        "index": "110107"
                    },
                    {
                        "value": "海淀区",
                        "label": "海淀区",
                        "pid": "110100",
                        "index": "110108"
                    },
                    {
                        "value": "门头沟区",
                        "label": "门头沟区",
                        "pid": "110100",
                        "index": "110109"
                    },
                    {
                        "value": "房山区",
                        "label": "房山区",
                        "pid": "110100",
                        "index": "110111"
                    },
                    {
                        "value": "通州区",
                        "label": "通州区",
                        "pid": "110100",
                        "index": "110112"
                    },
                    {
                        "value": "顺义区",
                        "label": "顺义区",
                        "pid": "110100",
                        "index": "110113"
                    },
                    {
                        "value": "昌平区",
                        "label": "昌平区",
                        "pid": "110100",
                        "index": "110114"
                    },
                    {
                        "value": "大兴区",
                        "label": "大兴区",
                        "pid": "110100",
                        "index": "110115"
                    },
                    {
                        "value": "怀柔区",
                        "label": "怀柔区",
                        "pid": "110100",
                        "index": "110116"
                    },
                    {
                        "value": "平谷区",
                        "label": "平谷区",
                        "pid": "110100",
                        "index": "110117"
                    },
                    {
                        "value": "密云区",
                        "label": "密云区",
                        "pid": "110100",
                        "index": "110118"
                    },
                    {
                        "value": "延庆区",
                        "label": "延庆区",
                        "pid": "110100",
                        "index": "110119"
                    }
                ]
            }
        ]
    },
    {
        "value": "天津市",
        "label": "天津市",
        "pid": "86",
        "index": "120000",
        "children": [
            {
                "value": "天津市",
                "label": "天津市",
                "pid": "120000",
                "index": "120100",
                "children": [
                    {
                        "value": "和平区",
                        "label": "和平区",
                        "pid": "120100",
                        "index": "120101"
                    },
                    {
                        "value": "河东区",
                        "label": "河东区",
                        "pid": "120100",
                        "index": "120102"
                    },
                    {
                        "value": "河西区",
                        "label": "河西区",
                        "pid": "120100",
                        "index": "120103"
                    },
                    {
                        "value": "南开区",
                        "label": "南开区",
                        "pid": "120100",
                        "index": "120104"
                    },
                    {
                        "value": "河北区",
                        "label": "河北区",
                        "pid": "120100",
                        "index": "120105"
                    },
                    {
                        "value": "红桥区",
                        "label": "红桥区",
                        "pid": "120100",
                        "index": "120106"
                    },
                    {
                        "value": "东丽区",
                        "label": "东丽区",
                        "pid": "120100",
                        "index": "120110"
                    },
                    {
                        "value": "西青区",
                        "label": "西青区",
                        "pid": "120100",
                        "index": "120111"
                    },
                    {
                        "value": "津南区",
                        "label": "津南区",
                        "pid": "120100",
                        "index": "120112"
                    },
                    {
                        "value": "北辰区",
                        "label": "北辰区",
                        "pid": "120100",
                        "index": "120113"
                    },
                    {
                        "value": "武清区",
                        "label": "武清区",
                        "pid": "120100",
                        "index": "120114"
                    },
                    {
                        "value": "宝坻区",
                        "label": "宝坻区",
                        "pid": "120100",
                        "index": "120115"
                    },
                    {
                        "value": "滨海新区",
                        "label": "滨海新区",
                        "pid": "120100",
                        "index": "120116"
                    },
                    {
                        "value": "宁河区",
                        "label": "宁河区",
                        "pid": "120100",
                        "index": "120117"
                    },
                    {
                        "value": "静海区",
                        "label": "静海区",
                        "pid": "120100",
                        "index": "120118"
                    },
                    {
                        "value": "蓟州区",
                        "label": "蓟州区",
                        "pid": "120100",
                        "index": "120119"
                    }
                ]
            }
        ]
    },
    {
        "value": "河北省",
        "label": "河北省",
        "pid": "86",
        "index": "130000",
        "children": [
            {
                "value": "石家庄市",
                "label": "石家庄市",
                "pid": "130000",
                "index": "130100",
                "children": [
                    {
                        "value": "长安区",
                        "label": "长安区",
                        "pid": "130100",
                        "index": "130102"
                    },
                    {
                        "value": "桥西区",
                        "label": "桥西区",
                        "pid": "130100",
                        "index": "130104"
                    },
                    {
                        "value": "新华区",
                        "label": "新华区",
                        "pid": "130100",
                        "index": "130105"
                    },
                    {
                        "value": "井陉矿区",
                        "label": "井陉矿区",
                        "pid": "130100",
                        "index": "130107"
                    },
                    {
                        "value": "裕华区",
                        "label": "裕华区",
                        "pid": "130100",
                        "index": "130108"
                    },
                    {
                        "value": "藁城区",
                        "label": "藁城区",
                        "pid": "130100",
                        "index": "130109"
                    },
                    {
                        "value": "鹿泉区",
                        "label": "鹿泉区",
                        "pid": "130100",
                        "index": "130110"
                    },
                    {
                        "value": "栾城区",
                        "label": "栾城区",
                        "pid": "130100",
                        "index": "130111"
                    },
                    {
                        "value": "井陉县",
                        "label": "井陉县",
                        "pid": "130100",
                        "index": "130121"
                    },
                    {
                        "value": "正定县",
                        "label": "正定县",
                        "pid": "130100",
                        "index": "130123"
                    },
                    {
                        "value": "行唐县",
                        "label": "行唐县",
                        "pid": "130100",
                        "index": "130125"
                    },
                    {
                        "value": "灵寿县",
                        "label": "灵寿县",
                        "pid": "130100",
                        "index": "130126"
                    },
                    {
                        "value": "高邑县",
                        "label": "高邑县",
                        "pid": "130100",
                        "index": "130127"
                    },
                    {
                        "value": "深泽县",
                        "label": "深泽县",
                        "pid": "130100",
                        "index": "130128"
                    },
                    {
                        "value": "赞皇县",
                        "label": "赞皇县",
                        "pid": "130100",
                        "index": "130129"
                    },
                    {
                        "value": "无极县",
                        "label": "无极县",
                        "pid": "130100",
                        "index": "130130"
                    },
                    {
                        "value": "平山县",
                        "label": "平山县",
                        "pid": "130100",
                        "index": "130131"
                    },
                    {
                        "value": "元氏县",
                        "label": "元氏县",
                        "pid": "130100",
                        "index": "130132"
                    },
                    {
                        "value": "赵县",
                        "label": "赵县",
                        "pid": "130100",
                        "index": "130133"
                    },
                    {
                        "value": "石家庄高新技术产业开发区",
                        "label": "石家庄高新技术产业开发区",
                        "pid": "130100",
                        "index": "130171"
                    },
                    {
                        "value": "石家庄循环化工园区",
                        "label": "石家庄循环化工园区",
                        "pid": "130100",
                        "index": "130172"
                    },
                    {
                        "value": "辛集市",
                        "label": "辛集市",
                        "pid": "130100",
                        "index": "130181"
                    },
                    {
                        "value": "晋州市",
                        "label": "晋州市",
                        "pid": "130100",
                        "index": "130183"
                    },
                    {
                        "value": "新乐市",
                        "label": "新乐市",
                        "pid": "130100",
                        "index": "130184"
                    }
                ]
            },
            {
                "value": "唐山市",
                "label": "唐山市",
                "pid": "130000",
                "index": "130200",
                "children": [
                    {
                        "value": "路南区",
                        "label": "路南区",
                        "pid": "130200",
                        "index": "130202"
                    },
                    {
                        "value": "路北区",
                        "label": "路北区",
                        "pid": "130200",
                        "index": "130203"
                    },
                    {
                        "value": "古冶区",
                        "label": "古冶区",
                        "pid": "130200",
                        "index": "130204"
                    },
                    {
                        "value": "开平区",
                        "label": "开平区",
                        "pid": "130200",
                        "index": "130205"
                    },
                    {
                        "value": "丰南区",
                        "label": "丰南区",
                        "pid": "130200",
                        "index": "130207"
                    },
                    {
                        "value": "丰润区",
                        "label": "丰润区",
                        "pid": "130200",
                        "index": "130208"
                    },
                    {
                        "value": "曹妃甸区",
                        "label": "曹妃甸区",
                        "pid": "130200",
                        "index": "130209"
                    },
                    {
                        "value": "滦南县",
                        "label": "滦南县",
                        "pid": "130200",
                        "index": "130224"
                    },
                    {
                        "value": "乐亭县",
                        "label": "乐亭县",
                        "pid": "130200",
                        "index": "130225"
                    },
                    {
                        "value": "迁西县",
                        "label": "迁西县",
                        "pid": "130200",
                        "index": "130227"
                    },
                    {
                        "value": "玉田县",
                        "label": "玉田县",
                        "pid": "130200",
                        "index": "130229"
                    },
                    {
                        "value": "河北唐山芦台经济开发区",
                        "label": "河北唐山芦台经济开发区",
                        "pid": "130200",
                        "index": "130271"
                    },
                    {
                        "value": "唐山市汉沽管理区",
                        "label": "唐山市汉沽管理区",
                        "pid": "130200",
                        "index": "130272"
                    },
                    {
                        "value": "唐山高新技术产业开发区",
                        "label": "唐山高新技术产业开发区",
                        "pid": "130200",
                        "index": "130273"
                    },
                    {
                        "value": "河北唐山海港经济开发区",
                        "label": "河北唐山海港经济开发区",
                        "pid": "130200",
                        "index": "130274"
                    },
                    {
                        "value": "遵化市",
                        "label": "遵化市",
                        "pid": "130200",
                        "index": "130281"
                    },
                    {
                        "value": "迁安市",
                        "label": "迁安市",
                        "pid": "130200",
                        "index": "130283"
                    },
                    {
                        "value": "滦州市",
                        "label": "滦州市",
                        "pid": "130200",
                        "index": "130284"
                    }
                ]
            },
            {
                "value": "秦皇岛市",
                "label": "秦皇岛市",
                "pid": "130000",
                "index": "130300",
                "children": [
                    {
                        "value": "海港区",
                        "label": "海港区",
                        "pid": "130300",
                        "index": "130302"
                    },
                    {
                        "value": "山海关区",
                        "label": "山海关区",
                        "pid": "130300",
                        "index": "130303"
                    },
                    {
                        "value": "北戴河区",
                        "label": "北戴河区",
                        "pid": "130300",
                        "index": "130304"
                    },
                    {
                        "value": "抚宁区",
                        "label": "抚宁区",
                        "pid": "130300",
                        "index": "130306"
                    },
                    {
                        "value": "青龙满族自治县",
                        "label": "青龙满族自治县",
                        "pid": "130300",
                        "index": "130321"
                    },
                    {
                        "value": "昌黎县",
                        "label": "昌黎县",
                        "pid": "130300",
                        "index": "130322"
                    },
                    {
                        "value": "卢龙县",
                        "label": "卢龙县",
                        "pid": "130300",
                        "index": "130324"
                    },
                    {
                        "value": "秦皇岛市经济技术开发区",
                        "label": "秦皇岛市经济技术开发区",
                        "pid": "130300",
                        "index": "130371"
                    },
                    {
                        "value": "北戴河新区",
                        "label": "北戴河新区",
                        "pid": "130300",
                        "index": "130372"
                    }
                ]
            },
            {
                "value": "邯郸市",
                "label": "邯郸市",
                "pid": "130000",
                "index": "130400",
                "children": [
                    {
                        "value": "邯山区",
                        "label": "邯山区",
                        "pid": "130400",
                        "index": "130402"
                    },
                    {
                        "value": "丛台区",
                        "label": "丛台区",
                        "pid": "130400",
                        "index": "130403"
                    },
                    {
                        "value": "复兴区",
                        "label": "复兴区",
                        "pid": "130400",
                        "index": "130404"
                    },
                    {
                        "value": "峰峰矿区",
                        "label": "峰峰矿区",
                        "pid": "130400",
                        "index": "130406"
                    },
                    {
                        "value": "肥乡区",
                        "label": "肥乡区",
                        "pid": "130400",
                        "index": "130407"
                    },
                    {
                        "value": "永年区",
                        "label": "永年区",
                        "pid": "130400",
                        "index": "130408"
                    },
                    {
                        "value": "临漳县",
                        "label": "临漳县",
                        "pid": "130400",
                        "index": "130423"
                    },
                    {
                        "value": "成安县",
                        "label": "成安县",
                        "pid": "130400",
                        "index": "130424"
                    },
                    {
                        "value": "大名县",
                        "label": "大名县",
                        "pid": "130400",
                        "index": "130425"
                    },
                    {
                        "value": "涉县",
                        "label": "涉县",
                        "pid": "130400",
                        "index": "130426"
                    },
                    {
                        "value": "磁县",
                        "label": "磁县",
                        "pid": "130400",
                        "index": "130427"
                    },
                    {
                        "value": "邱县",
                        "label": "邱县",
                        "pid": "130400",
                        "index": "130430"
                    },
                    {
                        "value": "鸡泽县",
                        "label": "鸡泽县",
                        "pid": "130400",
                        "index": "130431"
                    },
                    {
                        "value": "广平县",
                        "label": "广平县",
                        "pid": "130400",
                        "index": "130432"
                    },
                    {
                        "value": "馆陶县",
                        "label": "馆陶县",
                        "pid": "130400",
                        "index": "130433"
                    },
                    {
                        "value": "魏县",
                        "label": "魏县",
                        "pid": "130400",
                        "index": "130434"
                    },
                    {
                        "value": "曲周县",
                        "label": "曲周县",
                        "pid": "130400",
                        "index": "130435"
                    },
                    {
                        "value": "邯郸经济技术开发区",
                        "label": "邯郸经济技术开发区",
                        "pid": "130400",
                        "index": "130471"
                    },
                    {
                        "value": "邯郸冀南新区",
                        "label": "邯郸冀南新区",
                        "pid": "130400",
                        "index": "130473"
                    },
                    {
                        "value": "武安市",
                        "label": "武安市",
                        "pid": "130400",
                        "index": "130481"
                    }
                ]
            },
            {
                "value": "邢台市",
                "label": "邢台市",
                "pid": "130000",
                "index": "130500",
                "children": [
                    {
                        "value": "桥东区",
                        "label": "桥东区",
                        "pid": "130500",
                        "index": "130502"
                    },
                    {
                        "value": "桥西区",
                        "label": "桥西区",
                        "pid": "130500",
                        "index": "130503"
                    },
                    {
                        "value": "邢台县",
                        "label": "邢台县",
                        "pid": "130500",
                        "index": "130521"
                    },
                    {
                        "value": "临城县",
                        "label": "临城县",
                        "pid": "130500",
                        "index": "130522"
                    },
                    {
                        "value": "内丘县",
                        "label": "内丘县",
                        "pid": "130500",
                        "index": "130523"
                    },
                    {
                        "value": "柏乡县",
                        "label": "柏乡县",
                        "pid": "130500",
                        "index": "130524"
                    },
                    {
                        "value": "隆尧县",
                        "label": "隆尧县",
                        "pid": "130500",
                        "index": "130525"
                    },
                    {
                        "value": "任县",
                        "label": "任县",
                        "pid": "130500",
                        "index": "130526"
                    },
                    {
                        "value": "南和县",
                        "label": "南和县",
                        "pid": "130500",
                        "index": "130527"
                    },
                    {
                        "value": "宁晋县",
                        "label": "宁晋县",
                        "pid": "130500",
                        "index": "130528"
                    },
                    {
                        "value": "巨鹿县",
                        "label": "巨鹿县",
                        "pid": "130500",
                        "index": "130529"
                    },
                    {
                        "value": "新河县",
                        "label": "新河县",
                        "pid": "130500",
                        "index": "130530"
                    },
                    {
                        "value": "广宗县",
                        "label": "广宗县",
                        "pid": "130500",
                        "index": "130531"
                    },
                    {
                        "value": "平乡县",
                        "label": "平乡县",
                        "pid": "130500",
                        "index": "130532"
                    },
                    {
                        "value": "威县",
                        "label": "威县",
                        "pid": "130500",
                        "index": "130533"
                    },
                    {
                        "value": "清河县",
                        "label": "清河县",
                        "pid": "130500",
                        "index": "130534"
                    },
                    {
                        "value": "临西县",
                        "label": "临西县",
                        "pid": "130500",
                        "index": "130535"
                    },
                    {
                        "value": "河北邢台经济开发区",
                        "label": "河北邢台经济开发区",
                        "pid": "130500",
                        "index": "130571"
                    },
                    {
                        "value": "南宫市",
                        "label": "南宫市",
                        "pid": "130500",
                        "index": "130581"
                    },
                    {
                        "value": "沙河市",
                        "label": "沙河市",
                        "pid": "130500",
                        "index": "130582"
                    }
                ]
            },
            {
                "value": "保定市",
                "label": "保定市",
                "pid": "130000",
                "index": "130600",
                "children": [
                    {
                        "value": "竞秀区",
                        "label": "竞秀区",
                        "pid": "130600",
                        "index": "130602"
                    },
                    {
                        "value": "莲池区",
                        "label": "莲池区",
                        "pid": "130600",
                        "index": "130606"
                    },
                    {
                        "value": "满城区",
                        "label": "满城区",
                        "pid": "130600",
                        "index": "130607"
                    },
                    {
                        "value": "清苑区",
                        "label": "清苑区",
                        "pid": "130600",
                        "index": "130608"
                    },
                    {
                        "value": "徐水区",
                        "label": "徐水区",
                        "pid": "130600",
                        "index": "130609"
                    },
                    {
                        "value": "涞水县",
                        "label": "涞水县",
                        "pid": "130600",
                        "index": "130623"
                    },
                    {
                        "value": "阜平县",
                        "label": "阜平县",
                        "pid": "130600",
                        "index": "130624"
                    },
                    {
                        "value": "定兴县",
                        "label": "定兴县",
                        "pid": "130600",
                        "index": "130626"
                    },
                    {
                        "value": "唐县",
                        "label": "唐县",
                        "pid": "130600",
                        "index": "130627"
                    },
                    {
                        "value": "高阳县",
                        "label": "高阳县",
                        "pid": "130600",
                        "index": "130628"
                    },
                    {
                        "value": "容城县",
                        "label": "容城县",
                        "pid": "130600",
                        "index": "130629"
                    },
                    {
                        "value": "涞源县",
                        "label": "涞源县",
                        "pid": "130600",
                        "index": "130630"
                    },
                    {
                        "value": "望都县",
                        "label": "望都县",
                        "pid": "130600",
                        "index": "130631"
                    },
                    {
                        "value": "安新县",
                        "label": "安新县",
                        "pid": "130600",
                        "index": "130632"
                    },
                    {
                        "value": "易县",
                        "label": "易县",
                        "pid": "130600",
                        "index": "130633"
                    },
                    {
                        "value": "曲阳县",
                        "label": "曲阳县",
                        "pid": "130600",
                        "index": "130634"
                    },
                    {
                        "value": "蠡县",
                        "label": "蠡县",
                        "pid": "130600",
                        "index": "130635"
                    },
                    {
                        "value": "顺平县",
                        "label": "顺平县",
                        "pid": "130600",
                        "index": "130636"
                    },
                    {
                        "value": "博野县",
                        "label": "博野县",
                        "pid": "130600",
                        "index": "130637"
                    },
                    {
                        "value": "雄县",
                        "label": "雄县",
                        "pid": "130600",
                        "index": "130638"
                    },
                    {
                        "value": "保定高新技术产业开发区",
                        "label": "保定高新技术产业开发区",
                        "pid": "130600",
                        "index": "130671"
                    },
                    {
                        "value": "保定白沟新城",
                        "label": "保定白沟新城",
                        "pid": "130600",
                        "index": "130672"
                    },
                    {
                        "value": "涿州市",
                        "label": "涿州市",
                        "pid": "130600",
                        "index": "130681"
                    },
                    {
                        "value": "定州市",
                        "label": "定州市",
                        "pid": "130600",
                        "index": "130682"
                    },
                    {
                        "value": "安国市",
                        "label": "安国市",
                        "pid": "130600",
                        "index": "130683"
                    },
                    {
                        "value": "高碑店市",
                        "label": "高碑店市",
                        "pid": "130600",
                        "index": "130684"
                    }
                ]
            },
            {
                "value": "张家口市",
                "label": "张家口市",
                "pid": "130000",
                "index": "130700",
                "children": [
                    {
                        "value": "桥东区",
                        "label": "桥东区",
                        "pid": "130700",
                        "index": "130702"
                    },
                    {
                        "value": "桥西区",
                        "label": "桥西区",
                        "pid": "130700",
                        "index": "130703"
                    },
                    {
                        "value": "宣化区",
                        "label": "宣化区",
                        "pid": "130700",
                        "index": "130705"
                    },
                    {
                        "value": "下花园区",
                        "label": "下花园区",
                        "pid": "130700",
                        "index": "130706"
                    },
                    {
                        "value": "万全区",
                        "label": "万全区",
                        "pid": "130700",
                        "index": "130708"
                    },
                    {
                        "value": "崇礼区",
                        "label": "崇礼区",
                        "pid": "130700",
                        "index": "130709"
                    },
                    {
                        "value": "张北县",
                        "label": "张北县",
                        "pid": "130700",
                        "index": "130722"
                    },
                    {
                        "value": "康保县",
                        "label": "康保县",
                        "pid": "130700",
                        "index": "130723"
                    },
                    {
                        "value": "沽源县",
                        "label": "沽源县",
                        "pid": "130700",
                        "index": "130724"
                    },
                    {
                        "value": "尚义县",
                        "label": "尚义县",
                        "pid": "130700",
                        "index": "130725"
                    },
                    {
                        "value": "蔚县",
                        "label": "蔚县",
                        "pid": "130700",
                        "index": "130726"
                    },
                    {
                        "value": "阳原县",
                        "label": "阳原县",
                        "pid": "130700",
                        "index": "130727"
                    },
                    {
                        "value": "怀安县",
                        "label": "怀安县",
                        "pid": "130700",
                        "index": "130728"
                    },
                    {
                        "value": "怀来县",
                        "label": "怀来县",
                        "pid": "130700",
                        "index": "130730"
                    },
                    {
                        "value": "涿鹿县",
                        "label": "涿鹿县",
                        "pid": "130700",
                        "index": "130731"
                    },
                    {
                        "value": "赤城县",
                        "label": "赤城县",
                        "pid": "130700",
                        "index": "130732"
                    },
                    {
                        "value": "张家口经济开发区",
                        "label": "张家口经济开发区",
                        "pid": "130700",
                        "index": "130771"
                    },
                    {
                        "value": "张家口市察北管理区",
                        "label": "张家口市察北管理区",
                        "pid": "130700",
                        "index": "130772"
                    },
                    {
                        "value": "张家口市塞北管理区",
                        "label": "张家口市塞北管理区",
                        "pid": "130700",
                        "index": "130773"
                    }
                ]
            },
            {
                "value": "承德市",
                "label": "承德市",
                "pid": "130000",
                "index": "130800",
                "children": [
                    {
                        "value": "双桥区",
                        "label": "双桥区",
                        "pid": "130800",
                        "index": "130802"
                    },
                    {
                        "value": "双滦区",
                        "label": "双滦区",
                        "pid": "130800",
                        "index": "130803"
                    },
                    {
                        "value": "鹰手营子矿区",
                        "label": "鹰手营子矿区",
                        "pid": "130800",
                        "index": "130804"
                    },
                    {
                        "value": "承德县",
                        "label": "承德县",
                        "pid": "130800",
                        "index": "130821"
                    },
                    {
                        "value": "兴隆县",
                        "label": "兴隆县",
                        "pid": "130800",
                        "index": "130822"
                    },
                    {
                        "value": "滦平县",
                        "label": "滦平县",
                        "pid": "130800",
                        "index": "130824"
                    },
                    {
                        "value": "隆化县",
                        "label": "隆化县",
                        "pid": "130800",
                        "index": "130825"
                    },
                    {
                        "value": "丰宁满族自治县",
                        "label": "丰宁满族自治县",
                        "pid": "130800",
                        "index": "130826"
                    },
                    {
                        "value": "宽城满族自治县",
                        "label": "宽城满族自治县",
                        "pid": "130800",
                        "index": "130827"
                    },
                    {
                        "value": "围场满族蒙古族自治县",
                        "label": "围场满族蒙古族自治县",
                        "pid": "130800",
                        "index": "130828"
                    },
                    {
                        "value": "承德高新技术产业开发区",
                        "label": "承德高新技术产业开发区",
                        "pid": "130800",
                        "index": "130871"
                    },
                    {
                        "value": "平泉市",
                        "label": "平泉市",
                        "pid": "130800",
                        "index": "130881"
                    }
                ]
            },
            {
                "value": "沧州市",
                "label": "沧州市",
                "pid": "130000",
                "index": "130900",
                "children": [
                    {
                        "value": "新华区",
                        "label": "新华区",
                        "pid": "130900",
                        "index": "130902"
                    },
                    {
                        "value": "运河区",
                        "label": "运河区",
                        "pid": "130900",
                        "index": "130903"
                    },
                    {
                        "value": "沧县",
                        "label": "沧县",
                        "pid": "130900",
                        "index": "130921"
                    },
                    {
                        "value": "青县",
                        "label": "青县",
                        "pid": "130900",
                        "index": "130922"
                    },
                    {
                        "value": "东光县",
                        "label": "东光县",
                        "pid": "130900",
                        "index": "130923"
                    },
                    {
                        "value": "海兴县",
                        "label": "海兴县",
                        "pid": "130900",
                        "index": "130924"
                    },
                    {
                        "value": "盐山县",
                        "label": "盐山县",
                        "pid": "130900",
                        "index": "130925"
                    },
                    {
                        "value": "肃宁县",
                        "label": "肃宁县",
                        "pid": "130900",
                        "index": "130926"
                    },
                    {
                        "value": "南皮县",
                        "label": "南皮县",
                        "pid": "130900",
                        "index": "130927"
                    },
                    {
                        "value": "吴桥县",
                        "label": "吴桥县",
                        "pid": "130900",
                        "index": "130928"
                    },
                    {
                        "value": "献县",
                        "label": "献县",
                        "pid": "130900",
                        "index": "130929"
                    },
                    {
                        "value": "孟村回族自治县",
                        "label": "孟村回族自治县",
                        "pid": "130900",
                        "index": "130930"
                    },
                    {
                        "value": "河北沧州经济开发区",
                        "label": "河北沧州经济开发区",
                        "pid": "130900",
                        "index": "130971"
                    },
                    {
                        "value": "沧州高新技术产业开发区",
                        "label": "沧州高新技术产业开发区",
                        "pid": "130900",
                        "index": "130972"
                    },
                    {
                        "value": "沧州渤海新区",
                        "label": "沧州渤海新区",
                        "pid": "130900",
                        "index": "130973"
                    },
                    {
                        "value": "泊头市",
                        "label": "泊头市",
                        "pid": "130900",
                        "index": "130981"
                    },
                    {
                        "value": "任丘市",
                        "label": "任丘市",
                        "pid": "130900",
                        "index": "130982"
                    },
                    {
                        "value": "黄骅市",
                        "label": "黄骅市",
                        "pid": "130900",
                        "index": "130983"
                    },
                    {
                        "value": "河间市",
                        "label": "河间市",
                        "pid": "130900",
                        "index": "130984"
                    }
                ]
            },
            {
                "value": "廊坊市",
                "label": "廊坊市",
                "pid": "130000",
                "index": "131000",
                "children": [
                    {
                        "value": "安次区",
                        "label": "安次区",
                        "pid": "131000",
                        "index": "131002"
                    },
                    {
                        "value": "广阳区",
                        "label": "广阳区",
                        "pid": "131000",
                        "index": "131003"
                    },
                    {
                        "value": "固安县",
                        "label": "固安县",
                        "pid": "131000",
                        "index": "131022"
                    },
                    {
                        "value": "永清县",
                        "label": "永清县",
                        "pid": "131000",
                        "index": "131023"
                    },
                    {
                        "value": "香河县",
                        "label": "香河县",
                        "pid": "131000",
                        "index": "131024"
                    },
                    {
                        "value": "大城县",
                        "label": "大城县",
                        "pid": "131000",
                        "index": "131025"
                    },
                    {
                        "value": "文安县",
                        "label": "文安县",
                        "pid": "131000",
                        "index": "131026"
                    },
                    {
                        "value": "大厂回族自治县",
                        "label": "大厂回族自治县",
                        "pid": "131000",
                        "index": "131028"
                    },
                    {
                        "value": "廊坊经济技术开发区",
                        "label": "廊坊经济技术开发区",
                        "pid": "131000",
                        "index": "131071"
                    },
                    {
                        "value": "霸州市",
                        "label": "霸州市",
                        "pid": "131000",
                        "index": "131081"
                    },
                    {
                        "value": "三河市",
                        "label": "三河市",
                        "pid": "131000",
                        "index": "131082"
                    }
                ]
            },
            {
                "value": "衡水市",
                "label": "衡水市",
                "pid": "130000",
                "index": "131100",
                "children": [
                    {
                        "value": "桃城区",
                        "label": "桃城区",
                        "pid": "131100",
                        "index": "131102"
                    },
                    {
                        "value": "冀州区",
                        "label": "冀州区",
                        "pid": "131100",
                        "index": "131103"
                    },
                    {
                        "value": "枣强县",
                        "label": "枣强县",
                        "pid": "131100",
                        "index": "131121"
                    },
                    {
                        "value": "武邑县",
                        "label": "武邑县",
                        "pid": "131100",
                        "index": "131122"
                    },
                    {
                        "value": "武强县",
                        "label": "武强县",
                        "pid": "131100",
                        "index": "131123"
                    },
                    {
                        "value": "饶阳县",
                        "label": "饶阳县",
                        "pid": "131100",
                        "index": "131124"
                    },
                    {
                        "value": "安平县",
                        "label": "安平县",
                        "pid": "131100",
                        "index": "131125"
                    },
                    {
                        "value": "故城县",
                        "label": "故城县",
                        "pid": "131100",
                        "index": "131126"
                    },
                    {
                        "value": "景县",
                        "label": "景县",
                        "pid": "131100",
                        "index": "131127"
                    },
                    {
                        "value": "阜城县",
                        "label": "阜城县",
                        "pid": "131100",
                        "index": "131128"
                    },
                    {
                        "value": "河北衡水高新技术产业开发区",
                        "label": "河北衡水高新技术产业开发区",
                        "pid": "131100",
                        "index": "131171"
                    },
                    {
                        "value": "衡水滨湖新区",
                        "label": "衡水滨湖新区",
                        "pid": "131100",
                        "index": "131172"
                    },
                    {
                        "value": "深州市",
                        "label": "深州市",
                        "pid": "131100",
                        "index": "131182"
                    }
                ]
            }
        ]
    },
    {
        "value": "山西省",
        "label": "山西省",
        "pid": "86",
        "index": "140000",
        "children": [
            {
                "value": "太原市",
                "label": "太原市",
                "pid": "140000",
                "index": "140100",
                "children": [
                    {
                        "value": "小店区",
                        "label": "小店区",
                        "pid": "140100",
                        "index": "140105"
                    },
                    {
                        "value": "迎泽区",
                        "label": "迎泽区",
                        "pid": "140100",
                        "index": "140106"
                    },
                    {
                        "value": "杏花岭区",
                        "label": "杏花岭区",
                        "pid": "140100",
                        "index": "140107"
                    },
                    {
                        "value": "尖草坪区",
                        "label": "尖草坪区",
                        "pid": "140100",
                        "index": "140108"
                    },
                    {
                        "value": "万柏林区",
                        "label": "万柏林区",
                        "pid": "140100",
                        "index": "140109"
                    },
                    {
                        "value": "晋源区",
                        "label": "晋源区",
                        "pid": "140100",
                        "index": "140110"
                    },
                    {
                        "value": "清徐县",
                        "label": "清徐县",
                        "pid": "140100",
                        "index": "140121"
                    },
                    {
                        "value": "阳曲县",
                        "label": "阳曲县",
                        "pid": "140100",
                        "index": "140122"
                    },
                    {
                        "value": "娄烦县",
                        "label": "娄烦县",
                        "pid": "140100",
                        "index": "140123"
                    },
                    {
                        "value": "山西转型综合改革示范区",
                        "label": "山西转型综合改革示范区",
                        "pid": "140100",
                        "index": "140171"
                    },
                    {
                        "value": "古交市",
                        "label": "古交市",
                        "pid": "140100",
                        "index": "140181"
                    }
                ]
            },
            {
                "value": "大同市",
                "label": "大同市",
                "pid": "140000",
                "index": "140200",
                "children": [
                    {
                        "value": "新荣区",
                        "label": "新荣区",
                        "pid": "140200",
                        "index": "140212"
                    },
                    {
                        "value": "平城区",
                        "label": "平城区",
                        "pid": "140200",
                        "index": "140213"
                    },
                    {
                        "value": "云冈区",
                        "label": "云冈区",
                        "pid": "140200",
                        "index": "140214"
                    },
                    {
                        "value": "云州区",
                        "label": "云州区",
                        "pid": "140200",
                        "index": "140215"
                    },
                    {
                        "value": "阳高县",
                        "label": "阳高县",
                        "pid": "140200",
                        "index": "140221"
                    },
                    {
                        "value": "天镇县",
                        "label": "天镇县",
                        "pid": "140200",
                        "index": "140222"
                    },
                    {
                        "value": "广灵县",
                        "label": "广灵县",
                        "pid": "140200",
                        "index": "140223"
                    },
                    {
                        "value": "灵丘县",
                        "label": "灵丘县",
                        "pid": "140200",
                        "index": "140224"
                    },
                    {
                        "value": "浑源县",
                        "label": "浑源县",
                        "pid": "140200",
                        "index": "140225"
                    },
                    {
                        "value": "左云县",
                        "label": "左云县",
                        "pid": "140200",
                        "index": "140226"
                    },
                    {
                        "value": "山西大同经济开发区",
                        "label": "山西大同经济开发区",
                        "pid": "140200",
                        "index": "140271"
                    }
                ]
            },
            {
                "value": "阳泉市",
                "label": "阳泉市",
                "pid": "140000",
                "index": "140300",
                "children": [
                    {
                        "value": "城区",
                        "label": "城区",
                        "pid": "140300",
                        "index": "140302"
                    },
                    {
                        "value": "矿区",
                        "label": "矿区",
                        "pid": "140300",
                        "index": "140303"
                    },
                    {
                        "value": "郊区",
                        "label": "郊区",
                        "pid": "140300",
                        "index": "140311"
                    },
                    {
                        "value": "平定县",
                        "label": "平定县",
                        "pid": "140300",
                        "index": "140321"
                    },
                    {
                        "value": "盂县",
                        "label": "盂县",
                        "pid": "140300",
                        "index": "140322"
                    }
                ]
            },
            {
                "value": "长治市",
                "label": "长治市",
                "pid": "140000",
                "index": "140400",
                "children": [
                    {
                        "value": "潞州区",
                        "label": "潞州区",
                        "pid": "140400",
                        "index": "140403"
                    },
                    {
                        "value": "上党区",
                        "label": "上党区",
                        "pid": "140400",
                        "index": "140404"
                    },
                    {
                        "value": "屯留区",
                        "label": "屯留区",
                        "pid": "140400",
                        "index": "140405"
                    },
                    {
                        "value": "潞城区",
                        "label": "潞城区",
                        "pid": "140400",
                        "index": "140406"
                    },
                    {
                        "value": "襄垣县",
                        "label": "襄垣县",
                        "pid": "140400",
                        "index": "140423"
                    },
                    {
                        "value": "平顺县",
                        "label": "平顺县",
                        "pid": "140400",
                        "index": "140425"
                    },
                    {
                        "value": "黎城县",
                        "label": "黎城县",
                        "pid": "140400",
                        "index": "140426"
                    },
                    {
                        "value": "壶关县",
                        "label": "壶关县",
                        "pid": "140400",
                        "index": "140427"
                    },
                    {
                        "value": "长子县",
                        "label": "长子县",
                        "pid": "140400",
                        "index": "140428"
                    },
                    {
                        "value": "武乡县",
                        "label": "武乡县",
                        "pid": "140400",
                        "index": "140429"
                    },
                    {
                        "value": "沁县",
                        "label": "沁县",
                        "pid": "140400",
                        "index": "140430"
                    },
                    {
                        "value": "沁源县",
                        "label": "沁源县",
                        "pid": "140400",
                        "index": "140431"
                    },
                    {
                        "value": "山西长治高新技术产业园区",
                        "label": "山西长治高新技术产业园区",
                        "pid": "140400",
                        "index": "140471"
                    }
                ]
            },
            {
                "value": "晋城市",
                "label": "晋城市",
                "pid": "140000",
                "index": "140500",
                "children": [
                    {
                        "value": "城区",
                        "label": "城区",
                        "pid": "140500",
                        "index": "140502"
                    },
                    {
                        "value": "沁水县",
                        "label": "沁水县",
                        "pid": "140500",
                        "index": "140521"
                    },
                    {
                        "value": "阳城县",
                        "label": "阳城县",
                        "pid": "140500",
                        "index": "140522"
                    },
                    {
                        "value": "陵川县",
                        "label": "陵川县",
                        "pid": "140500",
                        "index": "140524"
                    },
                    {
                        "value": "泽州县",
                        "label": "泽州县",
                        "pid": "140500",
                        "index": "140525"
                    },
                    {
                        "value": "高平市",
                        "label": "高平市",
                        "pid": "140500",
                        "index": "140581"
                    }
                ]
            },
            {
                "value": "朔州市",
                "label": "朔州市",
                "pid": "140000",
                "index": "140600",
                "children": [
                    {
                        "value": "朔城区",
                        "label": "朔城区",
                        "pid": "140600",
                        "index": "140602"
                    },
                    {
                        "value": "平鲁区",
                        "label": "平鲁区",
                        "pid": "140600",
                        "index": "140603"
                    },
                    {
                        "value": "山阴县",
                        "label": "山阴县",
                        "pid": "140600",
                        "index": "140621"
                    },
                    {
                        "value": "应县",
                        "label": "应县",
                        "pid": "140600",
                        "index": "140622"
                    },
                    {
                        "value": "右玉县",
                        "label": "右玉县",
                        "pid": "140600",
                        "index": "140623"
                    },
                    {
                        "value": "山西朔州经济开发区",
                        "label": "山西朔州经济开发区",
                        "pid": "140600",
                        "index": "140671"
                    },
                    {
                        "value": "怀仁市",
                        "label": "怀仁市",
                        "pid": "140600",
                        "index": "140681"
                    }
                ]
            },
            {
                "value": "晋中市",
                "label": "晋中市",
                "pid": "140000",
                "index": "140700",
                "children": [
                    {
                        "value": "榆次区",
                        "label": "榆次区",
                        "pid": "140700",
                        "index": "140702"
                    },
                    {
                        "value": "榆社县",
                        "label": "榆社县",
                        "pid": "140700",
                        "index": "140721"
                    },
                    {
                        "value": "左权县",
                        "label": "左权县",
                        "pid": "140700",
                        "index": "140722"
                    },
                    {
                        "value": "和顺县",
                        "label": "和顺县",
                        "pid": "140700",
                        "index": "140723"
                    },
                    {
                        "value": "昔阳县",
                        "label": "昔阳县",
                        "pid": "140700",
                        "index": "140724"
                    },
                    {
                        "value": "寿阳县",
                        "label": "寿阳县",
                        "pid": "140700",
                        "index": "140725"
                    },
                    {
                        "value": "太谷县",
                        "label": "太谷县",
                        "pid": "140700",
                        "index": "140726"
                    },
                    {
                        "value": "祁县",
                        "label": "祁县",
                        "pid": "140700",
                        "index": "140727"
                    },
                    {
                        "value": "平遥县",
                        "label": "平遥县",
                        "pid": "140700",
                        "index": "140728"
                    },
                    {
                        "value": "灵石县",
                        "label": "灵石县",
                        "pid": "140700",
                        "index": "140729"
                    },
                    {
                        "value": "介休市",
                        "label": "介休市",
                        "pid": "140700",
                        "index": "140781"
                    }
                ]
            },
            {
                "value": "运城市",
                "label": "运城市",
                "pid": "140000",
                "index": "140800",
                "children": [
                    {
                        "value": "盐湖区",
                        "label": "盐湖区",
                        "pid": "140800",
                        "index": "140802"
                    },
                    {
                        "value": "临猗县",
                        "label": "临猗县",
                        "pid": "140800",
                        "index": "140821"
                    },
                    {
                        "value": "万荣县",
                        "label": "万荣县",
                        "pid": "140800",
                        "index": "140822"
                    },
                    {
                        "value": "闻喜县",
                        "label": "闻喜县",
                        "pid": "140800",
                        "index": "140823"
                    },
                    {
                        "value": "稷山县",
                        "label": "稷山县",
                        "pid": "140800",
                        "index": "140824"
                    },
                    {
                        "value": "新绛县",
                        "label": "新绛县",
                        "pid": "140800",
                        "index": "140825"
                    },
                    {
                        "value": "绛县",
                        "label": "绛县",
                        "pid": "140800",
                        "index": "140826"
                    },
                    {
                        "value": "垣曲县",
                        "label": "垣曲县",
                        "pid": "140800",
                        "index": "140827"
                    },
                    {
                        "value": "夏县",
                        "label": "夏县",
                        "pid": "140800",
                        "index": "140828"
                    },
                    {
                        "value": "平陆县",
                        "label": "平陆县",
                        "pid": "140800",
                        "index": "140829"
                    },
                    {
                        "value": "芮城县",
                        "label": "芮城县",
                        "pid": "140800",
                        "index": "140830"
                    },
                    {
                        "value": "永济市",
                        "label": "永济市",
                        "pid": "140800",
                        "index": "140881"
                    },
                    {
                        "value": "河津市",
                        "label": "河津市",
                        "pid": "140800",
                        "index": "140882"
                    }
                ]
            },
            {
                "value": "忻州市",
                "label": "忻州市",
                "pid": "140000",
                "index": "140900",
                "children": [
                    {
                        "value": "忻府区",
                        "label": "忻府区",
                        "pid": "140900",
                        "index": "140902"
                    },
                    {
                        "value": "定襄县",
                        "label": "定襄县",
                        "pid": "140900",
                        "index": "140921"
                    },
                    {
                        "value": "五台县",
                        "label": "五台县",
                        "pid": "140900",
                        "index": "140922"
                    },
                    {
                        "value": "代县",
                        "label": "代县",
                        "pid": "140900",
                        "index": "140923"
                    },
                    {
                        "value": "繁峙县",
                        "label": "繁峙县",
                        "pid": "140900",
                        "index": "140924"
                    },
                    {
                        "value": "宁武县",
                        "label": "宁武县",
                        "pid": "140900",
                        "index": "140925"
                    },
                    {
                        "value": "静乐县",
                        "label": "静乐县",
                        "pid": "140900",
                        "index": "140926"
                    },
                    {
                        "value": "神池县",
                        "label": "神池县",
                        "pid": "140900",
                        "index": "140927"
                    },
                    {
                        "value": "五寨县",
                        "label": "五寨县",
                        "pid": "140900",
                        "index": "140928"
                    },
                    {
                        "value": "岢岚县",
                        "label": "岢岚县",
                        "pid": "140900",
                        "index": "140929"
                    },
                    {
                        "value": "河曲县",
                        "label": "河曲县",
                        "pid": "140900",
                        "index": "140930"
                    },
                    {
                        "value": "保德县",
                        "label": "保德县",
                        "pid": "140900",
                        "index": "140931"
                    },
                    {
                        "value": "偏关县",
                        "label": "偏关县",
                        "pid": "140900",
                        "index": "140932"
                    },
                    {
                        "value": "五台山风景名胜区",
                        "label": "五台山风景名胜区",
                        "pid": "140900",
                        "index": "140971"
                    },
                    {
                        "value": "原平市",
                        "label": "原平市",
                        "pid": "140900",
                        "index": "140981"
                    }
                ]
            },
            {
                "value": "临汾市",
                "label": "临汾市",
                "pid": "140000",
                "index": "141000",
                "children": [
                    {
                        "value": "尧都区",
                        "label": "尧都区",
                        "pid": "141000",
                        "index": "141002"
                    },
                    {
                        "value": "曲沃县",
                        "label": "曲沃县",
                        "pid": "141000",
                        "index": "141021"
                    },
                    {
                        "value": "翼城县",
                        "label": "翼城县",
                        "pid": "141000",
                        "index": "141022"
                    },
                    {
                        "value": "襄汾县",
                        "label": "襄汾县",
                        "pid": "141000",
                        "index": "141023"
                    },
                    {
                        "value": "洪洞县",
                        "label": "洪洞县",
                        "pid": "141000",
                        "index": "141024"
                    },
                    {
                        "value": "古县",
                        "label": "古县",
                        "pid": "141000",
                        "index": "141025"
                    },
                    {
                        "value": "安泽县",
                        "label": "安泽县",
                        "pid": "141000",
                        "index": "141026"
                    },
                    {
                        "value": "浮山县",
                        "label": "浮山县",
                        "pid": "141000",
                        "index": "141027"
                    },
                    {
                        "value": "吉县",
                        "label": "吉县",
                        "pid": "141000",
                        "index": "141028"
                    },
                    {
                        "value": "乡宁县",
                        "label": "乡宁县",
                        "pid": "141000",
                        "index": "141029"
                    },
                    {
                        "value": "大宁县",
                        "label": "大宁县",
                        "pid": "141000",
                        "index": "141030"
                    },
                    {
                        "value": "隰县",
                        "label": "隰县",
                        "pid": "141000",
                        "index": "141031"
                    },
                    {
                        "value": "永和县",
                        "label": "永和县",
                        "pid": "141000",
                        "index": "141032"
                    },
                    {
                        "value": "蒲县",
                        "label": "蒲县",
                        "pid": "141000",
                        "index": "141033"
                    },
                    {
                        "value": "汾西县",
                        "label": "汾西县",
                        "pid": "141000",
                        "index": "141034"
                    },
                    {
                        "value": "侯马市",
                        "label": "侯马市",
                        "pid": "141000",
                        "index": "141081"
                    },
                    {
                        "value": "霍州市",
                        "label": "霍州市",
                        "pid": "141000",
                        "index": "141082"
                    }
                ]
            },
            {
                "value": "吕梁市",
                "label": "吕梁市",
                "pid": "140000",
                "index": "141100",
                "children": [
                    {
                        "value": "离石区",
                        "label": "离石区",
                        "pid": "141100",
                        "index": "141102"
                    },
                    {
                        "value": "文水县",
                        "label": "文水县",
                        "pid": "141100",
                        "index": "141121"
                    },
                    {
                        "value": "交城县",
                        "label": "交城县",
                        "pid": "141100",
                        "index": "141122"
                    },
                    {
                        "value": "兴县",
                        "label": "兴县",
                        "pid": "141100",
                        "index": "141123"
                    },
                    {
                        "value": "临县",
                        "label": "临县",
                        "pid": "141100",
                        "index": "141124"
                    },
                    {
                        "value": "柳林县",
                        "label": "柳林县",
                        "pid": "141100",
                        "index": "141125"
                    },
                    {
                        "value": "石楼县",
                        "label": "石楼县",
                        "pid": "141100",
                        "index": "141126"
                    },
                    {
                        "value": "岚县",
                        "label": "岚县",
                        "pid": "141100",
                        "index": "141127"
                    },
                    {
                        "value": "方山县",
                        "label": "方山县",
                        "pid": "141100",
                        "index": "141128"
                    },
                    {
                        "value": "中阳县",
                        "label": "中阳县",
                        "pid": "141100",
                        "index": "141129"
                    },
                    {
                        "value": "交口县",
                        "label": "交口县",
                        "pid": "141100",
                        "index": "141130"
                    },
                    {
                        "value": "孝义市",
                        "label": "孝义市",
                        "pid": "141100",
                        "index": "141181"
                    },
                    {
                        "value": "汾阳市",
                        "label": "汾阳市",
                        "pid": "141100",
                        "index": "141182"
                    }
                ]
            }
        ]
    },
    {
        "value": "内蒙古自治区",
        "label": "内蒙古自治区",
        "pid": "86",
        "index": "150000",
        "children": [
            {
                "value": "呼和浩特市",
                "label": "呼和浩特市",
                "pid": "150000",
                "index": "150100",
                "children": [
                    {
                        "value": "新城区",
                        "label": "新城区",
                        "pid": "150100",
                        "index": "150102"
                    },
                    {
                        "value": "回民区",
                        "label": "回民区",
                        "pid": "150100",
                        "index": "150103"
                    },
                    {
                        "value": "玉泉区",
                        "label": "玉泉区",
                        "pid": "150100",
                        "index": "150104"
                    },
                    {
                        "value": "赛罕区",
                        "label": "赛罕区",
                        "pid": "150100",
                        "index": "150105"
                    },
                    {
                        "value": "土默特左旗",
                        "label": "土默特左旗",
                        "pid": "150100",
                        "index": "150121"
                    },
                    {
                        "value": "托克托县",
                        "label": "托克托县",
                        "pid": "150100",
                        "index": "150122"
                    },
                    {
                        "value": "和林格尔县",
                        "label": "和林格尔县",
                        "pid": "150100",
                        "index": "150123"
                    },
                    {
                        "value": "清水河县",
                        "label": "清水河县",
                        "pid": "150100",
                        "index": "150124"
                    },
                    {
                        "value": "武川县",
                        "label": "武川县",
                        "pid": "150100",
                        "index": "150125"
                    },
                    {
                        "value": "呼和浩特金海工业园区",
                        "label": "呼和浩特金海工业园区",
                        "pid": "150100",
                        "index": "150171"
                    },
                    {
                        "value": "呼和浩特经济技术开发区",
                        "label": "呼和浩特经济技术开发区",
                        "pid": "150100",
                        "index": "150172"
                    }
                ]
            },
            {
                "value": "包头市",
                "label": "包头市",
                "pid": "150000",
                "index": "150200",
                "children": [
                    {
                        "value": "东河区",
                        "label": "东河区",
                        "pid": "150200",
                        "index": "150202"
                    },
                    {
                        "value": "昆都仑区",
                        "label": "昆都仑区",
                        "pid": "150200",
                        "index": "150203"
                    },
                    {
                        "value": "青山区",
                        "label": "青山区",
                        "pid": "150200",
                        "index": "150204"
                    },
                    {
                        "value": "石拐区",
                        "label": "石拐区",
                        "pid": "150200",
                        "index": "150205"
                    },
                    {
                        "value": "白云鄂博矿区",
                        "label": "白云鄂博矿区",
                        "pid": "150200",
                        "index": "150206"
                    },
                    {
                        "value": "九原区",
                        "label": "九原区",
                        "pid": "150200",
                        "index": "150207"
                    },
                    {
                        "value": "土默特右旗",
                        "label": "土默特右旗",
                        "pid": "150200",
                        "index": "150221"
                    },
                    {
                        "value": "固阳县",
                        "label": "固阳县",
                        "pid": "150200",
                        "index": "150222"
                    },
                    {
                        "value": "达尔罕茂明安联合旗",
                        "label": "达尔罕茂明安联合旗",
                        "pid": "150200",
                        "index": "150223"
                    },
                    {
                        "value": "包头稀土高新技术产业开发区",
                        "label": "包头稀土高新技术产业开发区",
                        "pid": "150200",
                        "index": "150271"
                    }
                ]
            },
            {
                "value": "乌海市",
                "label": "乌海市",
                "pid": "150000",
                "index": "150300",
                "children": [
                    {
                        "value": "海勃湾区",
                        "label": "海勃湾区",
                        "pid": "150300",
                        "index": "150302"
                    },
                    {
                        "value": "海南区",
                        "label": "海南区",
                        "pid": "150300",
                        "index": "150303"
                    },
                    {
                        "value": "乌达区",
                        "label": "乌达区",
                        "pid": "150300",
                        "index": "150304"
                    }
                ]
            },
            {
                "value": "赤峰市",
                "label": "赤峰市",
                "pid": "150000",
                "index": "150400",
                "children": [
                    {
                        "value": "红山区",
                        "label": "红山区",
                        "pid": "150400",
                        "index": "150402"
                    },
                    {
                        "value": "元宝山区",
                        "label": "元宝山区",
                        "pid": "150400",
                        "index": "150403"
                    },
                    {
                        "value": "松山区",
                        "label": "松山区",
                        "pid": "150400",
                        "index": "150404"
                    },
                    {
                        "value": "阿鲁科尔沁旗",
                        "label": "阿鲁科尔沁旗",
                        "pid": "150400",
                        "index": "150421"
                    },
                    {
                        "value": "巴林左旗",
                        "label": "巴林左旗",
                        "pid": "150400",
                        "index": "150422"
                    },
                    {
                        "value": "巴林右旗",
                        "label": "巴林右旗",
                        "pid": "150400",
                        "index": "150423"
                    },
                    {
                        "value": "林西县",
                        "label": "林西县",
                        "pid": "150400",
                        "index": "150424"
                    },
                    {
                        "value": "克什克腾旗",
                        "label": "克什克腾旗",
                        "pid": "150400",
                        "index": "150425"
                    },
                    {
                        "value": "翁牛特旗",
                        "label": "翁牛特旗",
                        "pid": "150400",
                        "index": "150426"
                    },
                    {
                        "value": "喀喇沁旗",
                        "label": "喀喇沁旗",
                        "pid": "150400",
                        "index": "150428"
                    },
                    {
                        "value": "宁城县",
                        "label": "宁城县",
                        "pid": "150400",
                        "index": "150429"
                    },
                    {
                        "value": "敖汉旗",
                        "label": "敖汉旗",
                        "pid": "150400",
                        "index": "150430"
                    }
                ]
            },
            {
                "value": "通辽市",
                "label": "通辽市",
                "pid": "150000",
                "index": "150500",
                "children": [
                    {
                        "value": "科尔沁区",
                        "label": "科尔沁区",
                        "pid": "150500",
                        "index": "150502"
                    },
                    {
                        "value": "科尔沁左翼中旗",
                        "label": "科尔沁左翼中旗",
                        "pid": "150500",
                        "index": "150521"
                    },
                    {
                        "value": "科尔沁左翼后旗",
                        "label": "科尔沁左翼后旗",
                        "pid": "150500",
                        "index": "150522"
                    },
                    {
                        "value": "开鲁县",
                        "label": "开鲁县",
                        "pid": "150500",
                        "index": "150523"
                    },
                    {
                        "value": "库伦旗",
                        "label": "库伦旗",
                        "pid": "150500",
                        "index": "150524"
                    },
                    {
                        "value": "奈曼旗",
                        "label": "奈曼旗",
                        "pid": "150500",
                        "index": "150525"
                    },
                    {
                        "value": "扎鲁特旗",
                        "label": "扎鲁特旗",
                        "pid": "150500",
                        "index": "150526"
                    },
                    {
                        "value": "通辽经济技术开发区",
                        "label": "通辽经济技术开发区",
                        "pid": "150500",
                        "index": "150571"
                    },
                    {
                        "value": "霍林郭勒市",
                        "label": "霍林郭勒市",
                        "pid": "150500",
                        "index": "150581"
                    }
                ]
            },
            {
                "value": "鄂尔多斯市",
                "label": "鄂尔多斯市",
                "pid": "150000",
                "index": "150600",
                "children": [
                    {
                        "value": "东胜区",
                        "label": "东胜区",
                        "pid": "150600",
                        "index": "150602"
                    },
                    {
                        "value": "康巴什区",
                        "label": "康巴什区",
                        "pid": "150600",
                        "index": "150603"
                    },
                    {
                        "value": "达拉特旗",
                        "label": "达拉特旗",
                        "pid": "150600",
                        "index": "150621"
                    },
                    {
                        "value": "准格尔旗",
                        "label": "准格尔旗",
                        "pid": "150600",
                        "index": "150622"
                    },
                    {
                        "value": "鄂托克前旗",
                        "label": "鄂托克前旗",
                        "pid": "150600",
                        "index": "150623"
                    },
                    {
                        "value": "鄂托克旗",
                        "label": "鄂托克旗",
                        "pid": "150600",
                        "index": "150624"
                    },
                    {
                        "value": "杭锦旗",
                        "label": "杭锦旗",
                        "pid": "150600",
                        "index": "150625"
                    },
                    {
                        "value": "乌审旗",
                        "label": "乌审旗",
                        "pid": "150600",
                        "index": "150626"
                    },
                    {
                        "value": "伊金霍洛旗",
                        "label": "伊金霍洛旗",
                        "pid": "150600",
                        "index": "150627"
                    }
                ]
            },
            {
                "value": "呼伦贝尔市",
                "label": "呼伦贝尔市",
                "pid": "150000",
                "index": "150700",
                "children": [
                    {
                        "value": "海拉尔区",
                        "label": "海拉尔区",
                        "pid": "150700",
                        "index": "150702"
                    },
                    {
                        "value": "扎赉诺尔区",
                        "label": "扎赉诺尔区",
                        "pid": "150700",
                        "index": "150703"
                    },
                    {
                        "value": "阿荣旗",
                        "label": "阿荣旗",
                        "pid": "150700",
                        "index": "150721"
                    },
                    {
                        "value": "莫力达瓦达斡尔族自治旗",
                        "label": "莫力达瓦达斡尔族自治旗",
                        "pid": "150700",
                        "index": "150722"
                    },
                    {
                        "value": "鄂伦春自治旗",
                        "label": "鄂伦春自治旗",
                        "pid": "150700",
                        "index": "150723"
                    },
                    {
                        "value": "鄂温克族自治旗",
                        "label": "鄂温克族自治旗",
                        "pid": "150700",
                        "index": "150724"
                    },
                    {
                        "value": "陈巴尔虎旗",
                        "label": "陈巴尔虎旗",
                        "pid": "150700",
                        "index": "150725"
                    },
                    {
                        "value": "新巴尔虎左旗",
                        "label": "新巴尔虎左旗",
                        "pid": "150700",
                        "index": "150726"
                    },
                    {
                        "value": "新巴尔虎右旗",
                        "label": "新巴尔虎右旗",
                        "pid": "150700",
                        "index": "150727"
                    },
                    {
                        "value": "满洲里市",
                        "label": "满洲里市",
                        "pid": "150700",
                        "index": "150781"
                    },
                    {
                        "value": "牙克石市",
                        "label": "牙克石市",
                        "pid": "150700",
                        "index": "150782"
                    },
                    {
                        "value": "扎兰屯市",
                        "label": "扎兰屯市",
                        "pid": "150700",
                        "index": "150783"
                    },
                    {
                        "value": "额尔古纳市",
                        "label": "额尔古纳市",
                        "pid": "150700",
                        "index": "150784"
                    },
                    {
                        "value": "根河市",
                        "label": "根河市",
                        "pid": "150700",
                        "index": "150785"
                    }
                ]
            },
            {
                "value": "巴彦淖尔市",
                "label": "巴彦淖尔市",
                "pid": "150000",
                "index": "150800",
                "children": [
                    {
                        "value": "临河区",
                        "label": "临河区",
                        "pid": "150800",
                        "index": "150802"
                    },
                    {
                        "value": "五原县",
                        "label": "五原县",
                        "pid": "150800",
                        "index": "150821"
                    },
                    {
                        "value": "磴口县",
                        "label": "磴口县",
                        "pid": "150800",
                        "index": "150822"
                    },
                    {
                        "value": "乌拉特前旗",
                        "label": "乌拉特前旗",
                        "pid": "150800",
                        "index": "150823"
                    },
                    {
                        "value": "乌拉特中旗",
                        "label": "乌拉特中旗",
                        "pid": "150800",
                        "index": "150824"
                    },
                    {
                        "value": "乌拉特后旗",
                        "label": "乌拉特后旗",
                        "pid": "150800",
                        "index": "150825"
                    },
                    {
                        "value": "杭锦后旗",
                        "label": "杭锦后旗",
                        "pid": "150800",
                        "index": "150826"
                    }
                ]
            },
            {
                "value": "乌兰察布市",
                "label": "乌兰察布市",
                "pid": "150000",
                "index": "150900",
                "children": [
                    {
                        "value": "集宁区",
                        "label": "集宁区",
                        "pid": "150900",
                        "index": "150902"
                    },
                    {
                        "value": "卓资县",
                        "label": "卓资县",
                        "pid": "150900",
                        "index": "150921"
                    },
                    {
                        "value": "化德县",
                        "label": "化德县",
                        "pid": "150900",
                        "index": "150922"
                    },
                    {
                        "value": "商都县",
                        "label": "商都县",
                        "pid": "150900",
                        "index": "150923"
                    },
                    {
                        "value": "兴和县",
                        "label": "兴和县",
                        "pid": "150900",
                        "index": "150924"
                    },
                    {
                        "value": "凉城县",
                        "label": "凉城县",
                        "pid": "150900",
                        "index": "150925"
                    },
                    {
                        "value": "察哈尔右翼前旗",
                        "label": "察哈尔右翼前旗",
                        "pid": "150900",
                        "index": "150926"
                    },
                    {
                        "value": "察哈尔右翼中旗",
                        "label": "察哈尔右翼中旗",
                        "pid": "150900",
                        "index": "150927"
                    },
                    {
                        "value": "察哈尔右翼后旗",
                        "label": "察哈尔右翼后旗",
                        "pid": "150900",
                        "index": "150928"
                    },
                    {
                        "value": "四子王旗",
                        "label": "四子王旗",
                        "pid": "150900",
                        "index": "150929"
                    },
                    {
                        "value": "丰镇市",
                        "label": "丰镇市",
                        "pid": "150900",
                        "index": "150981"
                    }
                ]
            },
            {
                "value": "兴安盟",
                "label": "兴安盟",
                "pid": "150000",
                "index": "152200",
                "children": [
                    {
                        "value": "乌兰浩特市",
                        "label": "乌兰浩特市",
                        "pid": "152200",
                        "index": "152201"
                    },
                    {
                        "value": "阿尔山市",
                        "label": "阿尔山市",
                        "pid": "152200",
                        "index": "152202"
                    },
                    {
                        "value": "科尔沁右翼前旗",
                        "label": "科尔沁右翼前旗",
                        "pid": "152200",
                        "index": "152221"
                    },
                    {
                        "value": "科尔沁右翼中旗",
                        "label": "科尔沁右翼中旗",
                        "pid": "152200",
                        "index": "152222"
                    },
                    {
                        "value": "扎赉特旗",
                        "label": "扎赉特旗",
                        "pid": "152200",
                        "index": "152223"
                    },
                    {
                        "value": "突泉县",
                        "label": "突泉县",
                        "pid": "152200",
                        "index": "152224"
                    }
                ]
            },
            {
                "value": "锡林郭勒盟",
                "label": "锡林郭勒盟",
                "pid": "150000",
                "index": "152500",
                "children": [
                    {
                        "value": "二连浩特市",
                        "label": "二连浩特市",
                        "pid": "152500",
                        "index": "152501"
                    },
                    {
                        "value": "锡林浩特市",
                        "label": "锡林浩特市",
                        "pid": "152500",
                        "index": "152502"
                    },
                    {
                        "value": "阿巴嘎旗",
                        "label": "阿巴嘎旗",
                        "pid": "152500",
                        "index": "152522"
                    },
                    {
                        "value": "苏尼特左旗",
                        "label": "苏尼特左旗",
                        "pid": "152500",
                        "index": "152523"
                    },
                    {
                        "value": "苏尼特右旗",
                        "label": "苏尼特右旗",
                        "pid": "152500",
                        "index": "152524"
                    },
                    {
                        "value": "东乌珠穆沁旗",
                        "label": "东乌珠穆沁旗",
                        "pid": "152500",
                        "index": "152525"
                    },
                    {
                        "value": "西乌珠穆沁旗",
                        "label": "西乌珠穆沁旗",
                        "pid": "152500",
                        "index": "152526"
                    },
                    {
                        "value": "太仆寺旗",
                        "label": "太仆寺旗",
                        "pid": "152500",
                        "index": "152527"
                    },
                    {
                        "value": "镶黄旗",
                        "label": "镶黄旗",
                        "pid": "152500",
                        "index": "152528"
                    },
                    {
                        "value": "正镶白旗",
                        "label": "正镶白旗",
                        "pid": "152500",
                        "index": "152529"
                    },
                    {
                        "value": "正蓝旗",
                        "label": "正蓝旗",
                        "pid": "152500",
                        "index": "152530"
                    },
                    {
                        "value": "多伦县",
                        "label": "多伦县",
                        "pid": "152500",
                        "index": "152531"
                    },
                    {
                        "value": "乌拉盖管委会",
                        "label": "乌拉盖管委会",
                        "pid": "152500",
                        "index": "152571"
                    }
                ]
            },
            {
                "value": "阿拉善盟",
                "label": "阿拉善盟",
                "pid": "150000",
                "index": "152900",
                "children": [
                    {
                        "value": "阿拉善左旗",
                        "label": "阿拉善左旗",
                        "pid": "152900",
                        "index": "152921"
                    },
                    {
                        "value": "阿拉善右旗",
                        "label": "阿拉善右旗",
                        "pid": "152900",
                        "index": "152922"
                    },
                    {
                        "value": "额济纳旗",
                        "label": "额济纳旗",
                        "pid": "152900",
                        "index": "152923"
                    },
                    {
                        "value": "内蒙古阿拉善经济开发区",
                        "label": "内蒙古阿拉善经济开发区",
                        "pid": "152900",
                        "index": "152971"
                    }
                ]
            }
        ]
    },
    {
        "value": "辽宁省",
        "label": "辽宁省",
        "pid": "86",
        "index": "210000",
        "children": [
            {
                "value": "沈阳市",
                "label": "沈阳市",
                "pid": "210000",
                "index": "210100",
                "children": [
                    {
                        "value": "和平区",
                        "label": "和平区",
                        "pid": "210100",
                        "index": "210102"
                    },
                    {
                        "value": "沈河区",
                        "label": "沈河区",
                        "pid": "210100",
                        "index": "210103"
                    },
                    {
                        "value": "大东区",
                        "label": "大东区",
                        "pid": "210100",
                        "index": "210104"
                    },
                    {
                        "value": "皇姑区",
                        "label": "皇姑区",
                        "pid": "210100",
                        "index": "210105"
                    },
                    {
                        "value": "铁西区",
                        "label": "铁西区",
                        "pid": "210100",
                        "index": "210106"
                    },
                    {
                        "value": "苏家屯区",
                        "label": "苏家屯区",
                        "pid": "210100",
                        "index": "210111"
                    },
                    {
                        "value": "浑南区",
                        "label": "浑南区",
                        "pid": "210100",
                        "index": "210112"
                    },
                    {
                        "value": "沈北新区",
                        "label": "沈北新区",
                        "pid": "210100",
                        "index": "210113"
                    },
                    {
                        "value": "于洪区",
                        "label": "于洪区",
                        "pid": "210100",
                        "index": "210114"
                    },
                    {
                        "value": "辽中区",
                        "label": "辽中区",
                        "pid": "210100",
                        "index": "210115"
                    },
                    {
                        "value": "康平县",
                        "label": "康平县",
                        "pid": "210100",
                        "index": "210123"
                    },
                    {
                        "value": "法库县",
                        "label": "法库县",
                        "pid": "210100",
                        "index": "210124"
                    },
                    {
                        "value": "新民市",
                        "label": "新民市",
                        "pid": "210100",
                        "index": "210181"
                    }
                ]
            },
            {
                "value": "大连市",
                "label": "大连市",
                "pid": "210000",
                "index": "210200",
                "children": [
                    {
                        "value": "中山区",
                        "label": "中山区",
                        "pid": "210200",
                        "index": "210202"
                    },
                    {
                        "value": "西岗区",
                        "label": "西岗区",
                        "pid": "210200",
                        "index": "210203"
                    },
                    {
                        "value": "沙河口区",
                        "label": "沙河口区",
                        "pid": "210200",
                        "index": "210204"
                    },
                    {
                        "value": "甘井子区",
                        "label": "甘井子区",
                        "pid": "210200",
                        "index": "210211"
                    },
                    {
                        "value": "旅顺口区",
                        "label": "旅顺口区",
                        "pid": "210200",
                        "index": "210212"
                    },
                    {
                        "value": "金州区",
                        "label": "金州区",
                        "pid": "210200",
                        "index": "210213"
                    },
                    {
                        "value": "普兰店区",
                        "label": "普兰店区",
                        "pid": "210200",
                        "index": "210214"
                    },
                    {
                        "value": "长海县",
                        "label": "长海县",
                        "pid": "210200",
                        "index": "210224"
                    },
                    {
                        "value": "瓦房店市",
                        "label": "瓦房店市",
                        "pid": "210200",
                        "index": "210281"
                    },
                    {
                        "value": "庄河市",
                        "label": "庄河市",
                        "pid": "210200",
                        "index": "210283"
                    }
                ]
            },
            {
                "value": "鞍山市",
                "label": "鞍山市",
                "pid": "210000",
                "index": "210300",
                "children": [
                    {
                        "value": "铁东区",
                        "label": "铁东区",
                        "pid": "210300",
                        "index": "210302"
                    },
                    {
                        "value": "铁西区",
                        "label": "铁西区",
                        "pid": "210300",
                        "index": "210303"
                    },
                    {
                        "value": "立山区",
                        "label": "立山区",
                        "pid": "210300",
                        "index": "210304"
                    },
                    {
                        "value": "千山区",
                        "label": "千山区",
                        "pid": "210300",
                        "index": "210311"
                    },
                    {
                        "value": "台安县",
                        "label": "台安县",
                        "pid": "210300",
                        "index": "210321"
                    },
                    {
                        "value": "岫岩满族自治县",
                        "label": "岫岩满族自治县",
                        "pid": "210300",
                        "index": "210323"
                    },
                    {
                        "value": "海城市",
                        "label": "海城市",
                        "pid": "210300",
                        "index": "210381"
                    }
                ]
            },
            {
                "value": "抚顺市",
                "label": "抚顺市",
                "pid": "210000",
                "index": "210400",
                "children": [
                    {
                        "value": "新抚区",
                        "label": "新抚区",
                        "pid": "210400",
                        "index": "210402"
                    },
                    {
                        "value": "东洲区",
                        "label": "东洲区",
                        "pid": "210400",
                        "index": "210403"
                    },
                    {
                        "value": "望花区",
                        "label": "望花区",
                        "pid": "210400",
                        "index": "210404"
                    },
                    {
                        "value": "顺城区",
                        "label": "顺城区",
                        "pid": "210400",
                        "index": "210411"
                    },
                    {
                        "value": "抚顺县",
                        "label": "抚顺县",
                        "pid": "210400",
                        "index": "210421"
                    },
                    {
                        "value": "新宾满族自治县",
                        "label": "新宾满族自治县",
                        "pid": "210400",
                        "index": "210422"
                    },
                    {
                        "value": "清原满族自治县",
                        "label": "清原满族自治县",
                        "pid": "210400",
                        "index": "210423"
                    }
                ]
            },
            {
                "value": "本溪市",
                "label": "本溪市",
                "pid": "210000",
                "index": "210500",
                "children": [
                    {
                        "value": "平山区",
                        "label": "平山区",
                        "pid": "210500",
                        "index": "210502"
                    },
                    {
                        "value": "溪湖区",
                        "label": "溪湖区",
                        "pid": "210500",
                        "index": "210503"
                    },
                    {
                        "value": "明山区",
                        "label": "明山区",
                        "pid": "210500",
                        "index": "210504"
                    },
                    {
                        "value": "南芬区",
                        "label": "南芬区",
                        "pid": "210500",
                        "index": "210505"
                    },
                    {
                        "value": "本溪满族自治县",
                        "label": "本溪满族自治县",
                        "pid": "210500",
                        "index": "210521"
                    },
                    {
                        "value": "桓仁满族自治县",
                        "label": "桓仁满族自治县",
                        "pid": "210500",
                        "index": "210522"
                    }
                ]
            },
            {
                "value": "丹东市",
                "label": "丹东市",
                "pid": "210000",
                "index": "210600",
                "children": [
                    {
                        "value": "元宝区",
                        "label": "元宝区",
                        "pid": "210600",
                        "index": "210602"
                    },
                    {
                        "value": "振兴区",
                        "label": "振兴区",
                        "pid": "210600",
                        "index": "210603"
                    },
                    {
                        "value": "振安区",
                        "label": "振安区",
                        "pid": "210600",
                        "index": "210604"
                    },
                    {
                        "value": "宽甸满族自治县",
                        "label": "宽甸满族自治县",
                        "pid": "210600",
                        "index": "210624"
                    },
                    {
                        "value": "东港市",
                        "label": "东港市",
                        "pid": "210600",
                        "index": "210681"
                    },
                    {
                        "value": "凤城市",
                        "label": "凤城市",
                        "pid": "210600",
                        "index": "210682"
                    }
                ]
            },
            {
                "value": "锦州市",
                "label": "锦州市",
                "pid": "210000",
                "index": "210700",
                "children": [
                    {
                        "value": "古塔区",
                        "label": "古塔区",
                        "pid": "210700",
                        "index": "210702"
                    },
                    {
                        "value": "凌河区",
                        "label": "凌河区",
                        "pid": "210700",
                        "index": "210703"
                    },
                    {
                        "value": "太和区",
                        "label": "太和区",
                        "pid": "210700",
                        "index": "210711"
                    },
                    {
                        "value": "黑山县",
                        "label": "黑山县",
                        "pid": "210700",
                        "index": "210726"
                    },
                    {
                        "value": "义县",
                        "label": "义县",
                        "pid": "210700",
                        "index": "210727"
                    },
                    {
                        "value": "凌海市",
                        "label": "凌海市",
                        "pid": "210700",
                        "index": "210781"
                    },
                    {
                        "value": "北镇市",
                        "label": "北镇市",
                        "pid": "210700",
                        "index": "210782"
                    }
                ]
            },
            {
                "value": "营口市",
                "label": "营口市",
                "pid": "210000",
                "index": "210800",
                "children": [
                    {
                        "value": "站前区",
                        "label": "站前区",
                        "pid": "210800",
                        "index": "210802"
                    },
                    {
                        "value": "西市区",
                        "label": "西市区",
                        "pid": "210800",
                        "index": "210803"
                    },
                    {
                        "value": "鲅鱼圈区",
                        "label": "鲅鱼圈区",
                        "pid": "210800",
                        "index": "210804"
                    },
                    {
                        "value": "老边区",
                        "label": "老边区",
                        "pid": "210800",
                        "index": "210811"
                    },
                    {
                        "value": "盖州市",
                        "label": "盖州市",
                        "pid": "210800",
                        "index": "210881"
                    },
                    {
                        "value": "大石桥市",
                        "label": "大石桥市",
                        "pid": "210800",
                        "index": "210882"
                    }
                ]
            },
            {
                "value": "阜新市",
                "label": "阜新市",
                "pid": "210000",
                "index": "210900",
                "children": [
                    {
                        "value": "海州区",
                        "label": "海州区",
                        "pid": "210900",
                        "index": "210902"
                    },
                    {
                        "value": "新邱区",
                        "label": "新邱区",
                        "pid": "210900",
                        "index": "210903"
                    },
                    {
                        "value": "太平区",
                        "label": "太平区",
                        "pid": "210900",
                        "index": "210904"
                    },
                    {
                        "value": "清河门区",
                        "label": "清河门区",
                        "pid": "210900",
                        "index": "210905"
                    },
                    {
                        "value": "细河区",
                        "label": "细河区",
                        "pid": "210900",
                        "index": "210911"
                    },
                    {
                        "value": "阜新蒙古族自治县",
                        "label": "阜新蒙古族自治县",
                        "pid": "210900",
                        "index": "210921"
                    },
                    {
                        "value": "彰武县",
                        "label": "彰武县",
                        "pid": "210900",
                        "index": "210922"
                    }
                ]
            },
            {
                "value": "辽阳市",
                "label": "辽阳市",
                "pid": "210000",
                "index": "211000",
                "children": [
                    {
                        "value": "白塔区",
                        "label": "白塔区",
                        "pid": "211000",
                        "index": "211002"
                    },
                    {
                        "value": "文圣区",
                        "label": "文圣区",
                        "pid": "211000",
                        "index": "211003"
                    },
                    {
                        "value": "宏伟区",
                        "label": "宏伟区",
                        "pid": "211000",
                        "index": "211004"
                    },
                    {
                        "value": "弓长岭区",
                        "label": "弓长岭区",
                        "pid": "211000",
                        "index": "211005"
                    },
                    {
                        "value": "太子河区",
                        "label": "太子河区",
                        "pid": "211000",
                        "index": "211011"
                    },
                    {
                        "value": "辽阳县",
                        "label": "辽阳县",
                        "pid": "211000",
                        "index": "211021"
                    },
                    {
                        "value": "灯塔市",
                        "label": "灯塔市",
                        "pid": "211000",
                        "index": "211081"
                    }
                ]
            },
            {
                "value": "盘锦市",
                "label": "盘锦市",
                "pid": "210000",
                "index": "211100",
                "children": [
                    {
                        "value": "双台子区",
                        "label": "双台子区",
                        "pid": "211100",
                        "index": "211102"
                    },
                    {
                        "value": "兴隆台区",
                        "label": "兴隆台区",
                        "pid": "211100",
                        "index": "211103"
                    },
                    {
                        "value": "大洼区",
                        "label": "大洼区",
                        "pid": "211100",
                        "index": "211104"
                    },
                    {
                        "value": "盘山县",
                        "label": "盘山县",
                        "pid": "211100",
                        "index": "211122"
                    }
                ]
            },
            {
                "value": "铁岭市",
                "label": "铁岭市",
                "pid": "210000",
                "index": "211200",
                "children": [
                    {
                        "value": "银州区",
                        "label": "银州区",
                        "pid": "211200",
                        "index": "211202"
                    },
                    {
                        "value": "清河区",
                        "label": "清河区",
                        "pid": "211200",
                        "index": "211204"
                    },
                    {
                        "value": "铁岭县",
                        "label": "铁岭县",
                        "pid": "211200",
                        "index": "211221"
                    },
                    {
                        "value": "西丰县",
                        "label": "西丰县",
                        "pid": "211200",
                        "index": "211223"
                    },
                    {
                        "value": "昌图县",
                        "label": "昌图县",
                        "pid": "211200",
                        "index": "211224"
                    },
                    {
                        "value": "调兵山市",
                        "label": "调兵山市",
                        "pid": "211200",
                        "index": "211281"
                    },
                    {
                        "value": "开原市",
                        "label": "开原市",
                        "pid": "211200",
                        "index": "211282"
                    }
                ]
            },
            {
                "value": "朝阳市",
                "label": "朝阳市",
                "pid": "210000",
                "index": "211300",
                "children": [
                    {
                        "value": "双塔区",
                        "label": "双塔区",
                        "pid": "211300",
                        "index": "211302"
                    },
                    {
                        "value": "龙城区",
                        "label": "龙城区",
                        "pid": "211300",
                        "index": "211303"
                    },
                    {
                        "value": "朝阳县",
                        "label": "朝阳县",
                        "pid": "211300",
                        "index": "211321"
                    },
                    {
                        "value": "建平县",
                        "label": "建平县",
                        "pid": "211300",
                        "index": "211322"
                    },
                    {
                        "value": "喀喇沁左翼蒙古族自治县",
                        "label": "喀喇沁左翼蒙古族自治县",
                        "pid": "211300",
                        "index": "211324"
                    },
                    {
                        "value": "北票市",
                        "label": "北票市",
                        "pid": "211300",
                        "index": "211381"
                    },
                    {
                        "value": "凌源市",
                        "label": "凌源市",
                        "pid": "211300",
                        "index": "211382"
                    }
                ]
            },
            {
                "value": "葫芦岛市",
                "label": "葫芦岛市",
                "pid": "210000",
                "index": "211400",
                "children": [
                    {
                        "value": "连山区",
                        "label": "连山区",
                        "pid": "211400",
                        "index": "211402"
                    },
                    {
                        "value": "龙港区",
                        "label": "龙港区",
                        "pid": "211400",
                        "index": "211403"
                    },
                    {
                        "value": "南票区",
                        "label": "南票区",
                        "pid": "211400",
                        "index": "211404"
                    },
                    {
                        "value": "绥中县",
                        "label": "绥中县",
                        "pid": "211400",
                        "index": "211421"
                    },
                    {
                        "value": "建昌县",
                        "label": "建昌县",
                        "pid": "211400",
                        "index": "211422"
                    },
                    {
                        "value": "兴城市",
                        "label": "兴城市",
                        "pid": "211400",
                        "index": "211481"
                    }
                ]
            }
        ]
    },
    {
        "value": "吉林省",
        "label": "吉林省",
        "pid": "86",
        "index": "220000",
        "children": [
            {
                "value": "长春市",
                "label": "长春市",
                "pid": "220000",
                "index": "220100",
                "children": [
                    {
                        "value": "南关区",
                        "label": "南关区",
                        "pid": "220100",
                        "index": "220102"
                    },
                    {
                        "value": "宽城区",
                        "label": "宽城区",
                        "pid": "220100",
                        "index": "220103"
                    },
                    {
                        "value": "朝阳区",
                        "label": "朝阳区",
                        "pid": "220100",
                        "index": "220104"
                    },
                    {
                        "value": "二道区",
                        "label": "二道区",
                        "pid": "220100",
                        "index": "220105"
                    },
                    {
                        "value": "绿园区",
                        "label": "绿园区",
                        "pid": "220100",
                        "index": "220106"
                    },
                    {
                        "value": "双阳区",
                        "label": "双阳区",
                        "pid": "220100",
                        "index": "220112"
                    },
                    {
                        "value": "九台区",
                        "label": "九台区",
                        "pid": "220100",
                        "index": "220113"
                    },
                    {
                        "value": "农安县",
                        "label": "农安县",
                        "pid": "220100",
                        "index": "220122"
                    },
                    {
                        "value": "长春经济技术开发区",
                        "label": "长春经济技术开发区",
                        "pid": "220100",
                        "index": "220171"
                    },
                    {
                        "value": "长春净月高新技术产业开发区",
                        "label": "长春净月高新技术产业开发区",
                        "pid": "220100",
                        "index": "220172"
                    },
                    {
                        "value": "长春高新技术产业开发区",
                        "label": "长春高新技术产业开发区",
                        "pid": "220100",
                        "index": "220173"
                    },
                    {
                        "value": "长春汽车经济技术开发区",
                        "label": "长春汽车经济技术开发区",
                        "pid": "220100",
                        "index": "220174"
                    },
                    {
                        "value": "榆树市",
                        "label": "榆树市",
                        "pid": "220100",
                        "index": "220182"
                    },
                    {
                        "value": "德惠市",
                        "label": "德惠市",
                        "pid": "220100",
                        "index": "220183"
                    }
                ]
            },
            {
                "value": "吉林市",
                "label": "吉林市",
                "pid": "220000",
                "index": "220200",
                "children": [
                    {
                        "value": "昌邑区",
                        "label": "昌邑区",
                        "pid": "220200",
                        "index": "220202"
                    },
                    {
                        "value": "龙潭区",
                        "label": "龙潭区",
                        "pid": "220200",
                        "index": "220203"
                    },
                    {
                        "value": "船营区",
                        "label": "船营区",
                        "pid": "220200",
                        "index": "220204"
                    },
                    {
                        "value": "丰满区",
                        "label": "丰满区",
                        "pid": "220200",
                        "index": "220211"
                    },
                    {
                        "value": "永吉县",
                        "label": "永吉县",
                        "pid": "220200",
                        "index": "220221"
                    },
                    {
                        "value": "吉林经济开发区",
                        "label": "吉林经济开发区",
                        "pid": "220200",
                        "index": "220271"
                    },
                    {
                        "value": "吉林高新技术产业开发区",
                        "label": "吉林高新技术产业开发区",
                        "pid": "220200",
                        "index": "220272"
                    },
                    {
                        "value": "吉林中国新加坡食品区",
                        "label": "吉林中国新加坡食品区",
                        "pid": "220200",
                        "index": "220273"
                    },
                    {
                        "value": "蛟河市",
                        "label": "蛟河市",
                        "pid": "220200",
                        "index": "220281"
                    },
                    {
                        "value": "桦甸市",
                        "label": "桦甸市",
                        "pid": "220200",
                        "index": "220282"
                    },
                    {
                        "value": "舒兰市",
                        "label": "舒兰市",
                        "pid": "220200",
                        "index": "220283"
                    },
                    {
                        "value": "磐石市",
                        "label": "磐石市",
                        "pid": "220200",
                        "index": "220284"
                    }
                ]
            },
            {
                "value": "四平市",
                "label": "四平市",
                "pid": "220000",
                "index": "220300",
                "children": [
                    {
                        "value": "铁西区",
                        "label": "铁西区",
                        "pid": "220300",
                        "index": "220302"
                    },
                    {
                        "value": "铁东区",
                        "label": "铁东区",
                        "pid": "220300",
                        "index": "220303"
                    },
                    {
                        "value": "梨树县",
                        "label": "梨树县",
                        "pid": "220300",
                        "index": "220322"
                    },
                    {
                        "value": "伊通满族自治县",
                        "label": "伊通满族自治县",
                        "pid": "220300",
                        "index": "220323"
                    },
                    {
                        "value": "公主岭市",
                        "label": "公主岭市",
                        "pid": "220300",
                        "index": "220381"
                    },
                    {
                        "value": "双辽市",
                        "label": "双辽市",
                        "pid": "220300",
                        "index": "220382"
                    }
                ]
            },
            {
                "value": "辽源市",
                "label": "辽源市",
                "pid": "220000",
                "index": "220400",
                "children": [
                    {
                        "value": "龙山区",
                        "label": "龙山区",
                        "pid": "220400",
                        "index": "220402"
                    },
                    {
                        "value": "西安区",
                        "label": "西安区",
                        "pid": "220400",
                        "index": "220403"
                    },
                    {
                        "value": "东丰县",
                        "label": "东丰县",
                        "pid": "220400",
                        "index": "220421"
                    },
                    {
                        "value": "东辽县",
                        "label": "东辽县",
                        "pid": "220400",
                        "index": "220422"
                    }
                ]
            },
            {
                "value": "通化市",
                "label": "通化市",
                "pid": "220000",
                "index": "220500",
                "children": [
                    {
                        "value": "东昌区",
                        "label": "东昌区",
                        "pid": "220500",
                        "index": "220502"
                    },
                    {
                        "value": "二道江区",
                        "label": "二道江区",
                        "pid": "220500",
                        "index": "220503"
                    },
                    {
                        "value": "通化县",
                        "label": "通化县",
                        "pid": "220500",
                        "index": "220521"
                    },
                    {
                        "value": "辉南县",
                        "label": "辉南县",
                        "pid": "220500",
                        "index": "220523"
                    },
                    {
                        "value": "柳河县",
                        "label": "柳河县",
                        "pid": "220500",
                        "index": "220524"
                    },
                    {
                        "value": "梅河口市",
                        "label": "梅河口市",
                        "pid": "220500",
                        "index": "220581"
                    },
                    {
                        "value": "集安市",
                        "label": "集安市",
                        "pid": "220500",
                        "index": "220582"
                    }
                ]
            },
            {
                "value": "白山市",
                "label": "白山市",
                "pid": "220000",
                "index": "220600",
                "children": [
                    {
                        "value": "浑江区",
                        "label": "浑江区",
                        "pid": "220600",
                        "index": "220602"
                    },
                    {
                        "value": "江源区",
                        "label": "江源区",
                        "pid": "220600",
                        "index": "220605"
                    },
                    {
                        "value": "抚松县",
                        "label": "抚松县",
                        "pid": "220600",
                        "index": "220621"
                    },
                    {
                        "value": "靖宇县",
                        "label": "靖宇县",
                        "pid": "220600",
                        "index": "220622"
                    },
                    {
                        "value": "长白朝鲜族自治县",
                        "label": "长白朝鲜族自治县",
                        "pid": "220600",
                        "index": "220623"
                    },
                    {
                        "value": "临江市",
                        "label": "临江市",
                        "pid": "220600",
                        "index": "220681"
                    }
                ]
            },
            {
                "value": "松原市",
                "label": "松原市",
                "pid": "220000",
                "index": "220700",
                "children": [
                    {
                        "value": "宁江区",
                        "label": "宁江区",
                        "pid": "220700",
                        "index": "220702"
                    },
                    {
                        "value": "前郭尔罗斯蒙古族自治县",
                        "label": "前郭尔罗斯蒙古族自治县",
                        "pid": "220700",
                        "index": "220721"
                    },
                    {
                        "value": "长岭县",
                        "label": "长岭县",
                        "pid": "220700",
                        "index": "220722"
                    },
                    {
                        "value": "乾安县",
                        "label": "乾安县",
                        "pid": "220700",
                        "index": "220723"
                    },
                    {
                        "value": "吉林松原经济开发区",
                        "label": "吉林松原经济开发区",
                        "pid": "220700",
                        "index": "220771"
                    },
                    {
                        "value": "扶余市",
                        "label": "扶余市",
                        "pid": "220700",
                        "index": "220781"
                    }
                ]
            },
            {
                "value": "白城市",
                "label": "白城市",
                "pid": "220000",
                "index": "220800",
                "children": [
                    {
                        "value": "洮北区",
                        "label": "洮北区",
                        "pid": "220800",
                        "index": "220802"
                    },
                    {
                        "value": "镇赉县",
                        "label": "镇赉县",
                        "pid": "220800",
                        "index": "220821"
                    },
                    {
                        "value": "通榆县",
                        "label": "通榆县",
                        "pid": "220800",
                        "index": "220822"
                    },
                    {
                        "value": "吉林白城经济开发区",
                        "label": "吉林白城经济开发区",
                        "pid": "220800",
                        "index": "220871"
                    },
                    {
                        "value": "洮南市",
                        "label": "洮南市",
                        "pid": "220800",
                        "index": "220881"
                    },
                    {
                        "value": "大安市",
                        "label": "大安市",
                        "pid": "220800",
                        "index": "220882"
                    }
                ]
            },
            {
                "value": "延边朝鲜族自治州",
                "label": "延边朝鲜族自治州",
                "pid": "220000",
                "index": "222400",
                "children": [
                    {
                        "value": "延吉市",
                        "label": "延吉市",
                        "pid": "222400",
                        "index": "222401"
                    },
                    {
                        "value": "图们市",
                        "label": "图们市",
                        "pid": "222400",
                        "index": "222402"
                    },
                    {
                        "value": "敦化市",
                        "label": "敦化市",
                        "pid": "222400",
                        "index": "222403"
                    },
                    {
                        "value": "珲春市",
                        "label": "珲春市",
                        "pid": "222400",
                        "index": "222404"
                    },
                    {
                        "value": "龙井市",
                        "label": "龙井市",
                        "pid": "222400",
                        "index": "222405"
                    },
                    {
                        "value": "和龙市",
                        "label": "和龙市",
                        "pid": "222400",
                        "index": "222406"
                    },
                    {
                        "value": "汪清县",
                        "label": "汪清县",
                        "pid": "222400",
                        "index": "222424"
                    },
                    {
                        "value": "安图县",
                        "label": "安图县",
                        "pid": "222400",
                        "index": "222426"
                    }
                ]
            }
        ]
    },
    {
        "value": "黑龙江省",
        "label": "黑龙江省",
        "pid": "86",
        "index": "230000",
        "children": [
            {
                "value": "哈尔滨市",
                "label": "哈尔滨市",
                "pid": "230000",
                "index": "230100",
                "children": [
                    {
                        "value": "道里区",
                        "label": "道里区",
                        "pid": "230100",
                        "index": "230102"
                    },
                    {
                        "value": "南岗区",
                        "label": "南岗区",
                        "pid": "230100",
                        "index": "230103"
                    },
                    {
                        "value": "道外区",
                        "label": "道外区",
                        "pid": "230100",
                        "index": "230104"
                    },
                    {
                        "value": "平房区",
                        "label": "平房区",
                        "pid": "230100",
                        "index": "230108"
                    },
                    {
                        "value": "松北区",
                        "label": "松北区",
                        "pid": "230100",
                        "index": "230109"
                    },
                    {
                        "value": "香坊区",
                        "label": "香坊区",
                        "pid": "230100",
                        "index": "230110"
                    },
                    {
                        "value": "呼兰区",
                        "label": "呼兰区",
                        "pid": "230100",
                        "index": "230111"
                    },
                    {
                        "value": "阿城区",
                        "label": "阿城区",
                        "pid": "230100",
                        "index": "230112"
                    },
                    {
                        "value": "双城区",
                        "label": "双城区",
                        "pid": "230100",
                        "index": "230113"
                    },
                    {
                        "value": "依兰县",
                        "label": "依兰县",
                        "pid": "230100",
                        "index": "230123"
                    },
                    {
                        "value": "方正县",
                        "label": "方正县",
                        "pid": "230100",
                        "index": "230124"
                    },
                    {
                        "value": "宾县",
                        "label": "宾县",
                        "pid": "230100",
                        "index": "230125"
                    },
                    {
                        "value": "巴彦县",
                        "label": "巴彦县",
                        "pid": "230100",
                        "index": "230126"
                    },
                    {
                        "value": "木兰县",
                        "label": "木兰县",
                        "pid": "230100",
                        "index": "230127"
                    },
                    {
                        "value": "通河县",
                        "label": "通河县",
                        "pid": "230100",
                        "index": "230128"
                    },
                    {
                        "value": "延寿县",
                        "label": "延寿县",
                        "pid": "230100",
                        "index": "230129"
                    },
                    {
                        "value": "尚志市",
                        "label": "尚志市",
                        "pid": "230100",
                        "index": "230183"
                    },
                    {
                        "value": "五常市",
                        "label": "五常市",
                        "pid": "230100",
                        "index": "230184"
                    }
                ]
            },
            {
                "value": "齐齐哈尔市",
                "label": "齐齐哈尔市",
                "pid": "230000",
                "index": "230200",
                "children": [
                    {
                        "value": "龙沙区",
                        "label": "龙沙区",
                        "pid": "230200",
                        "index": "230202"
                    },
                    {
                        "value": "建华区",
                        "label": "建华区",
                        "pid": "230200",
                        "index": "230203"
                    },
                    {
                        "value": "铁锋区",
                        "label": "铁锋区",
                        "pid": "230200",
                        "index": "230204"
                    },
                    {
                        "value": "昂昂溪区",
                        "label": "昂昂溪区",
                        "pid": "230200",
                        "index": "230205"
                    },
                    {
                        "value": "富拉尔基区",
                        "label": "富拉尔基区",
                        "pid": "230200",
                        "index": "230206"
                    },
                    {
                        "value": "碾子山区",
                        "label": "碾子山区",
                        "pid": "230200",
                        "index": "230207"
                    },
                    {
                        "value": "梅里斯达斡尔族区",
                        "label": "梅里斯达斡尔族区",
                        "pid": "230200",
                        "index": "230208"
                    },
                    {
                        "value": "龙江县",
                        "label": "龙江县",
                        "pid": "230200",
                        "index": "230221"
                    },
                    {
                        "value": "依安县",
                        "label": "依安县",
                        "pid": "230200",
                        "index": "230223"
                    },
                    {
                        "value": "泰来县",
                        "label": "泰来县",
                        "pid": "230200",
                        "index": "230224"
                    },
                    {
                        "value": "甘南县",
                        "label": "甘南县",
                        "pid": "230200",
                        "index": "230225"
                    },
                    {
                        "value": "富裕县",
                        "label": "富裕县",
                        "pid": "230200",
                        "index": "230227"
                    },
                    {
                        "value": "克山县",
                        "label": "克山县",
                        "pid": "230200",
                        "index": "230229"
                    },
                    {
                        "value": "克东县",
                        "label": "克东县",
                        "pid": "230200",
                        "index": "230230"
                    },
                    {
                        "value": "拜泉县",
                        "label": "拜泉县",
                        "pid": "230200",
                        "index": "230231"
                    },
                    {
                        "value": "讷河市",
                        "label": "讷河市",
                        "pid": "230200",
                        "index": "230281"
                    }
                ]
            },
            {
                "value": "鸡西市",
                "label": "鸡西市",
                "pid": "230000",
                "index": "230300",
                "children": [
                    {
                        "value": "鸡冠区",
                        "label": "鸡冠区",
                        "pid": "230300",
                        "index": "230302"
                    },
                    {
                        "value": "恒山区",
                        "label": "恒山区",
                        "pid": "230300",
                        "index": "230303"
                    },
                    {
                        "value": "滴道区",
                        "label": "滴道区",
                        "pid": "230300",
                        "index": "230304"
                    },
                    {
                        "value": "梨树区",
                        "label": "梨树区",
                        "pid": "230300",
                        "index": "230305"
                    },
                    {
                        "value": "城子河区",
                        "label": "城子河区",
                        "pid": "230300",
                        "index": "230306"
                    },
                    {
                        "value": "麻山区",
                        "label": "麻山区",
                        "pid": "230300",
                        "index": "230307"
                    },
                    {
                        "value": "鸡东县",
                        "label": "鸡东县",
                        "pid": "230300",
                        "index": "230321"
                    },
                    {
                        "value": "虎林市",
                        "label": "虎林市",
                        "pid": "230300",
                        "index": "230381"
                    },
                    {
                        "value": "密山市",
                        "label": "密山市",
                        "pid": "230300",
                        "index": "230382"
                    }
                ]
            },
            {
                "value": "鹤岗市",
                "label": "鹤岗市",
                "pid": "230000",
                "index": "230400",
                "children": [
                    {
                        "value": "向阳区",
                        "label": "向阳区",
                        "pid": "230400",
                        "index": "230402"
                    },
                    {
                        "value": "工农区",
                        "label": "工农区",
                        "pid": "230400",
                        "index": "230403"
                    },
                    {
                        "value": "南山区",
                        "label": "南山区",
                        "pid": "230400",
                        "index": "230404"
                    },
                    {
                        "value": "兴安区",
                        "label": "兴安区",
                        "pid": "230400",
                        "index": "230405"
                    },
                    {
                        "value": "东山区",
                        "label": "东山区",
                        "pid": "230400",
                        "index": "230406"
                    },
                    {
                        "value": "兴山区",
                        "label": "兴山区",
                        "pid": "230400",
                        "index": "230407"
                    },
                    {
                        "value": "萝北县",
                        "label": "萝北县",
                        "pid": "230400",
                        "index": "230421"
                    },
                    {
                        "value": "绥滨县",
                        "label": "绥滨县",
                        "pid": "230400",
                        "index": "230422"
                    }
                ]
            },
            {
                "value": "双鸭山市",
                "label": "双鸭山市",
                "pid": "230000",
                "index": "230500",
                "children": [
                    {
                        "value": "尖山区",
                        "label": "尖山区",
                        "pid": "230500",
                        "index": "230502"
                    },
                    {
                        "value": "岭东区",
                        "label": "岭东区",
                        "pid": "230500",
                        "index": "230503"
                    },
                    {
                        "value": "四方台区",
                        "label": "四方台区",
                        "pid": "230500",
                        "index": "230505"
                    },
                    {
                        "value": "宝山区",
                        "label": "宝山区",
                        "pid": "230500",
                        "index": "230506"
                    },
                    {
                        "value": "集贤县",
                        "label": "集贤县",
                        "pid": "230500",
                        "index": "230521"
                    },
                    {
                        "value": "友谊县",
                        "label": "友谊县",
                        "pid": "230500",
                        "index": "230522"
                    },
                    {
                        "value": "宝清县",
                        "label": "宝清县",
                        "pid": "230500",
                        "index": "230523"
                    },
                    {
                        "value": "饶河县",
                        "label": "饶河县",
                        "pid": "230500",
                        "index": "230524"
                    }
                ]
            },
            {
                "value": "大庆市",
                "label": "大庆市",
                "pid": "230000",
                "index": "230600",
                "children": [
                    {
                        "value": "萨尔图区",
                        "label": "萨尔图区",
                        "pid": "230600",
                        "index": "230602"
                    },
                    {
                        "value": "龙凤区",
                        "label": "龙凤区",
                        "pid": "230600",
                        "index": "230603"
                    },
                    {
                        "value": "让胡路区",
                        "label": "让胡路区",
                        "pid": "230600",
                        "index": "230604"
                    },
                    {
                        "value": "红岗区",
                        "label": "红岗区",
                        "pid": "230600",
                        "index": "230605"
                    },
                    {
                        "value": "大同区",
                        "label": "大同区",
                        "pid": "230600",
                        "index": "230606"
                    },
                    {
                        "value": "肇州县",
                        "label": "肇州县",
                        "pid": "230600",
                        "index": "230621"
                    },
                    {
                        "value": "肇源县",
                        "label": "肇源县",
                        "pid": "230600",
                        "index": "230622"
                    },
                    {
                        "value": "林甸县",
                        "label": "林甸县",
                        "pid": "230600",
                        "index": "230623"
                    },
                    {
                        "value": "杜尔伯特蒙古族自治县",
                        "label": "杜尔伯特蒙古族自治县",
                        "pid": "230600",
                        "index": "230624"
                    },
                    {
                        "value": "大庆高新技术产业开发区",
                        "label": "大庆高新技术产业开发区",
                        "pid": "230600",
                        "index": "230671"
                    }
                ]
            },
            {
                "value": "伊春市",
                "label": "伊春市",
                "pid": "230000",
                "index": "230700",
                "children": [
                    {
                        "value": "伊美区",
                        "label": "伊美区",
                        "pid": "230700",
                        "index": "230717"
                    },
                    {
                        "value": "乌翠区",
                        "label": "乌翠区",
                        "pid": "230700",
                        "index": "230718"
                    },
                    {
                        "value": "友好区",
                        "label": "友好区",
                        "pid": "230700",
                        "index": "230719"
                    },
                    {
                        "value": "嘉荫县",
                        "label": "嘉荫县",
                        "pid": "230700",
                        "index": "230722"
                    },
                    {
                        "value": "汤旺县",
                        "label": "汤旺县",
                        "pid": "230700",
                        "index": "230723"
                    },
                    {
                        "value": "丰林县",
                        "label": "丰林县",
                        "pid": "230700",
                        "index": "230724"
                    },
                    {
                        "value": "大箐山县",
                        "label": "大箐山县",
                        "pid": "230700",
                        "index": "230725"
                    },
                    {
                        "value": "南岔县",
                        "label": "南岔县",
                        "pid": "230700",
                        "index": "230726"
                    },
                    {
                        "value": "金林区",
                        "label": "金林区",
                        "pid": "230700",
                        "index": "230751"
                    },
                    {
                        "value": "铁力市",
                        "label": "铁力市",
                        "pid": "230700",
                        "index": "230781"
                    }
                ]
            },
            {
                "value": "佳木斯市",
                "label": "佳木斯市",
                "pid": "230000",
                "index": "230800",
                "children": [
                    {
                        "value": "向阳区",
                        "label": "向阳区",
                        "pid": "230800",
                        "index": "230803"
                    },
                    {
                        "value": "前进区",
                        "label": "前进区",
                        "pid": "230800",
                        "index": "230804"
                    },
                    {
                        "value": "东风区",
                        "label": "东风区",
                        "pid": "230800",
                        "index": "230805"
                    },
                    {
                        "value": "郊区",
                        "label": "郊区",
                        "pid": "230800",
                        "index": "230811"
                    },
                    {
                        "value": "桦南县",
                        "label": "桦南县",
                        "pid": "230800",
                        "index": "230822"
                    },
                    {
                        "value": "桦川县",
                        "label": "桦川县",
                        "pid": "230800",
                        "index": "230826"
                    },
                    {
                        "value": "汤原县",
                        "label": "汤原县",
                        "pid": "230800",
                        "index": "230828"
                    },
                    {
                        "value": "同江市",
                        "label": "同江市",
                        "pid": "230800",
                        "index": "230881"
                    },
                    {
                        "value": "富锦市",
                        "label": "富锦市",
                        "pid": "230800",
                        "index": "230882"
                    },
                    {
                        "value": "抚远市",
                        "label": "抚远市",
                        "pid": "230800",
                        "index": "230883"
                    }
                ]
            },
            {
                "value": "七台河市",
                "label": "七台河市",
                "pid": "230000",
                "index": "230900",
                "children": [
                    {
                        "value": "新兴区",
                        "label": "新兴区",
                        "pid": "230900",
                        "index": "230902"
                    },
                    {
                        "value": "桃山区",
                        "label": "桃山区",
                        "pid": "230900",
                        "index": "230903"
                    },
                    {
                        "value": "茄子河区",
                        "label": "茄子河区",
                        "pid": "230900",
                        "index": "230904"
                    },
                    {
                        "value": "勃利县",
                        "label": "勃利县",
                        "pid": "230900",
                        "index": "230921"
                    }
                ]
            },
            {
                "value": "牡丹江市",
                "label": "牡丹江市",
                "pid": "230000",
                "index": "231000",
                "children": [
                    {
                        "value": "东安区",
                        "label": "东安区",
                        "pid": "231000",
                        "index": "231002"
                    },
                    {
                        "value": "阳明区",
                        "label": "阳明区",
                        "pid": "231000",
                        "index": "231003"
                    },
                    {
                        "value": "爱民区",
                        "label": "爱民区",
                        "pid": "231000",
                        "index": "231004"
                    },
                    {
                        "value": "西安区",
                        "label": "西安区",
                        "pid": "231000",
                        "index": "231005"
                    },
                    {
                        "value": "林口县",
                        "label": "林口县",
                        "pid": "231000",
                        "index": "231025"
                    },
                    {
                        "value": "牡丹江经济技术开发区",
                        "label": "牡丹江经济技术开发区",
                        "pid": "231000",
                        "index": "231071"
                    },
                    {
                        "value": "绥芬河市",
                        "label": "绥芬河市",
                        "pid": "231000",
                        "index": "231081"
                    },
                    {
                        "value": "海林市",
                        "label": "海林市",
                        "pid": "231000",
                        "index": "231083"
                    },
                    {
                        "value": "宁安市",
                        "label": "宁安市",
                        "pid": "231000",
                        "index": "231084"
                    },
                    {
                        "value": "穆棱市",
                        "label": "穆棱市",
                        "pid": "231000",
                        "index": "231085"
                    },
                    {
                        "value": "东宁市",
                        "label": "东宁市",
                        "pid": "231000",
                        "index": "231086"
                    }
                ]
            },
            {
                "value": "黑河市",
                "label": "黑河市",
                "pid": "230000",
                "index": "231100",
                "children": [
                    {
                        "value": "爱辉区",
                        "label": "爱辉区",
                        "pid": "231100",
                        "index": "231102"
                    },
                    {
                        "value": "逊克县",
                        "label": "逊克县",
                        "pid": "231100",
                        "index": "231123"
                    },
                    {
                        "value": "孙吴县",
                        "label": "孙吴县",
                        "pid": "231100",
                        "index": "231124"
                    },
                    {
                        "value": "北安市",
                        "label": "北安市",
                        "pid": "231100",
                        "index": "231181"
                    },
                    {
                        "value": "五大连池市",
                        "label": "五大连池市",
                        "pid": "231100",
                        "index": "231182"
                    },
                    {
                        "value": "嫩江市",
                        "label": "嫩江市",
                        "pid": "231100",
                        "index": "231183"
                    }
                ]
            },
            {
                "value": "绥化市",
                "label": "绥化市",
                "pid": "230000",
                "index": "231200",
                "children": [
                    {
                        "value": "北林区",
                        "label": "北林区",
                        "pid": "231200",
                        "index": "231202"
                    },
                    {
                        "value": "望奎县",
                        "label": "望奎县",
                        "pid": "231200",
                        "index": "231221"
                    },
                    {
                        "value": "兰西县",
                        "label": "兰西县",
                        "pid": "231200",
                        "index": "231222"
                    },
                    {
                        "value": "青冈县",
                        "label": "青冈县",
                        "pid": "231200",
                        "index": "231223"
                    },
                    {
                        "value": "庆安县",
                        "label": "庆安县",
                        "pid": "231200",
                        "index": "231224"
                    },
                    {
                        "value": "明水县",
                        "label": "明水县",
                        "pid": "231200",
                        "index": "231225"
                    },
                    {
                        "value": "绥棱县",
                        "label": "绥棱县",
                        "pid": "231200",
                        "index": "231226"
                    },
                    {
                        "value": "安达市",
                        "label": "安达市",
                        "pid": "231200",
                        "index": "231281"
                    },
                    {
                        "value": "肇东市",
                        "label": "肇东市",
                        "pid": "231200",
                        "index": "231282"
                    },
                    {
                        "value": "海伦市",
                        "label": "海伦市",
                        "pid": "231200",
                        "index": "231283"
                    }
                ]
            },
            {
                "value": "大兴安岭地区",
                "label": "大兴安岭地区",
                "pid": "230000",
                "index": "232700",
                "children": [
                    {
                        "value": "漠河市",
                        "label": "漠河市",
                        "pid": "232700",
                        "index": "232701"
                    },
                    {
                        "value": "呼玛县",
                        "label": "呼玛县",
                        "pid": "232700",
                        "index": "232721"
                    },
                    {
                        "value": "塔河县",
                        "label": "塔河县",
                        "pid": "232700",
                        "index": "232722"
                    },
                    {
                        "value": "加格达奇区",
                        "label": "加格达奇区",
                        "pid": "232700",
                        "index": "232761"
                    },
                    {
                        "value": "松岭区",
                        "label": "松岭区",
                        "pid": "232700",
                        "index": "232762"
                    },
                    {
                        "value": "新林区",
                        "label": "新林区",
                        "pid": "232700",
                        "index": "232763"
                    },
                    {
                        "value": "呼中区",
                        "label": "呼中区",
                        "pid": "232700",
                        "index": "232764"
                    }
                ]
            }
        ]
    },
    {
        "value": "上海市",
        "label": "上海市",
        "pid": "86",
        "index": "310000",
        "children": [
            {
                "value": "上海市",
                "label": "上海市",
                "pid": "310000",
                "index": "310100",
                "children": [
                    {
                        "value": "黄浦区",
                        "label": "黄浦区",
                        "pid": "310100",
                        "index": "310101"
                    },
                    {
                        "value": "徐汇区",
                        "label": "徐汇区",
                        "pid": "310100",
                        "index": "310104"
                    },
                    {
                        "value": "长宁区",
                        "label": "长宁区",
                        "pid": "310100",
                        "index": "310105"
                    },
                    {
                        "value": "静安区",
                        "label": "静安区",
                        "pid": "310100",
                        "index": "310106"
                    },
                    {
                        "value": "普陀区",
                        "label": "普陀区",
                        "pid": "310100",
                        "index": "310107"
                    },
                    {
                        "value": "虹口区",
                        "label": "虹口区",
                        "pid": "310100",
                        "index": "310109"
                    },
                    {
                        "value": "杨浦区",
                        "label": "杨浦区",
                        "pid": "310100",
                        "index": "310110"
                    },
                    {
                        "value": "闵行区",
                        "label": "闵行区",
                        "pid": "310100",
                        "index": "310112"
                    },
                    {
                        "value": "宝山区",
                        "label": "宝山区",
                        "pid": "310100",
                        "index": "310113"
                    },
                    {
                        "value": "嘉定区",
                        "label": "嘉定区",
                        "pid": "310100",
                        "index": "310114"
                    },
                    {
                        "value": "浦东新区",
                        "label": "浦东新区",
                        "pid": "310100",
                        "index": "310115"
                    },
                    {
                        "value": "金山区",
                        "label": "金山区",
                        "pid": "310100",
                        "index": "310116"
                    },
                    {
                        "value": "松江区",
                        "label": "松江区",
                        "pid": "310100",
                        "index": "310117"
                    },
                    {
                        "value": "青浦区",
                        "label": "青浦区",
                        "pid": "310100",
                        "index": "310118"
                    },
                    {
                        "value": "奉贤区",
                        "label": "奉贤区",
                        "pid": "310100",
                        "index": "310120"
                    },
                    {
                        "value": "崇明区",
                        "label": "崇明区",
                        "pid": "310100",
                        "index": "310151"
                    }
                ]
            }
        ]
    },
    {
        "value": "江苏省",
        "label": "江苏省",
        "pid": "86",
        "index": "320000",
        "children": [
            {
                "value": "南京市",
                "label": "南京市",
                "pid": "320000",
                "index": "320100",
                "children": [
                    {
                        "value": "玄武区",
                        "label": "玄武区",
                        "pid": "320100",
                        "index": "320102"
                    },
                    {
                        "value": "秦淮区",
                        "label": "秦淮区",
                        "pid": "320100",
                        "index": "320104"
                    },
                    {
                        "value": "建邺区",
                        "label": "建邺区",
                        "pid": "320100",
                        "index": "320105"
                    },
                    {
                        "value": "鼓楼区",
                        "label": "鼓楼区",
                        "pid": "320100",
                        "index": "320106"
                    },
                    {
                        "value": "浦口区",
                        "label": "浦口区",
                        "pid": "320100",
                        "index": "320111"
                    },
                    {
                        "value": "栖霞区",
                        "label": "栖霞区",
                        "pid": "320100",
                        "index": "320113"
                    },
                    {
                        "value": "雨花台区",
                        "label": "雨花台区",
                        "pid": "320100",
                        "index": "320114"
                    },
                    {
                        "value": "江宁区",
                        "label": "江宁区",
                        "pid": "320100",
                        "index": "320115"
                    },
                    {
                        "value": "六合区",
                        "label": "六合区",
                        "pid": "320100",
                        "index": "320116"
                    },
                    {
                        "value": "溧水区",
                        "label": "溧水区",
                        "pid": "320100",
                        "index": "320117"
                    },
                    {
                        "value": "高淳区",
                        "label": "高淳区",
                        "pid": "320100",
                        "index": "320118"
                    }
                ]
            },
            {
                "value": "无锡市",
                "label": "无锡市",
                "pid": "320000",
                "index": "320200",
                "children": [
                    {
                        "value": "锡山区",
                        "label": "锡山区",
                        "pid": "320200",
                        "index": "320205"
                    },
                    {
                        "value": "惠山区",
                        "label": "惠山区",
                        "pid": "320200",
                        "index": "320206"
                    },
                    {
                        "value": "滨湖区",
                        "label": "滨湖区",
                        "pid": "320200",
                        "index": "320211"
                    },
                    {
                        "value": "梁溪区",
                        "label": "梁溪区",
                        "pid": "320200",
                        "index": "320213"
                    },
                    {
                        "value": "新吴区",
                        "label": "新吴区",
                        "pid": "320200",
                        "index": "320214"
                    },
                    {
                        "value": "江阴市",
                        "label": "江阴市",
                        "pid": "320200",
                        "index": "320281"
                    },
                    {
                        "value": "宜兴市",
                        "label": "宜兴市",
                        "pid": "320200",
                        "index": "320282"
                    }
                ]
            },
            {
                "value": "徐州市",
                "label": "徐州市",
                "pid": "320000",
                "index": "320300",
                "children": [
                    {
                        "value": "鼓楼区",
                        "label": "鼓楼区",
                        "pid": "320300",
                        "index": "320302"
                    },
                    {
                        "value": "云龙区",
                        "label": "云龙区",
                        "pid": "320300",
                        "index": "320303"
                    },
                    {
                        "value": "贾汪区",
                        "label": "贾汪区",
                        "pid": "320300",
                        "index": "320305"
                    },
                    {
                        "value": "泉山区",
                        "label": "泉山区",
                        "pid": "320300",
                        "index": "320311"
                    },
                    {
                        "value": "铜山区",
                        "label": "铜山区",
                        "pid": "320300",
                        "index": "320312"
                    },
                    {
                        "value": "丰县",
                        "label": "丰县",
                        "pid": "320300",
                        "index": "320321"
                    },
                    {
                        "value": "沛县",
                        "label": "沛县",
                        "pid": "320300",
                        "index": "320322"
                    },
                    {
                        "value": "睢宁县",
                        "label": "睢宁县",
                        "pid": "320300",
                        "index": "320324"
                    },
                    {
                        "value": "徐州经济技术开发区",
                        "label": "徐州经济技术开发区",
                        "pid": "320300",
                        "index": "320371"
                    },
                    {
                        "value": "新沂市",
                        "label": "新沂市",
                        "pid": "320300",
                        "index": "320381"
                    },
                    {
                        "value": "邳州市",
                        "label": "邳州市",
                        "pid": "320300",
                        "index": "320382"
                    }
                ]
            },
            {
                "value": "常州市",
                "label": "常州市",
                "pid": "320000",
                "index": "320400",
                "children": [
                    {
                        "value": "天宁区",
                        "label": "天宁区",
                        "pid": "320400",
                        "index": "320402"
                    },
                    {
                        "value": "钟楼区",
                        "label": "钟楼区",
                        "pid": "320400",
                        "index": "320404"
                    },
                    {
                        "value": "新北区",
                        "label": "新北区",
                        "pid": "320400",
                        "index": "320411"
                    },
                    {
                        "value": "武进区",
                        "label": "武进区",
                        "pid": "320400",
                        "index": "320412"
                    },
                    {
                        "value": "金坛区",
                        "label": "金坛区",
                        "pid": "320400",
                        "index": "320413"
                    },
                    {
                        "value": "溧阳市",
                        "label": "溧阳市",
                        "pid": "320400",
                        "index": "320481"
                    }
                ]
            },
            {
                "value": "苏州市",
                "label": "苏州市",
                "pid": "320000",
                "index": "320500",
                "children": [
                    {
                        "value": "虎丘区",
                        "label": "虎丘区",
                        "pid": "320500",
                        "index": "320505"
                    },
                    {
                        "value": "吴中区",
                        "label": "吴中区",
                        "pid": "320500",
                        "index": "320506"
                    },
                    {
                        "value": "相城区",
                        "label": "相城区",
                        "pid": "320500",
                        "index": "320507"
                    },
                    {
                        "value": "姑苏区",
                        "label": "姑苏区",
                        "pid": "320500",
                        "index": "320508"
                    },
                    {
                        "value": "吴江区",
                        "label": "吴江区",
                        "pid": "320500",
                        "index": "320509"
                    },
                    {
                        "value": "苏州工业园区",
                        "label": "苏州工业园区",
                        "pid": "320500",
                        "index": "320571"
                    },
                    {
                        "value": "常熟市",
                        "label": "常熟市",
                        "pid": "320500",
                        "index": "320581"
                    },
                    {
                        "value": "张家港市",
                        "label": "张家港市",
                        "pid": "320500",
                        "index": "320582"
                    },
                    {
                        "value": "昆山市",
                        "label": "昆山市",
                        "pid": "320500",
                        "index": "320583"
                    },
                    {
                        "value": "太仓市",
                        "label": "太仓市",
                        "pid": "320500",
                        "index": "320585"
                    }
                ]
            },
            {
                "value": "南通市",
                "label": "南通市",
                "pid": "320000",
                "index": "320600",
                "children": [
                    {
                        "value": "崇川区",
                        "label": "崇川区",
                        "pid": "320600",
                        "index": "320602"
                    },
                    {
                        "value": "港闸区",
                        "label": "港闸区",
                        "pid": "320600",
                        "index": "320611"
                    },
                    {
                        "value": "通州区",
                        "label": "通州区",
                        "pid": "320600",
                        "index": "320612"
                    },
                    {
                        "value": "如东县",
                        "label": "如东县",
                        "pid": "320600",
                        "index": "320623"
                    },
                    {
                        "value": "南通经济技术开发区",
                        "label": "南通经济技术开发区",
                        "pid": "320600",
                        "index": "320671"
                    },
                    {
                        "value": "启东市",
                        "label": "启东市",
                        "pid": "320600",
                        "index": "320681"
                    },
                    {
                        "value": "如皋市",
                        "label": "如皋市",
                        "pid": "320600",
                        "index": "320682"
                    },
                    {
                        "value": "海门市",
                        "label": "海门市",
                        "pid": "320600",
                        "index": "320684"
                    },
                    {
                        "value": "海安市",
                        "label": "海安市",
                        "pid": "320600",
                        "index": "320685"
                    }
                ]
            },
            {
                "value": "连云港市",
                "label": "连云港市",
                "pid": "320000",
                "index": "320700",
                "children": [
                    {
                        "value": "连云区",
                        "label": "连云区",
                        "pid": "320700",
                        "index": "320703"
                    },
                    {
                        "value": "海州区",
                        "label": "海州区",
                        "pid": "320700",
                        "index": "320706"
                    },
                    {
                        "value": "赣榆区",
                        "label": "赣榆区",
                        "pid": "320700",
                        "index": "320707"
                    },
                    {
                        "value": "东海县",
                        "label": "东海县",
                        "pid": "320700",
                        "index": "320722"
                    },
                    {
                        "value": "灌云县",
                        "label": "灌云县",
                        "pid": "320700",
                        "index": "320723"
                    },
                    {
                        "value": "灌南县",
                        "label": "灌南县",
                        "pid": "320700",
                        "index": "320724"
                    },
                    {
                        "value": "连云港经济技术开发区",
                        "label": "连云港经济技术开发区",
                        "pid": "320700",
                        "index": "320771"
                    },
                    {
                        "value": "连云港高新技术产业开发区",
                        "label": "连云港高新技术产业开发区",
                        "pid": "320700",
                        "index": "320772"
                    }
                ]
            },
            {
                "value": "淮安市",
                "label": "淮安市",
                "pid": "320000",
                "index": "320800",
                "children": [
                    {
                        "value": "淮安区",
                        "label": "淮安区",
                        "pid": "320800",
                        "index": "320803"
                    },
                    {
                        "value": "淮阴区",
                        "label": "淮阴区",
                        "pid": "320800",
                        "index": "320804"
                    },
                    {
                        "value": "清江浦区",
                        "label": "清江浦区",
                        "pid": "320800",
                        "index": "320812"
                    },
                    {
                        "value": "洪泽区",
                        "label": "洪泽区",
                        "pid": "320800",
                        "index": "320813"
                    },
                    {
                        "value": "涟水县",
                        "label": "涟水县",
                        "pid": "320800",
                        "index": "320826"
                    },
                    {
                        "value": "盱眙县",
                        "label": "盱眙县",
                        "pid": "320800",
                        "index": "320830"
                    },
                    {
                        "value": "金湖县",
                        "label": "金湖县",
                        "pid": "320800",
                        "index": "320831"
                    },
                    {
                        "value": "淮安经济技术开发区",
                        "label": "淮安经济技术开发区",
                        "pid": "320800",
                        "index": "320871"
                    }
                ]
            },
            {
                "value": "盐城市",
                "label": "盐城市",
                "pid": "320000",
                "index": "320900",
                "children": [
                    {
                        "value": "亭湖区",
                        "label": "亭湖区",
                        "pid": "320900",
                        "index": "320902"
                    },
                    {
                        "value": "盐都区",
                        "label": "盐都区",
                        "pid": "320900",
                        "index": "320903"
                    },
                    {
                        "value": "大丰区",
                        "label": "大丰区",
                        "pid": "320900",
                        "index": "320904"
                    },
                    {
                        "value": "响水县",
                        "label": "响水县",
                        "pid": "320900",
                        "index": "320921"
                    },
                    {
                        "value": "滨海县",
                        "label": "滨海县",
                        "pid": "320900",
                        "index": "320922"
                    },
                    {
                        "value": "阜宁县",
                        "label": "阜宁县",
                        "pid": "320900",
                        "index": "320923"
                    },
                    {
                        "value": "射阳县",
                        "label": "射阳县",
                        "pid": "320900",
                        "index": "320924"
                    },
                    {
                        "value": "建湖县",
                        "label": "建湖县",
                        "pid": "320900",
                        "index": "320925"
                    },
                    {
                        "value": "盐城经济技术开发区",
                        "label": "盐城经济技术开发区",
                        "pid": "320900",
                        "index": "320971"
                    },
                    {
                        "value": "东台市",
                        "label": "东台市",
                        "pid": "320900",
                        "index": "320981"
                    }
                ]
            },
            {
                "value": "扬州市",
                "label": "扬州市",
                "pid": "320000",
                "index": "321000",
                "children": [
                    {
                        "value": "广陵区",
                        "label": "广陵区",
                        "pid": "321000",
                        "index": "321002"
                    },
                    {
                        "value": "邗江区",
                        "label": "邗江区",
                        "pid": "321000",
                        "index": "321003"
                    },
                    {
                        "value": "江都区",
                        "label": "江都区",
                        "pid": "321000",
                        "index": "321012"
                    },
                    {
                        "value": "宝应县",
                        "label": "宝应县",
                        "pid": "321000",
                        "index": "321023"
                    },
                    {
                        "value": "扬州经济技术开发区",
                        "label": "扬州经济技术开发区",
                        "pid": "321000",
                        "index": "321071"
                    },
                    {
                        "value": "仪征市",
                        "label": "仪征市",
                        "pid": "321000",
                        "index": "321081"
                    },
                    {
                        "value": "高邮市",
                        "label": "高邮市",
                        "pid": "321000",
                        "index": "321084"
                    }
                ]
            },
            {
                "value": "镇江市",
                "label": "镇江市",
                "pid": "320000",
                "index": "321100",
                "children": [
                    {
                        "value": "京口区",
                        "label": "京口区",
                        "pid": "321100",
                        "index": "321102"
                    },
                    {
                        "value": "润州区",
                        "label": "润州区",
                        "pid": "321100",
                        "index": "321111"
                    },
                    {
                        "value": "丹徒区",
                        "label": "丹徒区",
                        "pid": "321100",
                        "index": "321112"
                    },
                    {
                        "value": "镇江新区",
                        "label": "镇江新区",
                        "pid": "321100",
                        "index": "321171"
                    },
                    {
                        "value": "丹阳市",
                        "label": "丹阳市",
                        "pid": "321100",
                        "index": "321181"
                    },
                    {
                        "value": "扬中市",
                        "label": "扬中市",
                        "pid": "321100",
                        "index": "321182"
                    },
                    {
                        "value": "句容市",
                        "label": "句容市",
                        "pid": "321100",
                        "index": "321183"
                    }
                ]
            },
            {
                "value": "泰州市",
                "label": "泰州市",
                "pid": "320000",
                "index": "321200",
                "children": [
                    {
                        "value": "海陵区",
                        "label": "海陵区",
                        "pid": "321200",
                        "index": "321202"
                    },
                    {
                        "value": "高港区",
                        "label": "高港区",
                        "pid": "321200",
                        "index": "321203"
                    },
                    {
                        "value": "姜堰区",
                        "label": "姜堰区",
                        "pid": "321200",
                        "index": "321204"
                    },
                    {
                        "value": "泰州医药高新技术产业开发区",
                        "label": "泰州医药高新技术产业开发区",
                        "pid": "321200",
                        "index": "321271"
                    },
                    {
                        "value": "兴化市",
                        "label": "兴化市",
                        "pid": "321200",
                        "index": "321281"
                    },
                    {
                        "value": "靖江市",
                        "label": "靖江市",
                        "pid": "321200",
                        "index": "321282"
                    },
                    {
                        "value": "泰兴市",
                        "label": "泰兴市",
                        "pid": "321200",
                        "index": "321283"
                    }
                ]
            },
            {
                "value": "宿迁市",
                "label": "宿迁市",
                "pid": "320000",
                "index": "321300",
                "children": [
                    {
                        "value": "宿城区",
                        "label": "宿城区",
                        "pid": "321300",
                        "index": "321302"
                    },
                    {
                        "value": "宿豫区",
                        "label": "宿豫区",
                        "pid": "321300",
                        "index": "321311"
                    },
                    {
                        "value": "沭阳县",
                        "label": "沭阳县",
                        "pid": "321300",
                        "index": "321322"
                    },
                    {
                        "value": "泗阳县",
                        "label": "泗阳县",
                        "pid": "321300",
                        "index": "321323"
                    },
                    {
                        "value": "泗洪县",
                        "label": "泗洪县",
                        "pid": "321300",
                        "index": "321324"
                    },
                    {
                        "value": "宿迁经济技术开发区",
                        "label": "宿迁经济技术开发区",
                        "pid": "321300",
                        "index": "321371"
                    }
                ]
            }
        ]
    },
    {
        "value": "浙江省",
        "label": "浙江省",
        "pid": "86",
        "index": "330000",
        "children": [
            {
                "value": "杭州市",
                "label": "杭州市",
                "pid": "330000",
                "index": "330100",
                "children": [
                    {
                        "value": "上城区",
                        "label": "上城区",
                        "pid": "330100",
                        "index": "330102"
                    },
                    {
                        "value": "下城区",
                        "label": "下城区",
                        "pid": "330100",
                        "index": "330103"
                    },
                    {
                        "value": "江干区",
                        "label": "江干区",
                        "pid": "330100",
                        "index": "330104"
                    },
                    {
                        "value": "拱墅区",
                        "label": "拱墅区",
                        "pid": "330100",
                        "index": "330105"
                    },
                    {
                        "value": "西湖区",
                        "label": "西湖区",
                        "pid": "330100",
                        "index": "330106"
                    },
                    {
                        "value": "滨江区",
                        "label": "滨江区",
                        "pid": "330100",
                        "index": "330108"
                    },
                    {
                        "value": "萧山区",
                        "label": "萧山区",
                        "pid": "330100",
                        "index": "330109"
                    },
                    {
                        "value": "余杭区",
                        "label": "余杭区",
                        "pid": "330100",
                        "index": "330110"
                    },
                    {
                        "value": "富阳区",
                        "label": "富阳区",
                        "pid": "330100",
                        "index": "330111"
                    },
                    {
                        "value": "临安区",
                        "label": "临安区",
                        "pid": "330100",
                        "index": "330112"
                    },
                    {
                        "value": "临平区",
                        "label": "临平区",
                        "pid": "330100",
                        "index": "330113"
                    },
                    {
                        "value": "桐庐县",
                        "label": "桐庐县",
                        "pid": "330100",
                        "index": "330122"
                    },
                    {
                        "value": "淳安县",
                        "label": "淳安县",
                        "pid": "330100",
                        "index": "330127"
                    },
                    {
                        "value": "建德市",
                        "label": "建德市",
                        "pid": "330100",
                        "index": "330182"
                    },

                ]
            },
            {
                "value": "宁波市",
                "label": "宁波市",
                "pid": "330000",
                "index": "330200",
                "children": [
                    {
                        "value": "海曙区",
                        "label": "海曙区",
                        "pid": "330200",
                        "index": "330203"
                    },
                    {
                        "value": "江北区",
                        "label": "江北区",
                        "pid": "330200",
                        "index": "330205"
                    },
                    {
                        "value": "北仑区",
                        "label": "北仑区",
                        "pid": "330200",
                        "index": "330206"
                    },
                    {
                        "value": "镇海区",
                        "label": "镇海区",
                        "pid": "330200",
                        "index": "330211"
                    },
                    {
                        "value": "鄞州区",
                        "label": "鄞州区",
                        "pid": "330200",
                        "index": "330212"
                    },
                    {
                        "value": "奉化区",
                        "label": "奉化区",
                        "pid": "330200",
                        "index": "330213"
                    },
                    {
                        "value": "象山县",
                        "label": "象山县",
                        "pid": "330200",
                        "index": "330225"
                    },
                    {
                        "value": "宁海县",
                        "label": "宁海县",
                        "pid": "330200",
                        "index": "330226"
                    },
                    {
                        "value": "余姚市",
                        "label": "余姚市",
                        "pid": "330200",
                        "index": "330281"
                    },
                    {
                        "value": "慈溪市",
                        "label": "慈溪市",
                        "pid": "330200",
                        "index": "330282"
                    }
                ]
            },
            {
                "value": "温州市",
                "label": "温州市",
                "pid": "330000",
                "index": "330300",
                "children": [
                    {
                        "value": "鹿城区",
                        "label": "鹿城区",
                        "pid": "330300",
                        "index": "330302"
                    },
                    {
                        "value": "龙湾区",
                        "label": "龙湾区",
                        "pid": "330300",
                        "index": "330303"
                    },
                    {
                        "value": "瓯海区",
                        "label": "瓯海区",
                        "pid": "330300",
                        "index": "330304"
                    },
                    {
                        "value": "洞头区",
                        "label": "洞头区",
                        "pid": "330300",
                        "index": "330305"
                    },
                    {
                        "value": "永嘉县",
                        "label": "永嘉县",
                        "pid": "330300",
                        "index": "330324"
                    },
                    {
                        "value": "平阳县",
                        "label": "平阳县",
                        "pid": "330300",
                        "index": "330326"
                    },
                    {
                        "value": "苍南县",
                        "label": "苍南县",
                        "pid": "330300",
                        "index": "330327"
                    },
                    {
                        "value": "文成县",
                        "label": "文成县",
                        "pid": "330300",
                        "index": "330328"
                    },
                    {
                        "value": "泰顺县",
                        "label": "泰顺县",
                        "pid": "330300",
                        "index": "330329"
                    },
                    {
                        "value": "温州经济技术开发区",
                        "label": "温州经济技术开发区",
                        "pid": "330300",
                        "index": "330371"
                    },
                    {
                        "value": "瑞安市",
                        "label": "瑞安市",
                        "pid": "330300",
                        "index": "330381"
                    },
                    {
                        "value": "乐清市",
                        "label": "乐清市",
                        "pid": "330300",
                        "index": "330382"
                    },
                    {
                        "value": "龙港市",
                        "label": "龙港市",
                        "pid": "330300",
                        "index": "330383"
                    }
                ]
            },
            {
                "value": "嘉兴市",
                "label": "嘉兴市",
                "pid": "330000",
                "index": "330400",
                "children": [
                    {
                        "value": "南湖区",
                        "label": "南湖区",
                        "pid": "330400",
                        "index": "330402"
                    },
                    {
                        "value": "秀洲区",
                        "label": "秀洲区",
                        "pid": "330400",
                        "index": "330411"
                    },
                    {
                        "value": "嘉善县",
                        "label": "嘉善县",
                        "pid": "330400",
                        "index": "330421"
                    },
                    {
                        "value": "海盐县",
                        "label": "海盐县",
                        "pid": "330400",
                        "index": "330424"
                    },
                    {
                        "value": "海宁市",
                        "label": "海宁市",
                        "pid": "330400",
                        "index": "330481"
                    },
                    {
                        "value": "平湖市",
                        "label": "平湖市",
                        "pid": "330400",
                        "index": "330482"
                    },
                    {
                        "value": "桐乡市",
                        "label": "桐乡市",
                        "pid": "330400",
                        "index": "330483"
                    }
                ]
            },
            {
                "value": "湖州市",
                "label": "湖州市",
                "pid": "330000",
                "index": "330500",
                "children": [
                    {
                        "value": "吴兴区",
                        "label": "吴兴区",
                        "pid": "330500",
                        "index": "330502"
                    },
                    {
                        "value": "南浔区",
                        "label": "南浔区",
                        "pid": "330500",
                        "index": "330503"
                    },
                    {
                        "value": "德清县",
                        "label": "德清县",
                        "pid": "330500",
                        "index": "330521"
                    },
                    {
                        "value": "长兴县",
                        "label": "长兴县",
                        "pid": "330500",
                        "index": "330522"
                    },
                    {
                        "value": "安吉县",
                        "label": "安吉县",
                        "pid": "330500",
                        "index": "330523"
                    }
                ]
            },
            {
                "value": "绍兴市",
                "label": "绍兴市",
                "pid": "330000",
                "index": "330600",
                "children": [
                    {
                        "value": "越城区",
                        "label": "越城区",
                        "pid": "330600",
                        "index": "330602"
                    },
                    {
                        "value": "柯桥区",
                        "label": "柯桥区",
                        "pid": "330600",
                        "index": "330603"
                    },
                    {
                        "value": "上虞区",
                        "label": "上虞区",
                        "pid": "330600",
                        "index": "330604"
                    },
                    {
                        "value": "新昌县",
                        "label": "新昌县",
                        "pid": "330600",
                        "index": "330624"
                    },
                    {
                        "value": "诸暨市",
                        "label": "诸暨市",
                        "pid": "330600",
                        "index": "330681"
                    },
                    {
                        "value": "嵊州市",
                        "label": "嵊州市",
                        "pid": "330600",
                        "index": "330683"
                    }
                ]
            },
            {
                "value": "金华市",
                "label": "金华市",
                "pid": "330000",
                "index": "330700",
                "children": [
                    {
                        "value": "婺城区",
                        "label": "婺城区",
                        "pid": "330700",
                        "index": "330702"
                    },
                    {
                        "value": "金东区",
                        "label": "金东区",
                        "pid": "330700",
                        "index": "330703"
                    },
                    {
                        "value": "武义县",
                        "label": "武义县",
                        "pid": "330700",
                        "index": "330723"
                    },
                    {
                        "value": "浦江县",
                        "label": "浦江县",
                        "pid": "330700",
                        "index": "330726"
                    },
                    {
                        "value": "磐安县",
                        "label": "磐安县",
                        "pid": "330700",
                        "index": "330727"
                    },
                    {
                        "value": "兰溪市",
                        "label": "兰溪市",
                        "pid": "330700",
                        "index": "330781"
                    },
                    {
                        "value": "义乌市",
                        "label": "义乌市",
                        "pid": "330700",
                        "index": "330782"
                    },
                    {
                        "value": "东阳市",
                        "label": "东阳市",
                        "pid": "330700",
                        "index": "330783"
                    },
                    {
                        "value": "永康市",
                        "label": "永康市",
                        "pid": "330700",
                        "index": "330784"
                    }
                ]
            },
            {
                "value": "衢州市",
                "label": "衢州市",
                "pid": "330000",
                "index": "330800",
                "children": [
                    {
                        "value": "柯城区",
                        "label": "柯城区",
                        "pid": "330800",
                        "index": "330802"
                    },
                    {
                        "value": "衢江区",
                        "label": "衢江区",
                        "pid": "330800",
                        "index": "330803"
                    },
                    {
                        "value": "常山县",
                        "label": "常山县",
                        "pid": "330800",
                        "index": "330822"
                    },
                    {
                        "value": "开化县",
                        "label": "开化县",
                        "pid": "330800",
                        "index": "330824"
                    },
                    {
                        "value": "龙游县",
                        "label": "龙游县",
                        "pid": "330800",
                        "index": "330825"
                    },
                    {
                        "value": "江山市",
                        "label": "江山市",
                        "pid": "330800",
                        "index": "330881"
                    }
                ]
            },
            {
                "value": "舟山市",
                "label": "舟山市",
                "pid": "330000",
                "index": "330900",
                "children": [
                    {
                        "value": "定海区",
                        "label": "定海区",
                        "pid": "330900",
                        "index": "330902"
                    },
                    {
                        "value": "普陀区",
                        "label": "普陀区",
                        "pid": "330900",
                        "index": "330903"
                    },
                    {
                        "value": "岱山县",
                        "label": "岱山县",
                        "pid": "330900",
                        "index": "330921"
                    },
                    {
                        "value": "嵊泗县",
                        "label": "嵊泗县",
                        "pid": "330900",
                        "index": "330922"
                    }
                ]
            },
            {
                "value": "台州市",
                "label": "台州市",
                "pid": "330000",
                "index": "331000",
                "children": [
                    {
                        "value": "椒江区",
                        "label": "椒江区",
                        "pid": "331000",
                        "index": "331002"
                    },
                    {
                        "value": "黄岩区",
                        "label": "黄岩区",
                        "pid": "331000",
                        "index": "331003"
                    },
                    {
                        "value": "路桥区",
                        "label": "路桥区",
                        "pid": "331000",
                        "index": "331004"
                    },
                    {
                        "value": "三门县",
                        "label": "三门县",
                        "pid": "331000",
                        "index": "331022"
                    },
                    {
                        "value": "天台县",
                        "label": "天台县",
                        "pid": "331000",
                        "index": "331023"
                    },
                    {
                        "value": "仙居县",
                        "label": "仙居县",
                        "pid": "331000",
                        "index": "331024"
                    },
                    {
                        "value": "温岭市",
                        "label": "温岭市",
                        "pid": "331000",
                        "index": "331081"
                    },
                    {
                        "value": "临海市",
                        "label": "临海市",
                        "pid": "331000",
                        "index": "331082"
                    },
                    {
                        "value": "玉环市",
                        "label": "玉环市",
                        "pid": "331000",
                        "index": "331083"
                    }
                ]
            },
            {
                "value": "丽水市",
                "label": "丽水市",
                "pid": "330000",
                "index": "331100",
                "children": [
                    {
                        "value": "莲都区",
                        "label": "莲都区",
                        "pid": "331100",
                        "index": "331102"
                    },
                    {
                        "value": "青田县",
                        "label": "青田县",
                        "pid": "331100",
                        "index": "331121"
                    },
                    {
                        "value": "缙云县",
                        "label": "缙云县",
                        "pid": "331100",
                        "index": "331122"
                    },
                    {
                        "value": "遂昌县",
                        "label": "遂昌县",
                        "pid": "331100",
                        "index": "331123"
                    },
                    {
                        "value": "松阳县",
                        "label": "松阳县",
                        "pid": "331100",
                        "index": "331124"
                    },
                    {
                        "value": "云和县",
                        "label": "云和县",
                        "pid": "331100",
                        "index": "331125"
                    },
                    {
                        "value": "庆元县",
                        "label": "庆元县",
                        "pid": "331100",
                        "index": "331126"
                    },
                    {
                        "value": "景宁畲族自治县",
                        "label": "景宁畲族自治县",
                        "pid": "331100",
                        "index": "331127"
                    },
                    {
                        "value": "龙泉市",
                        "label": "龙泉市",
                        "pid": "331100",
                        "index": "331181"
                    }
                ]
            }
        ]
    },
    {
        "value": "安徽省",
        "label": "安徽省",
        "pid": "86",
        "index": "340000",
        "children": [
            {
                "value": "合肥市",
                "label": "合肥市",
                "pid": "340000",
                "index": "340100",
                "children": [
                    {
                        "value": "瑶海区",
                        "label": "瑶海区",
                        "pid": "340100",
                        "index": "340102"
                    },
                    {
                        "value": "庐阳区",
                        "label": "庐阳区",
                        "pid": "340100",
                        "index": "340103"
                    },
                    {
                        "value": "蜀山区",
                        "label": "蜀山区",
                        "pid": "340100",
                        "index": "340104"
                    },
                    {
                        "value": "包河区",
                        "label": "包河区",
                        "pid": "340100",
                        "index": "340111"
                    },
                    {
                        "value": "长丰县",
                        "label": "长丰县",
                        "pid": "340100",
                        "index": "340121"
                    },
                    {
                        "value": "肥东县",
                        "label": "肥东县",
                        "pid": "340100",
                        "index": "340122"
                    },
                    {
                        "value": "肥西县",
                        "label": "肥西县",
                        "pid": "340100",
                        "index": "340123"
                    },
                    {
                        "value": "庐江县",
                        "label": "庐江县",
                        "pid": "340100",
                        "index": "340124"
                    },
                    {
                        "value": "合肥高新技术产业开发区",
                        "label": "合肥高新技术产业开发区",
                        "pid": "340100",
                        "index": "340171"
                    },
                    {
                        "value": "合肥经济技术开发区",
                        "label": "合肥经济技术开发区",
                        "pid": "340100",
                        "index": "340172"
                    },
                    {
                        "value": "合肥新站高新技术产业开发区",
                        "label": "合肥新站高新技术产业开发区",
                        "pid": "340100",
                        "index": "340173"
                    },
                    {
                        "value": "巢湖市",
                        "label": "巢湖市",
                        "pid": "340100",
                        "index": "340181"
                    }
                ]
            },
            {
                "value": "芜湖市",
                "label": "芜湖市",
                "pid": "340000",
                "index": "340200",
                "children": [
                    {
                        "value": "镜湖区",
                        "label": "镜湖区",
                        "pid": "340200",
                        "index": "340202"
                    },
                    {
                        "value": "弋江区",
                        "label": "弋江区",
                        "pid": "340200",
                        "index": "340203"
                    },
                    {
                        "value": "鸠江区",
                        "label": "鸠江区",
                        "pid": "340200",
                        "index": "340207"
                    },
                    {
                        "value": "三山区",
                        "label": "三山区",
                        "pid": "340200",
                        "index": "340208"
                    },
                    {
                        "value": "芜湖县",
                        "label": "芜湖县",
                        "pid": "340200",
                        "index": "340221"
                    },
                    {
                        "value": "繁昌县",
                        "label": "繁昌县",
                        "pid": "340200",
                        "index": "340222"
                    },
                    {
                        "value": "南陵县",
                        "label": "南陵县",
                        "pid": "340200",
                        "index": "340223"
                    },
                    {
                        "value": "无为县",
                        "label": "无为县",
                        "pid": "340200",
                        "index": "340225"
                    },
                    {
                        "value": "芜湖经济技术开发区",
                        "label": "芜湖经济技术开发区",
                        "pid": "340200",
                        "index": "340271"
                    },
                    {
                        "value": "安徽芜湖长江大桥经济开发区",
                        "label": "安徽芜湖长江大桥经济开发区",
                        "pid": "340200",
                        "index": "340272"
                    }
                ]
            },
            {
                "value": "蚌埠市",
                "label": "蚌埠市",
                "pid": "340000",
                "index": "340300",
                "children": [
                    {
                        "value": "龙子湖区",
                        "label": "龙子湖区",
                        "pid": "340300",
                        "index": "340302"
                    },
                    {
                        "value": "蚌山区",
                        "label": "蚌山区",
                        "pid": "340300",
                        "index": "340303"
                    },
                    {
                        "value": "禹会区",
                        "label": "禹会区",
                        "pid": "340300",
                        "index": "340304"
                    },
                    {
                        "value": "淮上区",
                        "label": "淮上区",
                        "pid": "340300",
                        "index": "340311"
                    },
                    {
                        "value": "怀远县",
                        "label": "怀远县",
                        "pid": "340300",
                        "index": "340321"
                    },
                    {
                        "value": "五河县",
                        "label": "五河县",
                        "pid": "340300",
                        "index": "340322"
                    },
                    {
                        "value": "固镇县",
                        "label": "固镇县",
                        "pid": "340300",
                        "index": "340323"
                    },
                    {
                        "value": "蚌埠市高新技术开发区",
                        "label": "蚌埠市高新技术开发区",
                        "pid": "340300",
                        "index": "340371"
                    },
                    {
                        "value": "蚌埠市经济开发区",
                        "label": "蚌埠市经济开发区",
                        "pid": "340300",
                        "index": "340372"
                    }
                ]
            },
            {
                "value": "淮南市",
                "label": "淮南市",
                "pid": "340000",
                "index": "340400",
                "children": [
                    {
                        "value": "大通区",
                        "label": "大通区",
                        "pid": "340400",
                        "index": "340402"
                    },
                    {
                        "value": "田家庵区",
                        "label": "田家庵区",
                        "pid": "340400",
                        "index": "340403"
                    },
                    {
                        "value": "谢家集区",
                        "label": "谢家集区",
                        "pid": "340400",
                        "index": "340404"
                    },
                    {
                        "value": "八公山区",
                        "label": "八公山区",
                        "pid": "340400",
                        "index": "340405"
                    },
                    {
                        "value": "潘集区",
                        "label": "潘集区",
                        "pid": "340400",
                        "index": "340406"
                    },
                    {
                        "value": "凤台县",
                        "label": "凤台县",
                        "pid": "340400",
                        "index": "340421"
                    },
                    {
                        "value": "寿县",
                        "label": "寿县",
                        "pid": "340400",
                        "index": "340422"
                    }
                ]
            },
            {
                "value": "马鞍山市",
                "label": "马鞍山市",
                "pid": "340000",
                "index": "340500",
                "children": [
                    {
                        "value": "花山区",
                        "label": "花山区",
                        "pid": "340500",
                        "index": "340503"
                    },
                    {
                        "value": "雨山区",
                        "label": "雨山区",
                        "pid": "340500",
                        "index": "340504"
                    },
                    {
                        "value": "博望区",
                        "label": "博望区",
                        "pid": "340500",
                        "index": "340506"
                    },
                    {
                        "value": "当涂县",
                        "label": "当涂县",
                        "pid": "340500",
                        "index": "340521"
                    },
                    {
                        "value": "含山县",
                        "label": "含山县",
                        "pid": "340500",
                        "index": "340522"
                    },
                    {
                        "value": "和县",
                        "label": "和县",
                        "pid": "340500",
                        "index": "340523"
                    }
                ]
            },
            {
                "value": "淮北市",
                "label": "淮北市",
                "pid": "340000",
                "index": "340600",
                "children": [
                    {
                        "value": "杜集区",
                        "label": "杜集区",
                        "pid": "340600",
                        "index": "340602"
                    },
                    {
                        "value": "相山区",
                        "label": "相山区",
                        "pid": "340600",
                        "index": "340603"
                    },
                    {
                        "value": "烈山区",
                        "label": "烈山区",
                        "pid": "340600",
                        "index": "340604"
                    },
                    {
                        "value": "濉溪县",
                        "label": "濉溪县",
                        "pid": "340600",
                        "index": "340621"
                    }
                ]
            },
            {
                "value": "铜陵市",
                "label": "铜陵市",
                "pid": "340000",
                "index": "340700",
                "children": [
                    {
                        "value": "铜官区",
                        "label": "铜官区",
                        "pid": "340700",
                        "index": "340705"
                    },
                    {
                        "value": "义安区",
                        "label": "义安区",
                        "pid": "340700",
                        "index": "340706"
                    },
                    {
                        "value": "郊区",
                        "label": "郊区",
                        "pid": "340700",
                        "index": "340711"
                    },
                    {
                        "value": "枞阳县",
                        "label": "枞阳县",
                        "pid": "340700",
                        "index": "340722"
                    }
                ]
            },
            {
                "value": "安庆市",
                "label": "安庆市",
                "pid": "340000",
                "index": "340800",
                "children": [
                    {
                        "value": "迎江区",
                        "label": "迎江区",
                        "pid": "340800",
                        "index": "340802"
                    },
                    {
                        "value": "大观区",
                        "label": "大观区",
                        "pid": "340800",
                        "index": "340803"
                    },
                    {
                        "value": "宜秀区",
                        "label": "宜秀区",
                        "pid": "340800",
                        "index": "340811"
                    },
                    {
                        "value": "怀宁县",
                        "label": "怀宁县",
                        "pid": "340800",
                        "index": "340822"
                    },
                    {
                        "value": "太湖县",
                        "label": "太湖县",
                        "pid": "340800",
                        "index": "340825"
                    },
                    {
                        "value": "宿松县",
                        "label": "宿松县",
                        "pid": "340800",
                        "index": "340826"
                    },
                    {
                        "value": "望江县",
                        "label": "望江县",
                        "pid": "340800",
                        "index": "340827"
                    },
                    {
                        "value": "岳西县",
                        "label": "岳西县",
                        "pid": "340800",
                        "index": "340828"
                    },
                    {
                        "value": "安徽安庆经济开发区",
                        "label": "安徽安庆经济开发区",
                        "pid": "340800",
                        "index": "340871"
                    },
                    {
                        "value": "桐城市",
                        "label": "桐城市",
                        "pid": "340800",
                        "index": "340881"
                    },
                    {
                        "value": "潜山市",
                        "label": "潜山市",
                        "pid": "340800",
                        "index": "340882"
                    }
                ]
            },
            {
                "value": "黄山市",
                "label": "黄山市",
                "pid": "340000",
                "index": "341000",
                "children": [
                    {
                        "value": "屯溪区",
                        "label": "屯溪区",
                        "pid": "341000",
                        "index": "341002"
                    },
                    {
                        "value": "黄山区",
                        "label": "黄山区",
                        "pid": "341000",
                        "index": "341003"
                    },
                    {
                        "value": "徽州区",
                        "label": "徽州区",
                        "pid": "341000",
                        "index": "341004"
                    },
                    {
                        "value": "歙县",
                        "label": "歙县",
                        "pid": "341000",
                        "index": "341021"
                    },
                    {
                        "value": "休宁县",
                        "label": "休宁县",
                        "pid": "341000",
                        "index": "341022"
                    },
                    {
                        "value": "黟县",
                        "label": "黟县",
                        "pid": "341000",
                        "index": "341023"
                    },
                    {
                        "value": "祁门县",
                        "label": "祁门县",
                        "pid": "341000",
                        "index": "341024"
                    }
                ]
            },
            {
                "value": "滁州市",
                "label": "滁州市",
                "pid": "340000",
                "index": "341100",
                "children": [
                    {
                        "value": "琅琊区",
                        "label": "琅琊区",
                        "pid": "341100",
                        "index": "341102"
                    },
                    {
                        "value": "南谯区",
                        "label": "南谯区",
                        "pid": "341100",
                        "index": "341103"
                    },
                    {
                        "value": "来安县",
                        "label": "来安县",
                        "pid": "341100",
                        "index": "341122"
                    },
                    {
                        "value": "全椒县",
                        "label": "全椒县",
                        "pid": "341100",
                        "index": "341124"
                    },
                    {
                        "value": "定远县",
                        "label": "定远县",
                        "pid": "341100",
                        "index": "341125"
                    },
                    {
                        "value": "凤阳县",
                        "label": "凤阳县",
                        "pid": "341100",
                        "index": "341126"
                    },
                    {
                        "value": "苏滁现代产业园",
                        "label": "苏滁现代产业园",
                        "pid": "341100",
                        "index": "341171"
                    },
                    {
                        "value": "滁州经济技术开发区",
                        "label": "滁州经济技术开发区",
                        "pid": "341100",
                        "index": "341172"
                    },
                    {
                        "value": "天长市",
                        "label": "天长市",
                        "pid": "341100",
                        "index": "341181"
                    },
                    {
                        "value": "明光市",
                        "label": "明光市",
                        "pid": "341100",
                        "index": "341182"
                    }
                ]
            },
            {
                "value": "阜阳市",
                "label": "阜阳市",
                "pid": "340000",
                "index": "341200",
                "children": [
                    {
                        "value": "颍州区",
                        "label": "颍州区",
                        "pid": "341200",
                        "index": "341202"
                    },
                    {
                        "value": "颍东区",
                        "label": "颍东区",
                        "pid": "341200",
                        "index": "341203"
                    },
                    {
                        "value": "颍泉区",
                        "label": "颍泉区",
                        "pid": "341200",
                        "index": "341204"
                    },
                    {
                        "value": "临泉县",
                        "label": "临泉县",
                        "pid": "341200",
                        "index": "341221"
                    },
                    {
                        "value": "太和县",
                        "label": "太和县",
                        "pid": "341200",
                        "index": "341222"
                    },
                    {
                        "value": "阜南县",
                        "label": "阜南县",
                        "pid": "341200",
                        "index": "341225"
                    },
                    {
                        "value": "颍上县",
                        "label": "颍上县",
                        "pid": "341200",
                        "index": "341226"
                    },
                    {
                        "value": "阜阳合肥现代产业园区",
                        "label": "阜阳合肥现代产业园区",
                        "pid": "341200",
                        "index": "341271"
                    },
                    {
                        "value": "阜阳经济技术开发区",
                        "label": "阜阳经济技术开发区",
                        "pid": "341200",
                        "index": "341272"
                    },
                    {
                        "value": "界首市",
                        "label": "界首市",
                        "pid": "341200",
                        "index": "341282"
                    }
                ]
            },
            {
                "value": "宿州市",
                "label": "宿州市",
                "pid": "340000",
                "index": "341300",
                "children": [
                    {
                        "value": "埇桥区",
                        "label": "埇桥区",
                        "pid": "341300",
                        "index": "341302"
                    },
                    {
                        "value": "砀山县",
                        "label": "砀山县",
                        "pid": "341300",
                        "index": "341321"
                    },
                    {
                        "value": "萧县",
                        "label": "萧县",
                        "pid": "341300",
                        "index": "341322"
                    },
                    {
                        "value": "灵璧县",
                        "label": "灵璧县",
                        "pid": "341300",
                        "index": "341323"
                    },
                    {
                        "value": "泗县",
                        "label": "泗县",
                        "pid": "341300",
                        "index": "341324"
                    },
                    {
                        "value": "宿州马鞍山现代产业园区",
                        "label": "宿州马鞍山现代产业园区",
                        "pid": "341300",
                        "index": "341371"
                    },
                    {
                        "value": "宿州经济技术开发区",
                        "label": "宿州经济技术开发区",
                        "pid": "341300",
                        "index": "341372"
                    }
                ]
            },
            {
                "value": "六安市",
                "label": "六安市",
                "pid": "340000",
                "index": "341500",
                "children": [
                    {
                        "value": "金安区",
                        "label": "金安区",
                        "pid": "341500",
                        "index": "341502"
                    },
                    {
                        "value": "裕安区",
                        "label": "裕安区",
                        "pid": "341500",
                        "index": "341503"
                    },
                    {
                        "value": "叶集区",
                        "label": "叶集区",
                        "pid": "341500",
                        "index": "341504"
                    },
                    {
                        "value": "霍邱县",
                        "label": "霍邱县",
                        "pid": "341500",
                        "index": "341522"
                    },
                    {
                        "value": "舒城县",
                        "label": "舒城县",
                        "pid": "341500",
                        "index": "341523"
                    },
                    {
                        "value": "金寨县",
                        "label": "金寨县",
                        "pid": "341500",
                        "index": "341524"
                    },
                    {
                        "value": "霍山县",
                        "label": "霍山县",
                        "pid": "341500",
                        "index": "341525"
                    }
                ]
            },
            {
                "value": "亳州市",
                "label": "亳州市",
                "pid": "340000",
                "index": "341600",
                "children": [
                    {
                        "value": "谯城区",
                        "label": "谯城区",
                        "pid": "341600",
                        "index": "341602"
                    },
                    {
                        "value": "涡阳县",
                        "label": "涡阳县",
                        "pid": "341600",
                        "index": "341621"
                    },
                    {
                        "value": "蒙城县",
                        "label": "蒙城县",
                        "pid": "341600",
                        "index": "341622"
                    },
                    {
                        "value": "利辛县",
                        "label": "利辛县",
                        "pid": "341600",
                        "index": "341623"
                    }
                ]
            },
            {
                "value": "池州市",
                "label": "池州市",
                "pid": "340000",
                "index": "341700",
                "children": [
                    {
                        "value": "贵池区",
                        "label": "贵池区",
                        "pid": "341700",
                        "index": "341702"
                    },
                    {
                        "value": "东至县",
                        "label": "东至县",
                        "pid": "341700",
                        "index": "341721"
                    },
                    {
                        "value": "石台县",
                        "label": "石台县",
                        "pid": "341700",
                        "index": "341722"
                    },
                    {
                        "value": "青阳县",
                        "label": "青阳县",
                        "pid": "341700",
                        "index": "341723"
                    }
                ]
            },
            {
                "value": "宣城市",
                "label": "宣城市",
                "pid": "340000",
                "index": "341800",
                "children": [
                    {
                        "value": "宣州区",
                        "label": "宣州区",
                        "pid": "341800",
                        "index": "341802"
                    },
                    {
                        "value": "郎溪县",
                        "label": "郎溪县",
                        "pid": "341800",
                        "index": "341821"
                    },
                    {
                        "value": "泾县",
                        "label": "泾县",
                        "pid": "341800",
                        "index": "341823"
                    },
                    {
                        "value": "绩溪县",
                        "label": "绩溪县",
                        "pid": "341800",
                        "index": "341824"
                    },
                    {
                        "value": "旌德县",
                        "label": "旌德县",
                        "pid": "341800",
                        "index": "341825"
                    },
                    {
                        "value": "宣城市经济开发区",
                        "label": "宣城市经济开发区",
                        "pid": "341800",
                        "index": "341871"
                    },
                    {
                        "value": "宁国市",
                        "label": "宁国市",
                        "pid": "341800",
                        "index": "341881"
                    },
                    {
                        "value": "广德市",
                        "label": "广德市",
                        "pid": "341800",
                        "index": "341882"
                    }
                ]
            }
        ]
    },
    {
        "value": "福建省",
        "label": "福建省",
        "pid": "86",
        "index": "350000",
        "children": [
            {
                "value": "福州市",
                "label": "福州市",
                "pid": "350000",
                "index": "350100",
                "children": [
                    {
                        "value": "鼓楼区",
                        "label": "鼓楼区",
                        "pid": "350100",
                        "index": "350102"
                    },
                    {
                        "value": "台江区",
                        "label": "台江区",
                        "pid": "350100",
                        "index": "350103"
                    },
                    {
                        "value": "仓山区",
                        "label": "仓山区",
                        "pid": "350100",
                        "index": "350104"
                    },
                    {
                        "value": "马尾区",
                        "label": "马尾区",
                        "pid": "350100",
                        "index": "350105"
                    },
                    {
                        "value": "晋安区",
                        "label": "晋安区",
                        "pid": "350100",
                        "index": "350111"
                    },
                    {
                        "value": "长乐区",
                        "label": "长乐区",
                        "pid": "350100",
                        "index": "350112"
                    },
                    {
                        "value": "闽侯县",
                        "label": "闽侯县",
                        "pid": "350100",
                        "index": "350121"
                    },
                    {
                        "value": "连江县",
                        "label": "连江县",
                        "pid": "350100",
                        "index": "350122"
                    },
                    {
                        "value": "罗源县",
                        "label": "罗源县",
                        "pid": "350100",
                        "index": "350123"
                    },
                    {
                        "value": "闽清县",
                        "label": "闽清县",
                        "pid": "350100",
                        "index": "350124"
                    },
                    {
                        "value": "永泰县",
                        "label": "永泰县",
                        "pid": "350100",
                        "index": "350125"
                    },
                    {
                        "value": "平潭县",
                        "label": "平潭县",
                        "pid": "350100",
                        "index": "350128"
                    },
                    {
                        "value": "福清市",
                        "label": "福清市",
                        "pid": "350100",
                        "index": "350181"
                    }
                ]
            },
            {
                "value": "厦门市",
                "label": "厦门市",
                "pid": "350000",
                "index": "350200",
                "children": [
                    {
                        "value": "思明区",
                        "label": "思明区",
                        "pid": "350200",
                        "index": "350203"
                    },
                    {
                        "value": "海沧区",
                        "label": "海沧区",
                        "pid": "350200",
                        "index": "350205"
                    },
                    {
                        "value": "湖里区",
                        "label": "湖里区",
                        "pid": "350200",
                        "index": "350206"
                    },
                    {
                        "value": "集美区",
                        "label": "集美区",
                        "pid": "350200",
                        "index": "350211"
                    },
                    {
                        "value": "同安区",
                        "label": "同安区",
                        "pid": "350200",
                        "index": "350212"
                    },
                    {
                        "value": "翔安区",
                        "label": "翔安区",
                        "pid": "350200",
                        "index": "350213"
                    }
                ]
            },
            {
                "value": "莆田市",
                "label": "莆田市",
                "pid": "350000",
                "index": "350300",
                "children": [
                    {
                        "value": "城厢区",
                        "label": "城厢区",
                        "pid": "350300",
                        "index": "350302"
                    },
                    {
                        "value": "涵江区",
                        "label": "涵江区",
                        "pid": "350300",
                        "index": "350303"
                    },
                    {
                        "value": "荔城区",
                        "label": "荔城区",
                        "pid": "350300",
                        "index": "350304"
                    },
                    {
                        "value": "秀屿区",
                        "label": "秀屿区",
                        "pid": "350300",
                        "index": "350305"
                    },
                    {
                        "value": "仙游县",
                        "label": "仙游县",
                        "pid": "350300",
                        "index": "350322"
                    }
                ]
            },
            {
                "value": "三明市",
                "label": "三明市",
                "pid": "350000",
                "index": "350400",
                "children": [
                    {
                        "value": "梅列区",
                        "label": "梅列区",
                        "pid": "350400",
                        "index": "350402"
                    },
                    {
                        "value": "三元区",
                        "label": "三元区",
                        "pid": "350400",
                        "index": "350403"
                    },
                    {
                        "value": "明溪县",
                        "label": "明溪县",
                        "pid": "350400",
                        "index": "350421"
                    },
                    {
                        "value": "清流县",
                        "label": "清流县",
                        "pid": "350400",
                        "index": "350423"
                    },
                    {
                        "value": "宁化县",
                        "label": "宁化县",
                        "pid": "350400",
                        "index": "350424"
                    },
                    {
                        "value": "大田县",
                        "label": "大田县",
                        "pid": "350400",
                        "index": "350425"
                    },
                    {
                        "value": "尤溪县",
                        "label": "尤溪县",
                        "pid": "350400",
                        "index": "350426"
                    },
                    {
                        "value": "沙县",
                        "label": "沙县",
                        "pid": "350400",
                        "index": "350427"
                    },
                    {
                        "value": "将乐县",
                        "label": "将乐县",
                        "pid": "350400",
                        "index": "350428"
                    },
                    {
                        "value": "泰宁县",
                        "label": "泰宁县",
                        "pid": "350400",
                        "index": "350429"
                    },
                    {
                        "value": "建宁县",
                        "label": "建宁县",
                        "pid": "350400",
                        "index": "350430"
                    },
                    {
                        "value": "永安市",
                        "label": "永安市",
                        "pid": "350400",
                        "index": "350481"
                    }
                ]
            },
            {
                "value": "泉州市",
                "label": "泉州市",
                "pid": "350000",
                "index": "350500",
                "children": [
                    {
                        "value": "鲤城区",
                        "label": "鲤城区",
                        "pid": "350500",
                        "index": "350502"
                    },
                    {
                        "value": "丰泽区",
                        "label": "丰泽区",
                        "pid": "350500",
                        "index": "350503"
                    },
                    {
                        "value": "洛江区",
                        "label": "洛江区",
                        "pid": "350500",
                        "index": "350504"
                    },
                    {
                        "value": "泉港区",
                        "label": "泉港区",
                        "pid": "350500",
                        "index": "350505"
                    },
                    {
                        "value": "惠安县",
                        "label": "惠安县",
                        "pid": "350500",
                        "index": "350521"
                    },
                    {
                        "value": "安溪县",
                        "label": "安溪县",
                        "pid": "350500",
                        "index": "350524"
                    },
                    {
                        "value": "永春县",
                        "label": "永春县",
                        "pid": "350500",
                        "index": "350525"
                    },
                    {
                        "value": "德化县",
                        "label": "德化县",
                        "pid": "350500",
                        "index": "350526"
                    },
                    {
                        "value": "金门县",
                        "label": "金门县",
                        "pid": "350500",
                        "index": "350527"
                    },
                    {
                        "value": "石狮市",
                        "label": "石狮市",
                        "pid": "350500",
                        "index": "350581"
                    },
                    {
                        "value": "晋江市",
                        "label": "晋江市",
                        "pid": "350500",
                        "index": "350582"
                    },
                    {
                        "value": "南安市",
                        "label": "南安市",
                        "pid": "350500",
                        "index": "350583"
                    }
                ]
            },
            {
                "value": "漳州市",
                "label": "漳州市",
                "pid": "350000",
                "index": "350600",
                "children": [
                    {
                        "value": "芗城区",
                        "label": "芗城区",
                        "pid": "350600",
                        "index": "350602"
                    },
                    {
                        "value": "龙文区",
                        "label": "龙文区",
                        "pid": "350600",
                        "index": "350603"
                    },
                    {
                        "value": "云霄县",
                        "label": "云霄县",
                        "pid": "350600",
                        "index": "350622"
                    },
                    {
                        "value": "漳浦县",
                        "label": "漳浦县",
                        "pid": "350600",
                        "index": "350623"
                    },
                    {
                        "value": "诏安县",
                        "label": "诏安县",
                        "pid": "350600",
                        "index": "350624"
                    },
                    {
                        "value": "长泰县",
                        "label": "长泰县",
                        "pid": "350600",
                        "index": "350625"
                    },
                    {
                        "value": "东山县",
                        "label": "东山县",
                        "pid": "350600",
                        "index": "350626"
                    },
                    {
                        "value": "南靖县",
                        "label": "南靖县",
                        "pid": "350600",
                        "index": "350627"
                    },
                    {
                        "value": "平和县",
                        "label": "平和县",
                        "pid": "350600",
                        "index": "350628"
                    },
                    {
                        "value": "华安县",
                        "label": "华安县",
                        "pid": "350600",
                        "index": "350629"
                    },
                    {
                        "value": "龙海市",
                        "label": "龙海市",
                        "pid": "350600",
                        "index": "350681"
                    }
                ]
            },
            {
                "value": "南平市",
                "label": "南平市",
                "pid": "350000",
                "index": "350700",
                "children": [
                    {
                        "value": "延平区",
                        "label": "延平区",
                        "pid": "350700",
                        "index": "350702"
                    },
                    {
                        "value": "建阳区",
                        "label": "建阳区",
                        "pid": "350700",
                        "index": "350703"
                    },
                    {
                        "value": "顺昌县",
                        "label": "顺昌县",
                        "pid": "350700",
                        "index": "350721"
                    },
                    {
                        "value": "浦城县",
                        "label": "浦城县",
                        "pid": "350700",
                        "index": "350722"
                    },
                    {
                        "value": "光泽县",
                        "label": "光泽县",
                        "pid": "350700",
                        "index": "350723"
                    },
                    {
                        "value": "松溪县",
                        "label": "松溪县",
                        "pid": "350700",
                        "index": "350724"
                    },
                    {
                        "value": "政和县",
                        "label": "政和县",
                        "pid": "350700",
                        "index": "350725"
                    },
                    {
                        "value": "邵武市",
                        "label": "邵武市",
                        "pid": "350700",
                        "index": "350781"
                    },
                    {
                        "value": "武夷山市",
                        "label": "武夷山市",
                        "pid": "350700",
                        "index": "350782"
                    },
                    {
                        "value": "建瓯市",
                        "label": "建瓯市",
                        "pid": "350700",
                        "index": "350783"
                    }
                ]
            },
            {
                "value": "龙岩市",
                "label": "龙岩市",
                "pid": "350000",
                "index": "350800",
                "children": [
                    {
                        "value": "新罗区",
                        "label": "新罗区",
                        "pid": "350800",
                        "index": "350802"
                    },
                    {
                        "value": "永定区",
                        "label": "永定区",
                        "pid": "350800",
                        "index": "350803"
                    },
                    {
                        "value": "长汀县",
                        "label": "长汀县",
                        "pid": "350800",
                        "index": "350821"
                    },
                    {
                        "value": "上杭县",
                        "label": "上杭县",
                        "pid": "350800",
                        "index": "350823"
                    },
                    {
                        "value": "武平县",
                        "label": "武平县",
                        "pid": "350800",
                        "index": "350824"
                    },
                    {
                        "value": "连城县",
                        "label": "连城县",
                        "pid": "350800",
                        "index": "350825"
                    },
                    {
                        "value": "漳平市",
                        "label": "漳平市",
                        "pid": "350800",
                        "index": "350881"
                    }
                ]
            },
            {
                "value": "宁德市",
                "label": "宁德市",
                "pid": "350000",
                "index": "350900",
                "children": [
                    {
                        "value": "蕉城区",
                        "label": "蕉城区",
                        "pid": "350900",
                        "index": "350902"
                    },
                    {
                        "value": "霞浦县",
                        "label": "霞浦县",
                        "pid": "350900",
                        "index": "350921"
                    },
                    {
                        "value": "古田县",
                        "label": "古田县",
                        "pid": "350900",
                        "index": "350922"
                    },
                    {
                        "value": "屏南县",
                        "label": "屏南县",
                        "pid": "350900",
                        "index": "350923"
                    },
                    {
                        "value": "寿宁县",
                        "label": "寿宁县",
                        "pid": "350900",
                        "index": "350924"
                    },
                    {
                        "value": "周宁县",
                        "label": "周宁县",
                        "pid": "350900",
                        "index": "350925"
                    },
                    {
                        "value": "柘荣县",
                        "label": "柘荣县",
                        "pid": "350900",
                        "index": "350926"
                    },
                    {
                        "value": "福安市",
                        "label": "福安市",
                        "pid": "350900",
                        "index": "350981"
                    },
                    {
                        "value": "福鼎市",
                        "label": "福鼎市",
                        "pid": "350900",
                        "index": "350982"
                    }
                ]
            }
        ]
    },
    {
        "value": "江西省",
        "label": "江西省",
        "pid": "86",
        "index": "360000",
        "children": [
            {
                "value": "南昌市",
                "label": "南昌市",
                "pid": "360000",
                "index": "360100",
                "children": [
                    {
                        "value": "东湖区",
                        "label": "东湖区",
                        "pid": "360100",
                        "index": "360102"
                    },
                    {
                        "value": "西湖区",
                        "label": "西湖区",
                        "pid": "360100",
                        "index": "360103"
                    },
                    {
                        "value": "青云谱区",
                        "label": "青云谱区",
                        "pid": "360100",
                        "index": "360104"
                    },
                    {
                        "value": "湾里区",
                        "label": "湾里区",
                        "pid": "360100",
                        "index": "360105"
                    },
                    {
                        "value": "青山湖区",
                        "label": "青山湖区",
                        "pid": "360100",
                        "index": "360111"
                    },
                    {
                        "value": "新建区",
                        "label": "新建区",
                        "pid": "360100",
                        "index": "360112"
                    },
                    {
                        "value": "南昌县",
                        "label": "南昌县",
                        "pid": "360100",
                        "index": "360121"
                    },
                    {
                        "value": "安义县",
                        "label": "安义县",
                        "pid": "360100",
                        "index": "360123"
                    },
                    {
                        "value": "进贤县",
                        "label": "进贤县",
                        "pid": "360100",
                        "index": "360124"
                    }
                ]
            },
            {
                "value": "景德镇市",
                "label": "景德镇市",
                "pid": "360000",
                "index": "360200",
                "children": [
                    {
                        "value": "昌江区",
                        "label": "昌江区",
                        "pid": "360200",
                        "index": "360202"
                    },
                    {
                        "value": "珠山区",
                        "label": "珠山区",
                        "pid": "360200",
                        "index": "360203"
                    },
                    {
                        "value": "浮梁县",
                        "label": "浮梁县",
                        "pid": "360200",
                        "index": "360222"
                    },
                    {
                        "value": "乐平市",
                        "label": "乐平市",
                        "pid": "360200",
                        "index": "360281"
                    }
                ]
            },
            {
                "value": "萍乡市",
                "label": "萍乡市",
                "pid": "360000",
                "index": "360300",
                "children": [
                    {
                        "value": "安源区",
                        "label": "安源区",
                        "pid": "360300",
                        "index": "360302"
                    },
                    {
                        "value": "湘东区",
                        "label": "湘东区",
                        "pid": "360300",
                        "index": "360313"
                    },
                    {
                        "value": "莲花县",
                        "label": "莲花县",
                        "pid": "360300",
                        "index": "360321"
                    },
                    {
                        "value": "上栗县",
                        "label": "上栗县",
                        "pid": "360300",
                        "index": "360322"
                    },
                    {
                        "value": "芦溪县",
                        "label": "芦溪县",
                        "pid": "360300",
                        "index": "360323"
                    }
                ]
            },
            {
                "value": "九江市",
                "label": "九江市",
                "pid": "360000",
                "index": "360400",
                "children": [
                    {
                        "value": "濂溪区",
                        "label": "濂溪区",
                        "pid": "360400",
                        "index": "360402"
                    },
                    {
                        "value": "浔阳区",
                        "label": "浔阳区",
                        "pid": "360400",
                        "index": "360403"
                    },
                    {
                        "value": "柴桑区",
                        "label": "柴桑区",
                        "pid": "360400",
                        "index": "360404"
                    },
                    {
                        "value": "武宁县",
                        "label": "武宁县",
                        "pid": "360400",
                        "index": "360423"
                    },
                    {
                        "value": "修水县",
                        "label": "修水县",
                        "pid": "360400",
                        "index": "360424"
                    },
                    {
                        "value": "永修县",
                        "label": "永修县",
                        "pid": "360400",
                        "index": "360425"
                    },
                    {
                        "value": "德安县",
                        "label": "德安县",
                        "pid": "360400",
                        "index": "360426"
                    },
                    {
                        "value": "都昌县",
                        "label": "都昌县",
                        "pid": "360400",
                        "index": "360428"
                    },
                    {
                        "value": "湖口县",
                        "label": "湖口县",
                        "pid": "360400",
                        "index": "360429"
                    },
                    {
                        "value": "彭泽县",
                        "label": "彭泽县",
                        "pid": "360400",
                        "index": "360430"
                    },
                    {
                        "value": "瑞昌市",
                        "label": "瑞昌市",
                        "pid": "360400",
                        "index": "360481"
                    },
                    {
                        "value": "共青城市",
                        "label": "共青城市",
                        "pid": "360400",
                        "index": "360482"
                    },
                    {
                        "value": "庐山市",
                        "label": "庐山市",
                        "pid": "360400",
                        "index": "360483"
                    }
                ]
            },
            {
                "value": "新余市",
                "label": "新余市",
                "pid": "360000",
                "index": "360500",
                "children": [
                    {
                        "value": "渝水区",
                        "label": "渝水区",
                        "pid": "360500",
                        "index": "360502"
                    },
                    {
                        "value": "分宜县",
                        "label": "分宜县",
                        "pid": "360500",
                        "index": "360521"
                    }
                ]
            },
            {
                "value": "鹰潭市",
                "label": "鹰潭市",
                "pid": "360000",
                "index": "360600",
                "children": [
                    {
                        "value": "月湖区",
                        "label": "月湖区",
                        "pid": "360600",
                        "index": "360602"
                    },
                    {
                        "value": "余江区",
                        "label": "余江区",
                        "pid": "360600",
                        "index": "360603"
                    },
                    {
                        "value": "贵溪市",
                        "label": "贵溪市",
                        "pid": "360600",
                        "index": "360681"
                    }
                ]
            },
            {
                "value": "赣州市",
                "label": "赣州市",
                "pid": "360000",
                "index": "360700",
                "children": [
                    {
                        "value": "章贡区",
                        "label": "章贡区",
                        "pid": "360700",
                        "index": "360702"
                    },
                    {
                        "value": "南康区",
                        "label": "南康区",
                        "pid": "360700",
                        "index": "360703"
                    },
                    {
                        "value": "赣县区",
                        "label": "赣县区",
                        "pid": "360700",
                        "index": "360704"
                    },
                    {
                        "value": "信丰县",
                        "label": "信丰县",
                        "pid": "360700",
                        "index": "360722"
                    },
                    {
                        "value": "大余县",
                        "label": "大余县",
                        "pid": "360700",
                        "index": "360723"
                    },
                    {
                        "value": "上犹县",
                        "label": "上犹县",
                        "pid": "360700",
                        "index": "360724"
                    },
                    {
                        "value": "崇义县",
                        "label": "崇义县",
                        "pid": "360700",
                        "index": "360725"
                    },
                    {
                        "value": "安远县",
                        "label": "安远县",
                        "pid": "360700",
                        "index": "360726"
                    },
                    {
                        "value": "龙南县",
                        "label": "龙南县",
                        "pid": "360700",
                        "index": "360727"
                    },
                    {
                        "value": "定南县",
                        "label": "定南县",
                        "pid": "360700",
                        "index": "360728"
                    },
                    {
                        "value": "全南县",
                        "label": "全南县",
                        "pid": "360700",
                        "index": "360729"
                    },
                    {
                        "value": "宁都县",
                        "label": "宁都县",
                        "pid": "360700",
                        "index": "360730"
                    },
                    {
                        "value": "于都县",
                        "label": "于都县",
                        "pid": "360700",
                        "index": "360731"
                    },
                    {
                        "value": "兴国县",
                        "label": "兴国县",
                        "pid": "360700",
                        "index": "360732"
                    },
                    {
                        "value": "会昌县",
                        "label": "会昌县",
                        "pid": "360700",
                        "index": "360733"
                    },
                    {
                        "value": "寻乌县",
                        "label": "寻乌县",
                        "pid": "360700",
                        "index": "360734"
                    },
                    {
                        "value": "石城县",
                        "label": "石城县",
                        "pid": "360700",
                        "index": "360735"
                    },
                    {
                        "value": "瑞金市",
                        "label": "瑞金市",
                        "pid": "360700",
                        "index": "360781"
                    }
                ]
            },
            {
                "value": "吉安市",
                "label": "吉安市",
                "pid": "360000",
                "index": "360800",
                "children": [
                    {
                        "value": "吉州区",
                        "label": "吉州区",
                        "pid": "360800",
                        "index": "360802"
                    },
                    {
                        "value": "青原区",
                        "label": "青原区",
                        "pid": "360800",
                        "index": "360803"
                    },
                    {
                        "value": "吉安县",
                        "label": "吉安县",
                        "pid": "360800",
                        "index": "360821"
                    },
                    {
                        "value": "吉水县",
                        "label": "吉水县",
                        "pid": "360800",
                        "index": "360822"
                    },
                    {
                        "value": "峡江县",
                        "label": "峡江县",
                        "pid": "360800",
                        "index": "360823"
                    },
                    {
                        "value": "新干县",
                        "label": "新干县",
                        "pid": "360800",
                        "index": "360824"
                    },
                    {
                        "value": "永丰县",
                        "label": "永丰县",
                        "pid": "360800",
                        "index": "360825"
                    },
                    {
                        "value": "泰和县",
                        "label": "泰和县",
                        "pid": "360800",
                        "index": "360826"
                    },
                    {
                        "value": "遂川县",
                        "label": "遂川县",
                        "pid": "360800",
                        "index": "360827"
                    },
                    {
                        "value": "万安县",
                        "label": "万安县",
                        "pid": "360800",
                        "index": "360828"
                    },
                    {
                        "value": "安福县",
                        "label": "安福县",
                        "pid": "360800",
                        "index": "360829"
                    },
                    {
                        "value": "永新县",
                        "label": "永新县",
                        "pid": "360800",
                        "index": "360830"
                    },
                    {
                        "value": "井冈山市",
                        "label": "井冈山市",
                        "pid": "360800",
                        "index": "360881"
                    }
                ]
            },
            {
                "value": "宜春市",
                "label": "宜春市",
                "pid": "360000",
                "index": "360900",
                "children": [
                    {
                        "value": "袁州区",
                        "label": "袁州区",
                        "pid": "360900",
                        "index": "360902"
                    },
                    {
                        "value": "奉新县",
                        "label": "奉新县",
                        "pid": "360900",
                        "index": "360921"
                    },
                    {
                        "value": "万载县",
                        "label": "万载县",
                        "pid": "360900",
                        "index": "360922"
                    },
                    {
                        "value": "上高县",
                        "label": "上高县",
                        "pid": "360900",
                        "index": "360923"
                    },
                    {
                        "value": "宜丰县",
                        "label": "宜丰县",
                        "pid": "360900",
                        "index": "360924"
                    },
                    {
                        "value": "靖安县",
                        "label": "靖安县",
                        "pid": "360900",
                        "index": "360925"
                    },
                    {
                        "value": "铜鼓县",
                        "label": "铜鼓县",
                        "pid": "360900",
                        "index": "360926"
                    },
                    {
                        "value": "丰城市",
                        "label": "丰城市",
                        "pid": "360900",
                        "index": "360981"
                    },
                    {
                        "value": "樟树市",
                        "label": "樟树市",
                        "pid": "360900",
                        "index": "360982"
                    },
                    {
                        "value": "高安市",
                        "label": "高安市",
                        "pid": "360900",
                        "index": "360983"
                    }
                ]
            },
            {
                "value": "抚州市",
                "label": "抚州市",
                "pid": "360000",
                "index": "361000",
                "children": [
                    {
                        "value": "临川区",
                        "label": "临川区",
                        "pid": "361000",
                        "index": "361002"
                    },
                    {
                        "value": "东乡区",
                        "label": "东乡区",
                        "pid": "361000",
                        "index": "361003"
                    },
                    {
                        "value": "南城县",
                        "label": "南城县",
                        "pid": "361000",
                        "index": "361021"
                    },
                    {
                        "value": "黎川县",
                        "label": "黎川县",
                        "pid": "361000",
                        "index": "361022"
                    },
                    {
                        "value": "南丰县",
                        "label": "南丰县",
                        "pid": "361000",
                        "index": "361023"
                    },
                    {
                        "value": "崇仁县",
                        "label": "崇仁县",
                        "pid": "361000",
                        "index": "361024"
                    },
                    {
                        "value": "乐安县",
                        "label": "乐安县",
                        "pid": "361000",
                        "index": "361025"
                    },
                    {
                        "value": "宜黄县",
                        "label": "宜黄县",
                        "pid": "361000",
                        "index": "361026"
                    },
                    {
                        "value": "金溪县",
                        "label": "金溪县",
                        "pid": "361000",
                        "index": "361027"
                    },
                    {
                        "value": "资溪县",
                        "label": "资溪县",
                        "pid": "361000",
                        "index": "361028"
                    },
                    {
                        "value": "广昌县",
                        "label": "广昌县",
                        "pid": "361000",
                        "index": "361030"
                    }
                ]
            },
            {
                "value": "上饶市",
                "label": "上饶市",
                "pid": "360000",
                "index": "361100",
                "children": [
                    {
                        "value": "信州区",
                        "label": "信州区",
                        "pid": "361100",
                        "index": "361102"
                    },
                    {
                        "value": "广丰区",
                        "label": "广丰区",
                        "pid": "361100",
                        "index": "361103"
                    },
                    {
                        "value": "广信区",
                        "label": "广信区",
                        "pid": "361100",
                        "index": "361104"
                    },
                    {
                        "value": "玉山县",
                        "label": "玉山县",
                        "pid": "361100",
                        "index": "361123"
                    },
                    {
                        "value": "铅山县",
                        "label": "铅山县",
                        "pid": "361100",
                        "index": "361124"
                    },
                    {
                        "value": "横峰县",
                        "label": "横峰县",
                        "pid": "361100",
                        "index": "361125"
                    },
                    {
                        "value": "弋阳县",
                        "label": "弋阳县",
                        "pid": "361100",
                        "index": "361126"
                    },
                    {
                        "value": "余干县",
                        "label": "余干县",
                        "pid": "361100",
                        "index": "361127"
                    },
                    {
                        "value": "鄱阳县",
                        "label": "鄱阳县",
                        "pid": "361100",
                        "index": "361128"
                    },
                    {
                        "value": "万年县",
                        "label": "万年县",
                        "pid": "361100",
                        "index": "361129"
                    },
                    {
                        "value": "婺源县",
                        "label": "婺源县",
                        "pid": "361100",
                        "index": "361130"
                    },
                    {
                        "value": "德兴市",
                        "label": "德兴市",
                        "pid": "361100",
                        "index": "361181"
                    }
                ]
            }
        ]
    },
    {
        "value": "山东省",
        "label": "山东省",
        "pid": "86",
        "index": "370000",
        "children": [
            {
                "value": "济南市",
                "label": "济南市",
                "pid": "370000",
                "index": "370100",
                "children": [
                    {
                        "value": "历下区",
                        "label": "历下区",
                        "pid": "370100",
                        "index": "370102"
                    },
                    {
                        "value": "市中区",
                        "label": "市中区",
                        "pid": "370100",
                        "index": "370103"
                    },
                    {
                        "value": "槐荫区",
                        "label": "槐荫区",
                        "pid": "370100",
                        "index": "370104"
                    },
                    {
                        "value": "天桥区",
                        "label": "天桥区",
                        "pid": "370100",
                        "index": "370105"
                    },
                    {
                        "value": "历城区",
                        "label": "历城区",
                        "pid": "370100",
                        "index": "370112"
                    },
                    {
                        "value": "长清区",
                        "label": "长清区",
                        "pid": "370100",
                        "index": "370113"
                    },
                    {
                        "value": "章丘区",
                        "label": "章丘区",
                        "pid": "370100",
                        "index": "370114"
                    },
                    {
                        "value": "济阳区",
                        "label": "济阳区",
                        "pid": "370100",
                        "index": "370115"
                    },
                    {
                        "value": "莱芜区",
                        "label": "莱芜区",
                        "pid": "370100",
                        "index": "370116"
                    },
                    {
                        "value": "钢城区",
                        "label": "钢城区",
                        "pid": "370100",
                        "index": "370117"
                    },
                    {
                        "value": "平阴县",
                        "label": "平阴县",
                        "pid": "370100",
                        "index": "370124"
                    },
                    {
                        "value": "商河县",
                        "label": "商河县",
                        "pid": "370100",
                        "index": "370126"
                    },
                    {
                        "value": "济南高新技术产业开发区",
                        "label": "济南高新技术产业开发区",
                        "pid": "370100",
                        "index": "370171"
                    }
                ]
            },
            {
                "value": "青岛市",
                "label": "青岛市",
                "pid": "370000",
                "index": "370200",
                "children": [
                    {
                        "value": "市南区",
                        "label": "市南区",
                        "pid": "370200",
                        "index": "370202"
                    },
                    {
                        "value": "市北区",
                        "label": "市北区",
                        "pid": "370200",
                        "index": "370203"
                    },
                    {
                        "value": "黄岛区",
                        "label": "黄岛区",
                        "pid": "370200",
                        "index": "370211"
                    },
                    {
                        "value": "崂山区",
                        "label": "崂山区",
                        "pid": "370200",
                        "index": "370212"
                    },
                    {
                        "value": "李沧区",
                        "label": "李沧区",
                        "pid": "370200",
                        "index": "370213"
                    },
                    {
                        "value": "城阳区",
                        "label": "城阳区",
                        "pid": "370200",
                        "index": "370214"
                    },
                    {
                        "value": "即墨区",
                        "label": "即墨区",
                        "pid": "370200",
                        "index": "370215"
                    },
                    {
                        "value": "青岛高新技术产业开发区",
                        "label": "青岛高新技术产业开发区",
                        "pid": "370200",
                        "index": "370271"
                    },
                    {
                        "value": "胶州市",
                        "label": "胶州市",
                        "pid": "370200",
                        "index": "370281"
                    },
                    {
                        "value": "平度市",
                        "label": "平度市",
                        "pid": "370200",
                        "index": "370283"
                    },
                    {
                        "value": "莱西市",
                        "label": "莱西市",
                        "pid": "370200",
                        "index": "370285"
                    }
                ]
            },
            {
                "value": "淄博市",
                "label": "淄博市",
                "pid": "370000",
                "index": "370300",
                "children": [
                    {
                        "value": "淄川区",
                        "label": "淄川区",
                        "pid": "370300",
                        "index": "370302"
                    },
                    {
                        "value": "张店区",
                        "label": "张店区",
                        "pid": "370300",
                        "index": "370303"
                    },
                    {
                        "value": "博山区",
                        "label": "博山区",
                        "pid": "370300",
                        "index": "370304"
                    },
                    {
                        "value": "临淄区",
                        "label": "临淄区",
                        "pid": "370300",
                        "index": "370305"
                    },
                    {
                        "value": "周村区",
                        "label": "周村区",
                        "pid": "370300",
                        "index": "370306"
                    },
                    {
                        "value": "桓台县",
                        "label": "桓台县",
                        "pid": "370300",
                        "index": "370321"
                    },
                    {
                        "value": "高青县",
                        "label": "高青县",
                        "pid": "370300",
                        "index": "370322"
                    },
                    {
                        "value": "沂源县",
                        "label": "沂源县",
                        "pid": "370300",
                        "index": "370323"
                    }
                ]
            },
            {
                "value": "枣庄市",
                "label": "枣庄市",
                "pid": "370000",
                "index": "370400",
                "children": [
                    {
                        "value": "市中区",
                        "label": "市中区",
                        "pid": "370400",
                        "index": "370402"
                    },
                    {
                        "value": "薛城区",
                        "label": "薛城区",
                        "pid": "370400",
                        "index": "370403"
                    },
                    {
                        "value": "峄城区",
                        "label": "峄城区",
                        "pid": "370400",
                        "index": "370404"
                    },
                    {
                        "value": "台儿庄区",
                        "label": "台儿庄区",
                        "pid": "370400",
                        "index": "370405"
                    },
                    {
                        "value": "山亭区",
                        "label": "山亭区",
                        "pid": "370400",
                        "index": "370406"
                    },
                    {
                        "value": "滕州市",
                        "label": "滕州市",
                        "pid": "370400",
                        "index": "370481"
                    }
                ]
            },
            {
                "value": "东营市",
                "label": "东营市",
                "pid": "370000",
                "index": "370500",
                "children": [
                    {
                        "value": "东营区",
                        "label": "东营区",
                        "pid": "370500",
                        "index": "370502"
                    },
                    {
                        "value": "河口区",
                        "label": "河口区",
                        "pid": "370500",
                        "index": "370503"
                    },
                    {
                        "value": "垦利区",
                        "label": "垦利区",
                        "pid": "370500",
                        "index": "370505"
                    },
                    {
                        "value": "利津县",
                        "label": "利津县",
                        "pid": "370500",
                        "index": "370522"
                    },
                    {
                        "value": "广饶县",
                        "label": "广饶县",
                        "pid": "370500",
                        "index": "370523"
                    },
                    {
                        "value": "东营经济技术开发区",
                        "label": "东营经济技术开发区",
                        "pid": "370500",
                        "index": "370571"
                    },
                    {
                        "value": "东营港经济开发区",
                        "label": "东营港经济开发区",
                        "pid": "370500",
                        "index": "370572"
                    }
                ]
            },
            {
                "value": "烟台市",
                "label": "烟台市",
                "pid": "370000",
                "index": "370600",
                "children": [
                    {
                        "value": "芝罘区",
                        "label": "芝罘区",
                        "pid": "370600",
                        "index": "370602"
                    },
                    {
                        "value": "福山区",
                        "label": "福山区",
                        "pid": "370600",
                        "index": "370611"
                    },
                    {
                        "value": "牟平区",
                        "label": "牟平区",
                        "pid": "370600",
                        "index": "370612"
                    },
                    {
                        "value": "莱山区",
                        "label": "莱山区",
                        "pid": "370600",
                        "index": "370613"
                    },
                    {
                        "value": "长岛县",
                        "label": "长岛县",
                        "pid": "370600",
                        "index": "370634"
                    },
                    {
                        "value": "烟台高新技术产业开发区",
                        "label": "烟台高新技术产业开发区",
                        "pid": "370600",
                        "index": "370671"
                    },
                    {
                        "value": "烟台经济技术开发区",
                        "label": "烟台经济技术开发区",
                        "pid": "370600",
                        "index": "370672"
                    },
                    {
                        "value": "龙口市",
                        "label": "龙口市",
                        "pid": "370600",
                        "index": "370681"
                    },
                    {
                        "value": "莱阳市",
                        "label": "莱阳市",
                        "pid": "370600",
                        "index": "370682"
                    },
                    {
                        "value": "莱州市",
                        "label": "莱州市",
                        "pid": "370600",
                        "index": "370683"
                    },
                    {
                        "value": "蓬莱市",
                        "label": "蓬莱市",
                        "pid": "370600",
                        "index": "370684"
                    },
                    {
                        "value": "招远市",
                        "label": "招远市",
                        "pid": "370600",
                        "index": "370685"
                    },
                    {
                        "value": "栖霞市",
                        "label": "栖霞市",
                        "pid": "370600",
                        "index": "370686"
                    },
                    {
                        "value": "海阳市",
                        "label": "海阳市",
                        "pid": "370600",
                        "index": "370687"
                    }
                ]
            },
            {
                "value": "潍坊市",
                "label": "潍坊市",
                "pid": "370000",
                "index": "370700",
                "children": [
                    {
                        "value": "潍城区",
                        "label": "潍城区",
                        "pid": "370700",
                        "index": "370702"
                    },
                    {
                        "value": "寒亭区",
                        "label": "寒亭区",
                        "pid": "370700",
                        "index": "370703"
                    },
                    {
                        "value": "坊子区",
                        "label": "坊子区",
                        "pid": "370700",
                        "index": "370704"
                    },
                    {
                        "value": "奎文区",
                        "label": "奎文区",
                        "pid": "370700",
                        "index": "370705"
                    },
                    {
                        "value": "临朐县",
                        "label": "临朐县",
                        "pid": "370700",
                        "index": "370724"
                    },
                    {
                        "value": "昌乐县",
                        "label": "昌乐县",
                        "pid": "370700",
                        "index": "370725"
                    },
                    {
                        "value": "潍坊滨海经济技术开发区",
                        "label": "潍坊滨海经济技术开发区",
                        "pid": "370700",
                        "index": "370772"
                    },
                    {
                        "value": "青州市",
                        "label": "青州市",
                        "pid": "370700",
                        "index": "370781"
                    },
                    {
                        "value": "诸城市",
                        "label": "诸城市",
                        "pid": "370700",
                        "index": "370782"
                    },
                    {
                        "value": "寿光市",
                        "label": "寿光市",
                        "pid": "370700",
                        "index": "370783"
                    },
                    {
                        "value": "安丘市",
                        "label": "安丘市",
                        "pid": "370700",
                        "index": "370784"
                    },
                    {
                        "value": "高密市",
                        "label": "高密市",
                        "pid": "370700",
                        "index": "370785"
                    },
                    {
                        "value": "昌邑市",
                        "label": "昌邑市",
                        "pid": "370700",
                        "index": "370786"
                    }
                ]
            },
            {
                "value": "济宁市",
                "label": "济宁市",
                "pid": "370000",
                "index": "370800",
                "children": [
                    {
                        "value": "任城区",
                        "label": "任城区",
                        "pid": "370800",
                        "index": "370811"
                    },
                    {
                        "value": "兖州区",
                        "label": "兖州区",
                        "pid": "370800",
                        "index": "370812"
                    },
                    {
                        "value": "微山县",
                        "label": "微山县",
                        "pid": "370800",
                        "index": "370826"
                    },
                    {
                        "value": "鱼台县",
                        "label": "鱼台县",
                        "pid": "370800",
                        "index": "370827"
                    },
                    {
                        "value": "金乡县",
                        "label": "金乡县",
                        "pid": "370800",
                        "index": "370828"
                    },
                    {
                        "value": "嘉祥县",
                        "label": "嘉祥县",
                        "pid": "370800",
                        "index": "370829"
                    },
                    {
                        "value": "汶上县",
                        "label": "汶上县",
                        "pid": "370800",
                        "index": "370830"
                    },
                    {
                        "value": "泗水县",
                        "label": "泗水县",
                        "pid": "370800",
                        "index": "370831"
                    },
                    {
                        "value": "梁山县",
                        "label": "梁山县",
                        "pid": "370800",
                        "index": "370832"
                    },
                    {
                        "value": "济宁高新技术产业开发区",
                        "label": "济宁高新技术产业开发区",
                        "pid": "370800",
                        "index": "370871"
                    },
                    {
                        "value": "曲阜市",
                        "label": "曲阜市",
                        "pid": "370800",
                        "index": "370881"
                    },
                    {
                        "value": "邹城市",
                        "label": "邹城市",
                        "pid": "370800",
                        "index": "370883"
                    }
                ]
            },
            {
                "value": "泰安市",
                "label": "泰安市",
                "pid": "370000",
                "index": "370900",
                "children": [
                    {
                        "value": "泰山区",
                        "label": "泰山区",
                        "pid": "370900",
                        "index": "370902"
                    },
                    {
                        "value": "岱岳区",
                        "label": "岱岳区",
                        "pid": "370900",
                        "index": "370911"
                    },
                    {
                        "value": "宁阳县",
                        "label": "宁阳县",
                        "pid": "370900",
                        "index": "370921"
                    },
                    {
                        "value": "东平县",
                        "label": "东平县",
                        "pid": "370900",
                        "index": "370923"
                    },
                    {
                        "value": "新泰市",
                        "label": "新泰市",
                        "pid": "370900",
                        "index": "370982"
                    },
                    {
                        "value": "肥城市",
                        "label": "肥城市",
                        "pid": "370900",
                        "index": "370983"
                    }
                ]
            },
            {
                "value": "威海市",
                "label": "威海市",
                "pid": "370000",
                "index": "371000",
                "children": [
                    {
                        "value": "环翠区",
                        "label": "环翠区",
                        "pid": "371000",
                        "index": "371002"
                    },
                    {
                        "value": "文登区",
                        "label": "文登区",
                        "pid": "371000",
                        "index": "371003"
                    },
                    {
                        "value": "威海火炬高技术产业开发区",
                        "label": "威海火炬高技术产业开发区",
                        "pid": "371000",
                        "index": "371071"
                    },
                    {
                        "value": "威海经济技术开发区",
                        "label": "威海经济技术开发区",
                        "pid": "371000",
                        "index": "371072"
                    },
                    {
                        "value": "威海临港经济技术开发区",
                        "label": "威海临港经济技术开发区",
                        "pid": "371000",
                        "index": "371073"
                    },
                    {
                        "value": "荣成市",
                        "label": "荣成市",
                        "pid": "371000",
                        "index": "371082"
                    },
                    {
                        "value": "乳山市",
                        "label": "乳山市",
                        "pid": "371000",
                        "index": "371083"
                    }
                ]
            },
            {
                "value": "日照市",
                "label": "日照市",
                "pid": "370000",
                "index": "371100",
                "children": [
                    {
                        "value": "东港区",
                        "label": "东港区",
                        "pid": "371100",
                        "index": "371102"
                    },
                    {
                        "value": "岚山区",
                        "label": "岚山区",
                        "pid": "371100",
                        "index": "371103"
                    },
                    {
                        "value": "五莲县",
                        "label": "五莲县",
                        "pid": "371100",
                        "index": "371121"
                    },
                    {
                        "value": "莒县",
                        "label": "莒县",
                        "pid": "371100",
                        "index": "371122"
                    },
                    {
                        "value": "日照经济技术开发区",
                        "label": "日照经济技术开发区",
                        "pid": "371100",
                        "index": "371171"
                    }
                ]
            },
            {
                "value": "临沂市",
                "label": "临沂市",
                "pid": "370000",
                "index": "371300",
                "children": [
                    {
                        "value": "兰山区",
                        "label": "兰山区",
                        "pid": "371300",
                        "index": "371302"
                    },
                    {
                        "value": "罗庄区",
                        "label": "罗庄区",
                        "pid": "371300",
                        "index": "371311"
                    },
                    {
                        "value": "河东区",
                        "label": "河东区",
                        "pid": "371300",
                        "index": "371312"
                    },
                    {
                        "value": "沂南县",
                        "label": "沂南县",
                        "pid": "371300",
                        "index": "371321"
                    },
                    {
                        "value": "郯城县",
                        "label": "郯城县",
                        "pid": "371300",
                        "index": "371322"
                    },
                    {
                        "value": "沂水县",
                        "label": "沂水县",
                        "pid": "371300",
                        "index": "371323"
                    },
                    {
                        "value": "兰陵县",
                        "label": "兰陵县",
                        "pid": "371300",
                        "index": "371324"
                    },
                    {
                        "value": "费县",
                        "label": "费县",
                        "pid": "371300",
                        "index": "371325"
                    },
                    {
                        "value": "平邑县",
                        "label": "平邑县",
                        "pid": "371300",
                        "index": "371326"
                    },
                    {
                        "value": "莒南县",
                        "label": "莒南县",
                        "pid": "371300",
                        "index": "371327"
                    },
                    {
                        "value": "蒙阴县",
                        "label": "蒙阴县",
                        "pid": "371300",
                        "index": "371328"
                    },
                    {
                        "value": "临沭县",
                        "label": "临沭县",
                        "pid": "371300",
                        "index": "371329"
                    },
                    {
                        "value": "临沂高新技术产业开发区",
                        "label": "临沂高新技术产业开发区",
                        "pid": "371300",
                        "index": "371371"
                    },
                    {
                        "value": "临沂经济技术开发区",
                        "label": "临沂经济技术开发区",
                        "pid": "371300",
                        "index": "371372"
                    },
                    {
                        "value": "临沂临港经济开发区",
                        "label": "临沂临港经济开发区",
                        "pid": "371300",
                        "index": "371373"
                    }
                ]
            },
            {
                "value": "德州市",
                "label": "德州市",
                "pid": "370000",
                "index": "371400",
                "children": [
                    {
                        "value": "德城区",
                        "label": "德城区",
                        "pid": "371400",
                        "index": "371402"
                    },
                    {
                        "value": "陵城区",
                        "label": "陵城区",
                        "pid": "371400",
                        "index": "371403"
                    },
                    {
                        "value": "宁津县",
                        "label": "宁津县",
                        "pid": "371400",
                        "index": "371422"
                    },
                    {
                        "value": "庆云县",
                        "label": "庆云县",
                        "pid": "371400",
                        "index": "371423"
                    },
                    {
                        "value": "临邑县",
                        "label": "临邑县",
                        "pid": "371400",
                        "index": "371424"
                    },
                    {
                        "value": "齐河县",
                        "label": "齐河县",
                        "pid": "371400",
                        "index": "371425"
                    },
                    {
                        "value": "平原县",
                        "label": "平原县",
                        "pid": "371400",
                        "index": "371426"
                    },
                    {
                        "value": "夏津县",
                        "label": "夏津县",
                        "pid": "371400",
                        "index": "371427"
                    },
                    {
                        "value": "武城县",
                        "label": "武城县",
                        "pid": "371400",
                        "index": "371428"
                    },
                    {
                        "value": "德州经济技术开发区",
                        "label": "德州经济技术开发区",
                        "pid": "371400",
                        "index": "371471"
                    },
                    {
                        "value": "德州运河经济开发区",
                        "label": "德州运河经济开发区",
                        "pid": "371400",
                        "index": "371472"
                    },
                    {
                        "value": "乐陵市",
                        "label": "乐陵市",
                        "pid": "371400",
                        "index": "371481"
                    },
                    {
                        "value": "禹城市",
                        "label": "禹城市",
                        "pid": "371400",
                        "index": "371482"
                    }
                ]
            },
            {
                "value": "聊城市",
                "label": "聊城市",
                "pid": "370000",
                "index": "371500",
                "children": [
                    {
                        "value": "东昌府区",
                        "label": "东昌府区",
                        "pid": "371500",
                        "index": "371502"
                    },
                    {
                        "value": "茌平区",
                        "label": "茌平区",
                        "pid": "371500",
                        "index": "371503"
                    },
                    {
                        "value": "阳谷县",
                        "label": "阳谷县",
                        "pid": "371500",
                        "index": "371521"
                    },
                    {
                        "value": "莘县",
                        "label": "莘县",
                        "pid": "371500",
                        "index": "371522"
                    },
                    {
                        "value": "东阿县",
                        "label": "东阿县",
                        "pid": "371500",
                        "index": "371524"
                    },
                    {
                        "value": "冠县",
                        "label": "冠县",
                        "pid": "371500",
                        "index": "371525"
                    },
                    {
                        "value": "高唐县",
                        "label": "高唐县",
                        "pid": "371500",
                        "index": "371526"
                    },
                    {
                        "value": "临清市",
                        "label": "临清市",
                        "pid": "371500",
                        "index": "371581"
                    }
                ]
            },
            {
                "value": "滨州市",
                "label": "滨州市",
                "pid": "370000",
                "index": "371600",
                "children": [
                    {
                        "value": "滨城区",
                        "label": "滨城区",
                        "pid": "371600",
                        "index": "371602"
                    },
                    {
                        "value": "沾化区",
                        "label": "沾化区",
                        "pid": "371600",
                        "index": "371603"
                    },
                    {
                        "value": "惠民县",
                        "label": "惠民县",
                        "pid": "371600",
                        "index": "371621"
                    },
                    {
                        "value": "阳信县",
                        "label": "阳信县",
                        "pid": "371600",
                        "index": "371622"
                    },
                    {
                        "value": "无棣县",
                        "label": "无棣县",
                        "pid": "371600",
                        "index": "371623"
                    },
                    {
                        "value": "博兴县",
                        "label": "博兴县",
                        "pid": "371600",
                        "index": "371625"
                    },
                    {
                        "value": "邹平市",
                        "label": "邹平市",
                        "pid": "371600",
                        "index": "371681"
                    }
                ]
            },
            {
                "value": "菏泽市",
                "label": "菏泽市",
                "pid": "370000",
                "index": "371700",
                "children": [
                    {
                        "value": "牡丹区",
                        "label": "牡丹区",
                        "pid": "371700",
                        "index": "371702"
                    },
                    {
                        "value": "定陶区",
                        "label": "定陶区",
                        "pid": "371700",
                        "index": "371703"
                    },
                    {
                        "value": "曹县",
                        "label": "曹县",
                        "pid": "371700",
                        "index": "371721"
                    },
                    {
                        "value": "单县",
                        "label": "单县",
                        "pid": "371700",
                        "index": "371722"
                    },
                    {
                        "value": "成武县",
                        "label": "成武县",
                        "pid": "371700",
                        "index": "371723"
                    },
                    {
                        "value": "巨野县",
                        "label": "巨野县",
                        "pid": "371700",
                        "index": "371724"
                    },
                    {
                        "value": "郓城县",
                        "label": "郓城县",
                        "pid": "371700",
                        "index": "371725"
                    },
                    {
                        "value": "鄄城县",
                        "label": "鄄城县",
                        "pid": "371700",
                        "index": "371726"
                    },
                    {
                        "value": "东明县",
                        "label": "东明县",
                        "pid": "371700",
                        "index": "371728"
                    },
                    {
                        "value": "菏泽经济技术开发区",
                        "label": "菏泽经济技术开发区",
                        "pid": "371700",
                        "index": "371771"
                    },
                    {
                        "value": "菏泽高新技术开发区",
                        "label": "菏泽高新技术开发区",
                        "pid": "371700",
                        "index": "371772"
                    }
                ]
            }
        ]
    },
    {
        "value": "河南省",
        "label": "河南省",
        "pid": "86",
        "index": "410000",
        "children": [
            {
                "value": "郑州市",
                "label": "郑州市",
                "pid": "410000",
                "index": "410100",
                "children": [
                    {
                        "value": "中原区",
                        "label": "中原区",
                        "pid": "410100",
                        "index": "410102"
                    },
                    {
                        "value": "二七区",
                        "label": "二七区",
                        "pid": "410100",
                        "index": "410103"
                    },
                    {
                        "value": "管城回族区",
                        "label": "管城回族区",
                        "pid": "410100",
                        "index": "410104"
                    },
                    {
                        "value": "金水区",
                        "label": "金水区",
                        "pid": "410100",
                        "index": "410105"
                    },
                    {
                        "value": "上街区",
                        "label": "上街区",
                        "pid": "410100",
                        "index": "410106"
                    },
                    {
                        "value": "惠济区",
                        "label": "惠济区",
                        "pid": "410100",
                        "index": "410108"
                    },
                    {
                        "value": "中牟县",
                        "label": "中牟县",
                        "pid": "410100",
                        "index": "410122"
                    },
                    {
                        "value": "郑州经济技术开发区",
                        "label": "郑州经济技术开发区",
                        "pid": "410100",
                        "index": "410171"
                    },
                    {
                        "value": "郑州高新技术产业开发区",
                        "label": "郑州高新技术产业开发区",
                        "pid": "410100",
                        "index": "410172"
                    },
                    {
                        "value": "郑州航空港经济综合实验区",
                        "label": "郑州航空港经济综合实验区",
                        "pid": "410100",
                        "index": "410173"
                    },
                    {
                        "value": "巩义市",
                        "label": "巩义市",
                        "pid": "410100",
                        "index": "410181"
                    },
                    {
                        "value": "荥阳市",
                        "label": "荥阳市",
                        "pid": "410100",
                        "index": "410182"
                    },
                    {
                        "value": "新密市",
                        "label": "新密市",
                        "pid": "410100",
                        "index": "410183"
                    },
                    {
                        "value": "新郑市",
                        "label": "新郑市",
                        "pid": "410100",
                        "index": "410184"
                    },
                    {
                        "value": "登封市",
                        "label": "登封市",
                        "pid": "410100",
                        "index": "410185"
                    }
                ]
            },
            {
                "value": "开封市",
                "label": "开封市",
                "pid": "410000",
                "index": "410200",
                "children": [
                    {
                        "value": "龙亭区",
                        "label": "龙亭区",
                        "pid": "410200",
                        "index": "410202"
                    },
                    {
                        "value": "顺河回族区",
                        "label": "顺河回族区",
                        "pid": "410200",
                        "index": "410203"
                    },
                    {
                        "value": "鼓楼区",
                        "label": "鼓楼区",
                        "pid": "410200",
                        "index": "410204"
                    },
                    {
                        "value": "禹王台区",
                        "label": "禹王台区",
                        "pid": "410200",
                        "index": "410205"
                    },
                    {
                        "value": "祥符区",
                        "label": "祥符区",
                        "pid": "410200",
                        "index": "410212"
                    },
                    {
                        "value": "杞县",
                        "label": "杞县",
                        "pid": "410200",
                        "index": "410221"
                    },
                    {
                        "value": "通许县",
                        "label": "通许县",
                        "pid": "410200",
                        "index": "410222"
                    },
                    {
                        "value": "尉氏县",
                        "label": "尉氏县",
                        "pid": "410200",
                        "index": "410223"
                    },
                    {
                        "value": "兰考县",
                        "label": "兰考县",
                        "pid": "410200",
                        "index": "410225"
                    }
                ]
            },
            {
                "value": "洛阳市",
                "label": "洛阳市",
                "pid": "410000",
                "index": "410300",
                "children": [
                    {
                        "value": "老城区",
                        "label": "老城区",
                        "pid": "410300",
                        "index": "410302"
                    },
                    {
                        "value": "西工区",
                        "label": "西工区",
                        "pid": "410300",
                        "index": "410303"
                    },
                    {
                        "value": "瀍河回族区",
                        "label": "瀍河回族区",
                        "pid": "410300",
                        "index": "410304"
                    },
                    {
                        "value": "涧西区",
                        "label": "涧西区",
                        "pid": "410300",
                        "index": "410305"
                    },
                    {
                        "value": "吉利区",
                        "label": "吉利区",
                        "pid": "410300",
                        "index": "410306"
                    },
                    {
                        "value": "洛龙区",
                        "label": "洛龙区",
                        "pid": "410300",
                        "index": "410311"
                    },
                    {
                        "value": "孟津县",
                        "label": "孟津县",
                        "pid": "410300",
                        "index": "410322"
                    },
                    {
                        "value": "新安县",
                        "label": "新安县",
                        "pid": "410300",
                        "index": "410323"
                    },
                    {
                        "value": "栾川县",
                        "label": "栾川县",
                        "pid": "410300",
                        "index": "410324"
                    },
                    {
                        "value": "嵩县",
                        "label": "嵩县",
                        "pid": "410300",
                        "index": "410325"
                    },
                    {
                        "value": "汝阳县",
                        "label": "汝阳县",
                        "pid": "410300",
                        "index": "410326"
                    },
                    {
                        "value": "宜阳县",
                        "label": "宜阳县",
                        "pid": "410300",
                        "index": "410327"
                    },
                    {
                        "value": "洛宁县",
                        "label": "洛宁县",
                        "pid": "410300",
                        "index": "410328"
                    },
                    {
                        "value": "伊川县",
                        "label": "伊川县",
                        "pid": "410300",
                        "index": "410329"
                    },
                    {
                        "value": "洛阳高新技术产业开发区",
                        "label": "洛阳高新技术产业开发区",
                        "pid": "410300",
                        "index": "410371"
                    },
                    {
                        "value": "偃师市",
                        "label": "偃师市",
                        "pid": "410300",
                        "index": "410381"
                    }
                ]
            },
            {
                "value": "平顶山市",
                "label": "平顶山市",
                "pid": "410000",
                "index": "410400",
                "children": [
                    {
                        "value": "新华区",
                        "label": "新华区",
                        "pid": "410400",
                        "index": "410402"
                    },
                    {
                        "value": "卫东区",
                        "label": "卫东区",
                        "pid": "410400",
                        "index": "410403"
                    },
                    {
                        "value": "石龙区",
                        "label": "石龙区",
                        "pid": "410400",
                        "index": "410404"
                    },
                    {
                        "value": "湛河区",
                        "label": "湛河区",
                        "pid": "410400",
                        "index": "410411"
                    },
                    {
                        "value": "宝丰县",
                        "label": "宝丰县",
                        "pid": "410400",
                        "index": "410421"
                    },
                    {
                        "value": "叶县",
                        "label": "叶县",
                        "pid": "410400",
                        "index": "410422"
                    },
                    {
                        "value": "鲁山县",
                        "label": "鲁山县",
                        "pid": "410400",
                        "index": "410423"
                    },
                    {
                        "value": "郏县",
                        "label": "郏县",
                        "pid": "410400",
                        "index": "410425"
                    },
                    {
                        "value": "平顶山高新技术产业开发区",
                        "label": "平顶山高新技术产业开发区",
                        "pid": "410400",
                        "index": "410471"
                    },
                    {
                        "value": "平顶山市城乡一体化示范区",
                        "label": "平顶山市城乡一体化示范区",
                        "pid": "410400",
                        "index": "410472"
                    },
                    {
                        "value": "舞钢市",
                        "label": "舞钢市",
                        "pid": "410400",
                        "index": "410481"
                    },
                    {
                        "value": "汝州市",
                        "label": "汝州市",
                        "pid": "410400",
                        "index": "410482"
                    }
                ]
            },
            {
                "value": "安阳市",
                "label": "安阳市",
                "pid": "410000",
                "index": "410500",
                "children": [
                    {
                        "value": "文峰区",
                        "label": "文峰区",
                        "pid": "410500",
                        "index": "410502"
                    },
                    {
                        "value": "北关区",
                        "label": "北关区",
                        "pid": "410500",
                        "index": "410503"
                    },
                    {
                        "value": "殷都区",
                        "label": "殷都区",
                        "pid": "410500",
                        "index": "410505"
                    },
                    {
                        "value": "龙安区",
                        "label": "龙安区",
                        "pid": "410500",
                        "index": "410506"
                    },
                    {
                        "value": "安阳县",
                        "label": "安阳县",
                        "pid": "410500",
                        "index": "410522"
                    },
                    {
                        "value": "汤阴县",
                        "label": "汤阴县",
                        "pid": "410500",
                        "index": "410523"
                    },
                    {
                        "value": "滑县",
                        "label": "滑县",
                        "pid": "410500",
                        "index": "410526"
                    },
                    {
                        "value": "内黄县",
                        "label": "内黄县",
                        "pid": "410500",
                        "index": "410527"
                    },
                    {
                        "value": "安阳高新技术产业开发区",
                        "label": "安阳高新技术产业开发区",
                        "pid": "410500",
                        "index": "410571"
                    },
                    {
                        "value": "林州市",
                        "label": "林州市",
                        "pid": "410500",
                        "index": "410581"
                    }
                ]
            },
            {
                "value": "鹤壁市",
                "label": "鹤壁市",
                "pid": "410000",
                "index": "410600",
                "children": [
                    {
                        "value": "鹤山区",
                        "label": "鹤山区",
                        "pid": "410600",
                        "index": "410602"
                    },
                    {
                        "value": "山城区",
                        "label": "山城区",
                        "pid": "410600",
                        "index": "410603"
                    },
                    {
                        "value": "淇滨区",
                        "label": "淇滨区",
                        "pid": "410600",
                        "index": "410611"
                    },
                    {
                        "value": "浚县",
                        "label": "浚县",
                        "pid": "410600",
                        "index": "410621"
                    },
                    {
                        "value": "淇县",
                        "label": "淇县",
                        "pid": "410600",
                        "index": "410622"
                    },
                    {
                        "value": "鹤壁经济技术开发区",
                        "label": "鹤壁经济技术开发区",
                        "pid": "410600",
                        "index": "410671"
                    }
                ]
            },
            {
                "value": "新乡市",
                "label": "新乡市",
                "pid": "410000",
                "index": "410700",
                "children": [
                    {
                        "value": "红旗区",
                        "label": "红旗区",
                        "pid": "410700",
                        "index": "410702"
                    },
                    {
                        "value": "卫滨区",
                        "label": "卫滨区",
                        "pid": "410700",
                        "index": "410703"
                    },
                    {
                        "value": "凤泉区",
                        "label": "凤泉区",
                        "pid": "410700",
                        "index": "410704"
                    },
                    {
                        "value": "牧野区",
                        "label": "牧野区",
                        "pid": "410700",
                        "index": "410711"
                    },
                    {
                        "value": "新乡县",
                        "label": "新乡县",
                        "pid": "410700",
                        "index": "410721"
                    },
                    {
                        "value": "获嘉县",
                        "label": "获嘉县",
                        "pid": "410700",
                        "index": "410724"
                    },
                    {
                        "value": "原阳县",
                        "label": "原阳县",
                        "pid": "410700",
                        "index": "410725"
                    },
                    {
                        "value": "延津县",
                        "label": "延津县",
                        "pid": "410700",
                        "index": "410726"
                    },
                    {
                        "value": "封丘县",
                        "label": "封丘县",
                        "pid": "410700",
                        "index": "410727"
                    },
                    {
                        "value": "新乡高新技术产业开发区",
                        "label": "新乡高新技术产业开发区",
                        "pid": "410700",
                        "index": "410771"
                    },
                    {
                        "value": "新乡经济技术开发区",
                        "label": "新乡经济技术开发区",
                        "pid": "410700",
                        "index": "410772"
                    },
                    {
                        "value": "新乡市平原城乡一体化示范区",
                        "label": "新乡市平原城乡一体化示范区",
                        "pid": "410700",
                        "index": "410773"
                    },
                    {
                        "value": "卫辉市",
                        "label": "卫辉市",
                        "pid": "410700",
                        "index": "410781"
                    },
                    {
                        "value": "辉县市",
                        "label": "辉县市",
                        "pid": "410700",
                        "index": "410782"
                    },
                    {
                        "value": "长垣市",
                        "label": "长垣市",
                        "pid": "410700",
                        "index": "410783"
                    }
                ]
            },
            {
                "value": "焦作市",
                "label": "焦作市",
                "pid": "410000",
                "index": "410800",
                "children": [
                    {
                        "value": "解放区",
                        "label": "解放区",
                        "pid": "410800",
                        "index": "410802"
                    },
                    {
                        "value": "中站区",
                        "label": "中站区",
                        "pid": "410800",
                        "index": "410803"
                    },
                    {
                        "value": "马村区",
                        "label": "马村区",
                        "pid": "410800",
                        "index": "410804"
                    },
                    {
                        "value": "山阳区",
                        "label": "山阳区",
                        "pid": "410800",
                        "index": "410811"
                    },
                    {
                        "value": "修武县",
                        "label": "修武县",
                        "pid": "410800",
                        "index": "410821"
                    },
                    {
                        "value": "博爱县",
                        "label": "博爱县",
                        "pid": "410800",
                        "index": "410822"
                    },
                    {
                        "value": "武陟县",
                        "label": "武陟县",
                        "pid": "410800",
                        "index": "410823"
                    },
                    {
                        "value": "温县",
                        "label": "温县",
                        "pid": "410800",
                        "index": "410825"
                    },
                    {
                        "value": "焦作城乡一体化示范区",
                        "label": "焦作城乡一体化示范区",
                        "pid": "410800",
                        "index": "410871"
                    },
                    {
                        "value": "沁阳市",
                        "label": "沁阳市",
                        "pid": "410800",
                        "index": "410882"
                    },
                    {
                        "value": "孟州市",
                        "label": "孟州市",
                        "pid": "410800",
                        "index": "410883"
                    }
                ]
            },
            {
                "value": "濮阳市",
                "label": "濮阳市",
                "pid": "410000",
                "index": "410900",
                "children": [
                    {
                        "value": "华龙区",
                        "label": "华龙区",
                        "pid": "410900",
                        "index": "410902"
                    },
                    {
                        "value": "清丰县",
                        "label": "清丰县",
                        "pid": "410900",
                        "index": "410922"
                    },
                    {
                        "value": "南乐县",
                        "label": "南乐县",
                        "pid": "410900",
                        "index": "410923"
                    },
                    {
                        "value": "范县",
                        "label": "范县",
                        "pid": "410900",
                        "index": "410926"
                    },
                    {
                        "value": "台前县",
                        "label": "台前县",
                        "pid": "410900",
                        "index": "410927"
                    },
                    {
                        "value": "濮阳县",
                        "label": "濮阳县",
                        "pid": "410900",
                        "index": "410928"
                    },
                    {
                        "value": "河南濮阳工业园区",
                        "label": "河南濮阳工业园区",
                        "pid": "410900",
                        "index": "410971"
                    },
                    {
                        "value": "濮阳经济技术开发区",
                        "label": "濮阳经济技术开发区",
                        "pid": "410900",
                        "index": "410972"
                    }
                ]
            },
            {
                "value": "许昌市",
                "label": "许昌市",
                "pid": "410000",
                "index": "411000",
                "children": [
                    {
                        "value": "魏都区",
                        "label": "魏都区",
                        "pid": "411000",
                        "index": "411002"
                    },
                    {
                        "value": "建安区",
                        "label": "建安区",
                        "pid": "411000",
                        "index": "411003"
                    },
                    {
                        "value": "鄢陵县",
                        "label": "鄢陵县",
                        "pid": "411000",
                        "index": "411024"
                    },
                    {
                        "value": "襄城县",
                        "label": "襄城县",
                        "pid": "411000",
                        "index": "411025"
                    },
                    {
                        "value": "许昌经济技术开发区",
                        "label": "许昌经济技术开发区",
                        "pid": "411000",
                        "index": "411071"
                    },
                    {
                        "value": "禹州市",
                        "label": "禹州市",
                        "pid": "411000",
                        "index": "411081"
                    },
                    {
                        "value": "长葛市",
                        "label": "长葛市",
                        "pid": "411000",
                        "index": "411082"
                    }
                ]
            },
            {
                "value": "漯河市",
                "label": "漯河市",
                "pid": "410000",
                "index": "411100",
                "children": [
                    {
                        "value": "源汇区",
                        "label": "源汇区",
                        "pid": "411100",
                        "index": "411102"
                    },
                    {
                        "value": "郾城区",
                        "label": "郾城区",
                        "pid": "411100",
                        "index": "411103"
                    },
                    {
                        "value": "召陵区",
                        "label": "召陵区",
                        "pid": "411100",
                        "index": "411104"
                    },
                    {
                        "value": "舞阳县",
                        "label": "舞阳县",
                        "pid": "411100",
                        "index": "411121"
                    },
                    {
                        "value": "临颍县",
                        "label": "临颍县",
                        "pid": "411100",
                        "index": "411122"
                    },
                    {
                        "value": "漯河经济技术开发区",
                        "label": "漯河经济技术开发区",
                        "pid": "411100",
                        "index": "411171"
                    }
                ]
            },
            {
                "value": "三门峡市",
                "label": "三门峡市",
                "pid": "410000",
                "index": "411200",
                "children": [
                    {
                        "value": "湖滨区",
                        "label": "湖滨区",
                        "pid": "411200",
                        "index": "411202"
                    },
                    {
                        "value": "陕州区",
                        "label": "陕州区",
                        "pid": "411200",
                        "index": "411203"
                    },
                    {
                        "value": "渑池县",
                        "label": "渑池县",
                        "pid": "411200",
                        "index": "411221"
                    },
                    {
                        "value": "卢氏县",
                        "label": "卢氏县",
                        "pid": "411200",
                        "index": "411224"
                    },
                    {
                        "value": "河南三门峡经济开发区",
                        "label": "河南三门峡经济开发区",
                        "pid": "411200",
                        "index": "411271"
                    },
                    {
                        "value": "义马市",
                        "label": "义马市",
                        "pid": "411200",
                        "index": "411281"
                    },
                    {
                        "value": "灵宝市",
                        "label": "灵宝市",
                        "pid": "411200",
                        "index": "411282"
                    }
                ]
            },
            {
                "value": "南阳市",
                "label": "南阳市",
                "pid": "410000",
                "index": "411300",
                "children": [
                    {
                        "value": "宛城区",
                        "label": "宛城区",
                        "pid": "411300",
                        "index": "411302"
                    },
                    {
                        "value": "卧龙区",
                        "label": "卧龙区",
                        "pid": "411300",
                        "index": "411303"
                    },
                    {
                        "value": "南召县",
                        "label": "南召县",
                        "pid": "411300",
                        "index": "411321"
                    },
                    {
                        "value": "方城县",
                        "label": "方城县",
                        "pid": "411300",
                        "index": "411322"
                    },
                    {
                        "value": "西峡县",
                        "label": "西峡县",
                        "pid": "411300",
                        "index": "411323"
                    },
                    {
                        "value": "镇平县",
                        "label": "镇平县",
                        "pid": "411300",
                        "index": "411324"
                    },
                    {
                        "value": "内乡县",
                        "label": "内乡县",
                        "pid": "411300",
                        "index": "411325"
                    },
                    {
                        "value": "淅川县",
                        "label": "淅川县",
                        "pid": "411300",
                        "index": "411326"
                    },
                    {
                        "value": "社旗县",
                        "label": "社旗县",
                        "pid": "411300",
                        "index": "411327"
                    },
                    {
                        "value": "唐河县",
                        "label": "唐河县",
                        "pid": "411300",
                        "index": "411328"
                    },
                    {
                        "value": "新野县",
                        "label": "新野县",
                        "pid": "411300",
                        "index": "411329"
                    },
                    {
                        "value": "桐柏县",
                        "label": "桐柏县",
                        "pid": "411300",
                        "index": "411330"
                    },
                    {
                        "value": "南阳高新技术产业开发区",
                        "label": "南阳高新技术产业开发区",
                        "pid": "411300",
                        "index": "411371"
                    },
                    {
                        "value": "南阳市城乡一体化示范区",
                        "label": "南阳市城乡一体化示范区",
                        "pid": "411300",
                        "index": "411372"
                    },
                    {
                        "value": "邓州市",
                        "label": "邓州市",
                        "pid": "411300",
                        "index": "411381"
                    }
                ]
            },
            {
                "value": "商丘市",
                "label": "商丘市",
                "pid": "410000",
                "index": "411400",
                "children": [
                    {
                        "value": "梁园区",
                        "label": "梁园区",
                        "pid": "411400",
                        "index": "411402"
                    },
                    {
                        "value": "睢阳区",
                        "label": "睢阳区",
                        "pid": "411400",
                        "index": "411403"
                    },
                    {
                        "value": "民权县",
                        "label": "民权县",
                        "pid": "411400",
                        "index": "411421"
                    },
                    {
                        "value": "睢县",
                        "label": "睢县",
                        "pid": "411400",
                        "index": "411422"
                    },
                    {
                        "value": "宁陵县",
                        "label": "宁陵县",
                        "pid": "411400",
                        "index": "411423"
                    },
                    {
                        "value": "柘城县",
                        "label": "柘城县",
                        "pid": "411400",
                        "index": "411424"
                    },
                    {
                        "value": "虞城县",
                        "label": "虞城县",
                        "pid": "411400",
                        "index": "411425"
                    },
                    {
                        "value": "夏邑县",
                        "label": "夏邑县",
                        "pid": "411400",
                        "index": "411426"
                    },
                    {
                        "value": "豫东综合物流产业聚集区",
                        "label": "豫东综合物流产业聚集区",
                        "pid": "411400",
                        "index": "411471"
                    },
                    {
                        "value": "河南商丘经济开发区",
                        "label": "河南商丘经济开发区",
                        "pid": "411400",
                        "index": "411472"
                    },
                    {
                        "value": "永城市",
                        "label": "永城市",
                        "pid": "411400",
                        "index": "411481"
                    }
                ]
            },
            {
                "value": "信阳市",
                "label": "信阳市",
                "pid": "410000",
                "index": "411500",
                "children": [
                    {
                        "value": "浉河区",
                        "label": "浉河区",
                        "pid": "411500",
                        "index": "411502"
                    },
                    {
                        "value": "平桥区",
                        "label": "平桥区",
                        "pid": "411500",
                        "index": "411503"
                    },
                    {
                        "value": "罗山县",
                        "label": "罗山县",
                        "pid": "411500",
                        "index": "411521"
                    },
                    {
                        "value": "光山县",
                        "label": "光山县",
                        "pid": "411500",
                        "index": "411522"
                    },
                    {
                        "value": "新县",
                        "label": "新县",
                        "pid": "411500",
                        "index": "411523"
                    },
                    {
                        "value": "商城县",
                        "label": "商城县",
                        "pid": "411500",
                        "index": "411524"
                    },
                    {
                        "value": "固始县",
                        "label": "固始县",
                        "pid": "411500",
                        "index": "411525"
                    },
                    {
                        "value": "潢川县",
                        "label": "潢川县",
                        "pid": "411500",
                        "index": "411526"
                    },
                    {
                        "value": "淮滨县",
                        "label": "淮滨县",
                        "pid": "411500",
                        "index": "411527"
                    },
                    {
                        "value": "息县",
                        "label": "息县",
                        "pid": "411500",
                        "index": "411528"
                    },
                    {
                        "value": "信阳高新技术产业开发区",
                        "label": "信阳高新技术产业开发区",
                        "pid": "411500",
                        "index": "411571"
                    }
                ]
            },
            {
                "value": "周口市",
                "label": "周口市",
                "pid": "410000",
                "index": "411600",
                "children": [
                    {
                        "value": "川汇区",
                        "label": "川汇区",
                        "pid": "411600",
                        "index": "411602"
                    },
                    {
                        "value": "淮阳区",
                        "label": "淮阳区",
                        "pid": "411600",
                        "index": "411603"
                    },
                    {
                        "value": "扶沟县",
                        "label": "扶沟县",
                        "pid": "411600",
                        "index": "411621"
                    },
                    {
                        "value": "西华县",
                        "label": "西华县",
                        "pid": "411600",
                        "index": "411622"
                    },
                    {
                        "value": "商水县",
                        "label": "商水县",
                        "pid": "411600",
                        "index": "411623"
                    },
                    {
                        "value": "沈丘县",
                        "label": "沈丘县",
                        "pid": "411600",
                        "index": "411624"
                    },
                    {
                        "value": "郸城县",
                        "label": "郸城县",
                        "pid": "411600",
                        "index": "411625"
                    },
                    {
                        "value": "太康县",
                        "label": "太康县",
                        "pid": "411600",
                        "index": "411627"
                    },
                    {
                        "value": "鹿邑县",
                        "label": "鹿邑县",
                        "pid": "411600",
                        "index": "411628"
                    },
                    {
                        "value": "河南周口经济开发区",
                        "label": "河南周口经济开发区",
                        "pid": "411600",
                        "index": "411671"
                    },
                    {
                        "value": "项城市",
                        "label": "项城市",
                        "pid": "411600",
                        "index": "411681"
                    }
                ]
            },
            {
                "value": "驻马店市",
                "label": "驻马店市",
                "pid": "410000",
                "index": "411700",
                "children": [
                    {
                        "value": "驿城区",
                        "label": "驿城区",
                        "pid": "411700",
                        "index": "411702"
                    },
                    {
                        "value": "西平县",
                        "label": "西平县",
                        "pid": "411700",
                        "index": "411721"
                    },
                    {
                        "value": "上蔡县",
                        "label": "上蔡县",
                        "pid": "411700",
                        "index": "411722"
                    },
                    {
                        "value": "平舆县",
                        "label": "平舆县",
                        "pid": "411700",
                        "index": "411723"
                    },
                    {
                        "value": "正阳县",
                        "label": "正阳县",
                        "pid": "411700",
                        "index": "411724"
                    },
                    {
                        "value": "确山县",
                        "label": "确山县",
                        "pid": "411700",
                        "index": "411725"
                    },
                    {
                        "value": "泌阳县",
                        "label": "泌阳县",
                        "pid": "411700",
                        "index": "411726"
                    },
                    {
                        "value": "汝南县",
                        "label": "汝南县",
                        "pid": "411700",
                        "index": "411727"
                    },
                    {
                        "value": "遂平县",
                        "label": "遂平县",
                        "pid": "411700",
                        "index": "411728"
                    },
                    {
                        "value": "新蔡县",
                        "label": "新蔡县",
                        "pid": "411700",
                        "index": "411729"
                    },
                    {
                        "value": "河南驻马店经济开发区",
                        "label": "河南驻马店经济开发区",
                        "pid": "411700",
                        "index": "411771"
                    }
                ]
            },
            {
                "value": "省直辖县级行政区划",
                "label": "省直辖县级行政区划",
                "pid": "410000",
                "index": "419000",
                "children": [
                    {
                        "value": "济源市",
                        "label": "济源市",
                        "pid": "419000",
                        "index": "419001"
                    }
                ]
            }
        ]
    },
    {
        "value": "湖北省",
        "label": "湖北省",
        "pid": "86",
        "index": "420000",
        "children": [
            {
                "value": "武汉市",
                "label": "武汉市",
                "pid": "420000",
                "index": "420100",
                "children": [
                    {
                        "value": "江岸区",
                        "label": "江岸区",
                        "pid": "420100",
                        "index": "420102"
                    },
                    {
                        "value": "江汉区",
                        "label": "江汉区",
                        "pid": "420100",
                        "index": "420103"
                    },
                    {
                        "value": "硚口区",
                        "label": "硚口区",
                        "pid": "420100",
                        "index": "420104"
                    },
                    {
                        "value": "汉阳区",
                        "label": "汉阳区",
                        "pid": "420100",
                        "index": "420105"
                    },
                    {
                        "value": "武昌区",
                        "label": "武昌区",
                        "pid": "420100",
                        "index": "420106"
                    },
                    {
                        "value": "青山区",
                        "label": "青山区",
                        "pid": "420100",
                        "index": "420107"
                    },
                    {
                        "value": "洪山区",
                        "label": "洪山区",
                        "pid": "420100",
                        "index": "420111"
                    },
                    {
                        "value": "东西湖区",
                        "label": "东西湖区",
                        "pid": "420100",
                        "index": "420112"
                    },
                    {
                        "value": "汉南区",
                        "label": "汉南区",
                        "pid": "420100",
                        "index": "420113"
                    },
                    {
                        "value": "蔡甸区",
                        "label": "蔡甸区",
                        "pid": "420100",
                        "index": "420114"
                    },
                    {
                        "value": "江夏区",
                        "label": "江夏区",
                        "pid": "420100",
                        "index": "420115"
                    },
                    {
                        "value": "黄陂区",
                        "label": "黄陂区",
                        "pid": "420100",
                        "index": "420116"
                    },
                    {
                        "value": "新洲区",
                        "label": "新洲区",
                        "pid": "420100",
                        "index": "420117"
                    }
                ]
            },
            {
                "value": "黄石市",
                "label": "黄石市",
                "pid": "420000",
                "index": "420200",
                "children": [
                    {
                        "value": "黄石港区",
                        "label": "黄石港区",
                        "pid": "420200",
                        "index": "420202"
                    },
                    {
                        "value": "西塞山区",
                        "label": "西塞山区",
                        "pid": "420200",
                        "index": "420203"
                    },
                    {
                        "value": "下陆区",
                        "label": "下陆区",
                        "pid": "420200",
                        "index": "420204"
                    },
                    {
                        "value": "铁山区",
                        "label": "铁山区",
                        "pid": "420200",
                        "index": "420205"
                    },
                    {
                        "value": "阳新县",
                        "label": "阳新县",
                        "pid": "420200",
                        "index": "420222"
                    },
                    {
                        "value": "大冶市",
                        "label": "大冶市",
                        "pid": "420200",
                        "index": "420281"
                    }
                ]
            },
            {
                "value": "十堰市",
                "label": "十堰市",
                "pid": "420000",
                "index": "420300",
                "children": [
                    {
                        "value": "茅箭区",
                        "label": "茅箭区",
                        "pid": "420300",
                        "index": "420302"
                    },
                    {
                        "value": "张湾区",
                        "label": "张湾区",
                        "pid": "420300",
                        "index": "420303"
                    },
                    {
                        "value": "郧阳区",
                        "label": "郧阳区",
                        "pid": "420300",
                        "index": "420304"
                    },
                    {
                        "value": "郧西县",
                        "label": "郧西县",
                        "pid": "420300",
                        "index": "420322"
                    },
                    {
                        "value": "竹山县",
                        "label": "竹山县",
                        "pid": "420300",
                        "index": "420323"
                    },
                    {
                        "value": "竹溪县",
                        "label": "竹溪县",
                        "pid": "420300",
                        "index": "420324"
                    },
                    {
                        "value": "房县",
                        "label": "房县",
                        "pid": "420300",
                        "index": "420325"
                    },
                    {
                        "value": "丹江口市",
                        "label": "丹江口市",
                        "pid": "420300",
                        "index": "420381"
                    }
                ]
            },
            {
                "value": "宜昌市",
                "label": "宜昌市",
                "pid": "420000",
                "index": "420500",
                "children": [
                    {
                        "value": "西陵区",
                        "label": "西陵区",
                        "pid": "420500",
                        "index": "420502"
                    },
                    {
                        "value": "伍家岗区",
                        "label": "伍家岗区",
                        "pid": "420500",
                        "index": "420503"
                    },
                    {
                        "value": "点军区",
                        "label": "点军区",
                        "pid": "420500",
                        "index": "420504"
                    },
                    {
                        "value": "猇亭区",
                        "label": "猇亭区",
                        "pid": "420500",
                        "index": "420505"
                    },
                    {
                        "value": "夷陵区",
                        "label": "夷陵区",
                        "pid": "420500",
                        "index": "420506"
                    },
                    {
                        "value": "远安县",
                        "label": "远安县",
                        "pid": "420500",
                        "index": "420525"
                    },
                    {
                        "value": "兴山县",
                        "label": "兴山县",
                        "pid": "420500",
                        "index": "420526"
                    },
                    {
                        "value": "秭归县",
                        "label": "秭归县",
                        "pid": "420500",
                        "index": "420527"
                    },
                    {
                        "value": "长阳土家族自治县",
                        "label": "长阳土家族自治县",
                        "pid": "420500",
                        "index": "420528"
                    },
                    {
                        "value": "五峰土家族自治县",
                        "label": "五峰土家族自治县",
                        "pid": "420500",
                        "index": "420529"
                    },
                    {
                        "value": "宜都市",
                        "label": "宜都市",
                        "pid": "420500",
                        "index": "420581"
                    },
                    {
                        "value": "当阳市",
                        "label": "当阳市",
                        "pid": "420500",
                        "index": "420582"
                    },
                    {
                        "value": "枝江市",
                        "label": "枝江市",
                        "pid": "420500",
                        "index": "420583"
                    }
                ]
            },
            {
                "value": "襄阳市",
                "label": "襄阳市",
                "pid": "420000",
                "index": "420600",
                "children": [
                    {
                        "value": "襄城区",
                        "label": "襄城区",
                        "pid": "420600",
                        "index": "420602"
                    },
                    {
                        "value": "樊城区",
                        "label": "樊城区",
                        "pid": "420600",
                        "index": "420606"
                    },
                    {
                        "value": "襄州区",
                        "label": "襄州区",
                        "pid": "420600",
                        "index": "420607"
                    },
                    {
                        "value": "南漳县",
                        "label": "南漳县",
                        "pid": "420600",
                        "index": "420624"
                    },
                    {
                        "value": "谷城县",
                        "label": "谷城县",
                        "pid": "420600",
                        "index": "420625"
                    },
                    {
                        "value": "保康县",
                        "label": "保康县",
                        "pid": "420600",
                        "index": "420626"
                    },
                    {
                        "value": "老河口市",
                        "label": "老河口市",
                        "pid": "420600",
                        "index": "420682"
                    },
                    {
                        "value": "枣阳市",
                        "label": "枣阳市",
                        "pid": "420600",
                        "index": "420683"
                    },
                    {
                        "value": "宜城市",
                        "label": "宜城市",
                        "pid": "420600",
                        "index": "420684"
                    }
                ]
            },
            {
                "value": "鄂州市",
                "label": "鄂州市",
                "pid": "420000",
                "index": "420700",
                "children": [
                    {
                        "value": "梁子湖区",
                        "label": "梁子湖区",
                        "pid": "420700",
                        "index": "420702"
                    },
                    {
                        "value": "华容区",
                        "label": "华容区",
                        "pid": "420700",
                        "index": "420703"
                    },
                    {
                        "value": "鄂城区",
                        "label": "鄂城区",
                        "pid": "420700",
                        "index": "420704"
                    }
                ]
            },
            {
                "value": "荆门市",
                "label": "荆门市",
                "pid": "420000",
                "index": "420800",
                "children": [
                    {
                        "value": "东宝区",
                        "label": "东宝区",
                        "pid": "420800",
                        "index": "420802"
                    },
                    {
                        "value": "掇刀区",
                        "label": "掇刀区",
                        "pid": "420800",
                        "index": "420804"
                    },
                    {
                        "value": "沙洋县",
                        "label": "沙洋县",
                        "pid": "420800",
                        "index": "420822"
                    },
                    {
                        "value": "钟祥市",
                        "label": "钟祥市",
                        "pid": "420800",
                        "index": "420881"
                    },
                    {
                        "value": "京山市",
                        "label": "京山市",
                        "pid": "420800",
                        "index": "420882"
                    }
                ]
            },
            {
                "value": "孝感市",
                "label": "孝感市",
                "pid": "420000",
                "index": "420900",
                "children": [
                    {
                        "value": "孝南区",
                        "label": "孝南区",
                        "pid": "420900",
                        "index": "420902"
                    },
                    {
                        "value": "孝昌县",
                        "label": "孝昌县",
                        "pid": "420900",
                        "index": "420921"
                    },
                    {
                        "value": "大悟县",
                        "label": "大悟县",
                        "pid": "420900",
                        "index": "420922"
                    },
                    {
                        "value": "云梦县",
                        "label": "云梦县",
                        "pid": "420900",
                        "index": "420923"
                    },
                    {
                        "value": "应城市",
                        "label": "应城市",
                        "pid": "420900",
                        "index": "420981"
                    },
                    {
                        "value": "安陆市",
                        "label": "安陆市",
                        "pid": "420900",
                        "index": "420982"
                    },
                    {
                        "value": "汉川市",
                        "label": "汉川市",
                        "pid": "420900",
                        "index": "420984"
                    }
                ]
            },
            {
                "value": "荆州市",
                "label": "荆州市",
                "pid": "420000",
                "index": "421000",
                "children": [
                    {
                        "value": "沙市区",
                        "label": "沙市区",
                        "pid": "421000",
                        "index": "421002"
                    },
                    {
                        "value": "荆州区",
                        "label": "荆州区",
                        "pid": "421000",
                        "index": "421003"
                    },
                    {
                        "value": "公安县",
                        "label": "公安县",
                        "pid": "421000",
                        "index": "421022"
                    },
                    {
                        "value": "监利县",
                        "label": "监利县",
                        "pid": "421000",
                        "index": "421023"
                    },
                    {
                        "value": "江陵县",
                        "label": "江陵县",
                        "pid": "421000",
                        "index": "421024"
                    },
                    {
                        "value": "荆州经济技术开发区",
                        "label": "荆州经济技术开发区",
                        "pid": "421000",
                        "index": "421071"
                    },
                    {
                        "value": "石首市",
                        "label": "石首市",
                        "pid": "421000",
                        "index": "421081"
                    },
                    {
                        "value": "洪湖市",
                        "label": "洪湖市",
                        "pid": "421000",
                        "index": "421083"
                    },
                    {
                        "value": "松滋市",
                        "label": "松滋市",
                        "pid": "421000",
                        "index": "421087"
                    }
                ]
            },
            {
                "value": "黄冈市",
                "label": "黄冈市",
                "pid": "420000",
                "index": "421100",
                "children": [
                    {
                        "value": "黄州区",
                        "label": "黄州区",
                        "pid": "421100",
                        "index": "421102"
                    },
                    {
                        "value": "团风县",
                        "label": "团风县",
                        "pid": "421100",
                        "index": "421121"
                    },
                    {
                        "value": "红安县",
                        "label": "红安县",
                        "pid": "421100",
                        "index": "421122"
                    },
                    {
                        "value": "罗田县",
                        "label": "罗田县",
                        "pid": "421100",
                        "index": "421123"
                    },
                    {
                        "value": "英山县",
                        "label": "英山县",
                        "pid": "421100",
                        "index": "421124"
                    },
                    {
                        "value": "浠水县",
                        "label": "浠水县",
                        "pid": "421100",
                        "index": "421125"
                    },
                    {
                        "value": "蕲春县",
                        "label": "蕲春县",
                        "pid": "421100",
                        "index": "421126"
                    },
                    {
                        "value": "黄梅县",
                        "label": "黄梅县",
                        "pid": "421100",
                        "index": "421127"
                    },
                    {
                        "value": "龙感湖管理区",
                        "label": "龙感湖管理区",
                        "pid": "421100",
                        "index": "421171"
                    },
                    {
                        "value": "麻城市",
                        "label": "麻城市",
                        "pid": "421100",
                        "index": "421181"
                    },
                    {
                        "value": "武穴市",
                        "label": "武穴市",
                        "pid": "421100",
                        "index": "421182"
                    }
                ]
            },
            {
                "value": "咸宁市",
                "label": "咸宁市",
                "pid": "420000",
                "index": "421200",
                "children": [
                    {
                        "value": "咸安区",
                        "label": "咸安区",
                        "pid": "421200",
                        "index": "421202"
                    },
                    {
                        "value": "嘉鱼县",
                        "label": "嘉鱼县",
                        "pid": "421200",
                        "index": "421221"
                    },
                    {
                        "value": "通城县",
                        "label": "通城县",
                        "pid": "421200",
                        "index": "421222"
                    },
                    {
                        "value": "崇阳县",
                        "label": "崇阳县",
                        "pid": "421200",
                        "index": "421223"
                    },
                    {
                        "value": "通山县",
                        "label": "通山县",
                        "pid": "421200",
                        "index": "421224"
                    },
                    {
                        "value": "赤壁市",
                        "label": "赤壁市",
                        "pid": "421200",
                        "index": "421281"
                    }
                ]
            },
            {
                "value": "随州市",
                "label": "随州市",
                "pid": "420000",
                "index": "421300",
                "children": [
                    {
                        "value": "曾都区",
                        "label": "曾都区",
                        "pid": "421300",
                        "index": "421303"
                    },
                    {
                        "value": "随县",
                        "label": "随县",
                        "pid": "421300",
                        "index": "421321"
                    },
                    {
                        "value": "广水市",
                        "label": "广水市",
                        "pid": "421300",
                        "index": "421381"
                    }
                ]
            },
            {
                "value": "恩施土家族苗族自治州",
                "label": "恩施土家族苗族自治州",
                "pid": "420000",
                "index": "422800",
                "children": [
                    {
                        "value": "恩施市",
                        "label": "恩施市",
                        "pid": "422800",
                        "index": "422801"
                    },
                    {
                        "value": "利川市",
                        "label": "利川市",
                        "pid": "422800",
                        "index": "422802"
                    },
                    {
                        "value": "建始县",
                        "label": "建始县",
                        "pid": "422800",
                        "index": "422822"
                    },
                    {
                        "value": "巴东县",
                        "label": "巴东县",
                        "pid": "422800",
                        "index": "422823"
                    },
                    {
                        "value": "宣恩县",
                        "label": "宣恩县",
                        "pid": "422800",
                        "index": "422825"
                    },
                    {
                        "value": "咸丰县",
                        "label": "咸丰县",
                        "pid": "422800",
                        "index": "422826"
                    },
                    {
                        "value": "来凤县",
                        "label": "来凤县",
                        "pid": "422800",
                        "index": "422827"
                    },
                    {
                        "value": "鹤峰县",
                        "label": "鹤峰县",
                        "pid": "422800",
                        "index": "422828"
                    }
                ]
            },
            {
                "value": "省直辖县级行政区划",
                "label": "省直辖县级行政区划",
                "pid": "420000",
                "index": "429000",
                "children": [
                    {
                        "value": "仙桃市",
                        "label": "仙桃市",
                        "pid": "429000",
                        "index": "429004"
                    },
                    {
                        "value": "潜江市",
                        "label": "潜江市",
                        "pid": "429000",
                        "index": "429005"
                    },
                    {
                        "value": "天门市",
                        "label": "天门市",
                        "pid": "429000",
                        "index": "429006"
                    },
                    {
                        "value": "神农架林区",
                        "label": "神农架林区",
                        "pid": "429000",
                        "index": "429021"
                    }
                ]
            }
        ]
    },
    {
        "value": "湖南省",
        "label": "湖南省",
        "pid": "86",
        "index": "430000",
        "children": [
            {
                "value": "长沙市",
                "label": "长沙市",
                "pid": "430000",
                "index": "430100",
                "children": [
                    {
                        "value": "芙蓉区",
                        "label": "芙蓉区",
                        "pid": "430100",
                        "index": "430102"
                    },
                    {
                        "value": "天心区",
                        "label": "天心区",
                        "pid": "430100",
                        "index": "430103"
                    },
                    {
                        "value": "岳麓区",
                        "label": "岳麓区",
                        "pid": "430100",
                        "index": "430104"
                    },
                    {
                        "value": "开福区",
                        "label": "开福区",
                        "pid": "430100",
                        "index": "430105"
                    },
                    {
                        "value": "雨花区",
                        "label": "雨花区",
                        "pid": "430100",
                        "index": "430111"
                    },
                    {
                        "value": "望城区",
                        "label": "望城区",
                        "pid": "430100",
                        "index": "430112"
                    },
                    {
                        "value": "长沙县",
                        "label": "长沙县",
                        "pid": "430100",
                        "index": "430121"
                    },
                    {
                        "value": "浏阳市",
                        "label": "浏阳市",
                        "pid": "430100",
                        "index": "430181"
                    },
                    {
                        "value": "宁乡市",
                        "label": "宁乡市",
                        "pid": "430100",
                        "index": "430182"
                    }
                ]
            },
            {
                "value": "株洲市",
                "label": "株洲市",
                "pid": "430000",
                "index": "430200",
                "children": [
                    {
                        "value": "荷塘区",
                        "label": "荷塘区",
                        "pid": "430200",
                        "index": "430202"
                    },
                    {
                        "value": "芦淞区",
                        "label": "芦淞区",
                        "pid": "430200",
                        "index": "430203"
                    },
                    {
                        "value": "石峰区",
                        "label": "石峰区",
                        "pid": "430200",
                        "index": "430204"
                    },
                    {
                        "value": "天元区",
                        "label": "天元区",
                        "pid": "430200",
                        "index": "430211"
                    },
                    {
                        "value": "渌口区",
                        "label": "渌口区",
                        "pid": "430200",
                        "index": "430212"
                    },
                    {
                        "value": "攸县",
                        "label": "攸县",
                        "pid": "430200",
                        "index": "430223"
                    },
                    {
                        "value": "茶陵县",
                        "label": "茶陵县",
                        "pid": "430200",
                        "index": "430224"
                    },
                    {
                        "value": "炎陵县",
                        "label": "炎陵县",
                        "pid": "430200",
                        "index": "430225"
                    },
                    {
                        "value": "云龙示范区",
                        "label": "云龙示范区",
                        "pid": "430200",
                        "index": "430271"
                    },
                    {
                        "value": "醴陵市",
                        "label": "醴陵市",
                        "pid": "430200",
                        "index": "430281"
                    }
                ]
            },
            {
                "value": "湘潭市",
                "label": "湘潭市",
                "pid": "430000",
                "index": "430300",
                "children": [
                    {
                        "value": "雨湖区",
                        "label": "雨湖区",
                        "pid": "430300",
                        "index": "430302"
                    },
                    {
                        "value": "岳塘区",
                        "label": "岳塘区",
                        "pid": "430300",
                        "index": "430304"
                    },
                    {
                        "value": "湘潭县",
                        "label": "湘潭县",
                        "pid": "430300",
                        "index": "430321"
                    },
                    {
                        "value": "湖南湘潭高新技术产业园区",
                        "label": "湖南湘潭高新技术产业园区",
                        "pid": "430300",
                        "index": "430371"
                    },
                    {
                        "value": "湘潭昭山示范区",
                        "label": "湘潭昭山示范区",
                        "pid": "430300",
                        "index": "430372"
                    },
                    {
                        "value": "湘潭九华示范区",
                        "label": "湘潭九华示范区",
                        "pid": "430300",
                        "index": "430373"
                    },
                    {
                        "value": "湘乡市",
                        "label": "湘乡市",
                        "pid": "430300",
                        "index": "430381"
                    },
                    {
                        "value": "韶山市",
                        "label": "韶山市",
                        "pid": "430300",
                        "index": "430382"
                    }
                ]
            },
            {
                "value": "衡阳市",
                "label": "衡阳市",
                "pid": "430000",
                "index": "430400",
                "children": [
                    {
                        "value": "珠晖区",
                        "label": "珠晖区",
                        "pid": "430400",
                        "index": "430405"
                    },
                    {
                        "value": "雁峰区",
                        "label": "雁峰区",
                        "pid": "430400",
                        "index": "430406"
                    },
                    {
                        "value": "石鼓区",
                        "label": "石鼓区",
                        "pid": "430400",
                        "index": "430407"
                    },
                    {
                        "value": "蒸湘区",
                        "label": "蒸湘区",
                        "pid": "430400",
                        "index": "430408"
                    },
                    {
                        "value": "南岳区",
                        "label": "南岳区",
                        "pid": "430400",
                        "index": "430412"
                    },
                    {
                        "value": "衡阳县",
                        "label": "衡阳县",
                        "pid": "430400",
                        "index": "430421"
                    },
                    {
                        "value": "衡南县",
                        "label": "衡南县",
                        "pid": "430400",
                        "index": "430422"
                    },
                    {
                        "value": "衡山县",
                        "label": "衡山县",
                        "pid": "430400",
                        "index": "430423"
                    },
                    {
                        "value": "衡东县",
                        "label": "衡东县",
                        "pid": "430400",
                        "index": "430424"
                    },
                    {
                        "value": "祁东县",
                        "label": "祁东县",
                        "pid": "430400",
                        "index": "430426"
                    },
                    {
                        "value": "衡阳综合保税区",
                        "label": "衡阳综合保税区",
                        "pid": "430400",
                        "index": "430471"
                    },
                    {
                        "value": "湖南衡阳高新技术产业园区",
                        "label": "湖南衡阳高新技术产业园区",
                        "pid": "430400",
                        "index": "430472"
                    },
                    {
                        "value": "湖南衡阳松木经济开发区",
                        "label": "湖南衡阳松木经济开发区",
                        "pid": "430400",
                        "index": "430473"
                    },
                    {
                        "value": "耒阳市",
                        "label": "耒阳市",
                        "pid": "430400",
                        "index": "430481"
                    },
                    {
                        "value": "常宁市",
                        "label": "常宁市",
                        "pid": "430400",
                        "index": "430482"
                    }
                ]
            },
            {
                "value": "邵阳市",
                "label": "邵阳市",
                "pid": "430000",
                "index": "430500",
                "children": [
                    {
                        "value": "双清区",
                        "label": "双清区",
                        "pid": "430500",
                        "index": "430502"
                    },
                    {
                        "value": "大祥区",
                        "label": "大祥区",
                        "pid": "430500",
                        "index": "430503"
                    },
                    {
                        "value": "北塔区",
                        "label": "北塔区",
                        "pid": "430500",
                        "index": "430511"
                    },
                    {
                        "value": "新邵县",
                        "label": "新邵县",
                        "pid": "430500",
                        "index": "430522"
                    },
                    {
                        "value": "邵阳县",
                        "label": "邵阳县",
                        "pid": "430500",
                        "index": "430523"
                    },
                    {
                        "value": "隆回县",
                        "label": "隆回县",
                        "pid": "430500",
                        "index": "430524"
                    },
                    {
                        "value": "洞口县",
                        "label": "洞口县",
                        "pid": "430500",
                        "index": "430525"
                    },
                    {
                        "value": "绥宁县",
                        "label": "绥宁县",
                        "pid": "430500",
                        "index": "430527"
                    },
                    {
                        "value": "新宁县",
                        "label": "新宁县",
                        "pid": "430500",
                        "index": "430528"
                    },
                    {
                        "value": "城步苗族自治县",
                        "label": "城步苗族自治县",
                        "pid": "430500",
                        "index": "430529"
                    },
                    {
                        "value": "武冈市",
                        "label": "武冈市",
                        "pid": "430500",
                        "index": "430581"
                    },
                    {
                        "value": "邵东市",
                        "label": "邵东市",
                        "pid": "430500",
                        "index": "430582"
                    }
                ]
            },
            {
                "value": "岳阳市",
                "label": "岳阳市",
                "pid": "430000",
                "index": "430600",
                "children": [
                    {
                        "value": "岳阳楼区",
                        "label": "岳阳楼区",
                        "pid": "430600",
                        "index": "430602"
                    },
                    {
                        "value": "云溪区",
                        "label": "云溪区",
                        "pid": "430600",
                        "index": "430603"
                    },
                    {
                        "value": "君山区",
                        "label": "君山区",
                        "pid": "430600",
                        "index": "430611"
                    },
                    {
                        "value": "岳阳县",
                        "label": "岳阳县",
                        "pid": "430600",
                        "index": "430621"
                    },
                    {
                        "value": "华容县",
                        "label": "华容县",
                        "pid": "430600",
                        "index": "430623"
                    },
                    {
                        "value": "湘阴县",
                        "label": "湘阴县",
                        "pid": "430600",
                        "index": "430624"
                    },
                    {
                        "value": "平江县",
                        "label": "平江县",
                        "pid": "430600",
                        "index": "430626"
                    },
                    {
                        "value": "岳阳市屈原管理区",
                        "label": "岳阳市屈原管理区",
                        "pid": "430600",
                        "index": "430671"
                    },
                    {
                        "value": "汨罗市",
                        "label": "汨罗市",
                        "pid": "430600",
                        "index": "430681"
                    },
                    {
                        "value": "临湘市",
                        "label": "临湘市",
                        "pid": "430600",
                        "index": "430682"
                    }
                ]
            },
            {
                "value": "常德市",
                "label": "常德市",
                "pid": "430000",
                "index": "430700",
                "children": [
                    {
                        "value": "武陵区",
                        "label": "武陵区",
                        "pid": "430700",
                        "index": "430702"
                    },
                    {
                        "value": "鼎城区",
                        "label": "鼎城区",
                        "pid": "430700",
                        "index": "430703"
                    },
                    {
                        "value": "安乡县",
                        "label": "安乡县",
                        "pid": "430700",
                        "index": "430721"
                    },
                    {
                        "value": "汉寿县",
                        "label": "汉寿县",
                        "pid": "430700",
                        "index": "430722"
                    },
                    {
                        "value": "澧县",
                        "label": "澧县",
                        "pid": "430700",
                        "index": "430723"
                    },
                    {
                        "value": "临澧县",
                        "label": "临澧县",
                        "pid": "430700",
                        "index": "430724"
                    },
                    {
                        "value": "桃源县",
                        "label": "桃源县",
                        "pid": "430700",
                        "index": "430725"
                    },
                    {
                        "value": "石门县",
                        "label": "石门县",
                        "pid": "430700",
                        "index": "430726"
                    },
                    {
                        "value": "常德市西洞庭管理区",
                        "label": "常德市西洞庭管理区",
                        "pid": "430700",
                        "index": "430771"
                    },
                    {
                        "value": "津市市",
                        "label": "津市市",
                        "pid": "430700",
                        "index": "430781"
                    }
                ]
            },
            {
                "value": "张家界市",
                "label": "张家界市",
                "pid": "430000",
                "index": "430800",
                "children": [
                    {
                        "value": "永定区",
                        "label": "永定区",
                        "pid": "430800",
                        "index": "430802"
                    },
                    {
                        "value": "武陵源区",
                        "label": "武陵源区",
                        "pid": "430800",
                        "index": "430811"
                    },
                    {
                        "value": "慈利县",
                        "label": "慈利县",
                        "pid": "430800",
                        "index": "430821"
                    },
                    {
                        "value": "桑植县",
                        "label": "桑植县",
                        "pid": "430800",
                        "index": "430822"
                    }
                ]
            },
            {
                "value": "益阳市",
                "label": "益阳市",
                "pid": "430000",
                "index": "430900",
                "children": [
                    {
                        "value": "资阳区",
                        "label": "资阳区",
                        "pid": "430900",
                        "index": "430902"
                    },
                    {
                        "value": "赫山区",
                        "label": "赫山区",
                        "pid": "430900",
                        "index": "430903"
                    },
                    {
                        "value": "南县",
                        "label": "南县",
                        "pid": "430900",
                        "index": "430921"
                    },
                    {
                        "value": "桃江县",
                        "label": "桃江县",
                        "pid": "430900",
                        "index": "430922"
                    },
                    {
                        "value": "安化县",
                        "label": "安化县",
                        "pid": "430900",
                        "index": "430923"
                    },
                    {
                        "value": "益阳市大通湖管理区",
                        "label": "益阳市大通湖管理区",
                        "pid": "430900",
                        "index": "430971"
                    },
                    {
                        "value": "湖南益阳高新技术产业园区",
                        "label": "湖南益阳高新技术产业园区",
                        "pid": "430900",
                        "index": "430972"
                    },
                    {
                        "value": "沅江市",
                        "label": "沅江市",
                        "pid": "430900",
                        "index": "430981"
                    }
                ]
            },
            {
                "value": "郴州市",
                "label": "郴州市",
                "pid": "430000",
                "index": "431000",
                "children": [
                    {
                        "value": "北湖区",
                        "label": "北湖区",
                        "pid": "431000",
                        "index": "431002"
                    },
                    {
                        "value": "苏仙区",
                        "label": "苏仙区",
                        "pid": "431000",
                        "index": "431003"
                    },
                    {
                        "value": "桂阳县",
                        "label": "桂阳县",
                        "pid": "431000",
                        "index": "431021"
                    },
                    {
                        "value": "宜章县",
                        "label": "宜章县",
                        "pid": "431000",
                        "index": "431022"
                    },
                    {
                        "value": "永兴县",
                        "label": "永兴县",
                        "pid": "431000",
                        "index": "431023"
                    },
                    {
                        "value": "嘉禾县",
                        "label": "嘉禾县",
                        "pid": "431000",
                        "index": "431024"
                    },
                    {
                        "value": "临武县",
                        "label": "临武县",
                        "pid": "431000",
                        "index": "431025"
                    },
                    {
                        "value": "汝城县",
                        "label": "汝城县",
                        "pid": "431000",
                        "index": "431026"
                    },
                    {
                        "value": "桂东县",
                        "label": "桂东县",
                        "pid": "431000",
                        "index": "431027"
                    },
                    {
                        "value": "安仁县",
                        "label": "安仁县",
                        "pid": "431000",
                        "index": "431028"
                    },
                    {
                        "value": "资兴市",
                        "label": "资兴市",
                        "pid": "431000",
                        "index": "431081"
                    }
                ]
            },
            {
                "value": "永州市",
                "label": "永州市",
                "pid": "430000",
                "index": "431100",
                "children": [
                    {
                        "value": "零陵区",
                        "label": "零陵区",
                        "pid": "431100",
                        "index": "431102"
                    },
                    {
                        "value": "冷水滩区",
                        "label": "冷水滩区",
                        "pid": "431100",
                        "index": "431103"
                    },
                    {
                        "value": "祁阳县",
                        "label": "祁阳县",
                        "pid": "431100",
                        "index": "431121"
                    },
                    {
                        "value": "东安县",
                        "label": "东安县",
                        "pid": "431100",
                        "index": "431122"
                    },
                    {
                        "value": "双牌县",
                        "label": "双牌县",
                        "pid": "431100",
                        "index": "431123"
                    },
                    {
                        "value": "道县",
                        "label": "道县",
                        "pid": "431100",
                        "index": "431124"
                    },
                    {
                        "value": "江永县",
                        "label": "江永县",
                        "pid": "431100",
                        "index": "431125"
                    },
                    {
                        "value": "宁远县",
                        "label": "宁远县",
                        "pid": "431100",
                        "index": "431126"
                    },
                    {
                        "value": "蓝山县",
                        "label": "蓝山县",
                        "pid": "431100",
                        "index": "431127"
                    },
                    {
                        "value": "新田县",
                        "label": "新田县",
                        "pid": "431100",
                        "index": "431128"
                    },
                    {
                        "value": "江华瑶族自治县",
                        "label": "江华瑶族自治县",
                        "pid": "431100",
                        "index": "431129"
                    },
                    {
                        "value": "永州经济技术开发区",
                        "label": "永州经济技术开发区",
                        "pid": "431100",
                        "index": "431171"
                    },
                    {
                        "value": "永州市金洞管理区",
                        "label": "永州市金洞管理区",
                        "pid": "431100",
                        "index": "431172"
                    },
                    {
                        "value": "永州市回龙圩管理区",
                        "label": "永州市回龙圩管理区",
                        "pid": "431100",
                        "index": "431173"
                    }
                ]
            },
            {
                "value": "怀化市",
                "label": "怀化市",
                "pid": "430000",
                "index": "431200",
                "children": [
                    {
                        "value": "鹤城区",
                        "label": "鹤城区",
                        "pid": "431200",
                        "index": "431202"
                    },
                    {
                        "value": "中方县",
                        "label": "中方县",
                        "pid": "431200",
                        "index": "431221"
                    },
                    {
                        "value": "沅陵县",
                        "label": "沅陵县",
                        "pid": "431200",
                        "index": "431222"
                    },
                    {
                        "value": "辰溪县",
                        "label": "辰溪县",
                        "pid": "431200",
                        "index": "431223"
                    },
                    {
                        "value": "溆浦县",
                        "label": "溆浦县",
                        "pid": "431200",
                        "index": "431224"
                    },
                    {
                        "value": "会同县",
                        "label": "会同县",
                        "pid": "431200",
                        "index": "431225"
                    },
                    {
                        "value": "麻阳苗族自治县",
                        "label": "麻阳苗族自治县",
                        "pid": "431200",
                        "index": "431226"
                    },
                    {
                        "value": "新晃侗族自治县",
                        "label": "新晃侗族自治县",
                        "pid": "431200",
                        "index": "431227"
                    },
                    {
                        "value": "芷江侗族自治县",
                        "label": "芷江侗族自治县",
                        "pid": "431200",
                        "index": "431228"
                    },
                    {
                        "value": "靖州苗族侗族自治县",
                        "label": "靖州苗族侗族自治县",
                        "pid": "431200",
                        "index": "431229"
                    },
                    {
                        "value": "通道侗族自治县",
                        "label": "通道侗族自治县",
                        "pid": "431200",
                        "index": "431230"
                    },
                    {
                        "value": "怀化市洪江管理区",
                        "label": "怀化市洪江管理区",
                        "pid": "431200",
                        "index": "431271"
                    },
                    {
                        "value": "洪江市",
                        "label": "洪江市",
                        "pid": "431200",
                        "index": "431281"
                    }
                ]
            },
            {
                "value": "娄底市",
                "label": "娄底市",
                "pid": "430000",
                "index": "431300",
                "children": [
                    {
                        "value": "娄星区",
                        "label": "娄星区",
                        "pid": "431300",
                        "index": "431302"
                    },
                    {
                        "value": "双峰县",
                        "label": "双峰县",
                        "pid": "431300",
                        "index": "431321"
                    },
                    {
                        "value": "新化县",
                        "label": "新化县",
                        "pid": "431300",
                        "index": "431322"
                    },
                    {
                        "value": "冷水江市",
                        "label": "冷水江市",
                        "pid": "431300",
                        "index": "431381"
                    },
                    {
                        "value": "涟源市",
                        "label": "涟源市",
                        "pid": "431300",
                        "index": "431382"
                    }
                ]
            },
            {
                "value": "湘西土家族苗族自治州",
                "label": "湘西土家族苗族自治州",
                "pid": "430000",
                "index": "433100",
                "children": [
                    {
                        "value": "吉首市",
                        "label": "吉首市",
                        "pid": "433100",
                        "index": "433101"
                    },
                    {
                        "value": "泸溪县",
                        "label": "泸溪县",
                        "pid": "433100",
                        "index": "433122"
                    },
                    {
                        "value": "凤凰县",
                        "label": "凤凰县",
                        "pid": "433100",
                        "index": "433123"
                    },
                    {
                        "value": "花垣县",
                        "label": "花垣县",
                        "pid": "433100",
                        "index": "433124"
                    },
                    {
                        "value": "保靖县",
                        "label": "保靖县",
                        "pid": "433100",
                        "index": "433125"
                    },
                    {
                        "value": "古丈县",
                        "label": "古丈县",
                        "pid": "433100",
                        "index": "433126"
                    },
                    {
                        "value": "永顺县",
                        "label": "永顺县",
                        "pid": "433100",
                        "index": "433127"
                    },
                    {
                        "value": "龙山县",
                        "label": "龙山县",
                        "pid": "433100",
                        "index": "433130"
                    },
                    {
                        "value": "湖南永顺经济开发区",
                        "label": "湖南永顺经济开发区",
                        "pid": "433100",
                        "index": "433173"
                    }
                ]
            }
        ]
    },
    {
        "value": "广东省",
        "label": "广东省",
        "pid": "86",
        "index": "440000",
        "children": [
            {
                "value": "广州市",
                "label": "广州市",
                "pid": "440000",
                "index": "440100",
                "children": [
                    {
                        "value": "荔湾区",
                        "label": "荔湾区",
                        "pid": "440100",
                        "index": "440103"
                    },
                    {
                        "value": "越秀区",
                        "label": "越秀区",
                        "pid": "440100",
                        "index": "440104"
                    },
                    {
                        "value": "海珠区",
                        "label": "海珠区",
                        "pid": "440100",
                        "index": "440105"
                    },
                    {
                        "value": "天河区",
                        "label": "天河区",
                        "pid": "440100",
                        "index": "440106"
                    },
                    {
                        "value": "白云区",
                        "label": "白云区",
                        "pid": "440100",
                        "index": "440111"
                    },
                    {
                        "value": "黄埔区",
                        "label": "黄埔区",
                        "pid": "440100",
                        "index": "440112"
                    },
                    {
                        "value": "番禺区",
                        "label": "番禺区",
                        "pid": "440100",
                        "index": "440113"
                    },
                    {
                        "value": "花都区",
                        "label": "花都区",
                        "pid": "440100",
                        "index": "440114"
                    },
                    {
                        "value": "南沙区",
                        "label": "南沙区",
                        "pid": "440100",
                        "index": "440115"
                    },
                    {
                        "value": "从化区",
                        "label": "从化区",
                        "pid": "440100",
                        "index": "440117"
                    },
                    {
                        "value": "增城区",
                        "label": "增城区",
                        "pid": "440100",
                        "index": "440118"
                    }
                ]
            },
            {
                "value": "韶关市",
                "label": "韶关市",
                "pid": "440000",
                "index": "440200",
                "children": [
                    {
                        "value": "武江区",
                        "label": "武江区",
                        "pid": "440200",
                        "index": "440203"
                    },
                    {
                        "value": "浈江区",
                        "label": "浈江区",
                        "pid": "440200",
                        "index": "440204"
                    },
                    {
                        "value": "曲江区",
                        "label": "曲江区",
                        "pid": "440200",
                        "index": "440205"
                    },
                    {
                        "value": "始兴县",
                        "label": "始兴县",
                        "pid": "440200",
                        "index": "440222"
                    },
                    {
                        "value": "仁化县",
                        "label": "仁化县",
                        "pid": "440200",
                        "index": "440224"
                    },
                    {
                        "value": "翁源县",
                        "label": "翁源县",
                        "pid": "440200",
                        "index": "440229"
                    },
                    {
                        "value": "乳源瑶族自治县",
                        "label": "乳源瑶族自治县",
                        "pid": "440200",
                        "index": "440232"
                    },
                    {
                        "value": "新丰县",
                        "label": "新丰县",
                        "pid": "440200",
                        "index": "440233"
                    },
                    {
                        "value": "乐昌市",
                        "label": "乐昌市",
                        "pid": "440200",
                        "index": "440281"
                    },
                    {
                        "value": "南雄市",
                        "label": "南雄市",
                        "pid": "440200",
                        "index": "440282"
                    }
                ]
            },
            {
                "value": "深圳市",
                "label": "深圳市",
                "pid": "440000",
                "index": "440300",
                "children": [
                    {
                        "value": "罗湖区",
                        "label": "罗湖区",
                        "pid": "440300",
                        "index": "440303"
                    },
                    {
                        "value": "福田区",
                        "label": "福田区",
                        "pid": "440300",
                        "index": "440304"
                    },
                    {
                        "value": "南山区",
                        "label": "南山区",
                        "pid": "440300",
                        "index": "440305"
                    },
                    {
                        "value": "宝安区",
                        "label": "宝安区",
                        "pid": "440300",
                        "index": "440306"
                    },
                    {
                        "value": "龙岗区",
                        "label": "龙岗区",
                        "pid": "440300",
                        "index": "440307"
                    },
                    {
                        "value": "盐田区",
                        "label": "盐田区",
                        "pid": "440300",
                        "index": "440308"
                    },
                    {
                        "value": "龙华区",
                        "label": "龙华区",
                        "pid": "440300",
                        "index": "440309"
                    },
                    {
                        "value": "坪山区",
                        "label": "坪山区",
                        "pid": "440300",
                        "index": "440310"
                    },
                    {
                        "value": "光明区",
                        "label": "光明区",
                        "pid": "440300",
                        "index": "440311"
                    }
                ]
            },
            {
                "value": "珠海市",
                "label": "珠海市",
                "pid": "440000",
                "index": "440400",
                "children": [
                    {
                        "value": "香洲区",
                        "label": "香洲区",
                        "pid": "440400",
                        "index": "440402"
                    },
                    {
                        "value": "斗门区",
                        "label": "斗门区",
                        "pid": "440400",
                        "index": "440403"
                    },
                    {
                        "value": "金湾区",
                        "label": "金湾区",
                        "pid": "440400",
                        "index": "440404"
                    }
                ]
            },
            {
                "value": "汕头市",
                "label": "汕头市",
                "pid": "440000",
                "index": "440500",
                "children": [
                    {
                        "value": "龙湖区",
                        "label": "龙湖区",
                        "pid": "440500",
                        "index": "440507"
                    },
                    {
                        "value": "金平区",
                        "label": "金平区",
                        "pid": "440500",
                        "index": "440511"
                    },
                    {
                        "value": "濠江区",
                        "label": "濠江区",
                        "pid": "440500",
                        "index": "440512"
                    },
                    {
                        "value": "潮阳区",
                        "label": "潮阳区",
                        "pid": "440500",
                        "index": "440513"
                    },
                    {
                        "value": "潮南区",
                        "label": "潮南区",
                        "pid": "440500",
                        "index": "440514"
                    },
                    {
                        "value": "澄海区",
                        "label": "澄海区",
                        "pid": "440500",
                        "index": "440515"
                    },
                    {
                        "value": "南澳县",
                        "label": "南澳县",
                        "pid": "440500",
                        "index": "440523"
                    }
                ]
            },
            {
                "value": "佛山市",
                "label": "佛山市",
                "pid": "440000",
                "index": "440600",
                "children": [
                    {
                        "value": "禅城区",
                        "label": "禅城区",
                        "pid": "440600",
                        "index": "440604"
                    },
                    {
                        "value": "南海区",
                        "label": "南海区",
                        "pid": "440600",
                        "index": "440605"
                    },
                    {
                        "value": "顺德区",
                        "label": "顺德区",
                        "pid": "440600",
                        "index": "440606"
                    },
                    {
                        "value": "三水区",
                        "label": "三水区",
                        "pid": "440600",
                        "index": "440607"
                    },
                    {
                        "value": "高明区",
                        "label": "高明区",
                        "pid": "440600",
                        "index": "440608"
                    }
                ]
            },
            {
                "value": "江门市",
                "label": "江门市",
                "pid": "440000",
                "index": "440700",
                "children": [
                    {
                        "value": "蓬江区",
                        "label": "蓬江区",
                        "pid": "440700",
                        "index": "440703"
                    },
                    {
                        "value": "江海区",
                        "label": "江海区",
                        "pid": "440700",
                        "index": "440704"
                    },
                    {
                        "value": "新会区",
                        "label": "新会区",
                        "pid": "440700",
                        "index": "440705"
                    },
                    {
                        "value": "台山市",
                        "label": "台山市",
                        "pid": "440700",
                        "index": "440781"
                    },
                    {
                        "value": "开平市",
                        "label": "开平市",
                        "pid": "440700",
                        "index": "440783"
                    },
                    {
                        "value": "鹤山市",
                        "label": "鹤山市",
                        "pid": "440700",
                        "index": "440784"
                    },
                    {
                        "value": "恩平市",
                        "label": "恩平市",
                        "pid": "440700",
                        "index": "440785"
                    }
                ]
            },
            {
                "value": "湛江市",
                "label": "湛江市",
                "pid": "440000",
                "index": "440800",
                "children": [
                    {
                        "value": "赤坎区",
                        "label": "赤坎区",
                        "pid": "440800",
                        "index": "440802"
                    },
                    {
                        "value": "霞山区",
                        "label": "霞山区",
                        "pid": "440800",
                        "index": "440803"
                    },
                    {
                        "value": "坡头区",
                        "label": "坡头区",
                        "pid": "440800",
                        "index": "440804"
                    },
                    {
                        "value": "麻章区",
                        "label": "麻章区",
                        "pid": "440800",
                        "index": "440811"
                    },
                    {
                        "value": "遂溪县",
                        "label": "遂溪县",
                        "pid": "440800",
                        "index": "440823"
                    },
                    {
                        "value": "徐闻县",
                        "label": "徐闻县",
                        "pid": "440800",
                        "index": "440825"
                    },
                    {
                        "value": "廉江市",
                        "label": "廉江市",
                        "pid": "440800",
                        "index": "440881"
                    },
                    {
                        "value": "雷州市",
                        "label": "雷州市",
                        "pid": "440800",
                        "index": "440882"
                    },
                    {
                        "value": "吴川市",
                        "label": "吴川市",
                        "pid": "440800",
                        "index": "440883"
                    }
                ]
            },
            {
                "value": "茂名市",
                "label": "茂名市",
                "pid": "440000",
                "index": "440900",
                "children": [
                    {
                        "value": "茂南区",
                        "label": "茂南区",
                        "pid": "440900",
                        "index": "440902"
                    },
                    {
                        "value": "电白区",
                        "label": "电白区",
                        "pid": "440900",
                        "index": "440904"
                    },
                    {
                        "value": "高州市",
                        "label": "高州市",
                        "pid": "440900",
                        "index": "440981"
                    },
                    {
                        "value": "化州市",
                        "label": "化州市",
                        "pid": "440900",
                        "index": "440982"
                    },
                    {
                        "value": "信宜市",
                        "label": "信宜市",
                        "pid": "440900",
                        "index": "440983"
                    }
                ]
            },
            {
                "value": "肇庆市",
                "label": "肇庆市",
                "pid": "440000",
                "index": "441200",
                "children": [
                    {
                        "value": "端州区",
                        "label": "端州区",
                        "pid": "441200",
                        "index": "441202"
                    },
                    {
                        "value": "鼎湖区",
                        "label": "鼎湖区",
                        "pid": "441200",
                        "index": "441203"
                    },
                    {
                        "value": "高要区",
                        "label": "高要区",
                        "pid": "441200",
                        "index": "441204"
                    },
                    {
                        "value": "广宁县",
                        "label": "广宁县",
                        "pid": "441200",
                        "index": "441223"
                    },
                    {
                        "value": "怀集县",
                        "label": "怀集县",
                        "pid": "441200",
                        "index": "441224"
                    },
                    {
                        "value": "封开县",
                        "label": "封开县",
                        "pid": "441200",
                        "index": "441225"
                    },
                    {
                        "value": "德庆县",
                        "label": "德庆县",
                        "pid": "441200",
                        "index": "441226"
                    },
                    {
                        "value": "四会市",
                        "label": "四会市",
                        "pid": "441200",
                        "index": "441284"
                    }
                ]
            },
            {
                "value": "惠州市",
                "label": "惠州市",
                "pid": "440000",
                "index": "441300",
                "children": [
                    {
                        "value": "惠城区",
                        "label": "惠城区",
                        "pid": "441300",
                        "index": "441302"
                    },
                    {
                        "value": "惠阳区",
                        "label": "惠阳区",
                        "pid": "441300",
                        "index": "441303"
                    },
                    {
                        "value": "博罗县",
                        "label": "博罗县",
                        "pid": "441300",
                        "index": "441322"
                    },
                    {
                        "value": "惠东县",
                        "label": "惠东县",
                        "pid": "441300",
                        "index": "441323"
                    },
                    {
                        "value": "龙门县",
                        "label": "龙门县",
                        "pid": "441300",
                        "index": "441324"
                    }
                ]
            },
            {
                "value": "梅州市",
                "label": "梅州市",
                "pid": "440000",
                "index": "441400",
                "children": [
                    {
                        "value": "梅江区",
                        "label": "梅江区",
                        "pid": "441400",
                        "index": "441402"
                    },
                    {
                        "value": "梅县区",
                        "label": "梅县区",
                        "pid": "441400",
                        "index": "441403"
                    },
                    {
                        "value": "大埔县",
                        "label": "大埔县",
                        "pid": "441400",
                        "index": "441422"
                    },
                    {
                        "value": "丰顺县",
                        "label": "丰顺县",
                        "pid": "441400",
                        "index": "441423"
                    },
                    {
                        "value": "五华县",
                        "label": "五华县",
                        "pid": "441400",
                        "index": "441424"
                    },
                    {
                        "value": "平远县",
                        "label": "平远县",
                        "pid": "441400",
                        "index": "441426"
                    },
                    {
                        "value": "蕉岭县",
                        "label": "蕉岭县",
                        "pid": "441400",
                        "index": "441427"
                    },
                    {
                        "value": "兴宁市",
                        "label": "兴宁市",
                        "pid": "441400",
                        "index": "441481"
                    }
                ]
            },
            {
                "value": "汕尾市",
                "label": "汕尾市",
                "pid": "440000",
                "index": "441500",
                "children": [
                    {
                        "value": "城区",
                        "label": "城区",
                        "pid": "441500",
                        "index": "441502"
                    },
                    {
                        "value": "海丰县",
                        "label": "海丰县",
                        "pid": "441500",
                        "index": "441521"
                    },
                    {
                        "value": "陆河县",
                        "label": "陆河县",
                        "pid": "441500",
                        "index": "441523"
                    },
                    {
                        "value": "陆丰市",
                        "label": "陆丰市",
                        "pid": "441500",
                        "index": "441581"
                    }
                ]
            },
            {
                "value": "河源市",
                "label": "河源市",
                "pid": "440000",
                "index": "441600",
                "children": [
                    {
                        "value": "源城区",
                        "label": "源城区",
                        "pid": "441600",
                        "index": "441602"
                    },
                    {
                        "value": "紫金县",
                        "label": "紫金县",
                        "pid": "441600",
                        "index": "441621"
                    },
                    {
                        "value": "龙川县",
                        "label": "龙川县",
                        "pid": "441600",
                        "index": "441622"
                    },
                    {
                        "value": "连平县",
                        "label": "连平县",
                        "pid": "441600",
                        "index": "441623"
                    },
                    {
                        "value": "和平县",
                        "label": "和平县",
                        "pid": "441600",
                        "index": "441624"
                    },
                    {
                        "value": "东源县",
                        "label": "东源县",
                        "pid": "441600",
                        "index": "441625"
                    }
                ]
            },
            {
                "value": "阳江市",
                "label": "阳江市",
                "pid": "440000",
                "index": "441700",
                "children": [
                    {
                        "value": "江城区",
                        "label": "江城区",
                        "pid": "441700",
                        "index": "441702"
                    },
                    {
                        "value": "阳东区",
                        "label": "阳东区",
                        "pid": "441700",
                        "index": "441704"
                    },
                    {
                        "value": "阳西县",
                        "label": "阳西县",
                        "pid": "441700",
                        "index": "441721"
                    },
                    {
                        "value": "阳春市",
                        "label": "阳春市",
                        "pid": "441700",
                        "index": "441781"
                    }
                ]
            },
            {
                "value": "清远市",
                "label": "清远市",
                "pid": "440000",
                "index": "441800",
                "children": [
                    {
                        "value": "清城区",
                        "label": "清城区",
                        "pid": "441800",
                        "index": "441802"
                    },
                    {
                        "value": "清新区",
                        "label": "清新区",
                        "pid": "441800",
                        "index": "441803"
                    },
                    {
                        "value": "佛冈县",
                        "label": "佛冈县",
                        "pid": "441800",
                        "index": "441821"
                    },
                    {
                        "value": "阳山县",
                        "label": "阳山县",
                        "pid": "441800",
                        "index": "441823"
                    },
                    {
                        "value": "连山壮族瑶族自治县",
                        "label": "连山壮族瑶族自治县",
                        "pid": "441800",
                        "index": "441825"
                    },
                    {
                        "value": "连南瑶族自治县",
                        "label": "连南瑶族自治县",
                        "pid": "441800",
                        "index": "441826"
                    },
                    {
                        "value": "英德市",
                        "label": "英德市",
                        "pid": "441800",
                        "index": "441881"
                    },
                    {
                        "value": "连州市",
                        "label": "连州市",
                        "pid": "441800",
                        "index": "441882"
                    }
                ]
            },
            {
                "value": "东莞市",
                "label": "东莞市",
                "pid": "440000",
                "index": "441900",
                "children": [
                    {
                        "value": "东城街道",
                        "label": "东城街道",
                        "pid": "441900",
                        "index": "441900003"
                    },
                    {
                        "value": "南城街道",
                        "label": "南城街道",
                        "pid": "441900",
                        "index": "441900004"
                    },
                    {
                        "value": "万江街道",
                        "label": "万江街道",
                        "pid": "441900",
                        "index": "441900005"
                    },
                    {
                        "value": "莞城街道",
                        "label": "莞城街道",
                        "pid": "441900",
                        "index": "441900006"
                    },
                    {
                        "value": "石碣镇",
                        "label": "石碣镇",
                        "pid": "441900",
                        "index": "441900101"
                    },
                    {
                        "value": "石龙镇",
                        "label": "石龙镇",
                        "pid": "441900",
                        "index": "441900102"
                    },
                    {
                        "value": "茶山镇",
                        "label": "茶山镇",
                        "pid": "441900",
                        "index": "441900103"
                    },
                    {
                        "value": "石排镇",
                        "label": "石排镇",
                        "pid": "441900",
                        "index": "441900104"
                    },
                    {
                        "value": "企石镇",
                        "label": "企石镇",
                        "pid": "441900",
                        "index": "441900105"
                    },
                    {
                        "value": "横沥镇",
                        "label": "横沥镇",
                        "pid": "441900",
                        "index": "441900106"
                    },
                    {
                        "value": "桥头镇",
                        "label": "桥头镇",
                        "pid": "441900",
                        "index": "441900107"
                    },
                    {
                        "value": "谢岗镇",
                        "label": "谢岗镇",
                        "pid": "441900",
                        "index": "441900108"
                    },
                    {
                        "value": "东坑镇",
                        "label": "东坑镇",
                        "pid": "441900",
                        "index": "441900109"
                    },
                    {
                        "value": "常平镇",
                        "label": "常平镇",
                        "pid": "441900",
                        "index": "441900110"
                    },
                    {
                        "value": "寮步镇",
                        "label": "寮步镇",
                        "pid": "441900",
                        "index": "441900111"
                    },
                    {
                        "value": "樟木头镇",
                        "label": "樟木头镇",
                        "pid": "441900",
                        "index": "441900112"
                    },
                    {
                        "value": "大朗镇",
                        "label": "大朗镇",
                        "pid": "441900",
                        "index": "441900113"
                    },
                    {
                        "value": "黄江镇",
                        "label": "黄江镇",
                        "pid": "441900",
                        "index": "441900114"
                    },
                    {
                        "value": "清溪镇",
                        "label": "清溪镇",
                        "pid": "441900",
                        "index": "441900115"
                    },
                    {
                        "value": "塘厦镇",
                        "label": "塘厦镇",
                        "pid": "441900",
                        "index": "441900116"
                    },
                    {
                        "value": "凤岗镇",
                        "label": "凤岗镇",
                        "pid": "441900",
                        "index": "441900117"
                    },
                    {
                        "value": "大岭山镇",
                        "label": "大岭山镇",
                        "pid": "441900",
                        "index": "441900118"
                    },
                    {
                        "value": "长安镇",
                        "label": "长安镇",
                        "pid": "441900",
                        "index": "441900119"
                    },
                    {
                        "value": "虎门镇",
                        "label": "虎门镇",
                        "pid": "441900",
                        "index": "441900121"
                    },
                    {
                        "value": "厚街镇",
                        "label": "厚街镇",
                        "pid": "441900",
                        "index": "441900122"
                    },
                    {
                        "value": "沙田镇",
                        "label": "沙田镇",
                        "pid": "441900",
                        "index": "441900123"
                    },
                    {
                        "value": "道滘镇",
                        "label": "道滘镇",
                        "pid": "441900",
                        "index": "441900124"
                    },
                    {
                        "value": "洪梅镇",
                        "label": "洪梅镇",
                        "pid": "441900",
                        "index": "441900125"
                    },
                    {
                        "value": "麻涌镇",
                        "label": "麻涌镇",
                        "pid": "441900",
                        "index": "441900126"
                    },
                    {
                        "value": "望牛墩镇",
                        "label": "望牛墩镇",
                        "pid": "441900",
                        "index": "441900127"
                    },
                    {
                        "value": "中堂镇",
                        "label": "中堂镇",
                        "pid": "441900",
                        "index": "441900128"
                    },
                    {
                        "value": "高埗镇",
                        "label": "高埗镇",
                        "pid": "441900",
                        "index": "441900129"
                    },
                    {
                        "value": "松山湖",
                        "label": "松山湖",
                        "pid": "441900",
                        "index": "441900401"
                    },
                    {
                        "value": "东莞港",
                        "label": "东莞港",
                        "pid": "441900",
                        "index": "441900402"
                    },
                    {
                        "value": "东莞生态园",
                        "label": "东莞生态园",
                        "pid": "441900",
                        "index": "441900403"
                    }
                ]
            },
            {
                "value": "中山市",
                "label": "中山市",
                "pid": "440000",
                "index": "442000",
                "children": [
                    {
                        "value": "石岐街道",
                        "label": "石岐街道",
                        "pid": "442000",
                        "index": "442000001"
                    },
                    {
                        "value": "东区街道",
                        "label": "东区街道",
                        "pid": "442000",
                        "index": "442000002"
                    },
                    {
                        "value": "中山港街道",
                        "label": "中山港街道",
                        "pid": "442000",
                        "index": "442000003"
                    },
                    {
                        "value": "西区街道",
                        "label": "西区街道",
                        "pid": "442000",
                        "index": "442000004"
                    },
                    {
                        "value": "南区街道",
                        "label": "南区街道",
                        "pid": "442000",
                        "index": "442000005"
                    },
                    {
                        "value": "五桂山街道",
                        "label": "五桂山街道",
                        "pid": "442000",
                        "index": "442000006"
                    },
                    {
                        "value": "小榄镇",
                        "label": "小榄镇",
                        "pid": "442000",
                        "index": "442000100"
                    },
                    {
                        "value": "黄圃镇",
                        "label": "黄圃镇",
                        "pid": "442000",
                        "index": "442000101"
                    },
                    {
                        "value": "民众镇",
                        "label": "民众镇",
                        "pid": "442000",
                        "index": "442000102"
                    },
                    {
                        "value": "东凤镇",
                        "label": "东凤镇",
                        "pid": "442000",
                        "index": "442000103"
                    },
                    {
                        "value": "东升镇",
                        "label": "东升镇",
                        "pid": "442000",
                        "index": "442000104"
                    },
                    {
                        "value": "古镇镇",
                        "label": "古镇镇",
                        "pid": "442000",
                        "index": "442000105"
                    },
                    {
                        "value": "沙溪镇",
                        "label": "沙溪镇",
                        "pid": "442000",
                        "index": "442000106"
                    },
                    {
                        "value": "坦洲镇",
                        "label": "坦洲镇",
                        "pid": "442000",
                        "index": "442000107"
                    },
                    {
                        "value": "港口镇",
                        "label": "港口镇",
                        "pid": "442000",
                        "index": "442000108"
                    },
                    {
                        "value": "三角镇",
                        "label": "三角镇",
                        "pid": "442000",
                        "index": "442000109"
                    },
                    {
                        "value": "横栏镇",
                        "label": "横栏镇",
                        "pid": "442000",
                        "index": "442000110"
                    },
                    {
                        "value": "南头镇",
                        "label": "南头镇",
                        "pid": "442000",
                        "index": "442000111"
                    },
                    {
                        "value": "阜沙镇",
                        "label": "阜沙镇",
                        "pid": "442000",
                        "index": "442000112"
                    },
                    {
                        "value": "南朗镇",
                        "label": "南朗镇",
                        "pid": "442000",
                        "index": "442000113"
                    },
                    {
                        "value": "三乡镇",
                        "label": "三乡镇",
                        "pid": "442000",
                        "index": "442000114"
                    },
                    {
                        "value": "板芙镇",
                        "label": "板芙镇",
                        "pid": "442000",
                        "index": "442000115"
                    },
                    {
                        "value": "大涌镇",
                        "label": "大涌镇",
                        "pid": "442000",
                        "index": "442000116"
                    },
                    {
                        "value": "神湾镇",
                        "label": "神湾镇",
                        "pid": "442000",
                        "index": "442000117"
                    }
                ]
            },
            {
                "value": "潮州市",
                "label": "潮州市",
                "pid": "440000",
                "index": "445100",
                "children": [
                    {
                        "value": "湘桥区",
                        "label": "湘桥区",
                        "pid": "445100",
                        "index": "445102"
                    },
                    {
                        "value": "潮安区",
                        "label": "潮安区",
                        "pid": "445100",
                        "index": "445103"
                    },
                    {
                        "value": "饶平县",
                        "label": "饶平县",
                        "pid": "445100",
                        "index": "445122"
                    }
                ]
            },
            {
                "value": "揭阳市",
                "label": "揭阳市",
                "pid": "440000",
                "index": "445200",
                "children": [
                    {
                        "value": "榕城区",
                        "label": "榕城区",
                        "pid": "445200",
                        "index": "445202"
                    },
                    {
                        "value": "揭东区",
                        "label": "揭东区",
                        "pid": "445200",
                        "index": "445203"
                    },
                    {
                        "value": "揭西县",
                        "label": "揭西县",
                        "pid": "445200",
                        "index": "445222"
                    },
                    {
                        "value": "惠来县",
                        "label": "惠来县",
                        "pid": "445200",
                        "index": "445224"
                    },
                    {
                        "value": "普宁市",
                        "label": "普宁市",
                        "pid": "445200",
                        "index": "445281"
                    }
                ]
            },
            {
                "value": "云浮市",
                "label": "云浮市",
                "pid": "440000",
                "index": "445300",
                "children": [
                    {
                        "value": "云城区",
                        "label": "云城区",
                        "pid": "445300",
                        "index": "445302"
                    },
                    {
                        "value": "云安区",
                        "label": "云安区",
                        "pid": "445300",
                        "index": "445303"
                    },
                    {
                        "value": "新兴县",
                        "label": "新兴县",
                        "pid": "445300",
                        "index": "445321"
                    },
                    {
                        "value": "郁南县",
                        "label": "郁南县",
                        "pid": "445300",
                        "index": "445322"
                    },
                    {
                        "value": "罗定市",
                        "label": "罗定市",
                        "pid": "445300",
                        "index": "445381"
                    }
                ]
            }
        ]
    },
    {
        "value": "广西壮族自治区",
        "label": "广西壮族自治区",
        "pid": "86",
        "index": "450000",
        "children": [
            {
                "value": "南宁市",
                "label": "南宁市",
                "pid": "450000",
                "index": "450100",
                "children": [
                    {
                        "value": "兴宁区",
                        "label": "兴宁区",
                        "pid": "450100",
                        "index": "450102"
                    },
                    {
                        "value": "青秀区",
                        "label": "青秀区",
                        "pid": "450100",
                        "index": "450103"
                    },
                    {
                        "value": "江南区",
                        "label": "江南区",
                        "pid": "450100",
                        "index": "450105"
                    },
                    {
                        "value": "西乡塘区",
                        "label": "西乡塘区",
                        "pid": "450100",
                        "index": "450107"
                    },
                    {
                        "value": "良庆区",
                        "label": "良庆区",
                        "pid": "450100",
                        "index": "450108"
                    },
                    {
                        "value": "邕宁区",
                        "label": "邕宁区",
                        "pid": "450100",
                        "index": "450109"
                    },
                    {
                        "value": "武鸣区",
                        "label": "武鸣区",
                        "pid": "450100",
                        "index": "450110"
                    },
                    {
                        "value": "隆安县",
                        "label": "隆安县",
                        "pid": "450100",
                        "index": "450123"
                    },
                    {
                        "value": "马山县",
                        "label": "马山县",
                        "pid": "450100",
                        "index": "450124"
                    },
                    {
                        "value": "上林县",
                        "label": "上林县",
                        "pid": "450100",
                        "index": "450125"
                    },
                    {
                        "value": "宾阳县",
                        "label": "宾阳县",
                        "pid": "450100",
                        "index": "450126"
                    },
                    {
                        "value": "横县",
                        "label": "横县",
                        "pid": "450100",
                        "index": "450127"
                    }
                ]
            },
            {
                "value": "柳州市",
                "label": "柳州市",
                "pid": "450000",
                "index": "450200",
                "children": [
                    {
                        "value": "城中区",
                        "label": "城中区",
                        "pid": "450200",
                        "index": "450202"
                    },
                    {
                        "value": "鱼峰区",
                        "label": "鱼峰区",
                        "pid": "450200",
                        "index": "450203"
                    },
                    {
                        "value": "柳南区",
                        "label": "柳南区",
                        "pid": "450200",
                        "index": "450204"
                    },
                    {
                        "value": "柳北区",
                        "label": "柳北区",
                        "pid": "450200",
                        "index": "450205"
                    },
                    {
                        "value": "柳江区",
                        "label": "柳江区",
                        "pid": "450200",
                        "index": "450206"
                    },
                    {
                        "value": "柳城县",
                        "label": "柳城县",
                        "pid": "450200",
                        "index": "450222"
                    },
                    {
                        "value": "鹿寨县",
                        "label": "鹿寨县",
                        "pid": "450200",
                        "index": "450223"
                    },
                    {
                        "value": "融安县",
                        "label": "融安县",
                        "pid": "450200",
                        "index": "450224"
                    },
                    {
                        "value": "融水苗族自治县",
                        "label": "融水苗族自治县",
                        "pid": "450200",
                        "index": "450225"
                    },
                    {
                        "value": "三江侗族自治县",
                        "label": "三江侗族自治县",
                        "pid": "450200",
                        "index": "450226"
                    }
                ]
            },
            {
                "value": "桂林市",
                "label": "桂林市",
                "pid": "450000",
                "index": "450300",
                "children": [
                    {
                        "value": "秀峰区",
                        "label": "秀峰区",
                        "pid": "450300",
                        "index": "450302"
                    },
                    {
                        "value": "叠彩区",
                        "label": "叠彩区",
                        "pid": "450300",
                        "index": "450303"
                    },
                    {
                        "value": "象山区",
                        "label": "象山区",
                        "pid": "450300",
                        "index": "450304"
                    },
                    {
                        "value": "七星区",
                        "label": "七星区",
                        "pid": "450300",
                        "index": "450305"
                    },
                    {
                        "value": "雁山区",
                        "label": "雁山区",
                        "pid": "450300",
                        "index": "450311"
                    },
                    {
                        "value": "临桂区",
                        "label": "临桂区",
                        "pid": "450300",
                        "index": "450312"
                    },
                    {
                        "value": "阳朔县",
                        "label": "阳朔县",
                        "pid": "450300",
                        "index": "450321"
                    },
                    {
                        "value": "灵川县",
                        "label": "灵川县",
                        "pid": "450300",
                        "index": "450323"
                    },
                    {
                        "value": "全州县",
                        "label": "全州县",
                        "pid": "450300",
                        "index": "450324"
                    },
                    {
                        "value": "兴安县",
                        "label": "兴安县",
                        "pid": "450300",
                        "index": "450325"
                    },
                    {
                        "value": "永福县",
                        "label": "永福县",
                        "pid": "450300",
                        "index": "450326"
                    },
                    {
                        "value": "灌阳县",
                        "label": "灌阳县",
                        "pid": "450300",
                        "index": "450327"
                    },
                    {
                        "value": "龙胜各族自治县",
                        "label": "龙胜各族自治县",
                        "pid": "450300",
                        "index": "450328"
                    },
                    {
                        "value": "资源县",
                        "label": "资源县",
                        "pid": "450300",
                        "index": "450329"
                    },
                    {
                        "value": "平乐县",
                        "label": "平乐县",
                        "pid": "450300",
                        "index": "450330"
                    },
                    {
                        "value": "恭城瑶族自治县",
                        "label": "恭城瑶族自治县",
                        "pid": "450300",
                        "index": "450332"
                    },
                    {
                        "value": "荔浦市",
                        "label": "荔浦市",
                        "pid": "450300",
                        "index": "450381"
                    }
                ]
            },
            {
                "value": "梧州市",
                "label": "梧州市",
                "pid": "450000",
                "index": "450400",
                "children": [
                    {
                        "value": "万秀区",
                        "label": "万秀区",
                        "pid": "450400",
                        "index": "450403"
                    },
                    {
                        "value": "长洲区",
                        "label": "长洲区",
                        "pid": "450400",
                        "index": "450405"
                    },
                    {
                        "value": "龙圩区",
                        "label": "龙圩区",
                        "pid": "450400",
                        "index": "450406"
                    },
                    {
                        "value": "苍梧县",
                        "label": "苍梧县",
                        "pid": "450400",
                        "index": "450421"
                    },
                    {
                        "value": "藤县",
                        "label": "藤县",
                        "pid": "450400",
                        "index": "450422"
                    },
                    {
                        "value": "蒙山县",
                        "label": "蒙山县",
                        "pid": "450400",
                        "index": "450423"
                    },
                    {
                        "value": "岑溪市",
                        "label": "岑溪市",
                        "pid": "450400",
                        "index": "450481"
                    }
                ]
            },
            {
                "value": "北海市",
                "label": "北海市",
                "pid": "450000",
                "index": "450500",
                "children": [
                    {
                        "value": "海城区",
                        "label": "海城区",
                        "pid": "450500",
                        "index": "450502"
                    },
                    {
                        "value": "银海区",
                        "label": "银海区",
                        "pid": "450500",
                        "index": "450503"
                    },
                    {
                        "value": "铁山港区",
                        "label": "铁山港区",
                        "pid": "450500",
                        "index": "450512"
                    },
                    {
                        "value": "合浦县",
                        "label": "合浦县",
                        "pid": "450500",
                        "index": "450521"
                    }
                ]
            },
            {
                "value": "防城港市",
                "label": "防城港市",
                "pid": "450000",
                "index": "450600",
                "children": [
                    {
                        "value": "港口区",
                        "label": "港口区",
                        "pid": "450600",
                        "index": "450602"
                    },
                    {
                        "value": "防城区",
                        "label": "防城区",
                        "pid": "450600",
                        "index": "450603"
                    },
                    {
                        "value": "上思县",
                        "label": "上思县",
                        "pid": "450600",
                        "index": "450621"
                    },
                    {
                        "value": "东兴市",
                        "label": "东兴市",
                        "pid": "450600",
                        "index": "450681"
                    }
                ]
            },
            {
                "value": "钦州市",
                "label": "钦州市",
                "pid": "450000",
                "index": "450700",
                "children": [
                    {
                        "value": "钦南区",
                        "label": "钦南区",
                        "pid": "450700",
                        "index": "450702"
                    },
                    {
                        "value": "钦北区",
                        "label": "钦北区",
                        "pid": "450700",
                        "index": "450703"
                    },
                    {
                        "value": "灵山县",
                        "label": "灵山县",
                        "pid": "450700",
                        "index": "450721"
                    },
                    {
                        "value": "浦北县",
                        "label": "浦北县",
                        "pid": "450700",
                        "index": "450722"
                    }
                ]
            },
            {
                "value": "贵港市",
                "label": "贵港市",
                "pid": "450000",
                "index": "450800",
                "children": [
                    {
                        "value": "港北区",
                        "label": "港北区",
                        "pid": "450800",
                        "index": "450802"
                    },
                    {
                        "value": "港南区",
                        "label": "港南区",
                        "pid": "450800",
                        "index": "450803"
                    },
                    {
                        "value": "覃塘区",
                        "label": "覃塘区",
                        "pid": "450800",
                        "index": "450804"
                    },
                    {
                        "value": "平南县",
                        "label": "平南县",
                        "pid": "450800",
                        "index": "450821"
                    },
                    {
                        "value": "桂平市",
                        "label": "桂平市",
                        "pid": "450800",
                        "index": "450881"
                    }
                ]
            },
            {
                "value": "玉林市",
                "label": "玉林市",
                "pid": "450000",
                "index": "450900",
                "children": [
                    {
                        "value": "玉州区",
                        "label": "玉州区",
                        "pid": "450900",
                        "index": "450902"
                    },
                    {
                        "value": "福绵区",
                        "label": "福绵区",
                        "pid": "450900",
                        "index": "450903"
                    },
                    {
                        "value": "容县",
                        "label": "容县",
                        "pid": "450900",
                        "index": "450921"
                    },
                    {
                        "value": "陆川县",
                        "label": "陆川县",
                        "pid": "450900",
                        "index": "450922"
                    },
                    {
                        "value": "博白县",
                        "label": "博白县",
                        "pid": "450900",
                        "index": "450923"
                    },
                    {
                        "value": "兴业县",
                        "label": "兴业县",
                        "pid": "450900",
                        "index": "450924"
                    },
                    {
                        "value": "北流市",
                        "label": "北流市",
                        "pid": "450900",
                        "index": "450981"
                    }
                ]
            },
            {
                "value": "百色市",
                "label": "百色市",
                "pid": "450000",
                "index": "451000",
                "children": [
                    {
                        "value": "右江区",
                        "label": "右江区",
                        "pid": "451000",
                        "index": "451002"
                    },
                    {
                        "value": "田阳区",
                        "label": "田阳区",
                        "pid": "451000",
                        "index": "451003"
                    },
                    {
                        "value": "田东县",
                        "label": "田东县",
                        "pid": "451000",
                        "index": "451022"
                    },
                    {
                        "value": "平果县",
                        "label": "平果县",
                        "pid": "451000",
                        "index": "451023"
                    },
                    {
                        "value": "德保县",
                        "label": "德保县",
                        "pid": "451000",
                        "index": "451024"
                    },
                    {
                        "value": "那坡县",
                        "label": "那坡县",
                        "pid": "451000",
                        "index": "451026"
                    },
                    {
                        "value": "凌云县",
                        "label": "凌云县",
                        "pid": "451000",
                        "index": "451027"
                    },
                    {
                        "value": "乐业县",
                        "label": "乐业县",
                        "pid": "451000",
                        "index": "451028"
                    },
                    {
                        "value": "田林县",
                        "label": "田林县",
                        "pid": "451000",
                        "index": "451029"
                    },
                    {
                        "value": "西林县",
                        "label": "西林县",
                        "pid": "451000",
                        "index": "451030"
                    },
                    {
                        "value": "隆林各族自治县",
                        "label": "隆林各族自治县",
                        "pid": "451000",
                        "index": "451031"
                    },
                    {
                        "value": "靖西市",
                        "label": "靖西市",
                        "pid": "451000",
                        "index": "451081"
                    }
                ]
            },
            {
                "value": "贺州市",
                "label": "贺州市",
                "pid": "450000",
                "index": "451100",
                "children": [
                    {
                        "value": "八步区",
                        "label": "八步区",
                        "pid": "451100",
                        "index": "451102"
                    },
                    {
                        "value": "平桂区",
                        "label": "平桂区",
                        "pid": "451100",
                        "index": "451103"
                    },
                    {
                        "value": "昭平县",
                        "label": "昭平县",
                        "pid": "451100",
                        "index": "451121"
                    },
                    {
                        "value": "钟山县",
                        "label": "钟山县",
                        "pid": "451100",
                        "index": "451122"
                    },
                    {
                        "value": "富川瑶族自治县",
                        "label": "富川瑶族自治县",
                        "pid": "451100",
                        "index": "451123"
                    }
                ]
            },
            {
                "value": "河池市",
                "label": "河池市",
                "pid": "450000",
                "index": "451200",
                "children": [
                    {
                        "value": "金城江区",
                        "label": "金城江区",
                        "pid": "451200",
                        "index": "451202"
                    },
                    {
                        "value": "宜州区",
                        "label": "宜州区",
                        "pid": "451200",
                        "index": "451203"
                    },
                    {
                        "value": "南丹县",
                        "label": "南丹县",
                        "pid": "451200",
                        "index": "451221"
                    },
                    {
                        "value": "天峨县",
                        "label": "天峨县",
                        "pid": "451200",
                        "index": "451222"
                    },
                    {
                        "value": "凤山县",
                        "label": "凤山县",
                        "pid": "451200",
                        "index": "451223"
                    },
                    {
                        "value": "东兰县",
                        "label": "东兰县",
                        "pid": "451200",
                        "index": "451224"
                    },
                    {
                        "value": "罗城仫佬族自治县",
                        "label": "罗城仫佬族自治县",
                        "pid": "451200",
                        "index": "451225"
                    },
                    {
                        "value": "环江毛南族自治县",
                        "label": "环江毛南族自治县",
                        "pid": "451200",
                        "index": "451226"
                    },
                    {
                        "value": "巴马瑶族自治县",
                        "label": "巴马瑶族自治县",
                        "pid": "451200",
                        "index": "451227"
                    },
                    {
                        "value": "都安瑶族自治县",
                        "label": "都安瑶族自治县",
                        "pid": "451200",
                        "index": "451228"
                    },
                    {
                        "value": "大化瑶族自治县",
                        "label": "大化瑶族自治县",
                        "pid": "451200",
                        "index": "451229"
                    }
                ]
            },
            {
                "value": "来宾市",
                "label": "来宾市",
                "pid": "450000",
                "index": "451300",
                "children": [
                    {
                        "value": "兴宾区",
                        "label": "兴宾区",
                        "pid": "451300",
                        "index": "451302"
                    },
                    {
                        "value": "忻城县",
                        "label": "忻城县",
                        "pid": "451300",
                        "index": "451321"
                    },
                    {
                        "value": "象州县",
                        "label": "象州县",
                        "pid": "451300",
                        "index": "451322"
                    },
                    {
                        "value": "武宣县",
                        "label": "武宣县",
                        "pid": "451300",
                        "index": "451323"
                    },
                    {
                        "value": "金秀瑶族自治县",
                        "label": "金秀瑶族自治县",
                        "pid": "451300",
                        "index": "451324"
                    },
                    {
                        "value": "合山市",
                        "label": "合山市",
                        "pid": "451300",
                        "index": "451381"
                    }
                ]
            },
            {
                "value": "崇左市",
                "label": "崇左市",
                "pid": "450000",
                "index": "451400",
                "children": [
                    {
                        "value": "江州区",
                        "label": "江州区",
                        "pid": "451400",
                        "index": "451402"
                    },
                    {
                        "value": "扶绥县",
                        "label": "扶绥县",
                        "pid": "451400",
                        "index": "451421"
                    },
                    {
                        "value": "宁明县",
                        "label": "宁明县",
                        "pid": "451400",
                        "index": "451422"
                    },
                    {
                        "value": "龙州县",
                        "label": "龙州县",
                        "pid": "451400",
                        "index": "451423"
                    },
                    {
                        "value": "大新县",
                        "label": "大新县",
                        "pid": "451400",
                        "index": "451424"
                    },
                    {
                        "value": "天等县",
                        "label": "天等县",
                        "pid": "451400",
                        "index": "451425"
                    },
                    {
                        "value": "凭祥市",
                        "label": "凭祥市",
                        "pid": "451400",
                        "index": "451481"
                    }
                ]
            }
        ]
    },
    {
        "value": "海南省",
        "label": "海南省",
        "pid": "86",
        "index": "460000",
        "children": [
            {
                "value": "海口市",
                "label": "海口市",
                "pid": "460000",
                "index": "460100",
                "children": [
                    {
                        "value": "秀英区",
                        "label": "秀英区",
                        "pid": "460100",
                        "index": "460105"
                    },
                    {
                        "value": "龙华区",
                        "label": "龙华区",
                        "pid": "460100",
                        "index": "460106"
                    },
                    {
                        "value": "琼山区",
                        "label": "琼山区",
                        "pid": "460100",
                        "index": "460107"
                    },
                    {
                        "value": "美兰区",
                        "label": "美兰区",
                        "pid": "460100",
                        "index": "460108"
                    }
                ]
            },
            {
                "value": "三亚市",
                "label": "三亚市",
                "pid": "460000",
                "index": "460200",
                "children": [
                    {
                        "value": "海棠区",
                        "label": "海棠区",
                        "pid": "460200",
                        "index": "460202"
                    },
                    {
                        "value": "吉阳区",
                        "label": "吉阳区",
                        "pid": "460200",
                        "index": "460203"
                    },
                    {
                        "value": "天涯区",
                        "label": "天涯区",
                        "pid": "460200",
                        "index": "460204"
                    },
                    {
                        "value": "崖州区",
                        "label": "崖州区",
                        "pid": "460200",
                        "index": "460205"
                    }
                ]
            },
            {
                "value": "三沙市",
                "label": "三沙市",
                "pid": "460000",
                "index": "460300",
                "children": [
                    {
                        "value": "西沙群岛",
                        "label": "西沙群岛",
                        "pid": "460300",
                        "index": "460321"
                    },
                    {
                        "value": "南沙群岛",
                        "label": "南沙群岛",
                        "pid": "460300",
                        "index": "460322"
                    },
                    {
                        "value": "中沙群岛的岛礁及其海域",
                        "label": "中沙群岛的岛礁及其海域",
                        "pid": "460300",
                        "index": "460323"
                    }
                ]
            },
            {
                "value": "儋州市",
                "label": "儋州市",
                "pid": "460000",
                "index": "460400",
                "children": [
                    {
                        "value": "那大镇",
                        "label": "那大镇",
                        "pid": "460400",
                        "index": "460400100"
                    },
                    {
                        "value": "和庆镇",
                        "label": "和庆镇",
                        "pid": "460400",
                        "index": "460400101"
                    },
                    {
                        "value": "南丰镇",
                        "label": "南丰镇",
                        "pid": "460400",
                        "index": "460400102"
                    },
                    {
                        "value": "大成镇",
                        "label": "大成镇",
                        "pid": "460400",
                        "index": "460400103"
                    },
                    {
                        "value": "雅星镇",
                        "label": "雅星镇",
                        "pid": "460400",
                        "index": "460400104"
                    },
                    {
                        "value": "兰洋镇",
                        "label": "兰洋镇",
                        "pid": "460400",
                        "index": "460400105"
                    },
                    {
                        "value": "光村镇",
                        "label": "光村镇",
                        "pid": "460400",
                        "index": "460400106"
                    },
                    {
                        "value": "木棠镇",
                        "label": "木棠镇",
                        "pid": "460400",
                        "index": "460400107"
                    },
                    {
                        "value": "海头镇",
                        "label": "海头镇",
                        "pid": "460400",
                        "index": "460400108"
                    },
                    {
                        "value": "峨蔓镇",
                        "label": "峨蔓镇",
                        "pid": "460400",
                        "index": "460400109"
                    },
                    {
                        "value": "王五镇",
                        "label": "王五镇",
                        "pid": "460400",
                        "index": "460400111"
                    },
                    {
                        "value": "白马井镇",
                        "label": "白马井镇",
                        "pid": "460400",
                        "index": "460400112"
                    },
                    {
                        "value": "中和镇",
                        "label": "中和镇",
                        "pid": "460400",
                        "index": "460400113"
                    },
                    {
                        "value": "排浦镇",
                        "label": "排浦镇",
                        "pid": "460400",
                        "index": "460400114"
                    },
                    {
                        "value": "东成镇",
                        "label": "东成镇",
                        "pid": "460400",
                        "index": "460400115"
                    },
                    {
                        "value": "新州镇",
                        "label": "新州镇",
                        "pid": "460400",
                        "index": "460400116"
                    },
                    {
                        "value": "洋浦经济开发区",
                        "label": "洋浦经济开发区",
                        "pid": "460400",
                        "index": "460400499"
                    },
                    {
                        "value": "华南热作学院",
                        "label": "华南热作学院",
                        "pid": "460400",
                        "index": "460400500"
                    }
                ]
            },
            {
                "value": "省直辖县级行政区划",
                "label": "省直辖县级行政区划",
                "pid": "460000",
                "index": "469000",
                "children": [
                    {
                        "value": "五指山市",
                        "label": "五指山市",
                        "pid": "469000",
                        "index": "469001"
                    },
                    {
                        "value": "琼海市",
                        "label": "琼海市",
                        "pid": "469000",
                        "index": "469002"
                    },
                    {
                        "value": "文昌市",
                        "label": "文昌市",
                        "pid": "469000",
                        "index": "469005"
                    },
                    {
                        "value": "万宁市",
                        "label": "万宁市",
                        "pid": "469000",
                        "index": "469006"
                    },
                    {
                        "value": "东方市",
                        "label": "东方市",
                        "pid": "469000",
                        "index": "469007"
                    },
                    {
                        "value": "定安县",
                        "label": "定安县",
                        "pid": "469000",
                        "index": "469021"
                    },
                    {
                        "value": "屯昌县",
                        "label": "屯昌县",
                        "pid": "469000",
                        "index": "469022"
                    },
                    {
                        "value": "澄迈县",
                        "label": "澄迈县",
                        "pid": "469000",
                        "index": "469023"
                    },
                    {
                        "value": "临高县",
                        "label": "临高县",
                        "pid": "469000",
                        "index": "469024"
                    },
                    {
                        "value": "白沙黎族自治县",
                        "label": "白沙黎族自治县",
                        "pid": "469000",
                        "index": "469025"
                    },
                    {
                        "value": "昌江黎族自治县",
                        "label": "昌江黎族自治县",
                        "pid": "469000",
                        "index": "469026"
                    },
                    {
                        "value": "乐东黎族自治县",
                        "label": "乐东黎族自治县",
                        "pid": "469000",
                        "index": "469027"
                    },
                    {
                        "value": "陵水黎族自治县",
                        "label": "陵水黎族自治县",
                        "pid": "469000",
                        "index": "469028"
                    },
                    {
                        "value": "保亭黎族苗族自治县",
                        "label": "保亭黎族苗族自治县",
                        "pid": "469000",
                        "index": "469029"
                    },
                    {
                        "value": "琼中黎族苗族自治县",
                        "label": "琼中黎族苗族自治县",
                        "pid": "469000",
                        "index": "469030"
                    }
                ]
            }
        ]
    },
    {
        "value": "重庆市",
        "label": "重庆市",
        "pid": "86",
        "index": "500000",
        "children": [
            {
                "value": "重庆市",
                "label": "重庆市",
                "pid": "500000",
                "index": "500100",
                "children": [
                    {
                        "value": "万州区",
                        "label": "万州区",
                        "pid": "500100",
                        "index": "500101"
                    },
                    {
                        "value": "涪陵区",
                        "label": "涪陵区",
                        "pid": "500100",
                        "index": "500102"
                    },
                    {
                        "value": "渝中区",
                        "label": "渝中区",
                        "pid": "500100",
                        "index": "500103"
                    },
                    {
                        "value": "大渡口区",
                        "label": "大渡口区",
                        "pid": "500100",
                        "index": "500104"
                    },
                    {
                        "value": "江北区",
                        "label": "江北区",
                        "pid": "500100",
                        "index": "500105"
                    },
                    {
                        "value": "沙坪坝区",
                        "label": "沙坪坝区",
                        "pid": "500100",
                        "index": "500106"
                    },
                    {
                        "value": "九龙坡区",
                        "label": "九龙坡区",
                        "pid": "500100",
                        "index": "500107"
                    },
                    {
                        "value": "南岸区",
                        "label": "南岸区",
                        "pid": "500100",
                        "index": "500108"
                    },
                    {
                        "value": "北碚区",
                        "label": "北碚区",
                        "pid": "500100",
                        "index": "500109"
                    },
                    {
                        "value": "綦江区",
                        "label": "綦江区",
                        "pid": "500100",
                        "index": "500110"
                    },
                    {
                        "value": "大足区",
                        "label": "大足区",
                        "pid": "500100",
                        "index": "500111"
                    },
                    {
                        "value": "渝北区",
                        "label": "渝北区",
                        "pid": "500100",
                        "index": "500112"
                    },
                    {
                        "value": "巴南区",
                        "label": "巴南区",
                        "pid": "500100",
                        "index": "500113"
                    },
                    {
                        "value": "黔江区",
                        "label": "黔江区",
                        "pid": "500100",
                        "index": "500114"
                    },
                    {
                        "value": "长寿区",
                        "label": "长寿区",
                        "pid": "500100",
                        "index": "500115"
                    },
                    {
                        "value": "江津区",
                        "label": "江津区",
                        "pid": "500100",
                        "index": "500116"
                    },
                    {
                        "value": "合川区",
                        "label": "合川区",
                        "pid": "500100",
                        "index": "500117"
                    },
                    {
                        "value": "永川区",
                        "label": "永川区",
                        "pid": "500100",
                        "index": "500118"
                    },
                    {
                        "value": "南川区",
                        "label": "南川区",
                        "pid": "500100",
                        "index": "500119"
                    },
                    {
                        "value": "璧山区",
                        "label": "璧山区",
                        "pid": "500100",
                        "index": "500120"
                    },
                    {
                        "value": "铜梁区",
                        "label": "铜梁区",
                        "pid": "500100",
                        "index": "500151"
                    },
                    {
                        "value": "潼南区",
                        "label": "潼南区",
                        "pid": "500100",
                        "index": "500152"
                    },
                    {
                        "value": "荣昌区",
                        "label": "荣昌区",
                        "pid": "500100",
                        "index": "500153"
                    },
                    {
                        "value": "开州区",
                        "label": "开州区",
                        "pid": "500100",
                        "index": "500154"
                    },
                    {
                        "value": "梁平区",
                        "label": "梁平区",
                        "pid": "500100",
                        "index": "500155"
                    },
                    {
                        "value": "武隆区",
                        "label": "武隆区",
                        "pid": "500100",
                        "index": "500156"
                    },
                    {
                        "value": "城口县",
                        "label": "城口县",
                        "pid": "500200",
                        "index": "500229"
                    },
                    {
                        "value": "丰都县",
                        "label": "丰都县",
                        "pid": "500200",
                        "index": "500230"
                    },
                    {
                        "value": "垫江县",
                        "label": "垫江县",
                        "pid": "500200",
                        "index": "500231"
                    },
                    {
                        "value": "忠县",
                        "label": "忠县",
                        "pid": "500200",
                        "index": "500233"
                    },
                    {
                        "value": "云阳县",
                        "label": "云阳县",
                        "pid": "500200",
                        "index": "500235"
                    },
                    {
                        "value": "奉节县",
                        "label": "奉节县",
                        "pid": "500200",
                        "index": "500236"
                    },
                    {
                        "value": "巫山县",
                        "label": "巫山县",
                        "pid": "500200",
                        "index": "500237"
                    },
                    {
                        "value": "巫溪县",
                        "label": "巫溪县",
                        "pid": "500200",
                        "index": "500238"
                    },
                    {
                        "value": "石柱土家族自治县",
                        "label": "石柱土家族自治县",
                        "pid": "500200",
                        "index": "500240"
                    },
                    {
                        "value": "秀山土家族苗族自治县",
                        "label": "秀山土家族苗族自治县",
                        "pid": "500200",
                        "index": "500241"
                    },
                    {
                        "value": "酉阳土家族苗族自治县",
                        "label": "酉阳土家族苗族自治县",
                        "pid": "500200",
                        "index": "500242"
                    },
                    {
                        "value": "彭水苗族土家族自治县",
                        "label": "彭水苗族土家族自治县",
                        "pid": "500200",
                        "index": "500243"
                    }
                ]
            },
            // {
            //     "value": "县",
            //     "label": "县",
            //     "pid": "500000",
            //     "index": "500200",
            //     "children": [
            //         {
            //             "value": "城口县",
            //             "label": "城口县",
            //             "pid": "500200",
            //             "index": "500229"
            //         },
            //         {
            //             "value": "丰都县",
            //             "label": "丰都县",
            //             "pid": "500200",
            //             "index": "500230"
            //         },
            //         {
            //             "value": "垫江县",
            //             "label": "垫江县",
            //             "pid": "500200",
            //             "index": "500231"
            //         },
            //         {
            //             "value": "忠县",
            //             "label": "忠县",
            //             "pid": "500200",
            //             "index": "500233"
            //         },
            //         {
            //             "value": "云阳县",
            //             "label": "云阳县",
            //             "pid": "500200",
            //             "index": "500235"
            //         },
            //         {
            //             "value": "奉节县",
            //             "label": "奉节县",
            //             "pid": "500200",
            //             "index": "500236"
            //         },
            //         {
            //             "value": "巫山县",
            //             "label": "巫山县",
            //             "pid": "500200",
            //             "index": "500237"
            //         },
            //         {
            //             "value": "巫溪县",
            //             "label": "巫溪县",
            //             "pid": "500200",
            //             "index": "500238"
            //         },
            //         {
            //             "value": "石柱土家族自治县",
            //             "label": "石柱土家族自治县",
            //             "pid": "500200",
            //             "index": "500240"
            //         },
            //         {
            //             "value": "秀山土家族苗族自治县",
            //             "label": "秀山土家族苗族自治县",
            //             "pid": "500200",
            //             "index": "500241"
            //         },
            //         {
            //             "value": "酉阳土家族苗族自治县",
            //             "label": "酉阳土家族苗族自治县",
            //             "pid": "500200",
            //             "index": "500242"
            //         },
            //         {
            //             "value": "彭水苗族土家族自治县",
            //             "label": "彭水苗族土家族自治县",
            //             "pid": "500200",
            //             "index": "500243"
            //         }
            //     ]
            // }
        ]
    },
    {
        "value": "四川省",
        "label": "四川省",
        "pid": "86",
        "index": "510000",
        "children": [
            {
                "value": "成都市",
                "label": "成都市",
                "pid": "510000",
                "index": "510100",
                "children": [
                    {
                        "value": "锦江区",
                        "label": "锦江区",
                        "pid": "510100",
                        "index": "510104"
                    },
                    {
                        "value": "青羊区",
                        "label": "青羊区",
                        "pid": "510100",
                        "index": "510105"
                    },
                    {
                        "value": "金牛区",
                        "label": "金牛区",
                        "pid": "510100",
                        "index": "510106"
                    },
                    {
                        "value": "武侯区",
                        "label": "武侯区",
                        "pid": "510100",
                        "index": "510107"
                    },
                    {
                        "value": "成华区",
                        "label": "成华区",
                        "pid": "510100",
                        "index": "510108"
                    },
                    {
                        "value": "龙泉驿区",
                        "label": "龙泉驿区",
                        "pid": "510100",
                        "index": "510112"
                    },
                    {
                        "value": "青白江区",
                        "label": "青白江区",
                        "pid": "510100",
                        "index": "510113"
                    },
                    {
                        "value": "新都区",
                        "label": "新都区",
                        "pid": "510100",
                        "index": "510114"
                    },
                    {
                        "value": "温江区",
                        "label": "温江区",
                        "pid": "510100",
                        "index": "510115"
                    },
                    {
                        "value": "双流区",
                        "label": "双流区",
                        "pid": "510100",
                        "index": "510116"
                    },
                    {
                        "value": "郫都区",
                        "label": "郫都区",
                        "pid": "510100",
                        "index": "510117"
                    },
                    {
                        "value": "金堂县",
                        "label": "金堂县",
                        "pid": "510100",
                        "index": "510121"
                    },
                    {
                        "value": "大邑县",
                        "label": "大邑县",
                        "pid": "510100",
                        "index": "510129"
                    },
                    {
                        "value": "蒲江县",
                        "label": "蒲江县",
                        "pid": "510100",
                        "index": "510131"
                    },
                    {
                        "value": "新津县",
                        "label": "新津县",
                        "pid": "510100",
                        "index": "510132"
                    },
                    {
                        "value": "都江堰市",
                        "label": "都江堰市",
                        "pid": "510100",
                        "index": "510181"
                    },
                    {
                        "value": "彭州市",
                        "label": "彭州市",
                        "pid": "510100",
                        "index": "510182"
                    },
                    {
                        "value": "邛崃市",
                        "label": "邛崃市",
                        "pid": "510100",
                        "index": "510183"
                    },
                    {
                        "value": "崇州市",
                        "label": "崇州市",
                        "pid": "510100",
                        "index": "510184"
                    },
                    {
                        "value": "简阳市",
                        "label": "简阳市",
                        "pid": "510100",
                        "index": "510185"
                    }
                ]
            },
            {
                "value": "自贡市",
                "label": "自贡市",
                "pid": "510000",
                "index": "510300",
                "children": [
                    {
                        "value": "自流井区",
                        "label": "自流井区",
                        "pid": "510300",
                        "index": "510302"
                    },
                    {
                        "value": "贡井区",
                        "label": "贡井区",
                        "pid": "510300",
                        "index": "510303"
                    },
                    {
                        "value": "大安区",
                        "label": "大安区",
                        "pid": "510300",
                        "index": "510304"
                    },
                    {
                        "value": "沿滩区",
                        "label": "沿滩区",
                        "pid": "510300",
                        "index": "510311"
                    },
                    {
                        "value": "荣县",
                        "label": "荣县",
                        "pid": "510300",
                        "index": "510321"
                    },
                    {
                        "value": "富顺县",
                        "label": "富顺县",
                        "pid": "510300",
                        "index": "510322"
                    }
                ]
            },
            {
                "value": "攀枝花市",
                "label": "攀枝花市",
                "pid": "510000",
                "index": "510400",
                "children": [
                    {
                        "value": "东区",
                        "label": "东区",
                        "pid": "510400",
                        "index": "510402"
                    },
                    {
                        "value": "西区",
                        "label": "西区",
                        "pid": "510400",
                        "index": "510403"
                    },
                    {
                        "value": "仁和区",
                        "label": "仁和区",
                        "pid": "510400",
                        "index": "510411"
                    },
                    {
                        "value": "米易县",
                        "label": "米易县",
                        "pid": "510400",
                        "index": "510421"
                    },
                    {
                        "value": "盐边县",
                        "label": "盐边县",
                        "pid": "510400",
                        "index": "510422"
                    }
                ]
            },
            {
                "value": "泸州市",
                "label": "泸州市",
                "pid": "510000",
                "index": "510500",
                "children": [
                    {
                        "value": "江阳区",
                        "label": "江阳区",
                        "pid": "510500",
                        "index": "510502"
                    },
                    {
                        "value": "纳溪区",
                        "label": "纳溪区",
                        "pid": "510500",
                        "index": "510503"
                    },
                    {
                        "value": "龙马潭区",
                        "label": "龙马潭区",
                        "pid": "510500",
                        "index": "510504"
                    },
                    {
                        "value": "泸县",
                        "label": "泸县",
                        "pid": "510500",
                        "index": "510521"
                    },
                    {
                        "value": "合江县",
                        "label": "合江县",
                        "pid": "510500",
                        "index": "510522"
                    },
                    {
                        "value": "叙永县",
                        "label": "叙永县",
                        "pid": "510500",
                        "index": "510524"
                    },
                    {
                        "value": "古蔺县",
                        "label": "古蔺县",
                        "pid": "510500",
                        "index": "510525"
                    }
                ]
            },
            {
                "value": "德阳市",
                "label": "德阳市",
                "pid": "510000",
                "index": "510600",
                "children": [
                    {
                        "value": "旌阳区",
                        "label": "旌阳区",
                        "pid": "510600",
                        "index": "510603"
                    },
                    {
                        "value": "罗江区",
                        "label": "罗江区",
                        "pid": "510600",
                        "index": "510604"
                    },
                    {
                        "value": "中江县",
                        "label": "中江县",
                        "pid": "510600",
                        "index": "510623"
                    },
                    {
                        "value": "广汉市",
                        "label": "广汉市",
                        "pid": "510600",
                        "index": "510681"
                    },
                    {
                        "value": "什邡市",
                        "label": "什邡市",
                        "pid": "510600",
                        "index": "510682"
                    },
                    {
                        "value": "绵竹市",
                        "label": "绵竹市",
                        "pid": "510600",
                        "index": "510683"
                    }
                ]
            },
            {
                "value": "绵阳市",
                "label": "绵阳市",
                "pid": "510000",
                "index": "510700",
                "children": [
                    {
                        "value": "涪城区",
                        "label": "涪城区",
                        "pid": "510700",
                        "index": "510703"
                    },
                    {
                        "value": "游仙区",
                        "label": "游仙区",
                        "pid": "510700",
                        "index": "510704"
                    },
                    {
                        "value": "安州区",
                        "label": "安州区",
                        "pid": "510700",
                        "index": "510705"
                    },
                    {
                        "value": "三台县",
                        "label": "三台县",
                        "pid": "510700",
                        "index": "510722"
                    },
                    {
                        "value": "盐亭县",
                        "label": "盐亭县",
                        "pid": "510700",
                        "index": "510723"
                    },
                    {
                        "value": "梓潼县",
                        "label": "梓潼县",
                        "pid": "510700",
                        "index": "510725"
                    },
                    {
                        "value": "北川羌族自治县",
                        "label": "北川羌族自治县",
                        "pid": "510700",
                        "index": "510726"
                    },
                    {
                        "value": "平武县",
                        "label": "平武县",
                        "pid": "510700",
                        "index": "510727"
                    },
                    {
                        "value": "江油市",
                        "label": "江油市",
                        "pid": "510700",
                        "index": "510781"
                    }
                ]
            },
            {
                "value": "广元市",
                "label": "广元市",
                "pid": "510000",
                "index": "510800",
                "children": [
                    {
                        "value": "利州区",
                        "label": "利州区",
                        "pid": "510800",
                        "index": "510802"
                    },
                    {
                        "value": "昭化区",
                        "label": "昭化区",
                        "pid": "510800",
                        "index": "510811"
                    },
                    {
                        "value": "朝天区",
                        "label": "朝天区",
                        "pid": "510800",
                        "index": "510812"
                    },
                    {
                        "value": "旺苍县",
                        "label": "旺苍县",
                        "pid": "510800",
                        "index": "510821"
                    },
                    {
                        "value": "青川县",
                        "label": "青川县",
                        "pid": "510800",
                        "index": "510822"
                    },
                    {
                        "value": "剑阁县",
                        "label": "剑阁县",
                        "pid": "510800",
                        "index": "510823"
                    },
                    {
                        "value": "苍溪县",
                        "label": "苍溪县",
                        "pid": "510800",
                        "index": "510824"
                    }
                ]
            },
            {
                "value": "遂宁市",
                "label": "遂宁市",
                "pid": "510000",
                "index": "510900",
                "children": [
                    {
                        "value": "船山区",
                        "label": "船山区",
                        "pid": "510900",
                        "index": "510903"
                    },
                    {
                        "value": "安居区",
                        "label": "安居区",
                        "pid": "510900",
                        "index": "510904"
                    },
                    {
                        "value": "蓬溪县",
                        "label": "蓬溪县",
                        "pid": "510900",
                        "index": "510921"
                    },
                    {
                        "value": "大英县",
                        "label": "大英县",
                        "pid": "510900",
                        "index": "510923"
                    },
                    {
                        "value": "射洪市",
                        "label": "射洪市",
                        "pid": "510900",
                        "index": "510981"
                    }
                ]
            },
            {
                "value": "内江市",
                "label": "内江市",
                "pid": "510000",
                "index": "511000",
                "children": [
                    {
                        "value": "市中区",
                        "label": "市中区",
                        "pid": "511000",
                        "index": "511002"
                    },
                    {
                        "value": "东兴区",
                        "label": "东兴区",
                        "pid": "511000",
                        "index": "511011"
                    },
                    {
                        "value": "威远县",
                        "label": "威远县",
                        "pid": "511000",
                        "index": "511024"
                    },
                    {
                        "value": "资中县",
                        "label": "资中县",
                        "pid": "511000",
                        "index": "511025"
                    },
                    {
                        "value": "内江经济开发区",
                        "label": "内江经济开发区",
                        "pid": "511000",
                        "index": "511071"
                    },
                    {
                        "value": "隆昌市",
                        "label": "隆昌市",
                        "pid": "511000",
                        "index": "511083"
                    }
                ]
            },
            {
                "value": "乐山市",
                "label": "乐山市",
                "pid": "510000",
                "index": "511100",
                "children": [
                    {
                        "value": "市中区",
                        "label": "市中区",
                        "pid": "511100",
                        "index": "511102"
                    },
                    {
                        "value": "沙湾区",
                        "label": "沙湾区",
                        "pid": "511100",
                        "index": "511111"
                    },
                    {
                        "value": "五通桥区",
                        "label": "五通桥区",
                        "pid": "511100",
                        "index": "511112"
                    },
                    {
                        "value": "金口河区",
                        "label": "金口河区",
                        "pid": "511100",
                        "index": "511113"
                    },
                    {
                        "value": "犍为县",
                        "label": "犍为县",
                        "pid": "511100",
                        "index": "511123"
                    },
                    {
                        "value": "井研县",
                        "label": "井研县",
                        "pid": "511100",
                        "index": "511124"
                    },
                    {
                        "value": "夹江县",
                        "label": "夹江县",
                        "pid": "511100",
                        "index": "511126"
                    },
                    {
                        "value": "沐川县",
                        "label": "沐川县",
                        "pid": "511100",
                        "index": "511129"
                    },
                    {
                        "value": "峨边彝族自治县",
                        "label": "峨边彝族自治县",
                        "pid": "511100",
                        "index": "511132"
                    },
                    {
                        "value": "马边彝族自治县",
                        "label": "马边彝族自治县",
                        "pid": "511100",
                        "index": "511133"
                    },
                    {
                        "value": "峨眉山市",
                        "label": "峨眉山市",
                        "pid": "511100",
                        "index": "511181"
                    }
                ]
            },
            {
                "value": "南充市",
                "label": "南充市",
                "pid": "510000",
                "index": "511300",
                "children": [
                    {
                        "value": "顺庆区",
                        "label": "顺庆区",
                        "pid": "511300",
                        "index": "511302"
                    },
                    {
                        "value": "高坪区",
                        "label": "高坪区",
                        "pid": "511300",
                        "index": "511303"
                    },
                    {
                        "value": "嘉陵区",
                        "label": "嘉陵区",
                        "pid": "511300",
                        "index": "511304"
                    },
                    {
                        "value": "南部县",
                        "label": "南部县",
                        "pid": "511300",
                        "index": "511321"
                    },
                    {
                        "value": "营山县",
                        "label": "营山县",
                        "pid": "511300",
                        "index": "511322"
                    },
                    {
                        "value": "蓬安县",
                        "label": "蓬安县",
                        "pid": "511300",
                        "index": "511323"
                    },
                    {
                        "value": "仪陇县",
                        "label": "仪陇县",
                        "pid": "511300",
                        "index": "511324"
                    },
                    {
                        "value": "西充县",
                        "label": "西充县",
                        "pid": "511300",
                        "index": "511325"
                    },
                    {
                        "value": "阆中市",
                        "label": "阆中市",
                        "pid": "511300",
                        "index": "511381"
                    }
                ]
            },
            {
                "value": "眉山市",
                "label": "眉山市",
                "pid": "510000",
                "index": "511400",
                "children": [
                    {
                        "value": "东坡区",
                        "label": "东坡区",
                        "pid": "511400",
                        "index": "511402"
                    },
                    {
                        "value": "彭山区",
                        "label": "彭山区",
                        "pid": "511400",
                        "index": "511403"
                    },
                    {
                        "value": "仁寿县",
                        "label": "仁寿县",
                        "pid": "511400",
                        "index": "511421"
                    },
                    {
                        "value": "洪雅县",
                        "label": "洪雅县",
                        "pid": "511400",
                        "index": "511423"
                    },
                    {
                        "value": "丹棱县",
                        "label": "丹棱县",
                        "pid": "511400",
                        "index": "511424"
                    },
                    {
                        "value": "青神县",
                        "label": "青神县",
                        "pid": "511400",
                        "index": "511425"
                    }
                ]
            },
            {
                "value": "宜宾市",
                "label": "宜宾市",
                "pid": "510000",
                "index": "511500",
                "children": [
                    {
                        "value": "翠屏区",
                        "label": "翠屏区",
                        "pid": "511500",
                        "index": "511502"
                    },
                    {
                        "value": "南溪区",
                        "label": "南溪区",
                        "pid": "511500",
                        "index": "511503"
                    },
                    {
                        "value": "叙州区",
                        "label": "叙州区",
                        "pid": "511500",
                        "index": "511504"
                    },
                    {
                        "value": "江安县",
                        "label": "江安县",
                        "pid": "511500",
                        "index": "511523"
                    },
                    {
                        "value": "长宁县",
                        "label": "长宁县",
                        "pid": "511500",
                        "index": "511524"
                    },
                    {
                        "value": "高县",
                        "label": "高县",
                        "pid": "511500",
                        "index": "511525"
                    },
                    {
                        "value": "珙县",
                        "label": "珙县",
                        "pid": "511500",
                        "index": "511526"
                    },
                    {
                        "value": "筠连县",
                        "label": "筠连县",
                        "pid": "511500",
                        "index": "511527"
                    },
                    {
                        "value": "兴文县",
                        "label": "兴文县",
                        "pid": "511500",
                        "index": "511528"
                    },
                    {
                        "value": "屏山县",
                        "label": "屏山县",
                        "pid": "511500",
                        "index": "511529"
                    }
                ]
            },
            {
                "value": "广安市",
                "label": "广安市",
                "pid": "510000",
                "index": "511600",
                "children": [
                    {
                        "value": "广安区",
                        "label": "广安区",
                        "pid": "511600",
                        "index": "511602"
                    },
                    {
                        "value": "前锋区",
                        "label": "前锋区",
                        "pid": "511600",
                        "index": "511603"
                    },
                    {
                        "value": "岳池县",
                        "label": "岳池县",
                        "pid": "511600",
                        "index": "511621"
                    },
                    {
                        "value": "武胜县",
                        "label": "武胜县",
                        "pid": "511600",
                        "index": "511622"
                    },
                    {
                        "value": "邻水县",
                        "label": "邻水县",
                        "pid": "511600",
                        "index": "511623"
                    },
                    {
                        "value": "华蓥市",
                        "label": "华蓥市",
                        "pid": "511600",
                        "index": "511681"
                    }
                ]
            },
            {
                "value": "达州市",
                "label": "达州市",
                "pid": "510000",
                "index": "511700",
                "children": [
                    {
                        "value": "通川区",
                        "label": "通川区",
                        "pid": "511700",
                        "index": "511702"
                    },
                    {
                        "value": "达川区",
                        "label": "达川区",
                        "pid": "511700",
                        "index": "511703"
                    },
                    {
                        "value": "宣汉县",
                        "label": "宣汉县",
                        "pid": "511700",
                        "index": "511722"
                    },
                    {
                        "value": "开江县",
                        "label": "开江县",
                        "pid": "511700",
                        "index": "511723"
                    },
                    {
                        "value": "大竹县",
                        "label": "大竹县",
                        "pid": "511700",
                        "index": "511724"
                    },
                    {
                        "value": "渠县",
                        "label": "渠县",
                        "pid": "511700",
                        "index": "511725"
                    },
                    {
                        "value": "达州经济开发区",
                        "label": "达州经济开发区",
                        "pid": "511700",
                        "index": "511771"
                    },
                    {
                        "value": "万源市",
                        "label": "万源市",
                        "pid": "511700",
                        "index": "511781"
                    }
                ]
            },
            {
                "value": "雅安市",
                "label": "雅安市",
                "pid": "510000",
                "index": "511800",
                "children": [
                    {
                        "value": "雨城区",
                        "label": "雨城区",
                        "pid": "511800",
                        "index": "511802"
                    },
                    {
                        "value": "名山区",
                        "label": "名山区",
                        "pid": "511800",
                        "index": "511803"
                    },
                    {
                        "value": "荥经县",
                        "label": "荥经县",
                        "pid": "511800",
                        "index": "511822"
                    },
                    {
                        "value": "汉源县",
                        "label": "汉源县",
                        "pid": "511800",
                        "index": "511823"
                    },
                    {
                        "value": "石棉县",
                        "label": "石棉县",
                        "pid": "511800",
                        "index": "511824"
                    },
                    {
                        "value": "天全县",
                        "label": "天全县",
                        "pid": "511800",
                        "index": "511825"
                    },
                    {
                        "value": "芦山县",
                        "label": "芦山县",
                        "pid": "511800",
                        "index": "511826"
                    },
                    {
                        "value": "宝兴县",
                        "label": "宝兴县",
                        "pid": "511800",
                        "index": "511827"
                    }
                ]
            },
            {
                "value": "巴中市",
                "label": "巴中市",
                "pid": "510000",
                "index": "511900",
                "children": [
                    {
                        "value": "巴州区",
                        "label": "巴州区",
                        "pid": "511900",
                        "index": "511902"
                    },
                    {
                        "value": "恩阳区",
                        "label": "恩阳区",
                        "pid": "511900",
                        "index": "511903"
                    },
                    {
                        "value": "通江县",
                        "label": "通江县",
                        "pid": "511900",
                        "index": "511921"
                    },
                    {
                        "value": "南江县",
                        "label": "南江县",
                        "pid": "511900",
                        "index": "511922"
                    },
                    {
                        "value": "平昌县",
                        "label": "平昌县",
                        "pid": "511900",
                        "index": "511923"
                    },
                    {
                        "value": "巴中经济开发区",
                        "label": "巴中经济开发区",
                        "pid": "511900",
                        "index": "511971"
                    }
                ]
            },
            {
                "value": "资阳市",
                "label": "资阳市",
                "pid": "510000",
                "index": "512000",
                "children": [
                    {
                        "value": "雁江区",
                        "label": "雁江区",
                        "pid": "512000",
                        "index": "512002"
                    },
                    {
                        "value": "安岳县",
                        "label": "安岳县",
                        "pid": "512000",
                        "index": "512021"
                    },
                    {
                        "value": "乐至县",
                        "label": "乐至县",
                        "pid": "512000",
                        "index": "512022"
                    }
                ]
            },
            {
                "value": "阿坝藏族羌族自治州",
                "label": "阿坝藏族羌族自治州",
                "pid": "510000",
                "index": "513200",
                "children": [
                    {
                        "value": "马尔康市",
                        "label": "马尔康市",
                        "pid": "513200",
                        "index": "513201"
                    },
                    {
                        "value": "汶川县",
                        "label": "汶川县",
                        "pid": "513200",
                        "index": "513221"
                    },
                    {
                        "value": "理县",
                        "label": "理县",
                        "pid": "513200",
                        "index": "513222"
                    },
                    {
                        "value": "茂县",
                        "label": "茂县",
                        "pid": "513200",
                        "index": "513223"
                    },
                    {
                        "value": "松潘县",
                        "label": "松潘县",
                        "pid": "513200",
                        "index": "513224"
                    },
                    {
                        "value": "九寨沟县",
                        "label": "九寨沟县",
                        "pid": "513200",
                        "index": "513225"
                    },
                    {
                        "value": "金川县",
                        "label": "金川县",
                        "pid": "513200",
                        "index": "513226"
                    },
                    {
                        "value": "小金县",
                        "label": "小金县",
                        "pid": "513200",
                        "index": "513227"
                    },
                    {
                        "value": "黑水县",
                        "label": "黑水县",
                        "pid": "513200",
                        "index": "513228"
                    },
                    {
                        "value": "壤塘县",
                        "label": "壤塘县",
                        "pid": "513200",
                        "index": "513230"
                    },
                    {
                        "value": "阿坝县",
                        "label": "阿坝县",
                        "pid": "513200",
                        "index": "513231"
                    },
                    {
                        "value": "若尔盖县",
                        "label": "若尔盖县",
                        "pid": "513200",
                        "index": "513232"
                    },
                    {
                        "value": "红原县",
                        "label": "红原县",
                        "pid": "513200",
                        "index": "513233"
                    }
                ]
            },
            {
                "value": "甘孜藏族自治州",
                "label": "甘孜藏族自治州",
                "pid": "510000",
                "index": "513300",
                "children": [
                    {
                        "value": "康定市",
                        "label": "康定市",
                        "pid": "513300",
                        "index": "513301"
                    },
                    {
                        "value": "泸定县",
                        "label": "泸定县",
                        "pid": "513300",
                        "index": "513322"
                    },
                    {
                        "value": "丹巴县",
                        "label": "丹巴县",
                        "pid": "513300",
                        "index": "513323"
                    },
                    {
                        "value": "九龙县",
                        "label": "九龙县",
                        "pid": "513300",
                        "index": "513324"
                    },
                    {
                        "value": "雅江县",
                        "label": "雅江县",
                        "pid": "513300",
                        "index": "513325"
                    },
                    {
                        "value": "道孚县",
                        "label": "道孚县",
                        "pid": "513300",
                        "index": "513326"
                    },
                    {
                        "value": "炉霍县",
                        "label": "炉霍县",
                        "pid": "513300",
                        "index": "513327"
                    },
                    {
                        "value": "甘孜县",
                        "label": "甘孜县",
                        "pid": "513300",
                        "index": "513328"
                    },
                    {
                        "value": "新龙县",
                        "label": "新龙县",
                        "pid": "513300",
                        "index": "513329"
                    },
                    {
                        "value": "德格县",
                        "label": "德格县",
                        "pid": "513300",
                        "index": "513330"
                    },
                    {
                        "value": "白玉县",
                        "label": "白玉县",
                        "pid": "513300",
                        "index": "513331"
                    },
                    {
                        "value": "石渠县",
                        "label": "石渠县",
                        "pid": "513300",
                        "index": "513332"
                    },
                    {
                        "value": "色达县",
                        "label": "色达县",
                        "pid": "513300",
                        "index": "513333"
                    },
                    {
                        "value": "理塘县",
                        "label": "理塘县",
                        "pid": "513300",
                        "index": "513334"
                    },
                    {
                        "value": "巴塘县",
                        "label": "巴塘县",
                        "pid": "513300",
                        "index": "513335"
                    },
                    {
                        "value": "乡城县",
                        "label": "乡城县",
                        "pid": "513300",
                        "index": "513336"
                    },
                    {
                        "value": "稻城县",
                        "label": "稻城县",
                        "pid": "513300",
                        "index": "513337"
                    },
                    {
                        "value": "得荣县",
                        "label": "得荣县",
                        "pid": "513300",
                        "index": "513338"
                    }
                ]
            },
            {
                "value": "凉山彝族自治州",
                "label": "凉山彝族自治州",
                "pid": "510000",
                "index": "513400",
                "children": [
                    {
                        "value": "西昌市",
                        "label": "西昌市",
                        "pid": "513400",
                        "index": "513401"
                    },
                    {
                        "value": "木里藏族自治县",
                        "label": "木里藏族自治县",
                        "pid": "513400",
                        "index": "513422"
                    },
                    {
                        "value": "盐源县",
                        "label": "盐源县",
                        "pid": "513400",
                        "index": "513423"
                    },
                    {
                        "value": "德昌县",
                        "label": "德昌县",
                        "pid": "513400",
                        "index": "513424"
                    },
                    {
                        "value": "会理县",
                        "label": "会理县",
                        "pid": "513400",
                        "index": "513425"
                    },
                    {
                        "value": "会东县",
                        "label": "会东县",
                        "pid": "513400",
                        "index": "513426"
                    },
                    {
                        "value": "宁南县",
                        "label": "宁南县",
                        "pid": "513400",
                        "index": "513427"
                    },
                    {
                        "value": "普格县",
                        "label": "普格县",
                        "pid": "513400",
                        "index": "513428"
                    },
                    {
                        "value": "布拖县",
                        "label": "布拖县",
                        "pid": "513400",
                        "index": "513429"
                    },
                    {
                        "value": "金阳县",
                        "label": "金阳县",
                        "pid": "513400",
                        "index": "513430"
                    },
                    {
                        "value": "昭觉县",
                        "label": "昭觉县",
                        "pid": "513400",
                        "index": "513431"
                    },
                    {
                        "value": "喜德县",
                        "label": "喜德县",
                        "pid": "513400",
                        "index": "513432"
                    },
                    {
                        "value": "冕宁县",
                        "label": "冕宁县",
                        "pid": "513400",
                        "index": "513433"
                    },
                    {
                        "value": "越西县",
                        "label": "越西县",
                        "pid": "513400",
                        "index": "513434"
                    },
                    {
                        "value": "甘洛县",
                        "label": "甘洛县",
                        "pid": "513400",
                        "index": "513435"
                    },
                    {
                        "value": "美姑县",
                        "label": "美姑县",
                        "pid": "513400",
                        "index": "513436"
                    },
                    {
                        "value": "雷波县",
                        "label": "雷波县",
                        "pid": "513400",
                        "index": "513437"
                    }
                ]
            }
        ]
    },
    {
        "value": "贵州省",
        "label": "贵州省",
        "pid": "86",
        "index": "520000",
        "children": [
            {
                "value": "贵阳市",
                "label": "贵阳市",
                "pid": "520000",
                "index": "520100",
                "children": [
                    {
                        "value": "南明区",
                        "label": "南明区",
                        "pid": "520100",
                        "index": "520102"
                    },
                    {
                        "value": "云岩区",
                        "label": "云岩区",
                        "pid": "520100",
                        "index": "520103"
                    },
                    {
                        "value": "花溪区",
                        "label": "花溪区",
                        "pid": "520100",
                        "index": "520111"
                    },
                    {
                        "value": "乌当区",
                        "label": "乌当区",
                        "pid": "520100",
                        "index": "520112"
                    },
                    {
                        "value": "白云区",
                        "label": "白云区",
                        "pid": "520100",
                        "index": "520113"
                    },
                    {
                        "value": "观山湖区",
                        "label": "观山湖区",
                        "pid": "520100",
                        "index": "520115"
                    },
                    {
                        "value": "开阳县",
                        "label": "开阳县",
                        "pid": "520100",
                        "index": "520121"
                    },
                    {
                        "value": "息烽县",
                        "label": "息烽县",
                        "pid": "520100",
                        "index": "520122"
                    },
                    {
                        "value": "修文县",
                        "label": "修文县",
                        "pid": "520100",
                        "index": "520123"
                    },
                    {
                        "value": "清镇市",
                        "label": "清镇市",
                        "pid": "520100",
                        "index": "520181"
                    }
                ]
            },
            {
                "value": "六盘水市",
                "label": "六盘水市",
                "pid": "520000",
                "index": "520200",
                "children": [
                    {
                        "value": "钟山区",
                        "label": "钟山区",
                        "pid": "520200",
                        "index": "520201"
                    },
                    {
                        "value": "六枝特区",
                        "label": "六枝特区",
                        "pid": "520200",
                        "index": "520203"
                    },
                    {
                        "value": "水城县",
                        "label": "水城县",
                        "pid": "520200",
                        "index": "520221"
                    },
                    {
                        "value": "盘州市",
                        "label": "盘州市",
                        "pid": "520200",
                        "index": "520281"
                    }
                ]
            },
            {
                "value": "遵义市",
                "label": "遵义市",
                "pid": "520000",
                "index": "520300",
                "children": [
                    {
                        "value": "红花岗区",
                        "label": "红花岗区",
                        "pid": "520300",
                        "index": "520302"
                    },
                    {
                        "value": "汇川区",
                        "label": "汇川区",
                        "pid": "520300",
                        "index": "520303"
                    },
                    {
                        "value": "播州区",
                        "label": "播州区",
                        "pid": "520300",
                        "index": "520304"
                    },
                    {
                        "value": "桐梓县",
                        "label": "桐梓县",
                        "pid": "520300",
                        "index": "520322"
                    },
                    {
                        "value": "绥阳县",
                        "label": "绥阳县",
                        "pid": "520300",
                        "index": "520323"
                    },
                    {
                        "value": "正安县",
                        "label": "正安县",
                        "pid": "520300",
                        "index": "520324"
                    },
                    {
                        "value": "道真仡佬族苗族自治县",
                        "label": "道真仡佬族苗族自治县",
                        "pid": "520300",
                        "index": "520325"
                    },
                    {
                        "value": "务川仡佬族苗族自治县",
                        "label": "务川仡佬族苗族自治县",
                        "pid": "520300",
                        "index": "520326"
                    },
                    {
                        "value": "凤冈县",
                        "label": "凤冈县",
                        "pid": "520300",
                        "index": "520327"
                    },
                    {
                        "value": "湄潭县",
                        "label": "湄潭县",
                        "pid": "520300",
                        "index": "520328"
                    },
                    {
                        "value": "余庆县",
                        "label": "余庆县",
                        "pid": "520300",
                        "index": "520329"
                    },
                    {
                        "value": "习水县",
                        "label": "习水县",
                        "pid": "520300",
                        "index": "520330"
                    },
                    {
                        "value": "赤水市",
                        "label": "赤水市",
                        "pid": "520300",
                        "index": "520381"
                    },
                    {
                        "value": "仁怀市",
                        "label": "仁怀市",
                        "pid": "520300",
                        "index": "520382"
                    }
                ]
            },
            {
                "value": "安顺市",
                "label": "安顺市",
                "pid": "520000",
                "index": "520400",
                "children": [
                    {
                        "value": "西秀区",
                        "label": "西秀区",
                        "pid": "520400",
                        "index": "520402"
                    },
                    {
                        "value": "平坝区",
                        "label": "平坝区",
                        "pid": "520400",
                        "index": "520403"
                    },
                    {
                        "value": "普定县",
                        "label": "普定县",
                        "pid": "520400",
                        "index": "520422"
                    },
                    {
                        "value": "镇宁布依族苗族自治县",
                        "label": "镇宁布依族苗族自治县",
                        "pid": "520400",
                        "index": "520423"
                    },
                    {
                        "value": "关岭布依族苗族自治县",
                        "label": "关岭布依族苗族自治县",
                        "pid": "520400",
                        "index": "520424"
                    },
                    {
                        "value": "紫云苗族布依族自治县",
                        "label": "紫云苗族布依族自治县",
                        "pid": "520400",
                        "index": "520425"
                    }
                ]
            },
            {
                "value": "毕节市",
                "label": "毕节市",
                "pid": "520000",
                "index": "520500",
                "children": [
                    {
                        "value": "七星关区",
                        "label": "七星关区",
                        "pid": "520500",
                        "index": "520502"
                    },
                    {
                        "value": "大方县",
                        "label": "大方县",
                        "pid": "520500",
                        "index": "520521"
                    },
                    {
                        "value": "黔西县",
                        "label": "黔西县",
                        "pid": "520500",
                        "index": "520522"
                    },
                    {
                        "value": "金沙县",
                        "label": "金沙县",
                        "pid": "520500",
                        "index": "520523"
                    },
                    {
                        "value": "织金县",
                        "label": "织金县",
                        "pid": "520500",
                        "index": "520524"
                    },
                    {
                        "value": "纳雍县",
                        "label": "纳雍县",
                        "pid": "520500",
                        "index": "520525"
                    },
                    {
                        "value": "威宁彝族回族苗族自治县",
                        "label": "威宁彝族回族苗族自治县",
                        "pid": "520500",
                        "index": "520526"
                    },
                    {
                        "value": "赫章县",
                        "label": "赫章县",
                        "pid": "520500",
                        "index": "520527"
                    }
                ]
            },
            {
                "value": "铜仁市",
                "label": "铜仁市",
                "pid": "520000",
                "index": "520600",
                "children": [
                    {
                        "value": "碧江区",
                        "label": "碧江区",
                        "pid": "520600",
                        "index": "520602"
                    },
                    {
                        "value": "万山区",
                        "label": "万山区",
                        "pid": "520600",
                        "index": "520603"
                    },
                    {
                        "value": "江口县",
                        "label": "江口县",
                        "pid": "520600",
                        "index": "520621"
                    },
                    {
                        "value": "玉屏侗族自治县",
                        "label": "玉屏侗族自治县",
                        "pid": "520600",
                        "index": "520622"
                    },
                    {
                        "value": "石阡县",
                        "label": "石阡县",
                        "pid": "520600",
                        "index": "520623"
                    },
                    {
                        "value": "思南县",
                        "label": "思南县",
                        "pid": "520600",
                        "index": "520624"
                    },
                    {
                        "value": "印江土家族苗族自治县",
                        "label": "印江土家族苗族自治县",
                        "pid": "520600",
                        "index": "520625"
                    },
                    {
                        "value": "德江县",
                        "label": "德江县",
                        "pid": "520600",
                        "index": "520626"
                    },
                    {
                        "value": "沿河土家族自治县",
                        "label": "沿河土家族自治县",
                        "pid": "520600",
                        "index": "520627"
                    },
                    {
                        "value": "松桃苗族自治县",
                        "label": "松桃苗族自治县",
                        "pid": "520600",
                        "index": "520628"
                    }
                ]
            },
            {
                "value": "黔西南布依族苗族自治州",
                "label": "黔西南布依族苗族自治州",
                "pid": "520000",
                "index": "522300",
                "children": [
                    {
                        "value": "兴义市",
                        "label": "兴义市",
                        "pid": "522300",
                        "index": "522301"
                    },
                    {
                        "value": "兴仁市",
                        "label": "兴仁市",
                        "pid": "522300",
                        "index": "522302"
                    },
                    {
                        "value": "普安县",
                        "label": "普安县",
                        "pid": "522300",
                        "index": "522323"
                    },
                    {
                        "value": "晴隆县",
                        "label": "晴隆县",
                        "pid": "522300",
                        "index": "522324"
                    },
                    {
                        "value": "贞丰县",
                        "label": "贞丰县",
                        "pid": "522300",
                        "index": "522325"
                    },
                    {
                        "value": "望谟县",
                        "label": "望谟县",
                        "pid": "522300",
                        "index": "522326"
                    },
                    {
                        "value": "册亨县",
                        "label": "册亨县",
                        "pid": "522300",
                        "index": "522327"
                    },
                    {
                        "value": "安龙县",
                        "label": "安龙县",
                        "pid": "522300",
                        "index": "522328"
                    }
                ]
            },
            {
                "value": "黔东南苗族侗族自治州",
                "label": "黔东南苗族侗族自治州",
                "pid": "520000",
                "index": "522600",
                "children": [
                    {
                        "value": "凯里市",
                        "label": "凯里市",
                        "pid": "522600",
                        "index": "522601"
                    },
                    {
                        "value": "黄平县",
                        "label": "黄平县",
                        "pid": "522600",
                        "index": "522622"
                    },
                    {
                        "value": "施秉县",
                        "label": "施秉县",
                        "pid": "522600",
                        "index": "522623"
                    },
                    {
                        "value": "三穗县",
                        "label": "三穗县",
                        "pid": "522600",
                        "index": "522624"
                    },
                    {
                        "value": "镇远县",
                        "label": "镇远县",
                        "pid": "522600",
                        "index": "522625"
                    },
                    {
                        "value": "岑巩县",
                        "label": "岑巩县",
                        "pid": "522600",
                        "index": "522626"
                    },
                    {
                        "value": "天柱县",
                        "label": "天柱县",
                        "pid": "522600",
                        "index": "522627"
                    },
                    {
                        "value": "锦屏县",
                        "label": "锦屏县",
                        "pid": "522600",
                        "index": "522628"
                    },
                    {
                        "value": "剑河县",
                        "label": "剑河县",
                        "pid": "522600",
                        "index": "522629"
                    },
                    {
                        "value": "台江县",
                        "label": "台江县",
                        "pid": "522600",
                        "index": "522630"
                    },
                    {
                        "value": "黎平县",
                        "label": "黎平县",
                        "pid": "522600",
                        "index": "522631"
                    },
                    {
                        "value": "榕江县",
                        "label": "榕江县",
                        "pid": "522600",
                        "index": "522632"
                    },
                    {
                        "value": "从江县",
                        "label": "从江县",
                        "pid": "522600",
                        "index": "522633"
                    },
                    {
                        "value": "雷山县",
                        "label": "雷山县",
                        "pid": "522600",
                        "index": "522634"
                    },
                    {
                        "value": "麻江县",
                        "label": "麻江县",
                        "pid": "522600",
                        "index": "522635"
                    },
                    {
                        "value": "丹寨县",
                        "label": "丹寨县",
                        "pid": "522600",
                        "index": "522636"
                    }
                ]
            },
            {
                "value": "黔南布依族苗族自治州",
                "label": "黔南布依族苗族自治州",
                "pid": "520000",
                "index": "522700",
                "children": [
                    {
                        "value": "都匀市",
                        "label": "都匀市",
                        "pid": "522700",
                        "index": "522701"
                    },
                    {
                        "value": "福泉市",
                        "label": "福泉市",
                        "pid": "522700",
                        "index": "522702"
                    },
                    {
                        "value": "荔波县",
                        "label": "荔波县",
                        "pid": "522700",
                        "index": "522722"
                    },
                    {
                        "value": "贵定县",
                        "label": "贵定县",
                        "pid": "522700",
                        "index": "522723"
                    },
                    {
                        "value": "瓮安县",
                        "label": "瓮安县",
                        "pid": "522700",
                        "index": "522725"
                    },
                    {
                        "value": "独山县",
                        "label": "独山县",
                        "pid": "522700",
                        "index": "522726"
                    },
                    {
                        "value": "平塘县",
                        "label": "平塘县",
                        "pid": "522700",
                        "index": "522727"
                    },
                    {
                        "value": "罗甸县",
                        "label": "罗甸县",
                        "pid": "522700",
                        "index": "522728"
                    },
                    {
                        "value": "长顺县",
                        "label": "长顺县",
                        "pid": "522700",
                        "index": "522729"
                    },
                    {
                        "value": "龙里县",
                        "label": "龙里县",
                        "pid": "522700",
                        "index": "522730"
                    },
                    {
                        "value": "惠水县",
                        "label": "惠水县",
                        "pid": "522700",
                        "index": "522731"
                    },
                    {
                        "value": "三都水族自治县",
                        "label": "三都水族自治县",
                        "pid": "522700",
                        "index": "522732"
                    }
                ]
            }
        ]
    },
    {
        "value": "云南省",
        "label": "云南省",
        "pid": "86",
        "index": "530000",
        "children": [
            {
                "value": "昆明市",
                "label": "昆明市",
                "pid": "530000",
                "index": "530100",
                "children": [
                    {
                        "value": "五华区",
                        "label": "五华区",
                        "pid": "530100",
                        "index": "530102"
                    },
                    {
                        "value": "盘龙区",
                        "label": "盘龙区",
                        "pid": "530100",
                        "index": "530103"
                    },
                    {
                        "value": "官渡区",
                        "label": "官渡区",
                        "pid": "530100",
                        "index": "530111"
                    },
                    {
                        "value": "西山区",
                        "label": "西山区",
                        "pid": "530100",
                        "index": "530112"
                    },
                    {
                        "value": "东川区",
                        "label": "东川区",
                        "pid": "530100",
                        "index": "530113"
                    },
                    {
                        "value": "呈贡区",
                        "label": "呈贡区",
                        "pid": "530100",
                        "index": "530114"
                    },
                    {
                        "value": "晋宁区",
                        "label": "晋宁区",
                        "pid": "530100",
                        "index": "530115"
                    },
                    {
                        "value": "富民县",
                        "label": "富民县",
                        "pid": "530100",
                        "index": "530124"
                    },
                    {
                        "value": "宜良县",
                        "label": "宜良县",
                        "pid": "530100",
                        "index": "530125"
                    },
                    {
                        "value": "石林彝族自治县",
                        "label": "石林彝族自治县",
                        "pid": "530100",
                        "index": "530126"
                    },
                    {
                        "value": "嵩明县",
                        "label": "嵩明县",
                        "pid": "530100",
                        "index": "530127"
                    },
                    {
                        "value": "禄劝彝族苗族自治县",
                        "label": "禄劝彝族苗族自治县",
                        "pid": "530100",
                        "index": "530128"
                    },
                    {
                        "value": "寻甸回族彝族自治县",
                        "label": "寻甸回族彝族自治县",
                        "pid": "530100",
                        "index": "530129"
                    },
                    {
                        "value": "安宁市",
                        "label": "安宁市",
                        "pid": "530100",
                        "index": "530181"
                    }
                ]
            },
            {
                "value": "曲靖市",
                "label": "曲靖市",
                "pid": "530000",
                "index": "530300",
                "children": [
                    {
                        "value": "麒麟区",
                        "label": "麒麟区",
                        "pid": "530300",
                        "index": "530302"
                    },
                    {
                        "value": "沾益区",
                        "label": "沾益区",
                        "pid": "530300",
                        "index": "530303"
                    },
                    {
                        "value": "马龙区",
                        "label": "马龙区",
                        "pid": "530300",
                        "index": "530304"
                    },
                    {
                        "value": "陆良县",
                        "label": "陆良县",
                        "pid": "530300",
                        "index": "530322"
                    },
                    {
                        "value": "师宗县",
                        "label": "师宗县",
                        "pid": "530300",
                        "index": "530323"
                    },
                    {
                        "value": "罗平县",
                        "label": "罗平县",
                        "pid": "530300",
                        "index": "530324"
                    },
                    {
                        "value": "富源县",
                        "label": "富源县",
                        "pid": "530300",
                        "index": "530325"
                    },
                    {
                        "value": "会泽县",
                        "label": "会泽县",
                        "pid": "530300",
                        "index": "530326"
                    },
                    {
                        "value": "宣威市",
                        "label": "宣威市",
                        "pid": "530300",
                        "index": "530381"
                    }
                ]
            },
            {
                "value": "玉溪市",
                "label": "玉溪市",
                "pid": "530000",
                "index": "530400",
                "children": [
                    {
                        "value": "红塔区",
                        "label": "红塔区",
                        "pid": "530400",
                        "index": "530402"
                    },
                    {
                        "value": "江川区",
                        "label": "江川区",
                        "pid": "530400",
                        "index": "530403"
                    },
                    {
                        "value": "澄江县",
                        "label": "澄江县",
                        "pid": "530400",
                        "index": "530422"
                    },
                    {
                        "value": "通海县",
                        "label": "通海县",
                        "pid": "530400",
                        "index": "530423"
                    },
                    {
                        "value": "华宁县",
                        "label": "华宁县",
                        "pid": "530400",
                        "index": "530424"
                    },
                    {
                        "value": "易门县",
                        "label": "易门县",
                        "pid": "530400",
                        "index": "530425"
                    },
                    {
                        "value": "峨山彝族自治县",
                        "label": "峨山彝族自治县",
                        "pid": "530400",
                        "index": "530426"
                    },
                    {
                        "value": "新平彝族傣族自治县",
                        "label": "新平彝族傣族自治县",
                        "pid": "530400",
                        "index": "530427"
                    },
                    {
                        "value": "元江哈尼族彝族傣族自治县",
                        "label": "元江哈尼族彝族傣族自治县",
                        "pid": "530400",
                        "index": "530428"
                    }
                ]
            },
            {
                "value": "保山市",
                "label": "保山市",
                "pid": "530000",
                "index": "530500",
                "children": [
                    {
                        "value": "隆阳区",
                        "label": "隆阳区",
                        "pid": "530500",
                        "index": "530502"
                    },
                    {
                        "value": "施甸县",
                        "label": "施甸县",
                        "pid": "530500",
                        "index": "530521"
                    },
                    {
                        "value": "龙陵县",
                        "label": "龙陵县",
                        "pid": "530500",
                        "index": "530523"
                    },
                    {
                        "value": "昌宁县",
                        "label": "昌宁县",
                        "pid": "530500",
                        "index": "530524"
                    },
                    {
                        "value": "腾冲市",
                        "label": "腾冲市",
                        "pid": "530500",
                        "index": "530581"
                    }
                ]
            },
            {
                "value": "昭通市",
                "label": "昭通市",
                "pid": "530000",
                "index": "530600",
                "children": [
                    {
                        "value": "昭阳区",
                        "label": "昭阳区",
                        "pid": "530600",
                        "index": "530602"
                    },
                    {
                        "value": "鲁甸县",
                        "label": "鲁甸县",
                        "pid": "530600",
                        "index": "530621"
                    },
                    {
                        "value": "巧家县",
                        "label": "巧家县",
                        "pid": "530600",
                        "index": "530622"
                    },
                    {
                        "value": "盐津县",
                        "label": "盐津县",
                        "pid": "530600",
                        "index": "530623"
                    },
                    {
                        "value": "大关县",
                        "label": "大关县",
                        "pid": "530600",
                        "index": "530624"
                    },
                    {
                        "value": "永善县",
                        "label": "永善县",
                        "pid": "530600",
                        "index": "530625"
                    },
                    {
                        "value": "绥江县",
                        "label": "绥江县",
                        "pid": "530600",
                        "index": "530626"
                    },
                    {
                        "value": "镇雄县",
                        "label": "镇雄县",
                        "pid": "530600",
                        "index": "530627"
                    },
                    {
                        "value": "彝良县",
                        "label": "彝良县",
                        "pid": "530600",
                        "index": "530628"
                    },
                    {
                        "value": "威信县",
                        "label": "威信县",
                        "pid": "530600",
                        "index": "530629"
                    },
                    {
                        "value": "水富市",
                        "label": "水富市",
                        "pid": "530600",
                        "index": "530681"
                    }
                ]
            },
            {
                "value": "丽江市",
                "label": "丽江市",
                "pid": "530000",
                "index": "530700",
                "children": [
                    {
                        "value": "古城区",
                        "label": "古城区",
                        "pid": "530700",
                        "index": "530702"
                    },
                    {
                        "value": "玉龙纳西族自治县",
                        "label": "玉龙纳西族自治县",
                        "pid": "530700",
                        "index": "530721"
                    },
                    {
                        "value": "永胜县",
                        "label": "永胜县",
                        "pid": "530700",
                        "index": "530722"
                    },
                    {
                        "value": "华坪县",
                        "label": "华坪县",
                        "pid": "530700",
                        "index": "530723"
                    },
                    {
                        "value": "宁蒗彝族自治县",
                        "label": "宁蒗彝族自治县",
                        "pid": "530700",
                        "index": "530724"
                    }
                ]
            },
            {
                "value": "普洱市",
                "label": "普洱市",
                "pid": "530000",
                "index": "530800",
                "children": [
                    {
                        "value": "思茅区",
                        "label": "思茅区",
                        "pid": "530800",
                        "index": "530802"
                    },
                    {
                        "value": "宁洱哈尼族彝族自治县",
                        "label": "宁洱哈尼族彝族自治县",
                        "pid": "530800",
                        "index": "530821"
                    },
                    {
                        "value": "墨江哈尼族自治县",
                        "label": "墨江哈尼族自治县",
                        "pid": "530800",
                        "index": "530822"
                    },
                    {
                        "value": "景东彝族自治县",
                        "label": "景东彝族自治县",
                        "pid": "530800",
                        "index": "530823"
                    },
                    {
                        "value": "景谷傣族彝族自治县",
                        "label": "景谷傣族彝族自治县",
                        "pid": "530800",
                        "index": "530824"
                    },
                    {
                        "value": "镇沅彝族哈尼族拉祜族自治县",
                        "label": "镇沅彝族哈尼族拉祜族自治县",
                        "pid": "530800",
                        "index": "530825"
                    },
                    {
                        "value": "江城哈尼族彝族自治县",
                        "label": "江城哈尼族彝族自治县",
                        "pid": "530800",
                        "index": "530826"
                    },
                    {
                        "value": "孟连傣族拉祜族佤族自治县",
                        "label": "孟连傣族拉祜族佤族自治县",
                        "pid": "530800",
                        "index": "530827"
                    },
                    {
                        "value": "澜沧拉祜族自治县",
                        "label": "澜沧拉祜族自治县",
                        "pid": "530800",
                        "index": "530828"
                    },
                    {
                        "value": "西盟佤族自治县",
                        "label": "西盟佤族自治县",
                        "pid": "530800",
                        "index": "530829"
                    }
                ]
            },
            {
                "value": "临沧市",
                "label": "临沧市",
                "pid": "530000",
                "index": "530900",
                "children": [
                    {
                        "value": "临翔区",
                        "label": "临翔区",
                        "pid": "530900",
                        "index": "530902"
                    },
                    {
                        "value": "凤庆县",
                        "label": "凤庆县",
                        "pid": "530900",
                        "index": "530921"
                    },
                    {
                        "value": "云县",
                        "label": "云县",
                        "pid": "530900",
                        "index": "530922"
                    },
                    {
                        "value": "永德县",
                        "label": "永德县",
                        "pid": "530900",
                        "index": "530923"
                    },
                    {
                        "value": "镇康县",
                        "label": "镇康县",
                        "pid": "530900",
                        "index": "530924"
                    },
                    {
                        "value": "双江拉祜族佤族布朗族傣族自治县",
                        "label": "双江拉祜族佤族布朗族傣族自治县",
                        "pid": "530900",
                        "index": "530925"
                    },
                    {
                        "value": "耿马傣族佤族自治县",
                        "label": "耿马傣族佤族自治县",
                        "pid": "530900",
                        "index": "530926"
                    },
                    {
                        "value": "沧源佤族自治县",
                        "label": "沧源佤族自治县",
                        "pid": "530900",
                        "index": "530927"
                    }
                ]
            },
            {
                "value": "楚雄彝族自治州",
                "label": "楚雄彝族自治州",
                "pid": "530000",
                "index": "532300",
                "children": [
                    {
                        "value": "楚雄市",
                        "label": "楚雄市",
                        "pid": "532300",
                        "index": "532301"
                    },
                    {
                        "value": "双柏县",
                        "label": "双柏县",
                        "pid": "532300",
                        "index": "532322"
                    },
                    {
                        "value": "牟定县",
                        "label": "牟定县",
                        "pid": "532300",
                        "index": "532323"
                    },
                    {
                        "value": "南华县",
                        "label": "南华县",
                        "pid": "532300",
                        "index": "532324"
                    },
                    {
                        "value": "姚安县",
                        "label": "姚安县",
                        "pid": "532300",
                        "index": "532325"
                    },
                    {
                        "value": "大姚县",
                        "label": "大姚县",
                        "pid": "532300",
                        "index": "532326"
                    },
                    {
                        "value": "永仁县",
                        "label": "永仁县",
                        "pid": "532300",
                        "index": "532327"
                    },
                    {
                        "value": "元谋县",
                        "label": "元谋县",
                        "pid": "532300",
                        "index": "532328"
                    },
                    {
                        "value": "武定县",
                        "label": "武定县",
                        "pid": "532300",
                        "index": "532329"
                    },
                    {
                        "value": "禄丰县",
                        "label": "禄丰县",
                        "pid": "532300",
                        "index": "532331"
                    }
                ]
            },
            {
                "value": "红河哈尼族彝族自治州",
                "label": "红河哈尼族彝族自治州",
                "pid": "530000",
                "index": "532500",
                "children": [
                    {
                        "value": "个旧市",
                        "label": "个旧市",
                        "pid": "532500",
                        "index": "532501"
                    },
                    {
                        "value": "开远市",
                        "label": "开远市",
                        "pid": "532500",
                        "index": "532502"
                    },
                    {
                        "value": "蒙自市",
                        "label": "蒙自市",
                        "pid": "532500",
                        "index": "532503"
                    },
                    {
                        "value": "弥勒市",
                        "label": "弥勒市",
                        "pid": "532500",
                        "index": "532504"
                    },
                    {
                        "value": "屏边苗族自治县",
                        "label": "屏边苗族自治县",
                        "pid": "532500",
                        "index": "532523"
                    },
                    {
                        "value": "建水县",
                        "label": "建水县",
                        "pid": "532500",
                        "index": "532524"
                    },
                    {
                        "value": "石屏县",
                        "label": "石屏县",
                        "pid": "532500",
                        "index": "532525"
                    },
                    {
                        "value": "泸西县",
                        "label": "泸西县",
                        "pid": "532500",
                        "index": "532527"
                    },
                    {
                        "value": "元阳县",
                        "label": "元阳县",
                        "pid": "532500",
                        "index": "532528"
                    },
                    {
                        "value": "红河县",
                        "label": "红河县",
                        "pid": "532500",
                        "index": "532529"
                    },
                    {
                        "value": "金平苗族瑶族傣族自治县",
                        "label": "金平苗族瑶族傣族自治县",
                        "pid": "532500",
                        "index": "532530"
                    },
                    {
                        "value": "绿春县",
                        "label": "绿春县",
                        "pid": "532500",
                        "index": "532531"
                    },
                    {
                        "value": "河口瑶族自治县",
                        "label": "河口瑶族自治县",
                        "pid": "532500",
                        "index": "532532"
                    }
                ]
            },
            {
                "value": "文山壮族苗族自治州",
                "label": "文山壮族苗族自治州",
                "pid": "530000",
                "index": "532600",
                "children": [
                    {
                        "value": "文山市",
                        "label": "文山市",
                        "pid": "532600",
                        "index": "532601"
                    },
                    {
                        "value": "砚山县",
                        "label": "砚山县",
                        "pid": "532600",
                        "index": "532622"
                    },
                    {
                        "value": "西畴县",
                        "label": "西畴县",
                        "pid": "532600",
                        "index": "532623"
                    },
                    {
                        "value": "麻栗坡县",
                        "label": "麻栗坡县",
                        "pid": "532600",
                        "index": "532624"
                    },
                    {
                        "value": "马关县",
                        "label": "马关县",
                        "pid": "532600",
                        "index": "532625"
                    },
                    {
                        "value": "丘北县",
                        "label": "丘北县",
                        "pid": "532600",
                        "index": "532626"
                    },
                    {
                        "value": "广南县",
                        "label": "广南县",
                        "pid": "532600",
                        "index": "532627"
                    },
                    {
                        "value": "富宁县",
                        "label": "富宁县",
                        "pid": "532600",
                        "index": "532628"
                    }
                ]
            },
            {
                "value": "西双版纳傣族自治州",
                "label": "西双版纳傣族自治州",
                "pid": "530000",
                "index": "532800",
                "children": [
                    {
                        "value": "景洪市",
                        "label": "景洪市",
                        "pid": "532800",
                        "index": "532801"
                    },
                    {
                        "value": "勐海县",
                        "label": "勐海县",
                        "pid": "532800",
                        "index": "532822"
                    },
                    {
                        "value": "勐腊县",
                        "label": "勐腊县",
                        "pid": "532800",
                        "index": "532823"
                    }
                ]
            },
            {
                "value": "大理白族自治州",
                "label": "大理白族自治州",
                "pid": "530000",
                "index": "532900",
                "children": [
                    {
                        "value": "大理市",
                        "label": "大理市",
                        "pid": "532900",
                        "index": "532901"
                    },
                    {
                        "value": "漾濞彝族自治县",
                        "label": "漾濞彝族自治县",
                        "pid": "532900",
                        "index": "532922"
                    },
                    {
                        "value": "祥云县",
                        "label": "祥云县",
                        "pid": "532900",
                        "index": "532923"
                    },
                    {
                        "value": "宾川县",
                        "label": "宾川县",
                        "pid": "532900",
                        "index": "532924"
                    },
                    {
                        "value": "弥渡县",
                        "label": "弥渡县",
                        "pid": "532900",
                        "index": "532925"
                    },
                    {
                        "value": "南涧彝族自治县",
                        "label": "南涧彝族自治县",
                        "pid": "532900",
                        "index": "532926"
                    },
                    {
                        "value": "巍山彝族回族自治县",
                        "label": "巍山彝族回族自治县",
                        "pid": "532900",
                        "index": "532927"
                    },
                    {
                        "value": "永平县",
                        "label": "永平县",
                        "pid": "532900",
                        "index": "532928"
                    },
                    {
                        "value": "云龙县",
                        "label": "云龙县",
                        "pid": "532900",
                        "index": "532929"
                    },
                    {
                        "value": "洱源县",
                        "label": "洱源县",
                        "pid": "532900",
                        "index": "532930"
                    },
                    {
                        "value": "剑川县",
                        "label": "剑川县",
                        "pid": "532900",
                        "index": "532931"
                    },
                    {
                        "value": "鹤庆县",
                        "label": "鹤庆县",
                        "pid": "532900",
                        "index": "532932"
                    }
                ]
            },
            {
                "value": "德宏傣族景颇族自治州",
                "label": "德宏傣族景颇族自治州",
                "pid": "530000",
                "index": "533100",
                "children": [
                    {
                        "value": "瑞丽市",
                        "label": "瑞丽市",
                        "pid": "533100",
                        "index": "533102"
                    },
                    {
                        "value": "芒市",
                        "label": "芒市",
                        "pid": "533100",
                        "index": "533103"
                    },
                    {
                        "value": "梁河县",
                        "label": "梁河县",
                        "pid": "533100",
                        "index": "533122"
                    },
                    {
                        "value": "盈江县",
                        "label": "盈江县",
                        "pid": "533100",
                        "index": "533123"
                    },
                    {
                        "value": "陇川县",
                        "label": "陇川县",
                        "pid": "533100",
                        "index": "533124"
                    }
                ]
            },
            {
                "value": "怒江傈僳族自治州",
                "label": "怒江傈僳族自治州",
                "pid": "530000",
                "index": "533300",
                "children": [
                    {
                        "value": "泸水市",
                        "label": "泸水市",
                        "pid": "533300",
                        "index": "533301"
                    },
                    {
                        "value": "福贡县",
                        "label": "福贡县",
                        "pid": "533300",
                        "index": "533323"
                    },
                    {
                        "value": "贡山独龙族怒族自治县",
                        "label": "贡山独龙族怒族自治县",
                        "pid": "533300",
                        "index": "533324"
                    },
                    {
                        "value": "兰坪白族普米族自治县",
                        "label": "兰坪白族普米族自治县",
                        "pid": "533300",
                        "index": "533325"
                    }
                ]
            },
            {
                "value": "迪庆藏族自治州",
                "label": "迪庆藏族自治州",
                "pid": "530000",
                "index": "533400",
                "children": [
                    {
                        "value": "香格里拉市",
                        "label": "香格里拉市",
                        "pid": "533400",
                        "index": "533401"
                    },
                    {
                        "value": "德钦县",
                        "label": "德钦县",
                        "pid": "533400",
                        "index": "533422"
                    },
                    {
                        "value": "维西傈僳族自治县",
                        "label": "维西傈僳族自治县",
                        "pid": "533400",
                        "index": "533423"
                    }
                ]
            }
        ]
    },
    {
        "value": "西藏自治区",
        "label": "西藏自治区",
        "pid": "86",
        "index": "540000",
        "children": [
            {
                "value": "拉萨市",
                "label": "拉萨市",
                "pid": "540000",
                "index": "540100",
                "children": [
                    {
                        "value": "城关区",
                        "label": "城关区",
                        "pid": "540100",
                        "index": "540102"
                    },
                    {
                        "value": "堆龙德庆区",
                        "label": "堆龙德庆区",
                        "pid": "540100",
                        "index": "540103"
                    },
                    {
                        "value": "达孜区",
                        "label": "达孜区",
                        "pid": "540100",
                        "index": "540104"
                    },
                    {
                        "value": "林周县",
                        "label": "林周县",
                        "pid": "540100",
                        "index": "540121"
                    },
                    {
                        "value": "当雄县",
                        "label": "当雄县",
                        "pid": "540100",
                        "index": "540122"
                    },
                    {
                        "value": "尼木县",
                        "label": "尼木县",
                        "pid": "540100",
                        "index": "540123"
                    },
                    {
                        "value": "曲水县",
                        "label": "曲水县",
                        "pid": "540100",
                        "index": "540124"
                    },
                    {
                        "value": "墨竹工卡县",
                        "label": "墨竹工卡县",
                        "pid": "540100",
                        "index": "540127"
                    },
                    {
                        "value": "格尔木藏青工业园区",
                        "label": "格尔木藏青工业园区",
                        "pid": "540100",
                        "index": "540171"
                    },
                    {
                        "value": "拉萨经济技术开发区",
                        "label": "拉萨经济技术开发区",
                        "pid": "540100",
                        "index": "540172"
                    },
                    {
                        "value": "西藏文化旅游创意园区",
                        "label": "西藏文化旅游创意园区",
                        "pid": "540100",
                        "index": "540173"
                    },
                    {
                        "value": "达孜工业园区",
                        "label": "达孜工业园区",
                        "pid": "540100",
                        "index": "540174"
                    }
                ]
            },
            {
                "value": "日喀则市",
                "label": "日喀则市",
                "pid": "540000",
                "index": "540200",
                "children": [
                    {
                        "value": "桑珠孜区",
                        "label": "桑珠孜区",
                        "pid": "540200",
                        "index": "540202"
                    },
                    {
                        "value": "南木林县",
                        "label": "南木林县",
                        "pid": "540200",
                        "index": "540221"
                    },
                    {
                        "value": "江孜县",
                        "label": "江孜县",
                        "pid": "540200",
                        "index": "540222"
                    },
                    {
                        "value": "定日县",
                        "label": "定日县",
                        "pid": "540200",
                        "index": "540223"
                    },
                    {
                        "value": "萨迦县",
                        "label": "萨迦县",
                        "pid": "540200",
                        "index": "540224"
                    },
                    {
                        "value": "拉孜县",
                        "label": "拉孜县",
                        "pid": "540200",
                        "index": "540225"
                    },
                    {
                        "value": "昂仁县",
                        "label": "昂仁县",
                        "pid": "540200",
                        "index": "540226"
                    },
                    {
                        "value": "谢通门县",
                        "label": "谢通门县",
                        "pid": "540200",
                        "index": "540227"
                    },
                    {
                        "value": "白朗县",
                        "label": "白朗县",
                        "pid": "540200",
                        "index": "540228"
                    },
                    {
                        "value": "仁布县",
                        "label": "仁布县",
                        "pid": "540200",
                        "index": "540229"
                    },
                    {
                        "value": "康马县",
                        "label": "康马县",
                        "pid": "540200",
                        "index": "540230"
                    },
                    {
                        "value": "定结县",
                        "label": "定结县",
                        "pid": "540200",
                        "index": "540231"
                    },
                    {
                        "value": "仲巴县",
                        "label": "仲巴县",
                        "pid": "540200",
                        "index": "540232"
                    },
                    {
                        "value": "亚东县",
                        "label": "亚东县",
                        "pid": "540200",
                        "index": "540233"
                    },
                    {
                        "value": "吉隆县",
                        "label": "吉隆县",
                        "pid": "540200",
                        "index": "540234"
                    },
                    {
                        "value": "聂拉木县",
                        "label": "聂拉木县",
                        "pid": "540200",
                        "index": "540235"
                    },
                    {
                        "value": "萨嘎县",
                        "label": "萨嘎县",
                        "pid": "540200",
                        "index": "540236"
                    },
                    {
                        "value": "岗巴县",
                        "label": "岗巴县",
                        "pid": "540200",
                        "index": "540237"
                    }
                ]
            },
            {
                "value": "昌都市",
                "label": "昌都市",
                "pid": "540000",
                "index": "540300",
                "children": [
                    {
                        "value": "卡若区",
                        "label": "卡若区",
                        "pid": "540300",
                        "index": "540302"
                    },
                    {
                        "value": "江达县",
                        "label": "江达县",
                        "pid": "540300",
                        "index": "540321"
                    },
                    {
                        "value": "贡觉县",
                        "label": "贡觉县",
                        "pid": "540300",
                        "index": "540322"
                    },
                    {
                        "value": "类乌齐县",
                        "label": "类乌齐县",
                        "pid": "540300",
                        "index": "540323"
                    },
                    {
                        "value": "丁青县",
                        "label": "丁青县",
                        "pid": "540300",
                        "index": "540324"
                    },
                    {
                        "value": "察雅县",
                        "label": "察雅县",
                        "pid": "540300",
                        "index": "540325"
                    },
                    {
                        "value": "八宿县",
                        "label": "八宿县",
                        "pid": "540300",
                        "index": "540326"
                    },
                    {
                        "value": "左贡县",
                        "label": "左贡县",
                        "pid": "540300",
                        "index": "540327"
                    },
                    {
                        "value": "芒康县",
                        "label": "芒康县",
                        "pid": "540300",
                        "index": "540328"
                    },
                    {
                        "value": "洛隆县",
                        "label": "洛隆县",
                        "pid": "540300",
                        "index": "540329"
                    },
                    {
                        "value": "边坝县",
                        "label": "边坝县",
                        "pid": "540300",
                        "index": "540330"
                    }
                ]
            },
            {
                "value": "林芝市",
                "label": "林芝市",
                "pid": "540000",
                "index": "540400",
                "children": [
                    {
                        "value": "巴宜区",
                        "label": "巴宜区",
                        "pid": "540400",
                        "index": "540402"
                    },
                    {
                        "value": "工布江达县",
                        "label": "工布江达县",
                        "pid": "540400",
                        "index": "540421"
                    },
                    {
                        "value": "米林县",
                        "label": "米林县",
                        "pid": "540400",
                        "index": "540422"
                    },
                    {
                        "value": "墨脱县",
                        "label": "墨脱县",
                        "pid": "540400",
                        "index": "540423"
                    },
                    {
                        "value": "波密县",
                        "label": "波密县",
                        "pid": "540400",
                        "index": "540424"
                    },
                    {
                        "value": "察隅县",
                        "label": "察隅县",
                        "pid": "540400",
                        "index": "540425"
                    },
                    {
                        "value": "朗县",
                        "label": "朗县",
                        "pid": "540400",
                        "index": "540426"
                    }
                ]
            },
            {
                "value": "山南市",
                "label": "山南市",
                "pid": "540000",
                "index": "540500",
                "children": [
                    {
                        "value": "乃东区",
                        "label": "乃东区",
                        "pid": "540500",
                        "index": "540502"
                    },
                    {
                        "value": "扎囊县",
                        "label": "扎囊县",
                        "pid": "540500",
                        "index": "540521"
                    },
                    {
                        "value": "贡嘎县",
                        "label": "贡嘎县",
                        "pid": "540500",
                        "index": "540522"
                    },
                    {
                        "value": "桑日县",
                        "label": "桑日县",
                        "pid": "540500",
                        "index": "540523"
                    },
                    {
                        "value": "琼结县",
                        "label": "琼结县",
                        "pid": "540500",
                        "index": "540524"
                    },
                    {
                        "value": "曲松县",
                        "label": "曲松县",
                        "pid": "540500",
                        "index": "540525"
                    },
                    {
                        "value": "措美县",
                        "label": "措美县",
                        "pid": "540500",
                        "index": "540526"
                    },
                    {
                        "value": "洛扎县",
                        "label": "洛扎县",
                        "pid": "540500",
                        "index": "540527"
                    },
                    {
                        "value": "加查县",
                        "label": "加查县",
                        "pid": "540500",
                        "index": "540528"
                    },
                    {
                        "value": "隆子县",
                        "label": "隆子县",
                        "pid": "540500",
                        "index": "540529"
                    },
                    {
                        "value": "错那县",
                        "label": "错那县",
                        "pid": "540500",
                        "index": "540530"
                    },
                    {
                        "value": "浪卡子县",
                        "label": "浪卡子县",
                        "pid": "540500",
                        "index": "540531"
                    }
                ]
            },
            {
                "value": "那曲市",
                "label": "那曲市",
                "pid": "540000",
                "index": "540600",
                "children": [
                    {
                        "value": "色尼区",
                        "label": "色尼区",
                        "pid": "540600",
                        "index": "540602"
                    },
                    {
                        "value": "嘉黎县",
                        "label": "嘉黎县",
                        "pid": "540600",
                        "index": "540621"
                    },
                    {
                        "value": "比如县",
                        "label": "比如县",
                        "pid": "540600",
                        "index": "540622"
                    },
                    {
                        "value": "聂荣县",
                        "label": "聂荣县",
                        "pid": "540600",
                        "index": "540623"
                    },
                    {
                        "value": "安多县",
                        "label": "安多县",
                        "pid": "540600",
                        "index": "540624"
                    },
                    {
                        "value": "申扎县",
                        "label": "申扎县",
                        "pid": "540600",
                        "index": "540625"
                    },
                    {
                        "value": "索县",
                        "label": "索县",
                        "pid": "540600",
                        "index": "540626"
                    },
                    {
                        "value": "班戈县",
                        "label": "班戈县",
                        "pid": "540600",
                        "index": "540627"
                    },
                    {
                        "value": "巴青县",
                        "label": "巴青县",
                        "pid": "540600",
                        "index": "540628"
                    },
                    {
                        "value": "尼玛县",
                        "label": "尼玛县",
                        "pid": "540600",
                        "index": "540629"
                    },
                    {
                        "value": "双湖县",
                        "label": "双湖县",
                        "pid": "540600",
                        "index": "540630"
                    }
                ]
            },
            {
                "value": "阿里地区",
                "label": "阿里地区",
                "pid": "540000",
                "index": "542500",
                "children": [
                    {
                        "value": "普兰县",
                        "label": "普兰县",
                        "pid": "542500",
                        "index": "542521"
                    },
                    {
                        "value": "札达县",
                        "label": "札达县",
                        "pid": "542500",
                        "index": "542522"
                    },
                    {
                        "value": "噶尔县",
                        "label": "噶尔县",
                        "pid": "542500",
                        "index": "542523"
                    },
                    {
                        "value": "日土县",
                        "label": "日土县",
                        "pid": "542500",
                        "index": "542524"
                    },
                    {
                        "value": "革吉县",
                        "label": "革吉县",
                        "pid": "542500",
                        "index": "542525"
                    },
                    {
                        "value": "改则县",
                        "label": "改则县",
                        "pid": "542500",
                        "index": "542526"
                    },
                    {
                        "value": "措勤县",
                        "label": "措勤县",
                        "pid": "542500",
                        "index": "542527"
                    }
                ]
            }
        ]
    },
    {
        "value": "陕西省",
        "label": "陕西省",
        "pid": "86",
        "index": "610000",
        "children": [
            {
                "value": "西安市",
                "label": "西安市",
                "pid": "610000",
                "index": "610100",
                "children": [
                    {
                        "value": "新城区",
                        "label": "新城区",
                        "pid": "610100",
                        "index": "610102"
                    },
                    {
                        "value": "碑林区",
                        "label": "碑林区",
                        "pid": "610100",
                        "index": "610103"
                    },
                    {
                        "value": "莲湖区",
                        "label": "莲湖区",
                        "pid": "610100",
                        "index": "610104"
                    },
                    {
                        "value": "灞桥区",
                        "label": "灞桥区",
                        "pid": "610100",
                        "index": "610111"
                    },
                    {
                        "value": "未央区",
                        "label": "未央区",
                        "pid": "610100",
                        "index": "610112"
                    },
                    {
                        "value": "雁塔区",
                        "label": "雁塔区",
                        "pid": "610100",
                        "index": "610113"
                    },
                    {
                        "value": "阎良区",
                        "label": "阎良区",
                        "pid": "610100",
                        "index": "610114"
                    },
                    {
                        "value": "临潼区",
                        "label": "临潼区",
                        "pid": "610100",
                        "index": "610115"
                    },
                    {
                        "value": "长安区",
                        "label": "长安区",
                        "pid": "610100",
                        "index": "610116"
                    },
                    {
                        "value": "高陵区",
                        "label": "高陵区",
                        "pid": "610100",
                        "index": "610117"
                    },
                    {
                        "value": "鄠邑区",
                        "label": "鄠邑区",
                        "pid": "610100",
                        "index": "610118"
                    },
                    {
                        "value": "蓝田县",
                        "label": "蓝田县",
                        "pid": "610100",
                        "index": "610122"
                    },
                    {
                        "value": "周至县",
                        "label": "周至县",
                        "pid": "610100",
                        "index": "610124"
                    }
                ]
            },
            {
                "value": "铜川市",
                "label": "铜川市",
                "pid": "610000",
                "index": "610200",
                "children": [
                    {
                        "value": "王益区",
                        "label": "王益区",
                        "pid": "610200",
                        "index": "610202"
                    },
                    {
                        "value": "印台区",
                        "label": "印台区",
                        "pid": "610200",
                        "index": "610203"
                    },
                    {
                        "value": "耀州区",
                        "label": "耀州区",
                        "pid": "610200",
                        "index": "610204"
                    },
                    {
                        "value": "宜君县",
                        "label": "宜君县",
                        "pid": "610200",
                        "index": "610222"
                    }
                ]
            },
            {
                "value": "宝鸡市",
                "label": "宝鸡市",
                "pid": "610000",
                "index": "610300",
                "children": [
                    {
                        "value": "渭滨区",
                        "label": "渭滨区",
                        "pid": "610300",
                        "index": "610302"
                    },
                    {
                        "value": "金台区",
                        "label": "金台区",
                        "pid": "610300",
                        "index": "610303"
                    },
                    {
                        "value": "陈仓区",
                        "label": "陈仓区",
                        "pid": "610300",
                        "index": "610304"
                    },
                    {
                        "value": "凤翔县",
                        "label": "凤翔县",
                        "pid": "610300",
                        "index": "610322"
                    },
                    {
                        "value": "岐山县",
                        "label": "岐山县",
                        "pid": "610300",
                        "index": "610323"
                    },
                    {
                        "value": "扶风县",
                        "label": "扶风县",
                        "pid": "610300",
                        "index": "610324"
                    },
                    {
                        "value": "眉县",
                        "label": "眉县",
                        "pid": "610300",
                        "index": "610326"
                    },
                    {
                        "value": "陇县",
                        "label": "陇县",
                        "pid": "610300",
                        "index": "610327"
                    },
                    {
                        "value": "千阳县",
                        "label": "千阳县",
                        "pid": "610300",
                        "index": "610328"
                    },
                    {
                        "value": "麟游县",
                        "label": "麟游县",
                        "pid": "610300",
                        "index": "610329"
                    },
                    {
                        "value": "凤县",
                        "label": "凤县",
                        "pid": "610300",
                        "index": "610330"
                    },
                    {
                        "value": "太白县",
                        "label": "太白县",
                        "pid": "610300",
                        "index": "610331"
                    }
                ]
            },
            {
                "value": "咸阳市",
                "label": "咸阳市",
                "pid": "610000",
                "index": "610400",
                "children": [
                    {
                        "value": "秦都区",
                        "label": "秦都区",
                        "pid": "610400",
                        "index": "610402"
                    },
                    {
                        "value": "杨陵区",
                        "label": "杨陵区",
                        "pid": "610400",
                        "index": "610403"
                    },
                    {
                        "value": "渭城区",
                        "label": "渭城区",
                        "pid": "610400",
                        "index": "610404"
                    },
                    {
                        "value": "三原县",
                        "label": "三原县",
                        "pid": "610400",
                        "index": "610422"
                    },
                    {
                        "value": "泾阳县",
                        "label": "泾阳县",
                        "pid": "610400",
                        "index": "610423"
                    },
                    {
                        "value": "乾县",
                        "label": "乾县",
                        "pid": "610400",
                        "index": "610424"
                    },
                    {
                        "value": "礼泉县",
                        "label": "礼泉县",
                        "pid": "610400",
                        "index": "610425"
                    },
                    {
                        "value": "永寿县",
                        "label": "永寿县",
                        "pid": "610400",
                        "index": "610426"
                    },
                    {
                        "value": "长武县",
                        "label": "长武县",
                        "pid": "610400",
                        "index": "610428"
                    },
                    {
                        "value": "旬邑县",
                        "label": "旬邑县",
                        "pid": "610400",
                        "index": "610429"
                    },
                    {
                        "value": "淳化县",
                        "label": "淳化县",
                        "pid": "610400",
                        "index": "610430"
                    },
                    {
                        "value": "武功县",
                        "label": "武功县",
                        "pid": "610400",
                        "index": "610431"
                    },
                    {
                        "value": "兴平市",
                        "label": "兴平市",
                        "pid": "610400",
                        "index": "610481"
                    },
                    {
                        "value": "彬州市",
                        "label": "彬州市",
                        "pid": "610400",
                        "index": "610482"
                    }
                ]
            },
            {
                "value": "渭南市",
                "label": "渭南市",
                "pid": "610000",
                "index": "610500",
                "children": [
                    {
                        "value": "临渭区",
                        "label": "临渭区",
                        "pid": "610500",
                        "index": "610502"
                    },
                    {
                        "value": "华州区",
                        "label": "华州区",
                        "pid": "610500",
                        "index": "610503"
                    },
                    {
                        "value": "潼关县",
                        "label": "潼关县",
                        "pid": "610500",
                        "index": "610522"
                    },
                    {
                        "value": "大荔县",
                        "label": "大荔县",
                        "pid": "610500",
                        "index": "610523"
                    },
                    {
                        "value": "合阳县",
                        "label": "合阳县",
                        "pid": "610500",
                        "index": "610524"
                    },
                    {
                        "value": "澄城县",
                        "label": "澄城县",
                        "pid": "610500",
                        "index": "610525"
                    },
                    {
                        "value": "蒲城县",
                        "label": "蒲城县",
                        "pid": "610500",
                        "index": "610526"
                    },
                    {
                        "value": "白水县",
                        "label": "白水县",
                        "pid": "610500",
                        "index": "610527"
                    },
                    {
                        "value": "富平县",
                        "label": "富平县",
                        "pid": "610500",
                        "index": "610528"
                    },
                    {
                        "value": "韩城市",
                        "label": "韩城市",
                        "pid": "610500",
                        "index": "610581"
                    },
                    {
                        "value": "华阴市",
                        "label": "华阴市",
                        "pid": "610500",
                        "index": "610582"
                    }
                ]
            },
            {
                "value": "延安市",
                "label": "延安市",
                "pid": "610000",
                "index": "610600",
                "children": [
                    {
                        "value": "宝塔区",
                        "label": "宝塔区",
                        "pid": "610600",
                        "index": "610602"
                    },
                    {
                        "value": "安塞区",
                        "label": "安塞区",
                        "pid": "610600",
                        "index": "610603"
                    },
                    {
                        "value": "延长县",
                        "label": "延长县",
                        "pid": "610600",
                        "index": "610621"
                    },
                    {
                        "value": "延川县",
                        "label": "延川县",
                        "pid": "610600",
                        "index": "610622"
                    },
                    {
                        "value": "志丹县",
                        "label": "志丹县",
                        "pid": "610600",
                        "index": "610625"
                    },
                    {
                        "value": "吴起县",
                        "label": "吴起县",
                        "pid": "610600",
                        "index": "610626"
                    },
                    {
                        "value": "甘泉县",
                        "label": "甘泉县",
                        "pid": "610600",
                        "index": "610627"
                    },
                    {
                        "value": "富县",
                        "label": "富县",
                        "pid": "610600",
                        "index": "610628"
                    },
                    {
                        "value": "洛川县",
                        "label": "洛川县",
                        "pid": "610600",
                        "index": "610629"
                    },
                    {
                        "value": "宜川县",
                        "label": "宜川县",
                        "pid": "610600",
                        "index": "610630"
                    },
                    {
                        "value": "黄龙县",
                        "label": "黄龙县",
                        "pid": "610600",
                        "index": "610631"
                    },
                    {
                        "value": "黄陵县",
                        "label": "黄陵县",
                        "pid": "610600",
                        "index": "610632"
                    },
                    {
                        "value": "子长市",
                        "label": "子长市",
                        "pid": "610600",
                        "index": "610681"
                    }
                ]
            },
            {
                "value": "汉中市",
                "label": "汉中市",
                "pid": "610000",
                "index": "610700",
                "children": [
                    {
                        "value": "汉台区",
                        "label": "汉台区",
                        "pid": "610700",
                        "index": "610702"
                    },
                    {
                        "value": "南郑区",
                        "label": "南郑区",
                        "pid": "610700",
                        "index": "610703"
                    },
                    {
                        "value": "城固县",
                        "label": "城固县",
                        "pid": "610700",
                        "index": "610722"
                    },
                    {
                        "value": "洋县",
                        "label": "洋县",
                        "pid": "610700",
                        "index": "610723"
                    },
                    {
                        "value": "西乡县",
                        "label": "西乡县",
                        "pid": "610700",
                        "index": "610724"
                    },
                    {
                        "value": "勉县",
                        "label": "勉县",
                        "pid": "610700",
                        "index": "610725"
                    },
                    {
                        "value": "宁强县",
                        "label": "宁强县",
                        "pid": "610700",
                        "index": "610726"
                    },
                    {
                        "value": "略阳县",
                        "label": "略阳县",
                        "pid": "610700",
                        "index": "610727"
                    },
                    {
                        "value": "镇巴县",
                        "label": "镇巴县",
                        "pid": "610700",
                        "index": "610728"
                    },
                    {
                        "value": "留坝县",
                        "label": "留坝县",
                        "pid": "610700",
                        "index": "610729"
                    },
                    {
                        "value": "佛坪县",
                        "label": "佛坪县",
                        "pid": "610700",
                        "index": "610730"
                    }
                ]
            },
            {
                "value": "榆林市",
                "label": "榆林市",
                "pid": "610000",
                "index": "610800",
                "children": [
                    {
                        "value": "榆阳区",
                        "label": "榆阳区",
                        "pid": "610800",
                        "index": "610802"
                    },
                    {
                        "value": "横山区",
                        "label": "横山区",
                        "pid": "610800",
                        "index": "610803"
                    },
                    {
                        "value": "府谷县",
                        "label": "府谷县",
                        "pid": "610800",
                        "index": "610822"
                    },
                    {
                        "value": "靖边县",
                        "label": "靖边县",
                        "pid": "610800",
                        "index": "610824"
                    },
                    {
                        "value": "定边县",
                        "label": "定边县",
                        "pid": "610800",
                        "index": "610825"
                    },
                    {
                        "value": "绥德县",
                        "label": "绥德县",
                        "pid": "610800",
                        "index": "610826"
                    },
                    {
                        "value": "米脂县",
                        "label": "米脂县",
                        "pid": "610800",
                        "index": "610827"
                    },
                    {
                        "value": "佳县",
                        "label": "佳县",
                        "pid": "610800",
                        "index": "610828"
                    },
                    {
                        "value": "吴堡县",
                        "label": "吴堡县",
                        "pid": "610800",
                        "index": "610829"
                    },
                    {
                        "value": "清涧县",
                        "label": "清涧县",
                        "pid": "610800",
                        "index": "610830"
                    },
                    {
                        "value": "子洲县",
                        "label": "子洲县",
                        "pid": "610800",
                        "index": "610831"
                    },
                    {
                        "value": "神木市",
                        "label": "神木市",
                        "pid": "610800",
                        "index": "610881"
                    }
                ]
            },
            {
                "value": "安康市",
                "label": "安康市",
                "pid": "610000",
                "index": "610900",
                "children": [
                    {
                        "value": "汉滨区",
                        "label": "汉滨区",
                        "pid": "610900",
                        "index": "610902"
                    },
                    {
                        "value": "汉阴县",
                        "label": "汉阴县",
                        "pid": "610900",
                        "index": "610921"
                    },
                    {
                        "value": "石泉县",
                        "label": "石泉县",
                        "pid": "610900",
                        "index": "610922"
                    },
                    {
                        "value": "宁陕县",
                        "label": "宁陕县",
                        "pid": "610900",
                        "index": "610923"
                    },
                    {
                        "value": "紫阳县",
                        "label": "紫阳县",
                        "pid": "610900",
                        "index": "610924"
                    },
                    {
                        "value": "岚皋县",
                        "label": "岚皋县",
                        "pid": "610900",
                        "index": "610925"
                    },
                    {
                        "value": "平利县",
                        "label": "平利县",
                        "pid": "610900",
                        "index": "610926"
                    },
                    {
                        "value": "镇坪县",
                        "label": "镇坪县",
                        "pid": "610900",
                        "index": "610927"
                    },
                    {
                        "value": "旬阳县",
                        "label": "旬阳县",
                        "pid": "610900",
                        "index": "610928"
                    },
                    {
                        "value": "白河县",
                        "label": "白河县",
                        "pid": "610900",
                        "index": "610929"
                    }
                ]
            },
            {
                "value": "商洛市",
                "label": "商洛市",
                "pid": "610000",
                "index": "611000",
                "children": [
                    {
                        "value": "商州区",
                        "label": "商州区",
                        "pid": "611000",
                        "index": "611002"
                    },
                    {
                        "value": "洛南县",
                        "label": "洛南县",
                        "pid": "611000",
                        "index": "611021"
                    },
                    {
                        "value": "丹凤县",
                        "label": "丹凤县",
                        "pid": "611000",
                        "index": "611022"
                    },
                    {
                        "value": "商南县",
                        "label": "商南县",
                        "pid": "611000",
                        "index": "611023"
                    },
                    {
                        "value": "山阳县",
                        "label": "山阳县",
                        "pid": "611000",
                        "index": "611024"
                    },
                    {
                        "value": "镇安县",
                        "label": "镇安县",
                        "pid": "611000",
                        "index": "611025"
                    },
                    {
                        "value": "柞水县",
                        "label": "柞水县",
                        "pid": "611000",
                        "index": "611026"
                    }
                ]
            }
        ]
    },
    {
        "value": "甘肃省",
        "label": "甘肃省",
        "pid": "86",
        "index": "620000",
        "children": [
            {
                "value": "兰州市",
                "label": "兰州市",
                "pid": "620000",
                "index": "620100",
                "children": [
                    {
                        "value": "城关区",
                        "label": "城关区",
                        "pid": "620100",
                        "index": "620102"
                    },
                    {
                        "value": "七里河区",
                        "label": "七里河区",
                        "pid": "620100",
                        "index": "620103"
                    },
                    {
                        "value": "西固区",
                        "label": "西固区",
                        "pid": "620100",
                        "index": "620104"
                    },
                    {
                        "value": "安宁区",
                        "label": "安宁区",
                        "pid": "620100",
                        "index": "620105"
                    },
                    {
                        "value": "红古区",
                        "label": "红古区",
                        "pid": "620100",
                        "index": "620111"
                    },
                    {
                        "value": "永登县",
                        "label": "永登县",
                        "pid": "620100",
                        "index": "620121"
                    },
                    {
                        "value": "皋兰县",
                        "label": "皋兰县",
                        "pid": "620100",
                        "index": "620122"
                    },
                    {
                        "value": "榆中县",
                        "label": "榆中县",
                        "pid": "620100",
                        "index": "620123"
                    },
                    {
                        "value": "兰州新区",
                        "label": "兰州新区",
                        "pid": "620100",
                        "index": "620171"
                    }
                ]
            },
            {
                "value": "嘉峪关市",
                "label": "嘉峪关市",
                "pid": "620000",
                "index": "620200",
                "children": [
                  {
                    "value": "嘉峪关市",
                    "label": "嘉峪关市",
                    "pid": "620200",
                    "index": "620201"
                }
                ]
            },
            {
                "value": "金昌市",
                "label": "金昌市",
                "pid": "620000",
                "index": "620300",
                "children": [
                    {
                        "value": "金川区",
                        "label": "金川区",
                        "pid": "620300",
                        "index": "620302"
                    },
                    {
                        "value": "永昌县",
                        "label": "永昌县",
                        "pid": "620300",
                        "index": "620321"
                    }
                ]
            },
            {
                "value": "白银市",
                "label": "白银市",
                "pid": "620000",
                "index": "620400",
                "children": [
                    {
                        "value": "白银区",
                        "label": "白银区",
                        "pid": "620400",
                        "index": "620402"
                    },
                    {
                        "value": "平川区",
                        "label": "平川区",
                        "pid": "620400",
                        "index": "620403"
                    },
                    {
                        "value": "靖远县",
                        "label": "靖远县",
                        "pid": "620400",
                        "index": "620421"
                    },
                    {
                        "value": "会宁县",
                        "label": "会宁县",
                        "pid": "620400",
                        "index": "620422"
                    },
                    {
                        "value": "景泰县",
                        "label": "景泰县",
                        "pid": "620400",
                        "index": "620423"
                    }
                ]
            },
            {
                "value": "天水市",
                "label": "天水市",
                "pid": "620000",
                "index": "620500",
                "children": [
                    {
                        "value": "秦州区",
                        "label": "秦州区",
                        "pid": "620500",
                        "index": "620502"
                    },
                    {
                        "value": "麦积区",
                        "label": "麦积区",
                        "pid": "620500",
                        "index": "620503"
                    },
                    {
                        "value": "清水县",
                        "label": "清水县",
                        "pid": "620500",
                        "index": "620521"
                    },
                    {
                        "value": "秦安县",
                        "label": "秦安县",
                        "pid": "620500",
                        "index": "620522"
                    },
                    {
                        "value": "甘谷县",
                        "label": "甘谷县",
                        "pid": "620500",
                        "index": "620523"
                    },
                    {
                        "value": "武山县",
                        "label": "武山县",
                        "pid": "620500",
                        "index": "620524"
                    },
                    {
                        "value": "张家川回族自治县",
                        "label": "张家川回族自治县",
                        "pid": "620500",
                        "index": "620525"
                    }
                ]
            },
            {
                "value": "武威市",
                "label": "武威市",
                "pid": "620000",
                "index": "620600",
                "children": [
                    {
                        "value": "凉州区",
                        "label": "凉州区",
                        "pid": "620600",
                        "index": "620602"
                    },
                    {
                        "value": "民勤县",
                        "label": "民勤县",
                        "pid": "620600",
                        "index": "620621"
                    },
                    {
                        "value": "古浪县",
                        "label": "古浪县",
                        "pid": "620600",
                        "index": "620622"
                    },
                    {
                        "value": "天祝藏族自治县",
                        "label": "天祝藏族自治县",
                        "pid": "620600",
                        "index": "620623"
                    }
                ]
            },
            {
                "value": "张掖市",
                "label": "张掖市",
                "pid": "620000",
                "index": "620700",
                "children": [
                    {
                        "value": "甘州区",
                        "label": "甘州区",
                        "pid": "620700",
                        "index": "620702"
                    },
                    {
                        "value": "肃南裕固族自治县",
                        "label": "肃南裕固族自治县",
                        "pid": "620700",
                        "index": "620721"
                    },
                    {
                        "value": "民乐县",
                        "label": "民乐县",
                        "pid": "620700",
                        "index": "620722"
                    },
                    {
                        "value": "临泽县",
                        "label": "临泽县",
                        "pid": "620700",
                        "index": "620723"
                    },
                    {
                        "value": "高台县",
                        "label": "高台县",
                        "pid": "620700",
                        "index": "620724"
                    },
                    {
                        "value": "山丹县",
                        "label": "山丹县",
                        "pid": "620700",
                        "index": "620725"
                    }
                ]
            },
            {
                "value": "平凉市",
                "label": "平凉市",
                "pid": "620000",
                "index": "620800",
                "children": [
                    {
                        "value": "崆峒区",
                        "label": "崆峒区",
                        "pid": "620800",
                        "index": "620802"
                    },
                    {
                        "value": "泾川县",
                        "label": "泾川县",
                        "pid": "620800",
                        "index": "620821"
                    },
                    {
                        "value": "灵台县",
                        "label": "灵台县",
                        "pid": "620800",
                        "index": "620822"
                    },
                    {
                        "value": "崇信县",
                        "label": "崇信县",
                        "pid": "620800",
                        "index": "620823"
                    },
                    {
                        "value": "庄浪县",
                        "label": "庄浪县",
                        "pid": "620800",
                        "index": "620825"
                    },
                    {
                        "value": "静宁县",
                        "label": "静宁县",
                        "pid": "620800",
                        "index": "620826"
                    },
                    {
                        "value": "华亭市",
                        "label": "华亭市",
                        "pid": "620800",
                        "index": "620881"
                    }
                ]
            },
            {
                "value": "酒泉市",
                "label": "酒泉市",
                "pid": "620000",
                "index": "620900",
                "children": [
                    {
                        "value": "肃州区",
                        "label": "肃州区",
                        "pid": "620900",
                        "index": "620902"
                    },
                    {
                        "value": "金塔县",
                        "label": "金塔县",
                        "pid": "620900",
                        "index": "620921"
                    },
                    {
                        "value": "瓜州县",
                        "label": "瓜州县",
                        "pid": "620900",
                        "index": "620922"
                    },
                    {
                        "value": "肃北蒙古族自治县",
                        "label": "肃北蒙古族自治县",
                        "pid": "620900",
                        "index": "620923"
                    },
                    {
                        "value": "阿克塞哈萨克族自治县",
                        "label": "阿克塞哈萨克族自治县",
                        "pid": "620900",
                        "index": "620924"
                    },
                    {
                        "value": "玉门市",
                        "label": "玉门市",
                        "pid": "620900",
                        "index": "620981"
                    },
                    {
                        "value": "敦煌市",
                        "label": "敦煌市",
                        "pid": "620900",
                        "index": "620982"
                    }
                ]
            },
            {
                "value": "庆阳市",
                "label": "庆阳市",
                "pid": "620000",
                "index": "621000",
                "children": [
                    {
                        "value": "西峰区",
                        "label": "西峰区",
                        "pid": "621000",
                        "index": "621002"
                    },
                    {
                        "value": "庆城县",
                        "label": "庆城县",
                        "pid": "621000",
                        "index": "621021"
                    },
                    {
                        "value": "环县",
                        "label": "环县",
                        "pid": "621000",
                        "index": "621022"
                    },
                    {
                        "value": "华池县",
                        "label": "华池县",
                        "pid": "621000",
                        "index": "621023"
                    },
                    {
                        "value": "合水县",
                        "label": "合水县",
                        "pid": "621000",
                        "index": "621024"
                    },
                    {
                        "value": "正宁县",
                        "label": "正宁县",
                        "pid": "621000",
                        "index": "621025"
                    },
                    {
                        "value": "宁县",
                        "label": "宁县",
                        "pid": "621000",
                        "index": "621026"
                    },
                    {
                        "value": "镇原县",
                        "label": "镇原县",
                        "pid": "621000",
                        "index": "621027"
                    }
                ]
            },
            {
                "value": "定西市",
                "label": "定西市",
                "pid": "620000",
                "index": "621100",
                "children": [
                    {
                        "value": "安定区",
                        "label": "安定区",
                        "pid": "621100",
                        "index": "621102"
                    },
                    {
                        "value": "通渭县",
                        "label": "通渭县",
                        "pid": "621100",
                        "index": "621121"
                    },
                    {
                        "value": "陇西县",
                        "label": "陇西县",
                        "pid": "621100",
                        "index": "621122"
                    },
                    {
                        "value": "渭源县",
                        "label": "渭源县",
                        "pid": "621100",
                        "index": "621123"
                    },
                    {
                        "value": "临洮县",
                        "label": "临洮县",
                        "pid": "621100",
                        "index": "621124"
                    },
                    {
                        "value": "漳县",
                        "label": "漳县",
                        "pid": "621100",
                        "index": "621125"
                    },
                    {
                        "value": "岷县",
                        "label": "岷县",
                        "pid": "621100",
                        "index": "621126"
                    }
                ]
            },
            {
                "value": "陇南市",
                "label": "陇南市",
                "pid": "620000",
                "index": "621200",
                "children": [
                    {
                        "value": "武都区",
                        "label": "武都区",
                        "pid": "621200",
                        "index": "621202"
                    },
                    {
                        "value": "成县",
                        "label": "成县",
                        "pid": "621200",
                        "index": "621221"
                    },
                    {
                        "value": "文县",
                        "label": "文县",
                        "pid": "621200",
                        "index": "621222"
                    },
                    {
                        "value": "宕昌县",
                        "label": "宕昌县",
                        "pid": "621200",
                        "index": "621223"
                    },
                    {
                        "value": "康县",
                        "label": "康县",
                        "pid": "621200",
                        "index": "621224"
                    },
                    {
                        "value": "西和县",
                        "label": "西和县",
                        "pid": "621200",
                        "index": "621225"
                    },
                    {
                        "value": "礼县",
                        "label": "礼县",
                        "pid": "621200",
                        "index": "621226"
                    },
                    {
                        "value": "徽县",
                        "label": "徽县",
                        "pid": "621200",
                        "index": "621227"
                    },
                    {
                        "value": "两当县",
                        "label": "两当县",
                        "pid": "621200",
                        "index": "621228"
                    }
                ]
            },
            {
                "value": "临夏回族自治州",
                "label": "临夏回族自治州",
                "pid": "620000",
                "index": "622900",
                "children": [
                    {
                        "value": "临夏市",
                        "label": "临夏市",
                        "pid": "622900",
                        "index": "622901"
                    },
                    {
                        "value": "临夏县",
                        "label": "临夏县",
                        "pid": "622900",
                        "index": "622921"
                    },
                    {
                        "value": "康乐县",
                        "label": "康乐县",
                        "pid": "622900",
                        "index": "622922"
                    },
                    {
                        "value": "永靖县",
                        "label": "永靖县",
                        "pid": "622900",
                        "index": "622923"
                    },
                    {
                        "value": "广河县",
                        "label": "广河县",
                        "pid": "622900",
                        "index": "622924"
                    },
                    {
                        "value": "和政县",
                        "label": "和政县",
                        "pid": "622900",
                        "index": "622925"
                    },
                    {
                        "value": "东乡族自治县",
                        "label": "东乡族自治县",
                        "pid": "622900",
                        "index": "622926"
                    },
                    {
                        "value": "积石山保安族东乡族撒拉族自治县",
                        "label": "积石山保安族东乡族撒拉族自治县",
                        "pid": "622900",
                        "index": "622927"
                    }
                ]
            },
            {
                "value": "甘南藏族自治州",
                "label": "甘南藏族自治州",
                "pid": "620000",
                "index": "623000",
                "children": [
                    {
                        "value": "合作市",
                        "label": "合作市",
                        "pid": "623000",
                        "index": "623001"
                    },
                    {
                        "value": "临潭县",
                        "label": "临潭县",
                        "pid": "623000",
                        "index": "623021"
                    },
                    {
                        "value": "卓尼县",
                        "label": "卓尼县",
                        "pid": "623000",
                        "index": "623022"
                    },
                    {
                        "value": "舟曲县",
                        "label": "舟曲县",
                        "pid": "623000",
                        "index": "623023"
                    },
                    {
                        "value": "迭部县",
                        "label": "迭部县",
                        "pid": "623000",
                        "index": "623024"
                    },
                    {
                        "value": "玛曲县",
                        "label": "玛曲县",
                        "pid": "623000",
                        "index": "623025"
                    },
                    {
                        "value": "碌曲县",
                        "label": "碌曲县",
                        "pid": "623000",
                        "index": "623026"
                    },
                    {
                        "value": "夏河县",
                        "label": "夏河县",
                        "pid": "623000",
                        "index": "623027"
                    }
                ]
            }
        ]
    },
    {
        "value": "青海省",
        "label": "青海省",
        "pid": "86",
        "index": "630000",
        "children": [
            {
                "value": "西宁市",
                "label": "西宁市",
                "pid": "630000",
                "index": "630100",
                "children": [
                    {
                        "value": "城东区",
                        "label": "城东区",
                        "pid": "630100",
                        "index": "630102"
                    },
                    {
                        "value": "城中区",
                        "label": "城中区",
                        "pid": "630100",
                        "index": "630103"
                    },
                    {
                        "value": "城西区",
                        "label": "城西区",
                        "pid": "630100",
                        "index": "630104"
                    },
                    {
                        "value": "城北区",
                        "label": "城北区",
                        "pid": "630100",
                        "index": "630105"
                    },
                    {
                        "value": "大通回族土族自治县",
                        "label": "大通回族土族自治县",
                        "pid": "630100",
                        "index": "630121"
                    },
                    {
                        "value": "湟中县",
                        "label": "湟中县",
                        "pid": "630100",
                        "index": "630122"
                    },
                    {
                        "value": "湟源县",
                        "label": "湟源县",
                        "pid": "630100",
                        "index": "630123"
                    }
                ]
            },
            {
                "value": "海东市",
                "label": "海东市",
                "pid": "630000",
                "index": "630200",
                "children": [
                    {
                        "value": "乐都区",
                        "label": "乐都区",
                        "pid": "630200",
                        "index": "630202"
                    },
                    {
                        "value": "平安区",
                        "label": "平安区",
                        "pid": "630200",
                        "index": "630203"
                    },
                    {
                        "value": "民和回族土族自治县",
                        "label": "民和回族土族自治县",
                        "pid": "630200",
                        "index": "630222"
                    },
                    {
                        "value": "互助土族自治县",
                        "label": "互助土族自治县",
                        "pid": "630200",
                        "index": "630223"
                    },
                    {
                        "value": "化隆回族自治县",
                        "label": "化隆回族自治县",
                        "pid": "630200",
                        "index": "630224"
                    },
                    {
                        "value": "循化撒拉族自治县",
                        "label": "循化撒拉族自治县",
                        "pid": "630200",
                        "index": "630225"
                    }
                ]
            },
            {
                "value": "海北藏族自治州",
                "label": "海北藏族自治州",
                "pid": "630000",
                "index": "632200",
                "children": [
                    {
                        "value": "门源回族自治县",
                        "label": "门源回族自治县",
                        "pid": "632200",
                        "index": "632221"
                    },
                    {
                        "value": "祁连县",
                        "label": "祁连县",
                        "pid": "632200",
                        "index": "632222"
                    },
                    {
                        "value": "海晏县",
                        "label": "海晏县",
                        "pid": "632200",
                        "index": "632223"
                    },
                    {
                        "value": "刚察县",
                        "label": "刚察县",
                        "pid": "632200",
                        "index": "632224"
                    }
                ]
            },
            {
                "value": "黄南藏族自治州",
                "label": "黄南藏族自治州",
                "pid": "630000",
                "index": "632300",
                "children": [
                    {
                        "value": "同仁县",
                        "label": "同仁县",
                        "pid": "632300",
                        "index": "632321"
                    },
                    {
                        "value": "尖扎县",
                        "label": "尖扎县",
                        "pid": "632300",
                        "index": "632322"
                    },
                    {
                        "value": "泽库县",
                        "label": "泽库县",
                        "pid": "632300",
                        "index": "632323"
                    },
                    {
                        "value": "河南蒙古族自治县",
                        "label": "河南蒙古族自治县",
                        "pid": "632300",
                        "index": "632324"
                    }
                ]
            },
            {
                "value": "海南藏族自治州",
                "label": "海南藏族自治州",
                "pid": "630000",
                "index": "632500",
                "children": [
                    {
                        "value": "共和县",
                        "label": "共和县",
                        "pid": "632500",
                        "index": "632521"
                    },
                    {
                        "value": "同德县",
                        "label": "同德县",
                        "pid": "632500",
                        "index": "632522"
                    },
                    {
                        "value": "贵德县",
                        "label": "贵德县",
                        "pid": "632500",
                        "index": "632523"
                    },
                    {
                        "value": "兴海县",
                        "label": "兴海县",
                        "pid": "632500",
                        "index": "632524"
                    },
                    {
                        "value": "贵南县",
                        "label": "贵南县",
                        "pid": "632500",
                        "index": "632525"
                    }
                ]
            },
            {
                "value": "果洛藏族自治州",
                "label": "果洛藏族自治州",
                "pid": "630000",
                "index": "632600",
                "children": [
                    {
                        "value": "玛沁县",
                        "label": "玛沁县",
                        "pid": "632600",
                        "index": "632621"
                    },
                    {
                        "value": "班玛县",
                        "label": "班玛县",
                        "pid": "632600",
                        "index": "632622"
                    },
                    {
                        "value": "甘德县",
                        "label": "甘德县",
                        "pid": "632600",
                        "index": "632623"
                    },
                    {
                        "value": "达日县",
                        "label": "达日县",
                        "pid": "632600",
                        "index": "632624"
                    },
                    {
                        "value": "久治县",
                        "label": "久治县",
                        "pid": "632600",
                        "index": "632625"
                    },
                    {
                        "value": "玛多县",
                        "label": "玛多县",
                        "pid": "632600",
                        "index": "632626"
                    }
                ]
            },
            {
                "value": "玉树藏族自治州",
                "label": "玉树藏族自治州",
                "pid": "630000",
                "index": "632700",
                "children": [
                    {
                        "value": "玉树市",
                        "label": "玉树市",
                        "pid": "632700",
                        "index": "632701"
                    },
                    {
                        "value": "杂多县",
                        "label": "杂多县",
                        "pid": "632700",
                        "index": "632722"
                    },
                    {
                        "value": "称多县",
                        "label": "称多县",
                        "pid": "632700",
                        "index": "632723"
                    },
                    {
                        "value": "治多县",
                        "label": "治多县",
                        "pid": "632700",
                        "index": "632724"
                    },
                    {
                        "value": "囊谦县",
                        "label": "囊谦县",
                        "pid": "632700",
                        "index": "632725"
                    },
                    {
                        "value": "曲麻莱县",
                        "label": "曲麻莱县",
                        "pid": "632700",
                        "index": "632726"
                    }
                ]
            },
            {
                "value": "海西蒙古族藏族自治州",
                "label": "海西蒙古族藏族自治州",
                "pid": "630000",
                "index": "632800",
                "children": [
                    {
                        "value": "格尔木市",
                        "label": "格尔木市",
                        "pid": "632800",
                        "index": "632801"
                    },
                    {
                        "value": "德令哈市",
                        "label": "德令哈市",
                        "pid": "632800",
                        "index": "632802"
                    },
                    {
                        "value": "茫崖市",
                        "label": "茫崖市",
                        "pid": "632800",
                        "index": "632803"
                    },
                    {
                        "value": "乌兰县",
                        "label": "乌兰县",
                        "pid": "632800",
                        "index": "632821"
                    },
                    {
                        "value": "都兰县",
                        "label": "都兰县",
                        "pid": "632800",
                        "index": "632822"
                    },
                    {
                        "value": "天峻县",
                        "label": "天峻县",
                        "pid": "632800",
                        "index": "632823"
                    },
                    {
                        "value": "大柴旦行政委员会",
                        "label": "大柴旦行政委员会",
                        "pid": "632800",
                        "index": "632857"
                    }
                ]
            }
        ]
    },
    {
        "value": "宁夏回族自治区",
        "label": "宁夏回族自治区",
        "pid": "86",
        "index": "640000",
        "children": [
            {
                "value": "银川市",
                "label": "银川市",
                "pid": "640000",
                "index": "640100",
                "children": [
                    {
                        "value": "兴庆区",
                        "label": "兴庆区",
                        "pid": "640100",
                        "index": "640104"
                    },
                    {
                        "value": "西夏区",
                        "label": "西夏区",
                        "pid": "640100",
                        "index": "640105"
                    },
                    {
                        "value": "金凤区",
                        "label": "金凤区",
                        "pid": "640100",
                        "index": "640106"
                    },
                    {
                        "value": "永宁县",
                        "label": "永宁县",
                        "pid": "640100",
                        "index": "640121"
                    },
                    {
                        "value": "贺兰县",
                        "label": "贺兰县",
                        "pid": "640100",
                        "index": "640122"
                    },
                    {
                        "value": "灵武市",
                        "label": "灵武市",
                        "pid": "640100",
                        "index": "640181"
                    }
                ]
            },
            {
                "value": "石嘴山市",
                "label": "石嘴山市",
                "pid": "640000",
                "index": "640200",
                "children": [
                    {
                        "value": "大武口区",
                        "label": "大武口区",
                        "pid": "640200",
                        "index": "640202"
                    },
                    {
                        "value": "惠农区",
                        "label": "惠农区",
                        "pid": "640200",
                        "index": "640205"
                    },
                    {
                        "value": "平罗县",
                        "label": "平罗县",
                        "pid": "640200",
                        "index": "640221"
                    }
                ]
            },
            {
                "value": "吴忠市",
                "label": "吴忠市",
                "pid": "640000",
                "index": "640300",
                "children": [
                    {
                        "value": "利通区",
                        "label": "利通区",
                        "pid": "640300",
                        "index": "640302"
                    },
                    {
                        "value": "红寺堡区",
                        "label": "红寺堡区",
                        "pid": "640300",
                        "index": "640303"
                    },
                    {
                        "value": "盐池县",
                        "label": "盐池县",
                        "pid": "640300",
                        "index": "640323"
                    },
                    {
                        "value": "同心县",
                        "label": "同心县",
                        "pid": "640300",
                        "index": "640324"
                    },
                    {
                        "value": "青铜峡市",
                        "label": "青铜峡市",
                        "pid": "640300",
                        "index": "640381"
                    }
                ]
            },
            {
                "value": "固原市",
                "label": "固原市",
                "pid": "640000",
                "index": "640400",
                "children": [
                    {
                        "value": "原州区",
                        "label": "原州区",
                        "pid": "640400",
                        "index": "640402"
                    },
                    {
                        "value": "西吉县",
                        "label": "西吉县",
                        "pid": "640400",
                        "index": "640422"
                    },
                    {
                        "value": "隆德县",
                        "label": "隆德县",
                        "pid": "640400",
                        "index": "640423"
                    },
                    {
                        "value": "泾源县",
                        "label": "泾源县",
                        "pid": "640400",
                        "index": "640424"
                    },
                    {
                        "value": "彭阳县",
                        "label": "彭阳县",
                        "pid": "640400",
                        "index": "640425"
                    }
                ]
            },
            {
                "value": "中卫市",
                "label": "中卫市",
                "pid": "640000",
                "index": "640500",
                "children": [
                    {
                        "value": "沙坡头区",
                        "label": "沙坡头区",
                        "pid": "640500",
                        "index": "640502"
                    },
                    {
                        "value": "中宁县",
                        "label": "中宁县",
                        "pid": "640500",
                        "index": "640521"
                    },
                    {
                        "value": "海原县",
                        "label": "海原县",
                        "pid": "640500",
                        "index": "640522"
                    }
                ]
            }
        ]
    },
    {
        "value": "新疆维吾尔自治区",
        "label": "新疆维吾尔自治区",
        "pid": "86",
        "index": "650000",
        "children": [
            {
                "value": "乌鲁木齐市",
                "label": "乌鲁木齐市",
                "pid": "650000",
                "index": "650100",
                "children": [
                    {
                        "value": "天山区",
                        "label": "天山区",
                        "pid": "650100",
                        "index": "650102"
                    },
                    {
                        "value": "沙依巴克区",
                        "label": "沙依巴克区",
                        "pid": "650100",
                        "index": "650103"
                    },
                    {
                        "value": "新市区",
                        "label": "新市区",
                        "pid": "650100",
                        "index": "650104"
                    },
                    {
                        "value": "水磨沟区",
                        "label": "水磨沟区",
                        "pid": "650100",
                        "index": "650105"
                    },
                    {
                        "value": "头屯河区",
                        "label": "头屯河区",
                        "pid": "650100",
                        "index": "650106"
                    },
                    {
                        "value": "达坂城区",
                        "label": "达坂城区",
                        "pid": "650100",
                        "index": "650107"
                    },
                    {
                        "value": "米东区",
                        "label": "米东区",
                        "pid": "650100",
                        "index": "650109"
                    },
                    {
                        "value": "乌鲁木齐县",
                        "label": "乌鲁木齐县",
                        "pid": "650100",
                        "index": "650121"
                    }
                ]
            },
            {
                "value": "克拉玛依市",
                "label": "克拉玛依市",
                "pid": "650000",
                "index": "650200",
                "children": [
                    {
                        "value": "独山子区",
                        "label": "独山子区",
                        "pid": "650200",
                        "index": "650202"
                    },
                    {
                        "value": "克拉玛依区",
                        "label": "克拉玛依区",
                        "pid": "650200",
                        "index": "650203"
                    },
                    {
                        "value": "白碱滩区",
                        "label": "白碱滩区",
                        "pid": "650200",
                        "index": "650204"
                    },
                    {
                        "value": "乌尔禾区",
                        "label": "乌尔禾区",
                        "pid": "650200",
                        "index": "650205"
                    }
                ]
            },
            {
                "value": "吐鲁番市",
                "label": "吐鲁番市",
                "pid": "650000",
                "index": "650400",
                "children": [
                    {
                        "value": "高昌区",
                        "label": "高昌区",
                        "pid": "650400",
                        "index": "650402"
                    },
                    {
                        "value": "鄯善县",
                        "label": "鄯善县",
                        "pid": "650400",
                        "index": "650421"
                    },
                    {
                        "value": "托克逊县",
                        "label": "托克逊县",
                        "pid": "650400",
                        "index": "650422"
                    }
                ]
            },
            {
                "value": "哈密市",
                "label": "哈密市",
                "pid": "650000",
                "index": "650500",
                "children": [
                    {
                        "value": "伊州区",
                        "label": "伊州区",
                        "pid": "650500",
                        "index": "650502"
                    },
                    {
                        "value": "巴里坤哈萨克自治县",
                        "label": "巴里坤哈萨克自治县",
                        "pid": "650500",
                        "index": "650521"
                    },
                    {
                        "value": "伊吾县",
                        "label": "伊吾县",
                        "pid": "650500",
                        "index": "650522"
                    }
                ]
            },
            {
                "value": "昌吉回族自治州",
                "label": "昌吉回族自治州",
                "pid": "650000",
                "index": "652300",
                "children": [
                    {
                        "value": "昌吉市",
                        "label": "昌吉市",
                        "pid": "652300",
                        "index": "652301"
                    },
                    {
                        "value": "阜康市",
                        "label": "阜康市",
                        "pid": "652300",
                        "index": "652302"
                    },
                    {
                        "value": "呼图壁县",
                        "label": "呼图壁县",
                        "pid": "652300",
                        "index": "652323"
                    },
                    {
                        "value": "玛纳斯县",
                        "label": "玛纳斯县",
                        "pid": "652300",
                        "index": "652324"
                    },
                    {
                        "value": "奇台县",
                        "label": "奇台县",
                        "pid": "652300",
                        "index": "652325"
                    },
                    {
                        "value": "吉木萨尔县",
                        "label": "吉木萨尔县",
                        "pid": "652300",
                        "index": "652327"
                    },
                    {
                        "value": "木垒哈萨克自治县",
                        "label": "木垒哈萨克自治县",
                        "pid": "652300",
                        "index": "652328"
                    }
                ]
            },
            {
                "value": "博尔塔拉蒙古自治州",
                "label": "博尔塔拉蒙古自治州",
                "pid": "650000",
                "index": "652700",
                "children": [
                    {
                        "value": "博乐市",
                        "label": "博乐市",
                        "pid": "652700",
                        "index": "652701"
                    },
                    {
                        "value": "阿拉山口市",
                        "label": "阿拉山口市",
                        "pid": "652700",
                        "index": "652702"
                    },
                    {
                        "value": "精河县",
                        "label": "精河县",
                        "pid": "652700",
                        "index": "652722"
                    },
                    {
                        "value": "温泉县",
                        "label": "温泉县",
                        "pid": "652700",
                        "index": "652723"
                    }
                ]
            },
            {
                "value": "巴音郭楞蒙古自治州",
                "label": "巴音郭楞蒙古自治州",
                "pid": "650000",
                "index": "652800",
                "children": [
                    {
                        "value": "库尔勒市",
                        "label": "库尔勒市",
                        "pid": "652800",
                        "index": "652801"
                    },
                    {
                        "value": "轮台县",
                        "label": "轮台县",
                        "pid": "652800",
                        "index": "652822"
                    },
                    {
                        "value": "尉犁县",
                        "label": "尉犁县",
                        "pid": "652800",
                        "index": "652823"
                    },
                    {
                        "value": "若羌县",
                        "label": "若羌县",
                        "pid": "652800",
                        "index": "652824"
                    },
                    {
                        "value": "且末县",
                        "label": "且末县",
                        "pid": "652800",
                        "index": "652825"
                    },
                    {
                        "value": "焉耆回族自治县",
                        "label": "焉耆回族自治县",
                        "pid": "652800",
                        "index": "652826"
                    },
                    {
                        "value": "和静县",
                        "label": "和静县",
                        "pid": "652800",
                        "index": "652827"
                    },
                    {
                        "value": "和硕县",
                        "label": "和硕县",
                        "pid": "652800",
                        "index": "652828"
                    },
                    {
                        "value": "博湖县",
                        "label": "博湖县",
                        "pid": "652800",
                        "index": "652829"
                    },
                    {
                        "value": "库尔勒经济技术开发区",
                        "label": "库尔勒经济技术开发区",
                        "pid": "652800",
                        "index": "652871"
                    }
                ]
            },
            {
                "value": "阿克苏地区",
                "label": "阿克苏地区",
                "pid": "650000",
                "index": "652900",
                "children": [
                    {
                        "value": "阿克苏市",
                        "label": "阿克苏市",
                        "pid": "652900",
                        "index": "652901"
                    },
                    {
                        "value": "温宿县",
                        "label": "温宿县",
                        "pid": "652900",
                        "index": "652922"
                    },
                    {
                        "value": "库车县",
                        "label": "库车县",
                        "pid": "652900",
                        "index": "652923"
                    },
                    {
                        "value": "沙雅县",
                        "label": "沙雅县",
                        "pid": "652900",
                        "index": "652924"
                    },
                    {
                        "value": "新和县",
                        "label": "新和县",
                        "pid": "652900",
                        "index": "652925"
                    },
                    {
                        "value": "拜城县",
                        "label": "拜城县",
                        "pid": "652900",
                        "index": "652926"
                    },
                    {
                        "value": "乌什县",
                        "label": "乌什县",
                        "pid": "652900",
                        "index": "652927"
                    },
                    {
                        "value": "阿瓦提县",
                        "label": "阿瓦提县",
                        "pid": "652900",
                        "index": "652928"
                    },
                    {
                        "value": "柯坪县",
                        "label": "柯坪县",
                        "pid": "652900",
                        "index": "652929"
                    }
                ]
            },
            {
                "value": "克孜勒苏柯尔克孜自治州",
                "label": "克孜勒苏柯尔克孜自治州",
                "pid": "650000",
                "index": "653000",
                "children": [
                    {
                        "value": "阿图什市",
                        "label": "阿图什市",
                        "pid": "653000",
                        "index": "653001"
                    },
                    {
                        "value": "阿克陶县",
                        "label": "阿克陶县",
                        "pid": "653000",
                        "index": "653022"
                    },
                    {
                        "value": "阿合奇县",
                        "label": "阿合奇县",
                        "pid": "653000",
                        "index": "653023"
                    },
                    {
                        "value": "乌恰县",
                        "label": "乌恰县",
                        "pid": "653000",
                        "index": "653024"
                    }
                ]
            },
            {
                "value": "喀什地区",
                "label": "喀什地区",
                "pid": "650000",
                "index": "653100",
                "children": [
                    {
                        "value": "喀什市",
                        "label": "喀什市",
                        "pid": "653100",
                        "index": "653101"
                    },
                    {
                        "value": "疏附县",
                        "label": "疏附县",
                        "pid": "653100",
                        "index": "653121"
                    },
                    {
                        "value": "疏勒县",
                        "label": "疏勒县",
                        "pid": "653100",
                        "index": "653122"
                    },
                    {
                        "value": "英吉沙县",
                        "label": "英吉沙县",
                        "pid": "653100",
                        "index": "653123"
                    },
                    {
                        "value": "泽普县",
                        "label": "泽普县",
                        "pid": "653100",
                        "index": "653124"
                    },
                    {
                        "value": "莎车县",
                        "label": "莎车县",
                        "pid": "653100",
                        "index": "653125"
                    },
                    {
                        "value": "叶城县",
                        "label": "叶城县",
                        "pid": "653100",
                        "index": "653126"
                    },
                    {
                        "value": "麦盖提县",
                        "label": "麦盖提县",
                        "pid": "653100",
                        "index": "653127"
                    },
                    {
                        "value": "岳普湖县",
                        "label": "岳普湖县",
                        "pid": "653100",
                        "index": "653128"
                    },
                    {
                        "value": "伽师县",
                        "label": "伽师县",
                        "pid": "653100",
                        "index": "653129"
                    },
                    {
                        "value": "巴楚县",
                        "label": "巴楚县",
                        "pid": "653100",
                        "index": "653130"
                    },
                    {
                        "value": "塔什库尔干塔吉克自治县",
                        "label": "塔什库尔干塔吉克自治县",
                        "pid": "653100",
                        "index": "653131"
                    }
                ]
            },
            {
                "value": "和田地区",
                "label": "和田地区",
                "pid": "650000",
                "index": "653200",
                "children": [
                    {
                        "value": "和田市",
                        "label": "和田市",
                        "pid": "653200",
                        "index": "653201"
                    },
                    {
                        "value": "和田县",
                        "label": "和田县",
                        "pid": "653200",
                        "index": "653221"
                    },
                    {
                        "value": "墨玉县",
                        "label": "墨玉县",
                        "pid": "653200",
                        "index": "653222"
                    },
                    {
                        "value": "皮山县",
                        "label": "皮山县",
                        "pid": "653200",
                        "index": "653223"
                    },
                    {
                        "value": "洛浦县",
                        "label": "洛浦县",
                        "pid": "653200",
                        "index": "653224"
                    },
                    {
                        "value": "策勒县",
                        "label": "策勒县",
                        "pid": "653200",
                        "index": "653225"
                    },
                    {
                        "value": "于田县",
                        "label": "于田县",
                        "pid": "653200",
                        "index": "653226"
                    },
                    {
                        "value": "民丰县",
                        "label": "民丰县",
                        "pid": "653200",
                        "index": "653227"
                    }
                ]
            },
            {
                "value": "伊犁哈萨克自治州",
                "label": "伊犁哈萨克自治州",
                "pid": "650000",
                "index": "654000",
                "children": [
                    {
                        "value": "伊宁市",
                        "label": "伊宁市",
                        "pid": "654000",
                        "index": "654002"
                    },
                    {
                        "value": "奎屯市",
                        "label": "奎屯市",
                        "pid": "654000",
                        "index": "654003"
                    },
                    {
                        "value": "霍尔果斯市",
                        "label": "霍尔果斯市",
                        "pid": "654000",
                        "index": "654004"
                    },
                    {
                        "value": "伊宁县",
                        "label": "伊宁县",
                        "pid": "654000",
                        "index": "654021"
                    },
                    {
                        "value": "察布查尔锡伯自治县",
                        "label": "察布查尔锡伯自治县",
                        "pid": "654000",
                        "index": "654022"
                    },
                    {
                        "value": "霍城县",
                        "label": "霍城县",
                        "pid": "654000",
                        "index": "654023"
                    },
                    {
                        "value": "巩留县",
                        "label": "巩留县",
                        "pid": "654000",
                        "index": "654024"
                    },
                    {
                        "value": "新源县",
                        "label": "新源县",
                        "pid": "654000",
                        "index": "654025"
                    },
                    {
                        "value": "昭苏县",
                        "label": "昭苏县",
                        "pid": "654000",
                        "index": "654026"
                    },
                    {
                        "value": "特克斯县",
                        "label": "特克斯县",
                        "pid": "654000",
                        "index": "654027"
                    },
                    {
                        "value": "尼勒克县",
                        "label": "尼勒克县",
                        "pid": "654000",
                        "index": "654028"
                    }
                ]
            },
            {
                "value": "塔城地区",
                "label": "塔城地区",
                "pid": "650000",
                "index": "654200",
                "children": [
                    {
                        "value": "塔城市",
                        "label": "塔城市",
                        "pid": "654200",
                        "index": "654201"
                    },
                    {
                        "value": "乌苏市",
                        "label": "乌苏市",
                        "pid": "654200",
                        "index": "654202"
                    },
                    {
                        "value": "额敏县",
                        "label": "额敏县",
                        "pid": "654200",
                        "index": "654221"
                    },
                    {
                        "value": "沙湾县",
                        "label": "沙湾县",
                        "pid": "654200",
                        "index": "654223"
                    },
                    {
                        "value": "托里县",
                        "label": "托里县",
                        "pid": "654200",
                        "index": "654224"
                    },
                    {
                        "value": "裕民县",
                        "label": "裕民县",
                        "pid": "654200",
                        "index": "654225"
                    },
                    {
                        "value": "和布克赛尔蒙古自治县",
                        "label": "和布克赛尔蒙古自治县",
                        "pid": "654200",
                        "index": "654226"
                    }
                ]
            },
            {
                "value": "阿勒泰地区",
                "label": "阿勒泰地区",
                "pid": "650000",
                "index": "654300",
                "children": [
                    {
                        "value": "阿勒泰市",
                        "label": "阿勒泰市",
                        "pid": "654300",
                        "index": "654301"
                    },
                    {
                        "value": "布尔津县",
                        "label": "布尔津县",
                        "pid": "654300",
                        "index": "654321"
                    },
                    {
                        "value": "富蕴县",
                        "label": "富蕴县",
                        "pid": "654300",
                        "index": "654322"
                    },
                    {
                        "value": "福海县",
                        "label": "福海县",
                        "pid": "654300",
                        "index": "654323"
                    },
                    {
                        "value": "哈巴河县",
                        "label": "哈巴河县",
                        "pid": "654300",
                        "index": "654324"
                    },
                    {
                        "value": "青河县",
                        "label": "青河县",
                        "pid": "654300",
                        "index": "654325"
                    },
                    {
                        "value": "吉木乃县",
                        "label": "吉木乃县",
                        "pid": "654300",
                        "index": "654326"
                    }
                ]
            },
            {
                "value": "自治区直辖县级行政区划",
                "label": "自治区直辖县级行政区划",
                "pid": "650000",
                "index": "659000",
                "children": [
                    {
                        "value": "石河子市",
                        "label": "石河子市",
                        "pid": "659000",
                        "index": "659001"
                    },
                    {
                        "value": "阿拉尔市",
                        "label": "阿拉尔市",
                        "pid": "659000",
                        "index": "659002"
                    },
                    {
                        "value": "图木舒克市",
                        "label": "图木舒克市",
                        "pid": "659000",
                        "index": "659003"
                    },
                    {
                        "value": "五家渠市",
                        "label": "五家渠市",
                        "pid": "659000",
                        "index": "659004"
                    },
                    {
                        "value": "铁门关市",
                        "label": "铁门关市",
                        "pid": "659000",
                        "index": "659006"
                    }
                ]
            }
        ]
    },
    // {
    //     "value": "台湾省",
    //     "label": "台湾省",
    //     "pid": "86",
    //     "index": "710000",
    //     "children": [
    //         {
    //             "value": "台北市",
    //             "label": "台北市",
    //             "pid": "710000",
    //             "index": "710100",
    //             "children": [
    //                 {
    //                     "value": "内湖区",
    //                     "label": "内湖区",
    //                     "pid": "710100",
    //                     "index": "710101"
    //                 },
    //                 {
    //                     "value": "南港区",
    //                     "label": "南港区",
    //                     "pid": "710100",
    //                     "index": "710102"
    //                 },
    //                 {
    //                     "value": "中正区",
    //                     "label": "中正区",
    //                     "pid": "710100",
    //                     "index": "710103"
    //                 },
    //                 {
    //                     "value": "松山区",
    //                     "label": "松山区",
    //                     "pid": "710100",
    //                     "index": "710104"
    //                 },
    //                 {
    //                     "value": "信义区",
    //                     "label": "信义区",
    //                     "pid": "710100",
    //                     "index": "710105"
    //                 },
    //                 {
    //                     "value": "大安区",
    //                     "label": "大安区",
    //                     "pid": "710100",
    //                     "index": "710106"
    //                 },
    //                 {
    //                     "value": "中山区",
    //                     "label": "中山区",
    //                     "pid": "710100",
    //                     "index": "710107"
    //                 },
    //                 {
    //                     "value": "文山区",
    //                     "label": "文山区",
    //                     "pid": "710100",
    //                     "index": "710108"
    //                 },
    //                 {
    //                     "value": "大同区",
    //                     "label": "大同区",
    //                     "pid": "710100",
    //                     "index": "710109"
    //                 },
    //                 {
    //                     "value": "万华区",
    //                     "label": "万华区",
    //                     "pid": "710100",
    //                     "index": "710110"
    //                 },
    //                 {
    //                     "value": "士林区",
    //                     "label": "士林区",
    //                     "pid": "710100",
    //                     "index": "710111"
    //                 },
    //                 {
    //                     "value": "北投区",
    //                     "label": "北投区",
    //                     "pid": "710100",
    //                     "index": "710112"
    //                 }
    //             ]
    //         },
    //         {
    //             "value": "高雄市",
    //             "label": "高雄市",
    //             "pid": "710000",
    //             "index": "710200",
    //             "children": [
    //                 {
    //                     "value": "新兴区",
    //                     "label": "新兴区",
    //                     "pid": "710200",
    //                     "index": "710201"
    //                 },
    //                 {
    //                     "value": "前金区",
    //                     "label": "前金区",
    //                     "pid": "710200",
    //                     "index": "710202"
    //                 },
    //                 {
    //                     "value": "芩雅区",
    //                     "label": "芩雅区",
    //                     "pid": "710200",
    //                     "index": "710203"
    //                 },
    //                 {
    //                     "value": "盐埕区",
    //                     "label": "盐埕区",
    //                     "pid": "710200",
    //                     "index": "710204"
    //                 },
    //                 {
    //                     "value": "鼓山区",
    //                     "label": "鼓山区",
    //                     "pid": "710200",
    //                     "index": "710205"
    //                 },
    //                 {
    //                     "value": "旗津区",
    //                     "label": "旗津区",
    //                     "pid": "710200",
    //                     "index": "710206"
    //                 },
    //                 {
    //                     "value": "前镇区",
    //                     "label": "前镇区",
    //                     "pid": "710200",
    //                     "index": "710207"
    //                 },
    //                 {
    //                     "value": "三民区",
    //                     "label": "三民区",
    //                     "pid": "710200",
    //                     "index": "710208"
    //                 },
    //                 {
    //                     "value": "左营区",
    //                     "label": "左营区",
    //                     "pid": "710200",
    //                     "index": "710209"
    //                 },
    //                 {
    //                     "value": "楠梓区",
    //                     "label": "楠梓区",
    //                     "pid": "710200",
    //                     "index": "710210"
    //                 },
    //                 {
    //                     "value": "小港区",
    //                     "label": "小港区",
    //                     "pid": "710200",
    //                     "index": "710211"
    //                 }
    //             ]
    //         },
    //         {
    //             "value": "基隆市",
    //             "label": "基隆市",
    //             "pid": "710000",
    //             "index": "710300",
    //             "children": [
    //                 {
    //                     "value": "仁爱区",
    //                     "label": "仁爱区",
    //                     "pid": "710300",
    //                     "index": "710301"
    //                 },
    //                 {
    //                     "value": "信义区",
    //                     "label": "信义区",
    //                     "pid": "710300",
    //                     "index": "710302"
    //                 },
    //                 {
    //                     "value": "中正区",
    //                     "label": "中正区",
    //                     "pid": "710300",
    //                     "index": "710303"
    //                 },
    //                 {
    //                     "value": "暖暖区",
    //                     "label": "暖暖区",
    //                     "pid": "710300",
    //                     "index": "710304"
    //                 },
    //                 {
    //                     "value": "安乐区",
    //                     "label": "安乐区",
    //                     "pid": "710300",
    //                     "index": "710305"
    //                 },
    //                 {
    //                     "value": "七堵区",
    //                     "label": "七堵区",
    //                     "pid": "710300",
    //                     "index": "710307"
    //                 }
    //             ]
    //         },
    //         {
    //             "value": "台中市",
    //             "label": "台中市",
    //             "pid": "710000",
    //             "index": "710400",
    //             "children": [
    //                 {
    //                     "value": "中区",
    //                     "label": "中区",
    //                     "pid": "710400",
    //                     "index": "710301"
    //                 },
    //                 {
    //                     "value": "东区",
    //                     "label": "东区",
    //                     "pid": "710400",
    //                     "index": "710302"
    //                 },
    //                 {
    //                     "value": "南区",
    //                     "label": "南区",
    //                     "pid": "710400",
    //                     "index": "710303"
    //                 },
    //                 {
    //                     "value": "西区",
    //                     "label": "西区",
    //                     "pid": "710400",
    //                     "index": "710304"
    //                 },
    //                 {
    //                     "value": "北区",
    //                     "label": "北区",
    //                     "pid": "710400",
    //                     "index": "710305"
    //                 },
    //                 {
    //                     "value": "北屯区",
    //                     "label": "北屯区",
    //                     "pid": "710400",
    //                     "index": "710306"
    //                 },
    //                 {
    //                     "value": "西屯区",
    //                     "label": "西屯区",
    //                     "pid": "710400",
    //                     "index": "710307"
    //                 },
    //                 {
    //                     "value": "南屯区",
    //                     "label": "南屯区",
    //                     "pid": "710400",
    //                     "index": "710308"
    //                 }
    //             ]
    //         },
    //         {
    //             "value": "台南市",
    //             "label": "台南市",
    //             "pid": "710000",
    //             "index": "710500",
    //             "children": [
    //                 {
    //                     "value": "中西区",
    //                     "label": "中西区",
    //                     "pid": "710500",
    //                     "index": "710501"
    //                 },
    //                 {
    //                     "value": "东区",
    //                     "label": "东区",
    //                     "pid": "710500",
    //                     "index": "710502"
    //                 },
    //                 {
    //                     "value": "南区",
    //                     "label": "南区",
    //                     "pid": "710500",
    //                     "index": "710503"
    //                 },
    //                 {
    //                     "value": "北区",
    //                     "label": "北区",
    //                     "pid": "710500",
    //                     "index": "710504"
    //                 },
    //                 {
    //                     "value": "安平区",
    //                     "label": "安平区",
    //                     "pid": "710500",
    //                     "index": "710505"
    //                 },
    //                 {
    //                     "value": "安南区",
    //                     "label": "安南区",
    //                     "pid": "710500",
    //                     "index": "710506"
    //                 }
    //             ]
    //         },
    //         {
    //             "value": "新竹市",
    //             "label": "新竹市",
    //             "pid": "710000",
    //             "index": "710600",
    //             "children": [
    //                 {
    //                     "value": "东区",
    //                     "label": "东区",
    //                     "pid": "710600",
    //                     "index": "710601"
    //                 },
    //                 {
    //                     "value": "北区",
    //                     "label": "北区",
    //                     "pid": "710600",
    //                     "index": "710602"
    //                 },
    //                 {
    //                     "value": "香山区",
    //                     "label": "香山区",
    //                     "pid": "710600",
    //                     "index": "710603"
    //                 }
    //             ]
    //         },
    //         {
    //             "value": "嘉义市",
    //             "label": "嘉义市",
    //             "pid": "710000",
    //             "index": "710700",
    //             "children": [
    //                 {
    //                     "value": "东区",
    //                     "label": "东区",
    //                     "pid": "710700",
    //                     "index": "710701"
    //                 },
    //                 {
    //                     "value": "西区",
    //                     "label": "西区",
    //                     "pid": "710700",
    //                     "index": "710702"
    //                 }
    //             ]
    //         }
    //     ]
    // },
    // {
    //     "value": "香港特别行政区",
    //     "label": "香港特别行政区",
    //     "pid": "86",
    //     "index": "810000",
    //     "children": [
    //         {
    //             "value": "中西區",
    //             "label": "中西區",
    //             "pid": "810000",
    //             "index": "810001"
    //         },
    //         {
    //             "value": "灣仔區",
    //             "label": "灣仔區",
    //             "pid": "810000",
    //             "index": "810002"
    //         },
    //         {
    //             "value": "東區",
    //             "label": "東區",
    //             "pid": "810000",
    //             "index": "810003"
    //         },
    //         {
    //             "value": "南區",
    //             "label": "南區",
    //             "pid": "810000",
    //             "index": "810004"
    //         },
    //         {
    //             "value": "油尖旺區",
    //             "label": "油尖旺區",
    //             "pid": "810000",
    //             "index": "810005"
    //         },
    //         {
    //             "value": "深水埗區",
    //             "label": "深水埗區",
    //             "pid": "810000",
    //             "index": "810006"
    //         },
    //         {
    //             "value": "九龍城區",
    //             "label": "九龍城區",
    //             "pid": "810000",
    //             "index": "810007"
    //         },
    //         {
    //             "value": "黃大仙區",
    //             "label": "黃大仙區",
    //             "pid": "810000",
    //             "index": "810008"
    //         },
    //         {
    //             "value": "觀塘區",
    //             "label": "觀塘區",
    //             "pid": "810000",
    //             "index": "810009"
    //         },
    //         {
    //             "value": "荃灣區",
    //             "label": "荃灣區",
    //             "pid": "810000",
    //             "index": "810010"
    //         },
    //         {
    //             "value": "屯門區",
    //             "label": "屯門區",
    //             "pid": "810000",
    //             "index": "810011"
    //         },
    //         {
    //             "value": "元朗區",
    //             "label": "元朗區",
    //             "pid": "810000",
    //             "index": "810012"
    //         },
    //         {
    //             "value": "北區",
    //             "label": "北區",
    //             "pid": "810000",
    //             "index": "810013"
    //         },
    //         {
    //             "value": "大埔區",
    //             "label": "大埔區",
    //             "pid": "810000",
    //             "index": "810014"
    //         },
    //         {
    //             "value": "西貢區",
    //             "label": "西貢區",
    //             "pid": "810000",
    //             "index": "810015"
    //         },
    //         {
    //             "value": "沙田區",
    //             "label": "沙田區",
    //             "pid": "810000",
    //             "index": "810016"
    //         },
    //         {
    //             "value": "葵青區",
    //             "label": "葵青區",
    //             "pid": "810000",
    //             "index": "810017"
    //         },
    //         {
    //             "value": "離島區",
    //             "label": "離島區",
    //             "pid": "810000",
    //             "index": "810018"
    //         }
    //     ]
    // },
    // {
    //     "value": "澳门特别行政区",
    //     "label": "澳门特别行政区",
    //     "pid": "86",
    //     "index": "820000",
    //     "children": [
    //         {
    //             "value": "花地瑪堂區",
    //             "label": "花地瑪堂區",
    //             "pid": "820000",
    //             "index": "820001"
    //         },
    //         {
    //             "value": "花王堂區",
    //             "label": "花王堂區",
    //             "pid": "820000",
    //             "index": "820002"
    //         },
    //         {
    //             "value": "望德堂區",
    //             "label": "望德堂區",
    //             "pid": "820000",
    //             "index": "820003"
    //         },
    //         {
    //             "value": "大堂區",
    //             "label": "大堂區",
    //             "pid": "820000",
    //             "index": "820004"
    //         },
    //         {
    //             "value": "風順堂區",
    //             "label": "風順堂區",
    //             "pid": "820000",
    //             "index": "820005"
    //         },
    //         {
    //             "value": "嘉模堂區",
    //             "label": "嘉模堂區",
    //             "pid": "820000",
    //             "index": "820006"
    //         },
    //         {
    //             "value": "路氹填海區",
    //             "label": "路氹填海區",
    //             "pid": "820000",
    //             "index": "820007"
    //         },
    //         {
    //             "value": "聖方濟各堂區",
    //             "label": "聖方濟各堂區",
    //             "pid": "820000",
    //             "index": "820008"
    //         }
    //     ]
    // }
]
// 省市选择
export const _pvCity = [
    {
        "value": "北京市",
        "label": "北京市",
        "pid": "86",
        "index": "110000",
        "children": [
            {
                "value": "北京市",
                "label": "北京市",
                "pid": "110000",
                "index": "110100",
            }
        ]
    },
    {
        "value": "天津市",
        "label": "天津市",
        "pid": "86",
        "index": "120000",
        "children": [
            {
                "value": "天津市",
                "label": "天津市",
                "pid": "120000",
                "index": "120100",

            }
        ]
    },
    {
        "value": "河北省",
        "label": "河北省",
        "pid": "86",
        "index": "130000",
        "children": [
            {
                "value": "石家庄市",
                "label": "石家庄市",
                "pid": "130000",
                "index": "130100",

            },
            {
                "value": "唐山市",
                "label": "唐山市",
                "pid": "130000",
                "index": "130200",

            },
            {
                "value": "秦皇岛市",
                "label": "秦皇岛市",
                "pid": "130000",
                "index": "130300",

            },
            {
                "value": "邯郸市",
                "label": "邯郸市",
                "pid": "130000",
                "index": "130400",

            },
            {
                "value": "邢台市",
                "label": "邢台市",
                "pid": "130000",
                "index": "130500",

            },
            {
                "value": "保定市",
                "label": "保定市",
                "pid": "130000",
                "index": "130600",

            },
            {
                "value": "张家口市",
                "label": "张家口市",
                "pid": "130000",
                "index": "130700",

            },
            {
                "value": "承德市",
                "label": "承德市",
                "pid": "130000",
                "index": "130800",

            },
            {
                "value": "沧州市",
                "label": "沧州市",
                "pid": "130000",
                "index": "130900",

            },
            {
                "value": "廊坊市",
                "label": "廊坊市",
                "pid": "130000",
                "index": "131000",

            },
            {
                "value": "衡水市",
                "label": "衡水市",
                "pid": "130000",
                "index": "131100",

            }
        ]
    },
    {
        "value": "山西省",
        "label": "山西省",
        "pid": "86",
        "index": "140000",
        "children": [
            {
                "value": "太原市",
                "label": "太原市",
                "pid": "140000",
                "index": "140100",

            },
            {
                "value": "大同市",
                "label": "大同市",
                "pid": "140000",
                "index": "140200",

            },
            {
                "value": "阳泉市",
                "label": "阳泉市",
                "pid": "140000",
                "index": "140300",

            },
            {
                "value": "长治市",
                "label": "长治市",
                "pid": "140000",
                "index": "140400",

            },
            {
                "value": "晋城市",
                "label": "晋城市",
                "pid": "140000",
                "index": "140500",

            },
            {
                "value": "朔州市",
                "label": "朔州市",
                "pid": "140000",
                "index": "140600",

            },
            {
                "value": "晋中市",
                "label": "晋中市",
                "pid": "140000",
                "index": "140700",

            },
            {
                "value": "运城市",
                "label": "运城市",
                "pid": "140000",
                "index": "140800",

            },
            {
                "value": "忻州市",
                "label": "忻州市",
                "pid": "140000",
                "index": "140900",

            },
            {
                "value": "临汾市",
                "label": "临汾市",
                "pid": "140000",
                "index": "141000",

            },
            {
                "value": "吕梁市",
                "label": "吕梁市",
                "pid": "140000",
                "index": "141100",

            }
        ]
    },
    {
        "value": "内蒙古自治区",
        "label": "内蒙古自治区",
        "pid": "86",
        "index": "150000",
        "children": [
            {
                "value": "呼和浩特市",
                "label": "呼和浩特市",
                "pid": "150000",
                "index": "150100",

            },
            {
                "value": "包头市",
                "label": "包头市",
                "pid": "150000",
                "index": "150200",

            },
            {
                "value": "乌海市",
                "label": "乌海市",
                "pid": "150000",
                "index": "150300",

            },
            {
                "value": "赤峰市",
                "label": "赤峰市",
                "pid": "150000",
                "index": "150400",

            },
            {
                "value": "通辽市",
                "label": "通辽市",
                "pid": "150000",
                "index": "150500",

            },
            {
                "value": "鄂尔多斯市",
                "label": "鄂尔多斯市",
                "pid": "150000",
                "index": "150600",

            },
            {
                "value": "呼伦贝尔市",
                "label": "呼伦贝尔市",
                "pid": "150000",
                "index": "150700",

            },
            {
                "value": "巴彦淖尔市",
                "label": "巴彦淖尔市",
                "pid": "150000",
                "index": "150800",

            },
            {
                "value": "乌兰察布市",
                "label": "乌兰察布市",
                "pid": "150000",
                "index": "150900",

            },
            {
                "value": "兴安盟",
                "label": "兴安盟",
                "pid": "150000",
                "index": "152200",

            },
            {
                "value": "锡林郭勒盟",
                "label": "锡林郭勒盟",
                "pid": "150000",
                "index": "152500",

            },
            {
                "value": "阿拉善盟",
                "label": "阿拉善盟",
                "pid": "150000",
                "index": "152900",

            }
        ]
    },
    {
        "value": "辽宁省",
        "label": "辽宁省",
        "pid": "86",
        "index": "210000",
        "children": [
            {
                "value": "沈阳市",
                "label": "沈阳市",
                "pid": "210000",
                "index": "210100",

            },
            {
                "value": "大连市",
                "label": "大连市",
                "pid": "210000",
                "index": "210200",

            },
            {
                "value": "鞍山市",
                "label": "鞍山市",
                "pid": "210000",
                "index": "210300",

            },
            {
                "value": "抚顺市",
                "label": "抚顺市",
                "pid": "210000",
                "index": "210400",

            },
            {
                "value": "本溪市",
                "label": "本溪市",
                "pid": "210000",
                "index": "210500",

            },
            {
                "value": "丹东市",
                "label": "丹东市",
                "pid": "210000",
                "index": "210600",

            },
            {
                "value": "锦州市",
                "label": "锦州市",
                "pid": "210000",
                "index": "210700",

            },
            {
                "value": "营口市",
                "label": "营口市",
                "pid": "210000",
                "index": "210800",

            },
            {
                "value": "阜新市",
                "label": "阜新市",
                "pid": "210000",
                "index": "210900",

            },
            {
                "value": "辽阳市",
                "label": "辽阳市",
                "pid": "210000",
                "index": "211000",

            },
            {
                "value": "盘锦市",
                "label": "盘锦市",
                "pid": "210000",
                "index": "211100",

            },
            {
                "value": "铁岭市",
                "label": "铁岭市",
                "pid": "210000",
                "index": "211200",

            },
            {
                "value": "朝阳市",
                "label": "朝阳市",
                "pid": "210000",
                "index": "211300",

            },
            {
                "value": "葫芦岛市",
                "label": "葫芦岛市",
                "pid": "210000",
                "index": "211400",

            }
        ]
    },
    {
        "value": "吉林省",
        "label": "吉林省",
        "pid": "86",
        "index": "220000",
        "children": [
            {
                "value": "长春市",
                "label": "长春市",
                "pid": "220000",
                "index": "220100",

            },
            {
                "value": "吉林市",
                "label": "吉林市",
                "pid": "220000",
                "index": "220200",

            },
            {
                "value": "四平市",
                "label": "四平市",
                "pid": "220000",
                "index": "220300",

            },
            {
                "value": "辽源市",
                "label": "辽源市",
                "pid": "220000",
                "index": "220400",

            },
            {
                "value": "通化市",
                "label": "通化市",
                "pid": "220000",
                "index": "220500",

            },
            {
                "value": "白山市",
                "label": "白山市",
                "pid": "220000",
                "index": "220600",

            },
            {
                "value": "松原市",
                "label": "松原市",
                "pid": "220000",
                "index": "220700",

            },
            {
                "value": "白城市",
                "label": "白城市",
                "pid": "220000",
                "index": "220800",

            },
            {
                "value": "延边朝鲜族自治州",
                "label": "延边朝鲜族自治州",
                "pid": "220000",
                "index": "222400",

            }
        ]
    },
    {
        "value": "黑龙江省",
        "label": "黑龙江省",
        "pid": "86",
        "index": "230000",
        "children": [
            {
                "value": "哈尔滨市",
                "label": "哈尔滨市",
                "pid": "230000",
                "index": "230100",

            },
            {
                "value": "齐齐哈尔市",
                "label": "齐齐哈尔市",
                "pid": "230000",
                "index": "230200",

            },
            {
                "value": "鸡西市",
                "label": "鸡西市",
                "pid": "230000",
                "index": "230300",

            },
            {
                "value": "鹤岗市",
                "label": "鹤岗市",
                "pid": "230000",
                "index": "230400",

            },
            {
                "value": "双鸭山市",
                "label": "双鸭山市",
                "pid": "230000",
                "index": "230500",

            },
            {
                "value": "大庆市",
                "label": "大庆市",
                "pid": "230000",
                "index": "230600",

            },
            {
                "value": "伊春市",
                "label": "伊春市",
                "pid": "230000",
                "index": "230700",

            },
            {
                "value": "佳木斯市",
                "label": "佳木斯市",
                "pid": "230000",
                "index": "230800",

            },
            {
                "value": "七台河市",
                "label": "七台河市",
                "pid": "230000",
                "index": "230900",

            },
            {
                "value": "牡丹江市",
                "label": "牡丹江市",
                "pid": "230000",
                "index": "231000",

            },
            {
                "value": "黑河市",
                "label": "黑河市",
                "pid": "230000",
                "index": "231100",

            },
            {
                "value": "绥化市",
                "label": "绥化市",
                "pid": "230000",
                "index": "231200",

            },
            {
                "value": "大兴安岭地区",
                "label": "大兴安岭地区",
                "pid": "230000",
                "index": "232700",

            }
        ]
    },
    {
        "value": "上海市",
        "label": "上海市",
        "pid": "86",
        "index": "310000",
        "children": [
            {
                "value": "上海市",
                "label": "上海市",
                "pid": "310000",
                "index": "310100",

            }
        ]
    },
    {
        "value": "江苏省",
        "label": "江苏省",
        "pid": "86",
        "index": "320000",
        "children": [
            {
                "value": "南京市",
                "label": "南京市",
                "pid": "320000",
                "index": "320100",

            },
            {
                "value": "无锡市",
                "label": "无锡市",
                "pid": "320000",
                "index": "320200",

            },
            {
                "value": "徐州市",
                "label": "徐州市",
                "pid": "320000",
                "index": "320300",

            },
            {
                "value": "常州市",
                "label": "常州市",
                "pid": "320000",
                "index": "320400",

            },
            {
                "value": "苏州市",
                "label": "苏州市",
                "pid": "320000",
                "index": "320500",

            },
            {
                "value": "南通市",
                "label": "南通市",
                "pid": "320000",
                "index": "320600",

            },
            {
                "value": "连云港市",
                "label": "连云港市",
                "pid": "320000",
                "index": "320700",

            },
            {
                "value": "淮安市",
                "label": "淮安市",
                "pid": "320000",
                "index": "320800",

            },
            {
                "value": "盐城市",
                "label": "盐城市",
                "pid": "320000",
                "index": "320900",

            },
            {
                "value": "扬州市",
                "label": "扬州市",
                "pid": "320000",
                "index": "321000",

            },
            {
                "value": "镇江市",
                "label": "镇江市",
                "pid": "320000",
                "index": "321100",

            },
            {
                "value": "泰州市",
                "label": "泰州市",
                "pid": "320000",
                "index": "321200",

            },
            {
                "value": "宿迁市",
                "label": "宿迁市",
                "pid": "320000",
                "index": "321300",

            }
        ]
    },
    {
        "value": "浙江省",
        "label": "浙江省",
        "pid": "86",
        "index": "330000",
        "children": [
            {
                "value": "杭州市",
                "label": "杭州市",
                "pid": "330000",
                "index": "330100",

            },
            {
                "value": "宁波市",
                "label": "宁波市",
                "pid": "330000",
                "index": "330200",

            },
            {
                "value": "温州市",
                "label": "温州市",
                "pid": "330000",
                "index": "330300",

            },
            {
                "value": "嘉兴市",
                "label": "嘉兴市",
                "pid": "330000",
                "index": "330400",

            },
            {
                "value": "湖州市",
                "label": "湖州市",
                "pid": "330000",
                "index": "330500",

            },
            {
                "value": "绍兴市",
                "label": "绍兴市",
                "pid": "330000",
                "index": "330600",

            },
            {
                "value": "金华市",
                "label": "金华市",
                "pid": "330000",
                "index": "330700",

            },
            {
                "value": "衢州市",
                "label": "衢州市",
                "pid": "330000",
                "index": "330800",

            },
            {
                "value": "舟山市",
                "label": "舟山市",
                "pid": "330000",
                "index": "330900",

            },
            {
                "value": "台州市",
                "label": "台州市",
                "pid": "330000",
                "index": "331000",

            },
            {
                "value": "丽水市",
                "label": "丽水市",
                "pid": "330000",
                "index": "331100",

            }
        ]
    },
    {
        "value": "安徽省",
        "label": "安徽省",
        "pid": "86",
        "index": "340000",
        "children": [
            {
                "value": "合肥市",
                "label": "合肥市",
                "pid": "340000",
                "index": "340100",

            },
            {
                "value": "芜湖市",
                "label": "芜湖市",
                "pid": "340000",
                "index": "340200",

            },
            {
                "value": "蚌埠市",
                "label": "蚌埠市",
                "pid": "340000",
                "index": "340300",

            },
            {
                "value": "淮南市",
                "label": "淮南市",
                "pid": "340000",
                "index": "340400",

            },
            {
                "value": "马鞍山市",
                "label": "马鞍山市",
                "pid": "340000",
                "index": "340500",

            },
            {
                "value": "淮北市",
                "label": "淮北市",
                "pid": "340000",
                "index": "340600",

            },
            {
                "value": "铜陵市",
                "label": "铜陵市",
                "pid": "340000",
                "index": "340700",

            },
            {
                "value": "安庆市",
                "label": "安庆市",
                "pid": "340000",
                "index": "340800",

            },
            {
                "value": "黄山市",
                "label": "黄山市",
                "pid": "340000",
                "index": "341000",

            },
            {
                "value": "滁州市",
                "label": "滁州市",
                "pid": "340000",
                "index": "341100",

            },
            {
                "value": "阜阳市",
                "label": "阜阳市",
                "pid": "340000",
                "index": "341200",

            },
            {
                "value": "宿州市",
                "label": "宿州市",
                "pid": "340000",
                "index": "341300",

            },
            {
                "value": "六安市",
                "label": "六安市",
                "pid": "340000",
                "index": "341500",

            },
            {
                "value": "亳州市",
                "label": "亳州市",
                "pid": "340000",
                "index": "341600",

            },
            {
                "value": "池州市",
                "label": "池州市",
                "pid": "340000",
                "index": "341700",

            },
            {
                "value": "宣城市",
                "label": "宣城市",
                "pid": "340000",
                "index": "341800",

            }
        ]
    },
    {
        "value": "福建省",
        "label": "福建省",
        "pid": "86",
        "index": "350000",
        "children": [
            {
                "value": "福州市",
                "label": "福州市",
                "pid": "350000",
                "index": "350100",

            },
            {
                "value": "厦门市",
                "label": "厦门市",
                "pid": "350000",
                "index": "350200",

            },
            {
                "value": "莆田市",
                "label": "莆田市",
                "pid": "350000",
                "index": "350300",

            },
            {
                "value": "三明市",
                "label": "三明市",
                "pid": "350000",
                "index": "350400",

            },
            {
                "value": "泉州市",
                "label": "泉州市",
                "pid": "350000",
                "index": "350500",

            },
            {
                "value": "漳州市",
                "label": "漳州市",
                "pid": "350000",
                "index": "350600",

            },
            {
                "value": "南平市",
                "label": "南平市",
                "pid": "350000",
                "index": "350700",

            },
            {
                "value": "龙岩市",
                "label": "龙岩市",
                "pid": "350000",
                "index": "350800",

            },
            {
                "value": "宁德市",
                "label": "宁德市",
                "pid": "350000",
                "index": "350900",

            }
        ]
    },
    {
        "value": "江西省",
        "label": "江西省",
        "pid": "86",
        "index": "360000",
        "children": [
            {
                "value": "南昌市",
                "label": "南昌市",
                "pid": "360000",
                "index": "360100",

            },
            {
                "value": "景德镇市",
                "label": "景德镇市",
                "pid": "360000",
                "index": "360200",

            },
            {
                "value": "萍乡市",
                "label": "萍乡市",
                "pid": "360000",
                "index": "360300",

            },
            {
                "value": "九江市",
                "label": "九江市",
                "pid": "360000",
                "index": "360400",

            },
            {
                "value": "新余市",
                "label": "新余市",
                "pid": "360000",
                "index": "360500",

            },
            {
                "value": "鹰潭市",
                "label": "鹰潭市",
                "pid": "360000",
                "index": "360600",

            },
            {
                "value": "赣州市",
                "label": "赣州市",
                "pid": "360000",
                "index": "360700",

            },
            {
                "value": "吉安市",
                "label": "吉安市",
                "pid": "360000",
                "index": "360800",

            },
            {
                "value": "宜春市",
                "label": "宜春市",
                "pid": "360000",
                "index": "360900",

            },
            {
                "value": "抚州市",
                "label": "抚州市",
                "pid": "360000",
                "index": "361000",

            },
            {
                "value": "上饶市",
                "label": "上饶市",
                "pid": "360000",
                "index": "361100",

            }
        ]
    },
    {
        "value": "山东省",
        "label": "山东省",
        "pid": "86",
        "index": "370000",
        "children": [
            {
                "value": "济南市",
                "label": "济南市",
                "pid": "370000",
                "index": "370100",

            },
            {
                "value": "青岛市",
                "label": "青岛市",
                "pid": "370000",
                "index": "370200",

            },
            {
                "value": "淄博市",
                "label": "淄博市",
                "pid": "370000",
                "index": "370300",

            },
            {
                "value": "枣庄市",
                "label": "枣庄市",
                "pid": "370000",
                "index": "370400",

            },
            {
                "value": "东营市",
                "label": "东营市",
                "pid": "370000",
                "index": "370500",

            },
            {
                "value": "烟台市",
                "label": "烟台市",
                "pid": "370000",
                "index": "370600",

            },
            {
                "value": "潍坊市",
                "label": "潍坊市",
                "pid": "370000",
                "index": "370700",

            },
            {
                "value": "济宁市",
                "label": "济宁市",
                "pid": "370000",
                "index": "370800",

            },
            {
                "value": "泰安市",
                "label": "泰安市",
                "pid": "370000",
                "index": "370900",

            },
            {
                "value": "威海市",
                "label": "威海市",
                "pid": "370000",
                "index": "371000",

            },
            {
                "value": "日照市",
                "label": "日照市",
                "pid": "370000",
                "index": "371100",

            },
            {
                "value": "临沂市",
                "label": "临沂市",
                "pid": "370000",
                "index": "371300",

            },
            {
                "value": "德州市",
                "label": "德州市",
                "pid": "370000",
                "index": "371400",

            },
            {
                "value": "聊城市",
                "label": "聊城市",
                "pid": "370000",
                "index": "371500",

            },
            {
                "value": "滨州市",
                "label": "滨州市",
                "pid": "370000",
                "index": "371600",

            },
            {
                "value": "菏泽市",
                "label": "菏泽市",
                "pid": "370000",
                "index": "371700",

            }
        ]
    },
    {
        "value": "河南省",
        "label": "河南省",
        "pid": "86",
        "index": "410000",
        "children": [
            {
                "value": "郑州市",
                "label": "郑州市",
                "pid": "410000",
                "index": "410100",

            },
            {
                "value": "开封市",
                "label": "开封市",
                "pid": "410000",
                "index": "410200",

            },
            {
                "value": "洛阳市",
                "label": "洛阳市",
                "pid": "410000",
                "index": "410300",

            },
            {
                "value": "平顶山市",
                "label": "平顶山市",
                "pid": "410000",
                "index": "410400",

            },
            {
                "value": "安阳市",
                "label": "安阳市",
                "pid": "410000",
                "index": "410500",

            },
            {
                "value": "鹤壁市",
                "label": "鹤壁市",
                "pid": "410000",
                "index": "410600",

            },
            {
                "value": "新乡市",
                "label": "新乡市",
                "pid": "410000",
                "index": "410700",

            },
            {
                "value": "焦作市",
                "label": "焦作市",
                "pid": "410000",
                "index": "410800",

            },
            {
                "value": "濮阳市",
                "label": "濮阳市",
                "pid": "410000",
                "index": "410900",

            },
            {
                "value": "许昌市",
                "label": "许昌市",
                "pid": "410000",
                "index": "411000",

            },
            {
                "value": "漯河市",
                "label": "漯河市",
                "pid": "410000",
                "index": "411100",
            },
            {
                "value": "三门峡市",
                "label": "三门峡市",
                "pid": "410000",
                "index": "411200",

            },
            {
                "value": "南阳市",
                "label": "南阳市",
                "pid": "410000",
                "index": "411300",

            },
            {
                "value": "商丘市",
                "label": "商丘市",
                "pid": "410000",
                "index": "411400",

            },
            {
                "value": "信阳市",
                "label": "信阳市",
                "pid": "410000",
                "index": "411500",

            },
            {
                "value": "周口市",
                "label": "周口市",
                "pid": "410000",
                "index": "411600",

            },
            {
                "value": "驻马店市",
                "label": "驻马店市",
                "pid": "410000",
                "index": "411700",

            },
            {
                "value": "省直辖县级行政区划",
                "label": "省直辖县级行政区划",
                "pid": "410000",
                "index": "419000",
                "children": [
                    {
                        "value": "济源市",
                        "label": "济源市",
                        "pid": "419000",
                        "index": "419001"
                    }
                ]
            }
        ]
    },
    {
        "value": "湖北省",
        "label": "湖北省",
        "pid": "86",
        "index": "420000",
        "children": [
            {
                "value": "武汉市",
                "label": "武汉市",
                "pid": "420000",
                "index": "420100",

            },
            {
                "value": "黄石市",
                "label": "黄石市",
                "pid": "420000",
                "index": "420200",

            },
            {
                "value": "十堰市",
                "label": "十堰市",
                "pid": "420000",
                "index": "420300",

            },
            {
                "value": "宜昌市",
                "label": "宜昌市",
                "pid": "420000",
                "index": "420500",

            },
            {
                "value": "襄阳市",
                "label": "襄阳市",
                "pid": "420000",
                "index": "420600",

            },
            {
                "value": "鄂州市",
                "label": "鄂州市",
                "pid": "420000",
                "index": "420700",

            },
            {
                "value": "荆门市",
                "label": "荆门市",
                "pid": "420000",
                "index": "420800",

            },
            {
                "value": "孝感市",
                "label": "孝感市",
                "pid": "420000",
                "index": "420900",

            },
            {
                "value": "荆州市",
                "label": "荆州市",
                "pid": "420000",
                "index": "421000",

            },
            {
                "value": "黄冈市",
                "label": "黄冈市",
                "pid": "420000",
                "index": "421100",

            },
            {
                "value": "咸宁市",
                "label": "咸宁市",
                "pid": "420000",
                "index": "421200",

            },
            {
                "value": "随州市",
                "label": "随州市",
                "pid": "420000",
                "index": "421300",

            },
            {
                "value": "恩施土家族苗族自治州",
                "label": "恩施土家族苗族自治州",
                "pid": "420000",
                "index": "422800",

            },
            {
                "value": "省直辖县级行政区划",
                "label": "省直辖县级行政区划",
                "pid": "420000",
                "index": "429000",

            }
        ]
    },
    {
        "value": "湖南省",
        "label": "湖南省",
        "pid": "86",
        "index": "430000",
        "children": [
            {
                "value": "长沙市",
                "label": "长沙市",
                "pid": "430000",
                "index": "430100",

            },
            {
                "value": "株洲市",
                "label": "株洲市",
                "pid": "430000",
                "index": "430200",

            },
            {
                "value": "湘潭市",
                "label": "湘潭市",
                "pid": "430000",
                "index": "430300",

            },
            {
                "value": "衡阳市",
                "label": "衡阳市",
                "pid": "430000",
                "index": "430400",

            },
            {
                "value": "邵阳市",
                "label": "邵阳市",
                "pid": "430000",
                "index": "430500",

            },
            {
                "value": "岳阳市",
                "label": "岳阳市",
                "pid": "430000",
                "index": "430600",

            },
            {
                "value": "常德市",
                "label": "常德市",
                "pid": "430000",
                "index": "430700",

            },
            {
                "value": "张家界市",
                "label": "张家界市",
                "pid": "430000",
                "index": "430800",

            },
            {
                "value": "益阳市",
                "label": "益阳市",
                "pid": "430000",
                "index": "430900",

            },
            {
                "value": "郴州市",
                "label": "郴州市",
                "pid": "430000",
                "index": "431000",

            },
            {
                "value": "永州市",
                "label": "永州市",
                "pid": "430000",
                "index": "431100",

            },
            {
                "value": "怀化市",
                "label": "怀化市",
                "pid": "430000",
                "index": "431200",

            },
            {
                "value": "娄底市",
                "label": "娄底市",
                "pid": "430000",
                "index": "431300",

            },
            {
                "value": "湘西土家族苗族自治州",
                "label": "湘西土家族苗族自治州",
                "pid": "430000",
                "index": "433100",

            }
        ]
    },
    {
        "value": "广东省",
        "label": "广东省",
        "pid": "86",
        "index": "440000",
        "children": [
            {
                "value": "广州市",
                "label": "广州市",
                "pid": "440000",
                "index": "440100",

            },
            {
                "value": "韶关市",
                "label": "韶关市",
                "pid": "440000",
                "index": "440200",

            },
            {
                "value": "深圳市",
                "label": "深圳市",
                "pid": "440000",
                "index": "440300",

            },
            {
                "value": "珠海市",
                "label": "珠海市",
                "pid": "440000",
                "index": "440400",

            },
            {
                "value": "汕头市",
                "label": "汕头市",
                "pid": "440000",
                "index": "440500",

            },
            {
                "value": "佛山市",
                "label": "佛山市",
                "pid": "440000",
                "index": "440600",

            },
            {
                "value": "江门市",
                "label": "江门市",
                "pid": "440000",
                "index": "440700",

            },
            {
                "value": "湛江市",
                "label": "湛江市",
                "pid": "440000",
                "index": "440800",

            },
            {
                "value": "茂名市",
                "label": "茂名市",
                "pid": "440000",
                "index": "440900",

            },
            {
                "value": "肇庆市",
                "label": "肇庆市",
                "pid": "440000",
                "index": "441200",

            },
            {
                "value": "惠州市",
                "label": "惠州市",
                "pid": "440000",
                "index": "441300",

            },
            {
                "value": "梅州市",
                "label": "梅州市",
                "pid": "440000",
                "index": "441400",

            },
            {
                "value": "汕尾市",
                "label": "汕尾市",
                "pid": "440000",
                "index": "441500",

            },
            {
                "value": "河源市",
                "label": "河源市",
                "pid": "440000",
                "index": "441600",

            },
            {
                "value": "阳江市",
                "label": "阳江市",
                "pid": "440000",
                "index": "441700",

            },
            {
                "value": "清远市",
                "label": "清远市",
                "pid": "440000",
                "index": "441800",

            },
            {
                "value": "东莞市",
                "label": "东莞市",
                "pid": "440000",
                "index": "441900",

            },
            {
                "value": "中山市",
                "label": "中山市",
                "pid": "440000",
                "index": "442000",

            },
            {
                "value": "潮州市",
                "label": "潮州市",
                "pid": "440000",
                "index": "445100",

            },
            {
                "value": "揭阳市",
                "label": "揭阳市",
                "pid": "440000",
                "index": "445200",

            },
            {
                "value": "云浮市",
                "label": "云浮市",
                "pid": "440000",
                "index": "445300",

            }
        ]
    },
    {
        "value": "广西壮族自治区",
        "label": "广西壮族自治区",
        "pid": "86",
        "index": "450000",
        "children": [
            {
                "value": "南宁市",
                "label": "南宁市",
                "pid": "450000",
                "index": "450100",

            },
            {
                "value": "柳州市",
                "label": "柳州市",
                "pid": "450000",
                "index": "450200",

            },
            {
                "value": "桂林市",
                "label": "桂林市",
                "pid": "450000",
                "index": "450300",

            },
            {
                "value": "梧州市",
                "label": "梧州市",
                "pid": "450000",
                "index": "450400",

            },
            {
                "value": "北海市",
                "label": "北海市",
                "pid": "450000",
                "index": "450500",

            },
            {
                "value": "防城港市",
                "label": "防城港市",
                "pid": "450000",
                "index": "450600",

            },
            {
                "value": "钦州市",
                "label": "钦州市",
                "pid": "450000",
                "index": "450700",

            },
            {
                "value": "贵港市",
                "label": "贵港市",
                "pid": "450000",
                "index": "450800",

            },
            {
                "value": "玉林市",
                "label": "玉林市",
                "pid": "450000",
                "index": "450900",

            },
            {
                "value": "百色市",
                "label": "百色市",
                "pid": "450000",
                "index": "451000",

            },
            {
                "value": "贺州市",
                "label": "贺州市",
                "pid": "450000",
                "index": "451100",

            },
            {
                "value": "河池市",
                "label": "河池市",
                "pid": "450000",
                "index": "451200",

            },
            {
                "value": "来宾市",
                "label": "来宾市",
                "pid": "450000",
                "index": "451300",

            },
            {
                "value": "崇左市",
                "label": "崇左市",
                "pid": "450000",
                "index": "451400",

            }
        ]
    },
    {
        "value": "海南省",
        "label": "海南省",
        "pid": "86",
        "index": "460000",
        "children": [
            {
                "value": "海口市",
                "label": "海口市",
                "pid": "460000",
                "index": "460100",

            },
            {
                "value": "三亚市",
                "label": "三亚市",
                "pid": "460000",
                "index": "460200",

            },
            {
                "value": "三沙市",
                "label": "三沙市",
                "pid": "460000",
                "index": "460300",

            },
            {
                "value": "儋州市",
                "label": "儋州市",
                "pid": "460000",
                "index": "460400",

            },
            {
                "value": "省直辖县级行政区划",
                "label": "省直辖县级行政区划",
                "pid": "460000",
                "index": "469000",

            }
        ]
    },
    {
        "value": "重庆市",
        "label": "重庆市",
        "pid": "86",
        "index": "500000",
        "children": [
            {
                "value": "重庆市",
                "label": "重庆市",
                "pid": "500000",
                "index": "500100",

            },
            // {
            //     "value": "县",
            //     "label": "县",
            //     "pid": "500000",
            //     "index": "500200",
            //     "children": [
            //         {
            //             "value": "城口县",
            //             "label": "城口县",
            //             "pid": "500200",
            //             "index": "500229"
            //         },
            //         {
            //             "value": "丰都县",
            //             "label": "丰都县",
            //             "pid": "500200",
            //             "index": "500230"
            //         },
            //         {
            //             "value": "垫江县",
            //             "label": "垫江县",
            //             "pid": "500200",
            //             "index": "500231"
            //         },
            //         {
            //             "value": "忠县",
            //             "label": "忠县",
            //             "pid": "500200",
            //             "index": "500233"
            //         },
            //         {
            //             "value": "云阳县",
            //             "label": "云阳县",
            //             "pid": "500200",
            //             "index": "500235"
            //         },
            //         {
            //             "value": "奉节县",
            //             "label": "奉节县",
            //             "pid": "500200",
            //             "index": "500236"
            //         },
            //         {
            //             "value": "巫山县",
            //             "label": "巫山县",
            //             "pid": "500200",
            //             "index": "500237"
            //         },
            //         {
            //             "value": "巫溪县",
            //             "label": "巫溪县",
            //             "pid": "500200",
            //             "index": "500238"
            //         },
            //         {
            //             "value": "石柱土家族自治县",
            //             "label": "石柱土家族自治县",
            //             "pid": "500200",
            //             "index": "500240"
            //         },
            //         {
            //             "value": "秀山土家族苗族自治县",
            //             "label": "秀山土家族苗族自治县",
            //             "pid": "500200",
            //             "index": "500241"
            //         },
            //         {
            //             "value": "酉阳土家族苗族自治县",
            //             "label": "酉阳土家族苗族自治县",
            //             "pid": "500200",
            //             "index": "500242"
            //         },
            //         {
            //             "value": "彭水苗族土家族自治县",
            //             "label": "彭水苗族土家族自治县",
            //             "pid": "500200",
            //             "index": "500243"
            //         }
            //     ]
            // }
        ]
    },
    {
        "value": "四川省",
        "label": "四川省",
        "pid": "86",
        "index": "510000",
        "children": [
            {
                "value": "成都市",
                "label": "成都市",
                "pid": "510000",
                "index": "510100",

            },
            {
                "value": "自贡市",
                "label": "自贡市",
                "pid": "510000",
                "index": "510300",

            },
            {
                "value": "攀枝花市",
                "label": "攀枝花市",
                "pid": "510000",
                "index": "510400",

            },
            {
                "value": "泸州市",
                "label": "泸州市",
                "pid": "510000",
                "index": "510500",

            },
            {
                "value": "德阳市",
                "label": "德阳市",
                "pid": "510000",
                "index": "510600",

            },
            {
                "value": "绵阳市",
                "label": "绵阳市",
                "pid": "510000",
                "index": "510700",

            },
            {
                "value": "广元市",
                "label": "广元市",
                "pid": "510000",
                "index": "510800",

            },
            {
                "value": "遂宁市",
                "label": "遂宁市",
                "pid": "510000",
                "index": "510900",

            },
            {
                "value": "内江市",
                "label": "内江市",
                "pid": "510000",
                "index": "511000",

            },
            {
                "value": "乐山市",
                "label": "乐山市",
                "pid": "510000",
                "index": "511100",

            },
            {
                "value": "南充市",
                "label": "南充市",
                "pid": "510000",
                "index": "511300",

            },
            {
                "value": "眉山市",
                "label": "眉山市",
                "pid": "510000",
                "index": "511400",

            },
            {
                "value": "宜宾市",
                "label": "宜宾市",
                "pid": "510000",
                "index": "511500",

            },
            {
                "value": "广安市",
                "label": "广安市",
                "pid": "510000",
                "index": "511600",

            },
            {
                "value": "达州市",
                "label": "达州市",
                "pid": "510000",
                "index": "511700",

            },
            {
                "value": "雅安市",
                "label": "雅安市",
                "pid": "510000",
                "index": "511800",

            },
            {
                "value": "巴中市",
                "label": "巴中市",
                "pid": "510000",
                "index": "511900",

            },
            {
                "value": "资阳市",
                "label": "资阳市",
                "pid": "510000",
                "index": "512000",

            },
            {
                "value": "阿坝藏族羌族自治州",
                "label": "阿坝藏族羌族自治州",
                "pid": "510000",
                "index": "513200",

            },
            {
                "value": "甘孜藏族自治州",
                "label": "甘孜藏族自治州",
                "pid": "510000",
                "index": "513300",

            },
            {
                "value": "凉山彝族自治州",
                "label": "凉山彝族自治州",
                "pid": "510000",
                "index": "513400",

            }
        ]
    },
    {
        "value": "贵州省",
        "label": "贵州省",
        "pid": "86",
        "index": "520000",
        "children": [
            {
                "value": "贵阳市",
                "label": "贵阳市",
                "pid": "520000",
                "index": "520100",

            },
            {
                "value": "六盘水市",
                "label": "六盘水市",
                "pid": "520000",
                "index": "520200",
            },
            {
                "value": "遵义市",
                "label": "遵义市",
                "pid": "520000",
                "index": "520300",

            },
            {
                "value": "安顺市",
                "label": "安顺市",
                "pid": "520000",
                "index": "520400",

            },
            {
                "value": "毕节市",
                "label": "毕节市",
                "pid": "520000",
                "index": "520500",

            },
            {
                "value": "铜仁市",
                "label": "铜仁市",
                "pid": "520000",
                "index": "520600",

            },
            {
                "value": "黔西南布依族苗族自治州",
                "label": "黔西南布依族苗族自治州",
                "pid": "520000",
                "index": "522300",

            },
            {
                "value": "黔东南苗族侗族自治州",
                "label": "黔东南苗族侗族自治州",
                "pid": "520000",
                "index": "522600",

            },
            {
                "value": "黔南布依族苗族自治州",
                "label": "黔南布依族苗族自治州",
                "pid": "520000",
                "index": "522700",

            }
        ]
    },
    {
        "value": "云南省",
        "label": "云南省",
        "pid": "86",
        "index": "530000",
        "children": [
            {
                "value": "昆明市",
                "label": "昆明市",
                "pid": "530000",
                "index": "530100",

            },
            {
                "value": "曲靖市",
                "label": "曲靖市",
                "pid": "530000",
                "index": "530300",

            },
            {
                "value": "玉溪市",
                "label": "玉溪市",
                "pid": "530000",
                "index": "530400",

            },
            {
                "value": "保山市",
                "label": "保山市",
                "pid": "530000",
                "index": "530500",

            },
            {
                "value": "昭通市",
                "label": "昭通市",
                "pid": "530000",
                "index": "530600",

            },
            {
                "value": "丽江市",
                "label": "丽江市",
                "pid": "530000",
                "index": "530700",

            },
            {
                "value": "普洱市",
                "label": "普洱市",
                "pid": "530000",
                "index": "530800",

            },
            {
                "value": "临沧市",
                "label": "临沧市",
                "pid": "530000",
                "index": "530900",

            },
            {
                "value": "楚雄彝族自治州",
                "label": "楚雄彝族自治州",
                "pid": "530000",
                "index": "532300",

            },
            {
                "value": "红河哈尼族彝族自治州",
                "label": "红河哈尼族彝族自治州",
                "pid": "530000",
                "index": "532500",

            },
            {
                "value": "文山壮族苗族自治州",
                "label": "文山壮族苗族自治州",
                "pid": "530000",
                "index": "532600",

            },
            {
                "value": "西双版纳傣族自治州",
                "label": "西双版纳傣族自治州",
                "pid": "530000",
                "index": "532800",

            },
            {
                "value": "大理白族自治州",
                "label": "大理白族自治州",
                "pid": "530000",
                "index": "532900",

            },
            {
                "value": "德宏傣族景颇族自治州",
                "label": "德宏傣族景颇族自治州",
                "pid": "530000",
                "index": "533100",

            },
            {
                "value": "怒江傈僳族自治州",
                "label": "怒江傈僳族自治州",
                "pid": "530000",
                "index": "533300",

            },
            {
                "value": "迪庆藏族自治州",
                "label": "迪庆藏族自治州",
                "pid": "530000",
                "index": "533400",

            }
        ]
    },
    {
        "value": "西藏自治区",
        "label": "西藏自治区",
        "pid": "86",
        "index": "540000",
        "children": [
            {
                "value": "拉萨市",
                "label": "拉萨市",
                "pid": "540000",
                "index": "540100",

            },
            {
                "value": "日喀则市",
                "label": "日喀则市",
                "pid": "540000",
                "index": "540200",

            },
            {
                "value": "昌都市",
                "label": "昌都市",
                "pid": "540000",
                "index": "540300",

            },
            {
                "value": "林芝市",
                "label": "林芝市",
                "pid": "540000",
                "index": "540400",

            },
            {
                "value": "山南市",
                "label": "山南市",
                "pid": "540000",
                "index": "540500",

            },
            {
                "value": "那曲市",
                "label": "那曲市",
                "pid": "540000",
                "index": "540600",

            },
            {
                "value": "阿里地区",
                "label": "阿里地区",
                "pid": "540000",
                "index": "542500",

            }
        ]
    },
    {
        "value": "陕西省",
        "label": "陕西省",
        "pid": "86",
        "index": "610000",
        "children": [
            {
                "value": "西安市",
                "label": "西安市",
                "pid": "610000",
                "index": "610100",

            },
            {
                "value": "铜川市",
                "label": "铜川市",
                "pid": "610000",
                "index": "610200",

            },
            {
                "value": "宝鸡市",
                "label": "宝鸡市",
                "pid": "610000",
                "index": "610300",

            },
            {
                "value": "咸阳市",
                "label": "咸阳市",
                "pid": "610000",
                "index": "610400",

            },
            {
                "value": "渭南市",
                "label": "渭南市",
                "pid": "610000",
                "index": "610500",

            },
            {
                "value": "延安市",
                "label": "延安市",
                "pid": "610000",
                "index": "610600",

            },
            {
                "value": "汉中市",
                "label": "汉中市",
                "pid": "610000",
                "index": "610700",

            },
            {
                "value": "安康市",
                "label": "安康市",
                "pid": "610000",
                "index": "610900",

            },
            {
                "value": "商洛市",
                "label": "商洛市",
                "pid": "610000",
                "index": "611000",

            }
        ]
    },
    {
        "value": "甘肃省",
        "label": "甘肃省",
        "pid": "86",
        "index": "620000",
        "children": [
            {
                "value": "兰州市",
                "label": "兰州市",
                "pid": "620000",
                "index": "620100",

            },
            {
                "value": "嘉峪关市",
                "label": "嘉峪关市",
                "pid": "620000",
                "index": "620200",
                "children": []
            },
            {
                "value": "金昌市",
                "label": "金昌市",
                "pid": "620000",
                "index": "620300",

            },
            {
                "value": "白银市",
                "label": "白银市",
                "pid": "620000",
                "index": "620400",

            },
            {
                "value": "天水市",
                "label": "天水市",
                "pid": "620000",
                "index": "620500",

            },
            {
                "value": "武威市",
                "label": "武威市",
                "pid": "620000",
                "index": "620600",

            },
            {
                "value": "张掖市",
                "label": "张掖市",
                "pid": "620000",
                "index": "620700",

            },
            {
                "value": "平凉市",
                "label": "平凉市",
                "pid": "620000",
                "index": "620800",

            },
            {
                "value": "酒泉市",
                "label": "酒泉市",
                "pid": "620000",
                "index": "620900",

            },
            {
                "value": "庆阳市",
                "label": "庆阳市",
                "pid": "620000",
                "index": "621000",

            },
            {
                "value": "定西市",
                "label": "定西市",
                "pid": "620000",
                "index": "621100",

            },
            {
                "value": "陇南市",
                "label": "陇南市",
                "pid": "620000",
                "index": "621200",

            },
            {
                "value": "临夏回族自治州",
                "label": "临夏回族自治州",
                "pid": "620000",
                "index": "622900",

            },
            {
                "value": "甘南藏族自治州",
                "label": "甘南藏族自治州",
                "pid": "620000",
                "index": "623000",

            }
        ]
    },
    {
        "value": "青海省",
        "label": "青海省",
        "pid": "86",
        "index": "630000",
        "children": [
            {
                "value": "西宁市",
                "label": "西宁市",
                "pid": "630000",
                "index": "630100",

            },
            {
                "value": "海东市",
                "label": "海东市",
                "pid": "630000",
                "index": "630200",

            },
            {
                "value": "海北藏族自治州",
                "label": "海北藏族自治州",
                "pid": "630000",
                "index": "632200",

            },
            {
                "value": "黄南藏族自治州",
                "label": "黄南藏族自治州",
                "pid": "630000",
                "index": "632300",

            },
            {
                "value": "海南藏族自治州",
                "label": "海南藏族自治州",
                "pid": "630000",
                "index": "632500",

            },
            {
                "value": "果洛藏族自治州",
                "label": "果洛藏族自治州",
                "pid": "630000",
                "index": "632600",

            },
            {
                "value": "玉树藏族自治州",
                "label": "玉树藏族自治州",
                "pid": "630000",
                "index": "632700",

            },
            {
                "value": "海西蒙古族藏族自治州",
                "label": "海西蒙古族藏族自治州",
                "pid": "630000",
                "index": "632800",

            }
        ]
    },
    {
        "value": "宁夏回族自治区",
        "label": "宁夏回族自治区",
        "pid": "86",
        "index": "640000",
        "children": [
            {
                "value": "银川市",
                "label": "银川市",
                "pid": "640000",
                "index": "640100",

            },
            {
                "value": "石嘴山市",
                "label": "石嘴山市",
                "pid": "640000",
                "index": "640200",

            },
            {
                "value": "吴忠市",
                "label": "吴忠市",
                "pid": "640000",
                "index": "640300",

            },
            {
                "value": "固原市",
                "label": "固原市",
                "pid": "640000",
                "index": "640400",

            },
            {
                "value": "中卫市",
                "label": "中卫市",
                "pid": "640000",
                "index": "640500",

            }
        ]
    },
    {
        "value": "新疆维吾尔自治区",
        "label": "新疆维吾尔自治区",
        "pid": "86",
        "index": "650000",
        "children": [
            {
                "value": "乌鲁木齐市",
                "label": "乌鲁木齐市",
                "pid": "650000",
                "index": "650100",

            },
            {
                "value": "克拉玛依市",
                "label": "克拉玛依市",
                "pid": "650000",
                "index": "650200",

            },
            {
                "value": "吐鲁番市",
                "label": "吐鲁番市",
                "pid": "650000",
                "index": "650400",

            },
            {
                "value": "昌吉回族自治州",
                "label": "昌吉回族自治州",
                "pid": "650000",
                "index": "652300",

            },
            {
                "value": "博尔塔拉蒙古自治州",
                "label": "博尔塔拉蒙古自治州",
                "pid": "650000",
                "index": "652700",

            },
            {
                "value": "巴音郭楞蒙古自治州",
                "label": "巴音郭楞蒙古自治州",
                "pid": "650000",
                "index": "652800",

            },
            {
                "value": "阿克苏地区",
                "label": "阿克苏地区",
                "pid": "650000",
                "index": "652900",

            },
            {
                "value": "克孜勒苏柯尔克孜自治州",
                "label": "克孜勒苏柯尔克孜自治州",
                "pid": "650000",
                "index": "653000",
            },
            {
                "value": "喀什地区",
                "label": "喀什地区",
                "pid": "650000",
                "index": "653100",
            },
            {
                "value": "和田地区",
                "label": "和田地区",
                "pid": "650000",
                "index": "653200",
            },
            {
                "value": "伊犁哈萨克自治州",
                "label": "伊犁哈萨克自治州",
                "pid": "650000",
                "index": "654000",
            },
            {
                "value": "塔城地区",
                "label": "塔城地区",
                "pid": "650000",
                "index": "654200",
            },
            {
                "value": "阿勒泰地区",
                "label": "阿勒泰地区",
                "pid": "650000",
                "index": "654300",
            },
            {
                "value": "自治区直辖县级行政区划",
                "label": "自治区直辖县级行政区划",
                "pid": "650000",
                "index": "659000",
            }
        ]
    },
    // {
    //     "value": "台湾省",
    //     "label": "台湾省",
    //     "pid": "86",
    //     "index": "710000",
    //     "children": [
    //         {
    //             "value": "台北市",
    //             "label": "台北市",
    //             "pid": "710000",
    //             "index": "710100",
    //         },
    //         {
    //             "value": "高雄市",
    //             "label": "高雄市",
    //             "pid": "710000",
    //             "index": "710200",
    //         },
    //         {
    //             "value": "基隆市",
    //             "label": "基隆市",
    //             "pid": "710000",
    //             "index": "710300",
    //         },
    //         {
    //             "value": "台中市",
    //             "label": "台中市",
    //             "pid": "710000",
    //             "index": "710400",

    //         },
    //         {
    //             "value": "台南市",
    //             "label": "台南市",
    //             "pid": "710000",
    //             "index": "710500",
    //         },
    //         {
    //             "value": "新竹市",
    //             "label": "新竹市",
    //             "pid": "710000",
    //             "index": "710600",
    //         },
    //         {
    //             "value": "嘉义市",
    //             "label": "嘉义市",
    //             "pid": "710000",
    //             "index": "710700",
    //         }
    //     ]
    // },
    // {
    //     "value": "香港特别行政区",
    //     "label": "香港特别行政区",
    //     "pid": "86",
    //     "index": "810000",
    // },
    // {
    //     "value": "澳门特别行政区",
    //     "label": "澳门特别行政区",
    //     "pid": "86",
    //     "index": "820000",
    // }
]
