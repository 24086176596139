
import { ref, defineComponent, toRaw, toRef, reactive, UnwrapRef, watch, onMounted } from "vue";
import { RuleObject, ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import { ExpressOrderData, Option, ParamEstimateFreight, AddressItem } from '@/interface/print'
import { filterArea, _area } from '@/components/_util/Area'
import { queryEstimateFreight } from '@/api/manage'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: "ExpressOrder",
  props: {
    expressChecked: Boolean,
    address: {
      type: Array,
      default: () => ([])
    }
  },
  emits: ['changeChecked'],
  setup(props, context) {
    const expressChecked = toRef(props, 'expressChecked')
    const address = toRef(props, 'address')
    const addressValue = ref()
    
    const valueArea = ref([])
    // const options = ref<any>(inject('$pcaa'))
    const pcaaData = _area
    
    let validatePhone = async (rule: RuleObject, value: string) => {
      if (value === '') {
        return Promise.reject('请输入联系电话');
      } else {
        return Promise.resolve();
      }
    };
    const rules = {
      receivePhone: [{ required: true, message: "请输入联系电话", validator: validatePhone, trigger: 'change' }],
      receiveName: [{ required: true, message: "请输入收件人姓名" }],
      receiveAddress: [{ required: true, message: "请输入详细地址" }],
      valueArea: [{ required: true, message: "请选择省市区" }],
    };
    const formState: UnwrapRef<ExpressOrderData> = reactive({
      receivePhone: '',
      receiveName: '',
      expressType: '1',
      receiveAddress: '',
      valueArea: [],
      payType: 2,
      text: ''
    });
    // const filter = ;
    const filter = (inputValue: string, path: Option[]) => {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }
    const onChange = (e: any) => {
      formState.valueArea = e
      if (e[0] === '四川省' || e[0] === '重庆市') {
        formState.expressType = '2'
      }
    }
    const changeAddress = (e: any) => {
      console.log('changeAddress-----', e)
      const count: any = address.value[e]
      formState.receivePhone = count.receiverMobile || ''
      formState.receiveName = count.receiver || ''
      formState.receiveAddress = count.receiverAddress || ''
      formState.text = count.contactInfoDetail || ''
      if (count.receiverProvince && count.receiverCity) {
        if (count.receiverDistrict) {
          formState.valueArea = [count.receiverProvince, count.receiverCity, count.receiverDistrict]
        } else {
          formState.valueArea = [count.receiverProvince, count.receiverCity, count.town]
        }
      }
      if (count.receiverProvince == '四川省' || count.receiverProvince == '重庆市') {
        formState.expressType = '2'
      }
    }
    const changeChecked = (e: any) => {
      context.emit('changeChecked', e.target.checked)
    }
    const formRef = ref()
    const onSubmit = (cb: any) => {
      formRef.value.validate().then(async () => {
        // 后端查询快递费用
        // const { expressType, receiveAddress, receiveName, receivePhone, valueArea } = formState
        // const destAddress = valueArea.join('') + receiveAddress
        // let paramsEstimate: ParamEstimateFreight = {
        //   // 快递产品类型，例如：1：表示顺丰特快，2顺丰标快
        //   expressType: expressType,
        //   destAddress: destAddress,
        //   destProvince: valueArea[0],
        //   destCity: valueArea[1],
        //   destDistrict: valueArea[2],
        //   srcAddress: '四川省成都市武侯区武兴二路',
        //   srcCity: '成都市',
        //   srcDistrict: '武侯区',
        //   srcProvince: '四川省',
        //   volumn: '0.1,0.1,0.1',
        //   weight: '0.1',
        // }
        // const estimate = await queryEstimateFreight(paramsEstimate)
        typeof cb === 'function' && cb(true)
      }).catch((error: ValidateErrorEntity<ExpressOrderData>) => {
        typeof cb === 'function' && cb(false)
        console.log("error", error);
      });
      
    }
    watch(
      () => address.value,
      (count: any, prevCount) => {
        if (count.length) {
          addressValue.value = 0
          formState.receivePhone = count[0].receiverMobile || ''
          formState.receiveName = count[0].receiver || ''
          formState.receiveAddress = count[0].receiverAddress || ''
          formState.text = count[0].contactInfoDetail || ''
          if (count[0].receiverProvince && count[0].receiverCity) {
            if (count[0].receiverDistrict) {
              formState.valueArea = [count[0].receiverProvince, count[0].receiverCity, count[0].receiverDistrict]
            } else {
              formState.valueArea = [count[0].receiverProvince, count[0].receiverCity, count[0].town]
            }
          }
          if (count[0].receiverProvince == '四川省' || count[0].receiverProvince == '重庆市') {
            formState.expressType = '2'
          }
        }
        
      },
      {
        deep: true,
        immediate: true
      }
    )
    const router = useRouter();
    onMounted(() => {
      const take = router.currentRoute.value.query.takeMode
      if (take) {
        if (take === 'JSXJ') {
          formState.payType = 2
        } else if (take === 'JSDF') {
          formState.payType = 3
        } else if (take === 'ZQ') {
          context.emit('changeChecked', false)
        }
      }
    })
    return {
      formRef,
      rules,
      formState,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      filter,
      onChange,
      valueArea,
      address,
      pcaaData,
      addressValue,
      onSubmit,
      expressChecked,
      changeChecked,
      changeAddress
    };
  },
});
