
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
// import { Moment } from "moment";
import { ref, defineComponent, toRaw, toRef, watch,onMounted } from "vue";
import { message as Message } from 'ant-design-vue'
interface FormState {
  dmCopies: number;
  dmPaperSize: string;
  dmColor: string;
  jpPageRange: string | undefined;
  dmOrientation: string;
  dmDuplex: string;
  isDoublePrint:any;
  printNumbers:any;

}
export default defineComponent({
  name: "PrintModel",
  props: {
    contract: Object,
    printPrice: Object,
  },
  emits: ["handleClick", 'setTotalPayable'],
  setup(props, context) {
    const fatherState = toRef(props, 'contract')
    const printPrice = toRef(props, 'printPrice')
    const formState = ref()
    const start = ref(1)
    const finish = ref(2)
    const jpPageRange = ref('')
    formState.value = Object.assign({}, fatherState.value)
    if (Number(fatherState.value.printNumbers)) {
      formState.value.printNumbers = Number(fatherState.value.printNumbers)
    } else {
      formState.value.printNumbers = null
    }
    // formState.value.dmCopies = null
    const formRef = ref();

    const onSubmit = (cb: any): void => {
      // console.log("submit!", formState, toRaw(formState));
      formRef.value
        .validate()
        .then(() => {
          context.emit('handleClick', toRaw(formState))
          console.log("submit!", formState, toRaw(formState));
          typeof cb === 'function' && cb(true)
        })
        .catch((error) => {
          console.log("error------ValidateErrorEntity", error);
          Message.warning('请填写完整必要的参数！')
        });
    };
    const resetForm = (): void => {
        formRef.value.resetFields();
    };
    const calculateAndSave = (params: any): object => {
      const obj: any = Object.assign({}, params)
      let amountPayable: number = 0 // 价格
      let realPages: number = 0 // 最终打印纸张数量
      // let jpPageRange: number = 0
      let jpTotalPage: number = obj.jpTotalPage || 0 // 总页数
      let pageRange: string = jpPageRange.value || '' // 打印范围
      let dmDuplex: string = obj.dmDuplex || '' // 单双面打印
      let dmColor: string = obj.dmColor || '' // 打印颜色/彩色&黑白
     
      // 计算打印页数
      switch (pageRange) {
        case '-1':
          realPages = jpTotalPage % 2 == 0 ? Math.floor(jpTotalPage / 2) : Math.floor(jpTotalPage / 2) + 1;
          break;
        case '-2':
          realPages = Math.floor(jpTotalPage / 2);
          break;
        case '1':
          if (start.value === finish.value) {
            realPages = 1
          } else {
            realPages = Math.abs(finish.value - start.value) + 1
          }
          break;
        default:
          realPages = jpTotalPage;
          break;
      }
      // 计算打印色彩与单双面
      // 单面
      if (dmDuplex === '1') {
        // 黑白
        if (dmColor === '1') {
          // 黑白单面
          amountPayable = realPages * printPrice.value.colorlessPrice
        } else {
          // 彩色单面
          amountPayable = realPages * printPrice.value.colorPrice
        }
      } else {
        // 双面打印
        let pagerNum: number = realPages % 2 == 0 ? Math.floor(realPages / 2) : Math.floor(realPages / 2) + 1;
        // 黑白
        if (dmColor === '1') {
          // 黑白双面
          // if (jpTotalPage == 1) {
          //   amountPayable = pagerNum * printPrice.value.colorlessDoublePrice / 2
          // } else {
            amountPayable = pagerNum * printPrice.value.colorlessDoublePrice
          // }
        } else {
          // 彩色双面
          // if (jpTotalPage == 1) {
          //   amountPayable = pagerNum * printPrice.value.colorDoublePrice / 2
          // } else {
            amountPayable = pagerNum * printPrice.value.colorDoublePrice
          // }
        }
      }
      obj.amountPayable = obj.dmCopies * amountPayable
      return obj
    };
     onMounted(() => {
        console.log(formState.value,'fatherState');
        console.log(printPrice.value,'printPrice');
        
        // console.log(repositories,'isrepositoriesrepositoriesrepositoriesrepositoriesDoublePrint');
        
        
      })
      //选择打印色彩
      function onchangeRadio(e){
      formState.value.dmColor=e.target.value
      }
    const setPageRange = () => {
      // 自定义打印范围
      let arr: number[] = []
      let realPages: number = 1
      if (start.value < finish.value) {
        realPages = Math.abs(finish.value - start.value)
        for (let index = 0; index <= realPages; index++) {
          let conut = index + start.value
          if (conut <= finish.value) {
            arr.push(conut)
          }
          formState.value.jpPageRange = arr.join(',')
        }
      } else if (start.value > finish.value) {
        realPages = Math.abs(finish.value - start.value)
        for (let index = 0; index <= realPages; index++) {
          let conut = index + finish.value
          if (conut <= start.value) {
            arr.push(conut)
          }
          formState.value.jpPageRange = arr.join(',')
        }
      } else {
        realPages = start.value
        formState.value.jpPageRange = String(realPages)
      }
    }
    // 修改打印范围
    const changePageRange = (e: string | ''): void => {
      jpPageRange.value = e
      if (e === '1') {
        setPageRange()
      } else {
        formState.value.jpPageRange = e
      }
    };
    function setEditPrintItem(params: any): void {
      const data: any = calculateAndSave(params)
      context.emit('setTotalPayable', data)
    }
    const rules = {
      dmCopies: [{ required: true, message: "请输入正确份数" }],
    };
    watch(
      () => formState.value,
      (count, prevCount) => {
        setEditPrintItem(toRaw(count))
        if (count.dmPaperSize == '8') {
          if (count.dmColor != '1') {
            formState.value.dmColor = '1'
          }
          if (count.dmDuplex != '1') {
            formState.value.dmDuplex = '1'
          }
        }
      }, { deep: true }
    )
    watch(
      () => start.value,
      (count, prevCount) => {
        setPageRange()
        setEditPrintItem(formState.value)
      }
    )
    watch(
      () => finish.value,
      (count, prevCount) => {
        setPageRange()
        setEditPrintItem(formState.value)
      }
    )
    
    return {
      formRef,
      other: "",
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formState,
      onSubmit,
      resetForm,
      fatherState,
      printPrice,
      rules,
      changePageRange,
      jpPageRange,
      start,
      finish,
      onchangeRadio,
    };
  },
});
