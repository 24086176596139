
import { 
  defineComponent, 
  ref, 
  toRef,
  reactive,
} from 'vue'
import { expressOrderGetRoute } from '@/api/manage'
import { CheckCircleOutlined } from '@ant-design/icons-vue';
import { message as Message } from 'ant-design-vue'

export default defineComponent({
  name: 'LogisticsModal',
  props: {
    
  },
  components: {
    CheckCircleOutlined
  },
  setup(props, context) {
    const loadingMore = ref(false)
    const confirmLoading = ref(false)
    const state = reactive({
      logistics: {}
    })
    function cancel() {

    }
    async function getLogistics(params: any) {
      loadingMore.value = true
      confirmLoading.value = true
      const res = await expressOrderGetRoute(params)
      if (res[0].success) {
        state.logistics = res[0]
      } else {
        Message.warning('获取物流信息失败：' + res[0].msg)
      }
      
      confirmLoading.value = false
    }
    return {
      cancel,
      getLogistics,
      loadingMore,
      confirmLoading,
      state
    };
  },
});
