export const pastTime = () => {
  return 60*60*12*1000
}
export const isEmptyObj = (obj: object) => {
  Object.keys(obj).length === 0
}
export const returnQuery = (code: any) => {
  const str = unescape(window.atob(code))
  const obj = {}
  const arr = str.split('&')
  arr.forEach(item => {
    const query = item.split('=')
    obj[query[0]] = query[1]
  })
  
  if (Object.keys(obj).length === 0) {
    return null
  }
  return obj
}
